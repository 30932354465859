import React from 'react';
import RevoxLogo from '../assets/REVOX-logo.svg';
import TruAudio from '../assets/truaudio_logo.svg';
import BowerLogo from '../assets/BW.svg';
import PMCLogo from '../assets/PMCLogo.svg';
import SonusLogo from '../assets/SonusFaber.svg';
import SonosLogo from '../assets/sonos-logo.svg';
import ArcamLogo from '../assets/arcamLogo.svg';
import AudioControlLogo from '../assets/audioControlLogo.jpg';
import BenqLogo from '../assets/benqBlack.png';
import MarantzLogo from '../assets/marantz-logo.svg';
import YamahaBlack from '../assets/yamahaBlack.png';
import RotelLogo from '../assets/rotelLogo.png';
import JVCLogo from '../assets/jvcBlack.png';
import SonyLogo from '../assets/sonyBlack.png';
import EliteScreens from '../assets/eliteScreensLogo.png';
import ScreenResearch from '../assets/screenResearchBlack.png';
import BoseLogo from '../assets/boseLogo.jpg';
import lutronBlack from '../assets/lutronLogoBlack.png';

const Brands = () => {
  const [isMobile, setIsMobile] = React.useState(
    window.matchMedia('(max-width: 768px)').matches
  );

  React.useEffect(() => {
    if (document.getElementById('nav')) {
      document.getElementById('nav').style.display = 'flex';
    }
  }, []);

  if (!isMobile) {
    return (
      <div style={{ position: 'absolute', top: '80px', width: '100vw' }}>
        <h1
          style={{
            textAlign: 'center',
            fontFamily: 'lato',
            color: 'black',
            marginTop: '40px',
            marginBottom: '30px',
            fontWeight: '700',
          }}
        >
          Our Brands
        </h1>
        <div style={{ display: 'flex', marginTop: '10px' }}>
          <img
            src={lutronBlack}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '150px',
              paddingBottom: '10px',
              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential?active=homeDecoration')
            }
          />
          <img
            src={RevoxLogo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '150px',
              paddingBottom: '10px',
              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential/audio/speakers/revox')
            }
          />
          <img
            src={SonusLogo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '150px',
              paddingBottom: '10px',

              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential/audio/speakers/sonus')
            }
          />
          <img
            src={TruAudio}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '150px',
              paddingBottom: '10px',

              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential/audio/speakers/truAudio')
            }
          />
          <img
            src={BowerLogo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '150px',
              paddingBottom: '10px',

              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential/audio/speakers/bowers')
            }
          />
        </div>
        <div style={{ display: 'flex', marginTop: '30px' }}>
          <img
            src={PMCLogo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '150px',
              paddingBottom: '10px',

              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location = '/#/solutions/residential/audio/speakers/pmc')
            }
          />
          <img
            src={SonosLogo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '150px',
              paddingBottom: '-70px',

              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential/audio/speakers/sonos')
            }
          />
          <img
            src={ArcamLogo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '150px',
              paddingBottom: '10px',
              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential/audio/amplifiers/arcam')
            }
          />
          <img
            src={MarantzLogo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '150px',
              paddingBottom: '10px',

              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential/audio/amplifiers/marantz')
            }
          />
          <img
            src={AudioControlLogo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '150px',
              paddingBottom: '10px',

              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential/audio/amplifiers/audioControl')
            }
          />
        </div>
        <div style={{ display: 'flex', marginTop: '30px' }}>
          <img
            src={YamahaBlack}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '150px',
              paddingBottom: '10px',

              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential/audio/amplifiers/yamaha')
            }
          />
          <img
            src={RotelLogo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '150px',
              paddingBottom: '10px',

              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential/audio/amplifiers/rotel')
            }
          />
          <img
            src={BenqLogo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '150px',
              paddingBottom: '10px',
              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential/audio/projectors/benq')
            }
          />
          <img
            src={JVCLogo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '150px',
              paddingBottom: '10px',

              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential/audio/projectors/jvc')
            }
          />
          <img
            src={SonyLogo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '150px',
              paddingBottom: '10px',

              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential/audio/projectors/sony')
            }
          />
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: '30px',
            width: '60vw',
            margin: 'auto',
          }}
        >
          <img
            src={EliteScreens}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '150px',
              paddingBottom: '10px',

              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location = '/#/solutions/residential/audio/screens/elite')
            }
          />
          <img
            src={ScreenResearch}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '150px',
              paddingBottom: '10px',

              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential/audio/screens/research')
            }
          />
          <img
            src={BoseLogo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '90px',
              paddingBottom: '10px',
              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location = '/#/solutions/commercial?active=audioVisual')
            }
          />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <h1
          style={{
            textAlign: 'center',
            fontFamily: 'lato',
            color: 'black',
            marginTop: '-140px',
            marginBottom: '50px',
          }}
        >
          Brands
        </h1>
        <div style={{ display: 'flex', marginTop: '30px' }}>
          <img
            src={lutronBlack}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '100px',
              paddingBottom: '10px',
              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential?active=homeDecoration')
            }
          />
          <img
            src={RevoxLogo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '100px',
              paddingBottom: '10px',
              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential/audio/speakers/revox')
            }
          />
        </div>
        <div style={{ display: 'flex', marginTop: '30px' }}>
          <img
            src={TruAudio}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '100px',
              paddingBottom: '10px',
              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential/audio/speakers/truAudio')
            }
          />
          <img
            src={BowerLogo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '100px',
              paddingBottom: '10px',

              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential/audio/speakers/bowers')
            }
          />
        </div>
        <div style={{ display: 'flex', marginTop: '30px' }}>
          <img
            src={PMCLogo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '100px',
              paddingBottom: '10px',
              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location = '/#/solutions/residential/audio/speakers/pmc')
            }
          />
          <img
            src={SonosLogo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '100px',
              paddingBottom: '10px',

              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential/audio/speakers/sonos')
            }
          />
        </div>
        <div style={{ display: 'flex', marginTop: '30px' }}>
          <img
            src={ArcamLogo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '100px',
              paddingBottom: '10px',
              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential/audio/amplifiers/arcam')
            }
          />
          <img
            src={MarantzLogo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '100px',
              paddingBottom: '10px',

              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential/audio/amplifiers/marantz')
            }
          />
        </div>
        <div style={{ display: 'flex', marginTop: '30px' }}>
          <img
            src={AudioControlLogo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '100px',
              paddingBottom: '10px',
              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential/audio/amplifiers/audioControl')
            }
          />
          <img
            src={YamahaBlack}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '100px',
              paddingBottom: '10px',

              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential/audio/amplifiers/rotel')
            }
          />
        </div>
        <div style={{ display: 'flex', marginTop: '30px' }}>
          <img
            src={RotelLogo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '100px',
              paddingBottom: '10px',
              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential/audio/amplifiers/rotel')
            }
          />
          <img
            src={BenqLogo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '100px',
              paddingBottom: '10px',

              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential/audio/projectors/benq')
            }
          />
        </div>
        <div style={{ display: 'flex', marginTop: '30px' }}>
          <img
            src={JVCLogo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '100px',
              paddingBottom: '10px',
              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential/audio/projectors/jvc')
            }
          />
          <img
            src={SonyLogo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '100px',
              paddingBottom: '10px',

              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential/audio/projectors/sony')
            }
          />
        </div>
        <div style={{ display: 'flex', marginTop: '30px' }}>
          <img
            src={EliteScreens}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '100px',
              paddingBottom: '10px',
              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location = '/#/solutions/residential/audio/screens/elite')
            }
          />
          <img
            src={ScreenResearch}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '100px',
              paddingBottom: '10px',

              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential/audio/screens/research')
            }
          />
        </div>
        <div style={{ display: 'flex', marginTop: '30px' }}>
          <img
            src={BoseLogo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '100px',
              paddingBottom: '10px',
              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              window.open('/#/solutions/commercial?active=audioVisual')
            }
          />
          <img
            src={SonusLogo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '100px',
              paddingBottom: '10px',

              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
            onMouseEnter={(e) => {
              e.target.style.borderBottom = '2px solid black';
            }}
            onMouseLeave={(e) => {
              e.target.style.borderBottom = 'none';
            }}
            onClick={() =>
              (window.location =
                '/#/solutions/residential/audio/speakers/sonus')
            }
          />
        </div>
      </>
    );
  }
};

export default Brands;
