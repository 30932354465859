import './App.css';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Nav from './components/Nav';
import Home from './components/Home';
import Solutions from './components/solutions/Solutions';
import Speakers from './components/solutions/residential/audio-visual/speakers/Speakers';
import React from 'react';
import Contact from './components/Contact';
import About from './components/About';
import Seperator from './components/Seperator';
import OurProjects from './components/OurProjects';
import Amplifiers from './components/solutions/residential/audio-visual/amplifiers/Amplifiers';
import InTheMedia from './components/InTheMedia';
import Projectors from './components/solutions/residential/audio-visual/projectors/Projectors';
import Screens from './components/solutions/residential/audio-visual/screens/Screens';
import Brands from './components/Brands';

function App() {
  const [isMobile, setIsMobile] = React.useState(
    window.matchMedia('(max-width: 768px)').matches
  );

  return (
    <Router>
      <div>
        <Nav></Nav>

        <Seperator />
        <Routes>
          <Route path='/inTheMedia' element={<InTheMedia />}></Route>
          <Route path='/aboutUs' element={<About />}></Route>
          <Route path='/aboutUs/who-we-are' element={<About />}></Route>
          <Route
            path='/aboutUs/what-we-accomplished'
            element={<About />}
          ></Route>
          <Route path='/aboutUs/experience' element={<About />}></Route>

          <Route
            path='/solutions/residential/audio/speakers/revox'
            element={<Speakers />}
          ></Route>
          <Route
            path='/solutions/residential/audio/speakers/sonus'
            element={<Speakers />}
          ></Route>
          <Route
            path='/solutions/residential/audio/speakers/truAudio'
            element={<Speakers />}
          ></Route>
          <Route
            path='/solutions/residential/audio/speakers/bowers'
            element={<Speakers />}
          ></Route>

          <Route
            path='/solutions/residential/audio/speakers/pmc'
            element={<Speakers />}
          ></Route>
          <Route
            path='/solutions/residential/audio/speakers/sonos'
            element={<Speakers />}
          ></Route>

          <Route
            path='/solutions/residential/audio/amplifiers/arcam'
            element={<Amplifiers />}
          ></Route>
          <Route
            path='/solutions/residential/audio/amplifiers/marantz'
            element={<Amplifiers />}
          ></Route>

          <Route
            path='/solutions/residential/audio/amplifiers/yamaha'
            element={<Amplifiers />}
          ></Route>
          <Route
            path='/solutions/residential/audio/amplifiers/audioControl'
            element={<Amplifiers />}
          ></Route>
          <Route
            path='/solutions/residential/audio/amplifiers/rotel'
            element={<Amplifiers />}
          ></Route>

          <Route
            path='/solutions/residential/audio/projectors/benq'
            element={<Projectors />}
          ></Route>
          <Route
            path='/solutions/residential/audio/projectors/sony'
            element={<Projectors />}
          ></Route>
          <Route
            path='/solutions/residential/audio/projectors/jvc'
            element={<Projectors />}
          ></Route>
          <Route
            path='/solutions/residential/audio/screens/elite'
            element={<Screens />}
          ></Route>
          <Route
            path='/solutions/residential/audio/screens/research'
            element={<Screens />}
          ></Route>
          <Route path='/projects' element={<OurProjects />}></Route>

          <Route path='/solutions/commercial' element={<Solutions />}></Route>

          <Route path='/solutions/residential' element={<Solutions />}></Route>
          <Route path='/contact' element={<Contact />}></Route>
          <Route path='/home' element={<Home />}></Route>
          <Route path='/brands' element={<Brands />}></Route>

          <Route path='/' element={<Home />}></Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
