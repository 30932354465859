import React from 'react';
import logo from '../../../assets/Logo.png';
import background from '../../../assets/commercialAutomationBg.jpg';
import { createStyles, makeStyles } from '@mui/styles';
import lutronLogo from '../../../assets/lutron-logo.svg';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import logoBlack from '../../../assets/Logo Black.png';
import lutronBlack from '../../../assets/lutron-black.svg';
import homeAutoBackground from '../../../assets/homeAutomation-bg.svg';
import radioRarBg from '../../../assets/radio-ra3-bg.svg';
import { Link } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import commercialBg from '../../../assets/commercialBg.jpg';
import rightEnvironment from '../../../assets/rightEnvironmentImage.png';
import turntableCommercial from '../../../assets/tunableWhite.png';

const CommercialAutomation = () => {
  const [isVeryBigScreen, setIsVeryBigScreen] = React.useState(
    window.matchMedia('(min-width: 2000px)').matches
  );
  const [collection, setCollection] = React.useState(false);

  const [isSmallScreen, setIsSmallScreen] = React.useState(
    window.matchMedia('(max-width: 1300px)').matches
  );

  const [collectionCategory, setCollectionCategory] =
    React.useState('homeWorks');

  const [isVerySmallScreen, setIsVerySmallScreen] = React.useState(
    window.matchMedia('(max-width: 1100px)').matches
  );

  const [pcScreen, setPcScreen] = React.useState(
    window.matchMedia('(min-width: 1900px)').matches
  );

  React.useEffect(() => {
    if (document.getElementById('nav')) {
      document.getElementById('nav').style.display = 'none';
    }
  }, []);

  const [homeAutoScreen, setHomeAutoScreen] = React.useState(
    window.matchMedia('(min-width: 1500px)').matches
  );

  const [residentialDropDown, setResidentialDropDown] = React.useState(false);

  const [isMobile, setIsMobile] = React.useState(
    window.matchMedia('(max-width: 768px)').matches
  );

  const [homeWorksCategory, setHomeWorksCategory] = React.useState('lighting');

  const [radioAboutOpen, setRadioAboutOpen] = React.useState(false);

  const useStyles = makeStyles(() =>
    createStyles({
      background: {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100vw',
        zIndex: '-2',
        objectFit: 'cover',
        // height: '100vh',
      },
      subHeading: {
        color: 'white',
        fontFamily: 'lato',
        fontWeight: '700',
        textAlign: 'center',
        marginTop: !isMobile ? '8vh' : '-100px',
      },
      bottom: {
        width: '100vw',
        height: '35vh',
        position: 'fixed',
        bottom: '0',
        background: 'rgba(0, 0, 0, 0.5)',
        overflow: 'auto',
      },
      smallBg: {
        position: 'fixed',
        width: '100vw',
        top: !isMobile ? '130px' : '0',
        zIndex: '-2',
        display: collectionCategory === '' ? 'block' : 'none',
        height: isMobile ? '100vh' : 'auto',
        objectFit: 'cover',
      },
    })
  );
  const classes = useStyles();

  React.useEffect(() => {
    if (document.getElementById('underline-bottom-nav')) {
      if (homeWorksCategory === 'lighting') {
        document.getElementById('underline-bottom-nav').style.marginLeft =
          '-50.8%';
        document.getElementById('lighting-nav').style.opacity = '100%';
        document.getElementById('underline-bottom-nav').style.width =
          !isVeryBigScreen ? '58px' : '90px';
      } else if (homeWorksCategory === 'shades') {
        document.getElementById('underline-bottom-nav').style.marginLeft =
          !isVeryBigScreen ? '-33%' : '-32.4%';
        document.getElementById('shades-nav').style.opacity = '100%';

        document.getElementById('underline-bottom-nav').style.width =
          !isVeryBigScreen ? '58px' : '90px';
      } else if (homeWorksCategory === 'temperature') {
        document.getElementById('underline-bottom-nav').style.marginLeft =
          !isVeryBigScreen ? '-13.5%' : '-12.5%';
        document.getElementById('temperature-nav').style.opacity = '100%';

        document.getElementById('underline-bottom-nav').style.width =
          !isVeryBigScreen ? '85px' : '135px';
      } else if (homeWorksCategory === 'audio') {
        document.getElementById('underline-bottom-nav').style.marginLeft =
          !isVeryBigScreen ? '9.5%' : '9.5%';
        document.getElementById('audio-nav').style.opacity = '100%';

        document.getElementById('underline-bottom-nav').style.width =
          !isVeryBigScreen ? '85px' : '135px';
      } else if (homeWorksCategory === 'works-with') {
        document.getElementById('underline-bottom-nav').style.marginLeft =
          '49.2%';
        document.getElementById('works-with-nav').style.opacity = '100%';

        document.getElementById('underline-bottom-nav').style.width =
          !isVeryBigScreen ? '85px' : '135px';
      } else if (homeWorksCategory === 'energy') {
        document.getElementById('underline-bottom-nav').style.marginLeft =
          '29.4%';
        document.getElementById('energy-nav').style.opacity = '100%';

        document.getElementById('underline-bottom-nav').style.width =
          !isVeryBigScreen ? '58px' : '90px';
      } else {
        document.getElementById('underline-bottom-nav').style.marginLeft =
          '-50.8%';
        document.getElementById('lighting-nav').style.opacity = '100%';
        document.getElementById('underline-bottom-nav').style.width =
          !isVeryBigScreen ? '58px' : '90px';
      }
    }
  });
  React.useEffect(() => {
    if (collectionCategory !== 'homeWorks') {
      setHomeWorksCategory('lighting');
    }
  }, [collectionCategory]);

  return (
    <div>
      {!isMobile ? (
        <div
          className='topNav'
          style={{
            display: 'flex',
            width: '100vw',
            height: !isVeryBigScreen ? '80px' : '170px',
            justifyContent: 'center',
            marginTop: '-260px',
            background: !collection ? 'transparent' : 'white',
            top: '0',
            zIndex: '7',
          }}
        >
          <img
            src={!collection ? logo : logoBlack}
            alt='..'
            style={
              !isVeryBigScreen
                ? {
                    display: 'block',
                    margin: 'auto',
                    marginLeft: '3vw',
                    width: !collection ? '220px' : '250px',
                    marginTop: !collection ? 'auto' : '30px',
                    cursor: 'pointer',
                  }
                : {
                    display: 'block',
                    margin: 'auto',
                    marginLeft: '1vw',
                    width: '15vw',
                    cursor: 'pointer',
                  }
            }
            onClick={() => (window.location = '/')}
          />
          <div
            className='right'
            style={{
              display: 'flex',
              marginLeft: 'auto',
              marginRight: '40px',
              height: '100%',
              justifyContent: 'center',
            }}
          >
            <h5
              style={{
                fontFamily: 'lato',
                fontWeight: '700',
                margin: 'auto',
              }}
            >
              <span
                style={{
                  color: !collection
                    ? 'rgb(220, 220, 220)'
                    : 'rgb(140, 140, 140)',
                  fontSize: !isMobile
                    ? !isVeryBigScreen
                      ? '1em'
                      : '1.8em'
                    : '0.6em',
                  cursor: 'pointer',
                  marginLeft: !isMobile ? '20px' : '-1vw',
                  opacity: '70%',
                }}
                id='back'
                onClick={() => (window.location = '/')}
                onMouseEnter={(e) => {
                  e.target.style.opacity = '100%';
                  document.getElementById('home').style.opacity = '100%';
                }}
                onMouseLeave={(e) => {
                  e.target.style.opacity = '70%';
                  document.getElementById('home').style.opacity = '70%';
                }}
              >
                &lt;
              </span>
              <span
                style={{
                  color: !collection
                    ? 'rgb(220, 220, 220)'
                    : 'rgb(140, 140, 140)',
                  fontSize: !isMobile
                    ? !isVeryBigScreen
                      ? '1em'
                      : '1.8em'
                    : '0.6em',
                  cursor: 'pointer',
                  marginLeft: '10px',
                  opacity: '70%',
                }}
                id='home'
                onClick={() => (window.location = '/')}
                onMouseEnter={(e) => {
                  e.target.style.opacity = '100%';
                  document.getElementById('back').style.opacity = '100%';
                }}
                onMouseLeave={(e) => {
                  e.target.style.opacity = '70%';
                  document.getElementById('back').style.opacity = '70%';
                }}
              >
                Home
              </span>
              <span
                style={{
                  color: !collection
                    ? 'rgb(220, 220, 220)'
                    : 'rgb(140, 140, 140)',
                  fontSize: !isMobile
                    ? !isVeryBigScreen
                      ? '1em'
                      : '1.8em'
                    : '0.6em',
                  cursor: 'pointer',
                  marginLeft: !isMobile ? '40px' : '2vw',
                }}
                onClick={() => (window.location = '/')}
              >
                |
              </span>
              <span
                style={{
                  marginLeft: !isMobile
                    ? !isVeryBigScreen
                      ? '40px'
                      : '45px'
                    : '3vw',
                  fontSize: !isMobile
                    ? !isVeryBigScreen
                      ? '0.8em'
                      : '1.3em'
                    : '0.6em',
                  opacity: '75%',
                  whiteSpace: 'pre',
                  color: !collection ? 'white' : 'black',
                }}
                onMouseEnter={() => {
                  setResidentialDropDown(true);
                }}
                onMouseLeave={() => {
                  setResidentialDropDown(false);
                }}
              >
                {!isMobile
                  ? 'Solutions    >    Commercial    >   '
                  : 'Solutions > Commercial > '}
              </span>
              <span
                style={{
                  marginLeft: !isMobile
                    ? !isVeryBigScreen
                      ? '30px'
                      : '60px'
                    : '2vw',
                  fontSize: !isMobile
                    ? !isVeryBigScreen
                      ? '1.2em'
                      : '2em'
                    : '0.8em',
                  color: !collection
                    ? 'rgb(220, 220, 220)'
                    : 'rgb(140, 140, 140)',
                }}
              >
                {'Commercial Automation '}
              </span>
              <span
                style={{
                  transform: 'rotate(90deg)',
                  fontSize: !isMobile
                    ? !isVeryBigScreen
                      ? '1.2em'
                      : '2em'
                    : '0.8em',
                  color: !collection
                    ? 'rgb(220, 220, 220)'
                    : 'rgb(140, 140, 140)',
                }}
              >
                &gt;
              </span>
              <Fade in={residentialDropDown}>
                <div
                  style={{
                    display: 'flex',
                    marginLeft: !pcScreen
                      ? !isVerySmallScreen
                        ? '250px'
                        : !isSmallScreen || isVerySmallScreen
                        ? '24vw'
                        : '32vw'
                      : !isVeryBigScreen
                      ? '14vw'
                      : '14vw',
                    height: !isVeryBigScreen ? '120px' : '230px',
                    width: !isVeryBigScreen ? '190px' : '360px',
                    background: 'rgba(0, 0, 0, 0.9)',

                    border: 'none',
                    position: 'absolute',
                    top: !isVeryBigScreen ? '65px' : '100px',
                    border: 'none',
                    borderRadius: 'none',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    zIndex: '9',
                  }}
                  onMouseEnter={() => {
                    setResidentialDropDown(true);
                  }}
                  onMouseLeave={() => {
                    setResidentialDropDown(false);
                  }}
                >
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.7em' : '1.5em',
                      opacity: '70%',
                      color: 'white',
                    }}
                    to='/solutions/commercial?active=audioVisual'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Commercial Audio
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.7em' : '1.5em',
                      opacity: '70%',
                    }}
                    to='/solutions/commercial?active=homeDecoration'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Commercial Automation
                  </Link>
                </div>
              </Fade>
            </h5>
          </div>
        </div>
      ) : (
        ''
      )}
      {!isMobile ? (
        <div
          className='bottom'
          style={{
            width: '75vw',
            minWidth: '600px',
            marginLeft: 'auto',
            marginRight: '0',
            display: collection ? 'flex' : 'none',
            justifyContent: 'center',
            // background: 'white',
            height: '70px',
            marginTop: '-20px',
            marginBottom: '-20px',
            zIndex: '7',
          }}
        >
          <p
            style={{
              color: 'black',
              opacity: homeWorksCategory === 'lighting' ? '70%' : '50%',
              display: 'block',
              margin: 'auto',
              fontFamily: 'lato',
              cursor: 'pointer',
              fontWeight: '700',
              fontSize: !isMobile
                ? !isVeryBigScreen
                  ? '1em'
                  : '1.8em'
                : '0.6em',
            }}
            onMouseEnter={(e) => {
              e.target.style.opacity = '70%';
            }}
            onMouseLeave={(e) => {
              if (homeWorksCategory !== 'lighting') {
                e.target.style.opacity = '50%';
              }
            }}
            onClick={() => setHomeWorksCategory('lighting')}
          >
            Human Centric Lighting
          </p>
          <p
            style={{
              color: 'black',
              opacity: '70%',
              display: 'block',
              margin: 'auto',
              fontFamily: 'lato',
              cursor: 'pointer',
              fontWeight: '700',
              opacity: homeWorksCategory === 'shades' ? '70%' : '50%',
              fontSize: !isMobile
                ? !isVeryBigScreen
                  ? '1em'
                  : '1.8em'
                : '0.6em',
            }}
            onMouseEnter={(e) => {
              e.target.style.opacity = '70%';
            }}
            onMouseLeave={(e) => {
              if (homeWorksCategory !== 'shades') {
                e.target.style.opacity = '50%';
              }
            }}
            onClick={() => setHomeWorksCategory('shades')}
          >
            The Right Environment
          </p>
          <p
            style={{
              color: 'black',
              opacity: '70%',
              display: 'block',
              margin: 'auto',
              fontFamily: 'lato',
              cursor: 'pointer',
              fontWeight: '700',
              opacity: homeWorksCategory === 'temperature' ? '70%' : '50%',
              fontSize: !isMobile
                ? !isVeryBigScreen
                  ? '1em'
                  : '1.8em'
                : '0.6em',
            }}
            onMouseEnter={(e) => {
              e.target.style.opacity = '70%';
            }}
            onMouseLeave={(e) => {
              if (homeWorksCategory !== 'temperature') {
                e.target.style.opacity = '50%';
              }
            }}
            onClick={() => setHomeWorksCategory('temperature')}
          >
            Tunable White & Color Control
          </p>
          <img
            src={lutronBlack}
            alt='..'
            style={{
              display: 'block',
              margin: 'auto',
              width: !isVeryBigScreen ? '220px' : '350px',
            }}
          />
        </div>
      ) : (
        <div
          style={{
            display: collection ? 'flex' : 'none',
            flexDirection: 'column',
            paddingTop: '10px',
            paddingBottom: '0px',
            background: 'rgba(0, 0, 0, 0.7)',
            marginTop: '-160px',
            width: '100vw',
            height: '100px',
            justifyContent: 'center',
          }}
        >
          <div
            className='top'
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '60px',
              marginTop: '-16px',
            }}
          >
            <p
              style={{
                fontFamily: 'lato',
                color: 'black',
                display: 'block',
                margin: 'auto',
                fontSize: '0.9em',
                color: 'white',
              }}
              onClick={() => window.open('/')}
            >
              Home |
            </p>
            <p
              style={{
                fontFamily: 'lato',
                color: 'black',
                display: 'block',
                margin: 'auto',
                fontSize: '0.7em',
                color: 'white',
              }}
            >
              Solutions &gt;
            </p>
            <p
              style={{
                fontFamily: 'lato',
                color: 'black',
                display: 'block',
                margin: 'auto',

                color: 'white',

                fontSize: '0.7em',
              }}
            >
              Residential &gt;
            </p>

            <p
              style={{
                fontFamily: 'lato',
                color: 'black',
                display: 'block',
                margin: 'auto',
                fontSize: '0.9em',
                color: 'white',
                position: 'relative',
                left: '-1%',
              }}
            >
              Home Automation
            </p>
          </div>
          <img
            src={lutronLogo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '120px',
              marginTop: '10px',
            }}
          />
        </div>
      )}

      <Fade in={!collection} unmountOnExit>
        <div>
          <div
            style={{
              width: '100vw',
              height: '100vh',
              position: 'absolute',
              top: '0',
              background: 'rgba(0, 0, 0, 0.4)',
              zIndex: '-1',
            }}
          ></div>
          <img src={background} alt='..' className={classes.background} />

          <h4 className={classes.subHeading}>
            Discover Total Commercial Control
          </h4>
          <h6 className={classes.subHeading} style={{ marginTop: '20px' }}>
            With
          </h6>
          <img
            src={lutronLogo}
            alt='..'
            style={{ display: 'block', margin: 'auto', marginTop: '20px' }}
          />
          <Button
            variant='contained'
            style={{
              color: 'white',
              background: 'rgba(0, 0, 0, 0.7)',
              display: 'block',
              margin: 'auto',
              marginTop: '6vh',
              width: '280px',
              height: '50px',
              fontFamily: 'lato',
              fontWeight: '700',
              border: '1px solid white',
              transitionProperty: 'all',
              transitionDuration: '0.7s',
            }}
            onMouseEnter={(e) => {
              e.target.style.background = 'rgba(0, 0, 0, 0.9)';
            }}
            onMouseLeave={(e) => {
              e.target.style.background = 'rgba(0, 0, 0, 0.7)';
            }}
            onClick={(e) => {
              setCollection(true);
            }}
          >
            View Collection
          </Button>
          <div className={classes.bottom}>
            <h5 className={classes.subHeading} style={{ marginTop: '2%' }}>
              About Lutron
            </h5>
            <h6
              className={classes.subHeading}
              style={{
                width: !isMobile ? '80%' : '95%',
                maxWidth: '900px',
                margin: 'auto',
                marginTop: '2%',
                opacity: '80%',
              }}
            >
              For decades, Lutron has been at the forefront of lighting control
              and automated shades. With distinctly crafted design and
              innovative technology, everything we create enhances the
              atmosphere of a personal space. Today, Lutron channels the power
              of performance and turns technology into a timeless solution for
              the most discerning lifestyles and spaces. Homeworks is the
              illuminating force behind the scene to make personal moments shine
              bright. Always forward thinking, Lutron has added Ketra, the most
              innovative light source in the world, to our offerings. This
              expands our HomeWorks portfolio and allows us to build the
              complete luxury home experience.
            </h6>
          </div>
        </div>
      </Fade>
      <Fade in={collection} unmountOnExit>
        <div>
          <Fade
            in={
              collectionCategory !== 'homeWorks' &&
              collectionCategory !== 'radio-ra3'
            }
            unmountOnExit
          >
            <img
              src={homeAutoBackground}
              alt='..'
              className={classes.smallBg}
            />
          </Fade>
          <Fade in={collectionCategory === 'homeWorks'} unmountOnExit>
            <div
              style={{
                width: '100vw',
                height: '100vh',
                position: 'absolute',
                top: '0',
                zIndex: '-1',
              }}
            >
              <Fade in={homeWorksCategory === 'lighting'} unmountOnExit>
                <div
                  style={{
                    width: '100%',
                    // height: 'vh',
                    paddingBottom: '90px',
                  }}
                >
                  <img
                    src={commercialBg}
                    alt=''
                    style={{
                      display: 'block',
                      width: '100%',
                      position: 'absolute',
                      top: !isVeryBigScreen
                        ? !isMobile
                          ? '124px'
                          : '79px'
                        : '200px',
                      zIndex: '-1',
                      objectFit: 'cover',
                      height: isMobile ? '114vh' : '83vh',
                    }}
                  />
                  <div
                    style={{
                      display: 'block',
                      margin: 'auto',
                      marginLeft: !isMobile ? '17vw' : 'auto',
                      width: !isVeryBigScreen
                        ? !isMobile
                          ? '430px'
                          : '90vw'
                        : '530px',
                      height: !isVeryBigScreen
                        ? !isMobile
                          ? '380px'
                          : '440px'
                        : '480px',
                      background: 'rgba(0, 0, 0, 0.3)',
                      marginTop: !isMobile ? '30vh' : '250px',
                      paddingTop: '25px',
                    }}
                  >
                    <h4
                      className={classes.subHeading}
                      style={{
                        textAlign: 'left',
                        marginLeft: '8%',
                        marginTop: '2%',
                        fontSize: !isVeryBigScreen ? '1.6em' : '2.2em',
                      }}
                    >
                      Human Centric Lighting
                    </h4>
                    <h5
                      className={classes.subHeading}
                      style={{
                        textAlign: 'left',
                        marginLeft: '8%',
                        marginTop: '3%',
                        width: '85%',
                        opacity: '70%',
                        fontSize: !isVeryBigScreen ? '1.1em' : '1.6em',
                      }}
                    >
                      Create just the right light for any activity or set moods
                      in specific areas of your home – all at the touch of a
                      button. A dynamic lighting control system that brings the
                      magic of light to elevate everyday moments. Make a design
                      statement with any light fixture you choose – controlled
                      from one system. Nurture a mood in spaces large and small,
                      with tunable white and warm dimming. Use light as an
                      amenity – enhancing the experience in restaurants,
                      galleries, spas, and more. Personalise the moment with
                      user-friendly apps that make it easy to change your
                      lighting scene – and then change it back.
                    </h5>
                  </div>
                </div>
              </Fade>
              <Fade
                unmountOnExit
                in={!isMobile ? homeWorksCategory === 'shades' : true}
              >
                <div
                  style={{
                    width: '100%',
                    paddingBottom: '0px',
                    paddingTop: '30px',
                  }}
                >
                  <img
                    src={rightEnvironment}
                    alt=''
                    style={{
                      display: 'block',
                      width: '100%',
                      position: !isMobile ? 'absolute' : 'absolute',
                      marginTop: isMobile ? '-20px' : 'auto',
                      top: !isVeryBigScreen
                        ? !isMobile
                          ? '124px'
                          : 'auto'
                        : '200px',
                      zIndex: '-1',
                      objectFit: 'cover',
                      height: isMobile ? '109vh' : '83vh',
                    }}
                  />
                  <div
                    style={{
                      display: 'block',
                      margin: 'auto',
                      marginLeft: !isMobile ? '17vw' : 'auto',
                      width: !isVeryBigScreen
                        ? !isMobile
                          ? '430px'
                          : '90vw'
                        : '530px',
                      height: !isVeryBigScreen
                        ? !isMobile
                          ? '350px'
                          : '320px'
                        : '450px',
                      background: 'rgba(0, 0, 0, 0.7)',
                      marginTop: !isMobile ? '30vh' : '16vh',
                      paddingTop: '25px',
                    }}
                  >
                    <h4
                      className={classes.subHeading}
                      style={{
                        textAlign: 'left',
                        marginLeft: '8%',
                        marginTop: '6%',
                        fontSize: !isVeryBigScreen ? '1.6em' : '2.2em',
                      }}
                    >
                      The Right Environment
                    </h4>
                    <h5
                      className={classes.subHeading}
                      style={{
                        textAlign: 'left',
                        marginLeft: '8%',
                        marginTop: '7%',
                        width: '84%',
                        opacity: '70%',
                        fontSize: !isVeryBigScreen ? '1.2em' : '1.7em',
                      }}
                    >
                      Lutron helps you produce the Right Environment with
                      lighting, shade, and temperature controls that adapt to
                      your changing needs. We have a wide range of solutions for
                      your space, your budget, and your performance
                      requirements.
                    </h5>
                  </div>
                </div>
              </Fade>
              <Fade
                in={!isMobile ? homeWorksCategory === 'temperature' : true}
                unmountOnExit
              >
                <div
                  style={{
                    width: '100%',
                    paddingTop: isMobile ? '10%' : 'auto',
                    paddingBottom: isMobile ? '6%' : 'auto',
                    display: !isMobile ? 'flex' : 'block',
                  }}
                >
                  <img
                    src={turntableCommercial}
                    alt=''
                    style={{
                      display: 'block',
                      width: '100%',
                      position: !isMobile ? 'absolute' : 'absolute',

                      // marginTop: isMobile ? '-20px' : 'auto',
                      top: !isVeryBigScreen
                        ? !isMobile
                          ? '124px'
                          : ''
                        : '200px',
                      zIndex: '-1',
                      objectFit: 'cover',
                      height: isMobile ? '470px' : '83vh',
                    }}
                  />
                  <div
                    style={{
                      display: 'block',
                      margin: 'auto',
                      marginLeft: !isMobile ? '7vw' : 'auto',
                      width: !isVeryBigScreen
                        ? !isMobile
                          ? '430px'
                          : '90vw'
                        : '530px',
                      height: !isVeryBigScreen
                        ? !isMobile
                          ? '370px'
                          : '400px'
                        : '450px',
                      background: 'rgba(0, 0, 0, 0.3)',
                      marginTop: !isMobile ? '35vh' : '8vh',
                      paddingTop: !isMobile ? '25px' : '2px',
                    }}
                  >
                    <h4
                      className={classes.subHeading}
                      style={{
                        textAlign: 'left',
                        marginLeft: '8%',
                        marginTop: !isMobile ? '0%' : '7%',
                        fontSize: !isVeryBigScreen ? '1.6em' : '2.2em',
                      }}
                    >
                      Tunable White
                    </h4>
                    <h5
                      className={classes.subHeading}
                      style={{
                        textAlign: 'left',
                        marginLeft: '8%',
                        marginTop: '5%',
                        width: !isMobile ? '75%' : '85%',
                        opacity: '70%',
                        fontSize: !isVeryBigScreen ? '1.2em' : '1.7em',
                      }}
                    >
                      Tunable white LED lighting fixtures enable adjustment of
                      color temperature and intensity. The advancement of LED
                      lighting technology and the proliferation of cost
                      effective LEDs have brought the capability of tunable
                      white lighting into the mainstream of lighting design, and
                      to a much broader customer audience, but this new
                      capability also adds complexity to existing lighting
                      systems, and to lighting designs.
                    </h5>
                  </div>
                  <iframe
                    width={!isMobile ? '560' : '350'}
                    height={!isMobile ? '275' : '300'}
                    src='https://www.youtube.com/embed/IJZ1rmwwzx8'
                    title='YouTube video player'
                    frameborder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowfullscreen
                    style={{
                      marginTop: !isMobile ? '38vh' : '8vh',
                      marginRight: !isMobile ? '10vw' : 'auto',
                      marginLeft: isMobile ? 'auto' : '0',
                      display: 'block',
                    }}
                  ></iframe>
                </div>
              </Fade>
            </div>
          </Fade>
          <Fade in={collectionCategory === 'radio-ra3'} unmountOnExit>
            <div
              style={{
                width: '100vw',
                height: '100vh',
                position: 'absolute',
                top: '0',
                zIndex: '-1',
              }}
            >
              <Modal
                open={radioAboutOpen}
                onClose={() => setRadioAboutOpen(false)}
              >
                <div
                  style={{
                    display: 'block',
                    margin: 'auto',
                    marginTop: '13vh',
                    width: !isMobile ? '650px' : '90vw',
                    height: !isMobile ? '580px' : '40vh',
                    borderRadius: '10px',

                    background: !isMobile
                      ? 'rgba(0, 0, 0, 0.7)'
                      : 'rgba(255, 255, 255, 1)',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: '80%',
                      paddingBottom: '40px',
                      overflow: 'auto',
                    }}
                  >
                    <p
                      style={{
                        marginLeft: '5%',
                        width: '85%',
                        fontFamily: 'lato',
                        color: 'white',
                        marginTop: '5%',
                        position: 'relative',
                        top: '5%',
                        opacity: '70%',
                        fontWeight: !isMobile ? '400' : '700',
                        color: !isMobile ? 'white' : 'black',
                      }}
                    >
                      Enjoy personalised smart lighting and blind control for
                      any home, any budget, with RA2 Select. Added convenience.
                      More comfort. Enhanced peace of mind. Staying in control
                      of your home, even when you're not there.
                    </p>
                    <p
                      style={{
                        marginLeft: '5%',
                        width: '85%',
                        fontFamily: 'lato',
                        color: 'white',
                        marginTop: '5%',
                        position: 'relative',
                        top: '5%',
                        fontWeight: !isMobile ? '400' : '700',
                        opacity: !isMobile ? '70%' : '100%',
                        color: !isMobile ? 'white' : 'black',
                      }}
                    >
                      The RA2 Select main repeater lets you control your lights
                      and blinds on your smartphone or tablet, from anywhere in
                      the world using the free Lutron App. You can also use the
                      versatile Pico remote for control throughout your home.
                      The Pico is available as a keypad, for scene control, or
                      as a remote, for individual light and blind control. The
                      main repeater supports up to 100 devices.
                    </p>
                    <p
                      style={{
                        marginLeft: '5%',
                        width: '85%',
                        fontFamily: 'lato',
                        color: 'white',
                        marginTop: '5%',
                        position: 'relative',
                        top: '5%',
                        opacity: !isMobile ? '70%' : '100%',

                        fontWeight: !isMobile ? '400' : '700',
                        color: !isMobile ? 'white' : 'black',
                      }}
                    >
                      Easy system setup through the Lutron App automatically
                      configures light and blind levels for typical activities.
                      The pre-engraved Pico scene keypad guarantees every scene
                      keypad in the home has an intuitive scene icon, for those
                      pre-set light and blind levels.
                    </p>
                    <p
                      style={{
                        marginLeft: '5%',
                        width: '85%',
                        fontFamily: 'lato',
                        color: 'white',
                        marginTop: '5%',
                        position: 'relative',
                        top: '5%',
                        opacity: !isMobile ? '70%' : '100%',

                        color: !isMobile ? 'white' : 'black',
                        fontWeight: !isMobile ? '400' : '700',
                      }}
                    >
                      Lutron's patented Clear Connect RF technology sets the bar
                      for reliability. You can trust your system will work with
                      precision and accuracy, free from interference with other
                      frequency bands, every time you use it.
                    </p>
                  </div>
                  <div
                    className='bottom'
                    style={{
                      height: '20%',
                      position: 'sticky',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      variant='text'
                      style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: '5%',
                        fontFamily: 'lato',
                        color: 'red',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        border: '1px solid white',
                        padding: '10px',
                        width: !isMobile ? '200px' : '100px',
                        height: !isMobile ? 'auto' : '40px',
                      }}
                      onClick={() => setRadioAboutOpen(false)}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </Modal>
              <img
                src={radioRarBg}
                alt='..'
                className={classes.smallBg}
                style={{
                  display: !isMobile ? 'block' : 'none',
                  width: '100%',
                  position: 'absolute',
                  top: !isVeryBigScreen ? '124px' : '200px',
                  zIndex: '-1',
                  objectFit: 'cover',
                }}
              />
              <div
                style={{
                  display: 'block',
                  margin: 'auto',
                  marginLeft: '0',
                  width: !isVeryBigScreen
                    ? !isMobile
                      ? '580px'
                      : '100vw'
                    : '780px',
                  height: !isVeryBigScreen
                    ? !isMobile
                      ? '500px'
                      : 'auto'
                    : '700px',
                  background: !isMobile ? 'rgba(0, 0, 0, 0.6)' : 'black',
                  marginTop: !isMobile ? '15%' : '220px',
                  paddingTop: '25px',

                  paddingBottom: !isMobile ? 'auto' : '20px',
                }}
              >
                <h4
                  className={classes.subHeading}
                  style={{
                    textAlign: 'left',
                    marginLeft: '8%',
                    marginTop: '1%',
                    fontSize: !isVeryBigScreen ? '1.6em' : '2.2em',
                  }}
                >
                  Radio RA2
                </h4>
                <h5
                  className={classes.subHeading}
                  style={{
                    textAlign: 'left',
                    marginLeft: '8%',
                    marginTop: '7%',
                    width: '85%',
                    opacity: '70%',
                    fontSize: !isVeryBigScreen ? '1.2em' : '1.7em',
                  }}
                >
                  Enjoy personalised smart lighting and blind control for any
                  home, any budget, with RA2 Select. Added convenience. More
                  comfort. Enhanced peace of mind. Staying in control of your
                  home, even when you're not there.
                </h5>
                <h5
                  className={classes.subHeading}
                  style={{
                    textAlign: 'left',
                    marginLeft: '8%',
                    marginTop: '2%',
                    width: '85%',
                    opacity: '70%',
                    fontSize: !isVeryBigScreen ? '1.2em' : '1.7em',
                  }}
                >
                  The RA2 Select main repeater lets you control your lights and
                  blinds on your smartphone or tablet, from anywhere in the
                  world using the free Lutron App. You can also use the
                  versatile Pico remote for control throughout your home. The
                  Pico is available as a keypad, for scene control, or as a
                  remote, for individual light and blind control. The main
                  repeater supports up to 100 devices.
                </h5>

                <Button
                  style={{
                    background: 'rgba(0, 0, 0, 0.6)',
                    marginLeft: '8%',
                    fontFamily: 'lato',
                    color: 'white',
                    border: '1px solid white',
                    width: '200px',
                    height: '55px',
                    marginTop: '4%',
                  }}
                  onClick={() => setRadioAboutOpen(true)}
                >
                  View More
                </Button>
              </div>
            </div>
          </Fade>
        </div>
      </Fade>
    </div>
  );
};

export default CommercialAutomation;
