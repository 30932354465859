import React from 'react';
import { useLocation } from 'react-router-dom';
import AmplifiersNav from './AmplifiersNav';
import Arcam from './brands/Arcam';
import Marantz from './brands/Marantz';
import Yamaha from './brands/Yamaha';
import AudioControl from './brands/AudioControl';
import Rotel from './brands/Rotel';

const Speakers = () => {
  const [brandName, setBrandName] = React.useState('');

  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname.endsWith('/arcam')) {
      setBrandName('arcam');
    } else if (location.pathname.endsWith('/marantz')) {
      setBrandName('marantz');
    } else if (location.pathname.endsWith('/yamaha')) {
      setBrandName('yamaha');
    } else if (location.pathname.endsWith('/audioControl')) {
      setBrandName('audioControl');
    } else if (location.pathname.endsWith('/rotel')) {
      setBrandName('rotel');
    }
  }, [location]);

  React.useEffect(() => {
    if (document.getElementById('nav')) {
      document.getElementById('nav').style.display = 'none';
    }
  });

  return (
    <>
      <AmplifiersNav></AmplifiersNav>
      {brandName === 'arcam' ? (
        <Arcam />
      ) : brandName === 'marantz' ? (
        <Marantz />
      ) : brandName === 'yamaha' ? (
        <Yamaha />
      ) : brandName === 'audioControl' ? (
        <AudioControl />
      ) : brandName === 'rotel' ? (
        <Rotel />
      ) : (
        ''
      )}
    </>
  );
};

export default Speakers;
