import React from 'react';
import RevoxLogo from '../../../../../../assets/marantz-logo.svg';
import Fade from '@mui/material/Fade';
import Grow from '@mui/material/Grow';
import { Fade as Slide } from '@mui/material';
import revoxBg from '../../../../../../assets/maratanz-bg.svg';
import { createStyles, makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import RevoxWhite from '../../../../../../assets/maratanz-logo-tagline.png';
import logo from '../../../../../../assets/Logo Black.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link, useLocation } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import marantzAmplifierBanner from '../../../../../../assets/marantzAmplifiers.jpg';
import amplifiersProduct from '../../../../../../assets/amplifierProduct.png';
import AVRecieversBanner from '../../../../../../assets/marantzAvRecieversBanner.png';
import AVRecieversProduct from '../../../../../../assets/marantzAvRecieversProduct.png';
import turnTableBanner from '../../../../../../assets/marantzTurnTableBanner.png';
import marantzTurnTableProduct from '../../../../../../assets/marantzTurnTableProduct.png';
import networkAudioProduct from '../../../../../../assets/networkAudioProduct.png';
import networkAudioBanner from '../../../../../../assets/networkAudioBanner.png';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Speakers = () => {
  const [isBigScreen, setIsBigScreen] = React.useState(
    window.matchMedia('(min-width: 2000px)').matches
  );
  const [collection, setCollection] = React.useState(false);

  const location = useLocation();

  const query = useQuery();

  React.useEffect(() => {
    console.log(query.get('collection'));

    if (query.get('collection') === 'true') {
      setCollection(true);
    } else {
      setCollection(false);
    }
  }, [location]);

  React.useEffect(() => {
    console.log(query.get('collection'));

    if (query.get('collection') === 'true') {
      setCollection(true);
    } else {
      setCollection(false);
    }
  }, [query]);

  function detectMouseWheelDirection(e) {
    var delta = null,
      direction = false;
    if (!e) {
      // if the event is not provided, we get it from the window object
      e = window.event;
    }
    if (e.wheelDelta) {
      // will work in most cases
      delta = e.wheelDelta / 60;
    } else if (e.detail) {
      // fallback for Firefox
      delta = -e.detail / 2;
    }
    if (delta !== null) {
      direction = delta > 0 ? 'up' : 'down';
    }

    return direction;
  }

  const [smallPhone, setSmallPhone] = React.useState(
    window.matchMedia('(max-width: 420px)').matches
  );

  React.useEffect(() => {
    if (document.getElementById('nav')) {
      document.getElementById('nav').style.display = 'none';
    }
    document.body.style.overflowY = 'visible';
  }, []);

  const [isMobile, setIsMobile] = React.useState(
    window.matchMedia('(max-width: 768px)').matches
  );

  const [showSpeakers, setShowSpeakers] = React.useState(false);

  const [currentColor, setCurrentColor] = React.useState('black');

  const [isVeryBigScreen, setIsVeryBigScreen] = React.useState(
    window.matchMedia('(min-width: 2000px)').matches
  );

  const [isSmallScreen, setIsSmallScreen] = React.useState(
    window.matchMedia('(max-width: 1300px)').matches
  );

  const [isVerySmallScreen, setIsVerySmallScreen] = React.useState(
    window.matchMedia('(max-width: 1200px)').matches
  );
  const [pcScreen, setPcScreen] = React.useState(
    window.matchMedia('(min-width: 1900px)').matches
  );

  const [lastY, setLastY] = React.useState(window.scrollY);

  var currentScrollNumber = 0;

  const [changeEnabled, setChangeEnabled] = React.useState(true);

  var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

  // React.useEffect(() => {
  //   if (!changeEnabled) {
  //     setTimeout(() => {
  //       setChangeEnabled(true);
  //       disableScroll();
  //     }, 100);
  //   }
  // }, [changeEnabled]);

  function preventDefault(e) {
    e.preventDefault();
    e.preventDefault();
    console.log(changeEnabled);

    console.log('scroll number valid: ' + (currentScrollNumber % 15) == 0);
    console.log('scroll number ' + currentScrollNumber);
    if (currentScrollNumber < 7) {
      currentScrollNumber++;
    } else {
      currentScrollNumber = 0;
    }
    if (
      currentScrollNumber == 3 ||
      currentScrollNumber == 7 ||
      currentScrollNumber == 5
    ) {
      if (detectMouseWheelDirection(e) == 'down') {
        if (currentProduct == 'loud-speakers' && changeEnabled) {
          console.log('network');
          setCurrentProduct('network-recievers');
          setChangeEnabled(false);
          return;
        } else if (currentProduct == 'network-recievers' && changeEnabled) {
          console.log('studio');
          setCurrentProduct('studio-art');

          setChangeEnabled(false);
          return;
        } else if (currentProduct == 'studio-art' && changeEnabled) {
          console.log('turntables');
          setCurrentProduct('turntables');
          setChangeEnabled(false);
          return;
        }
      } else {
        if (currentProduct == 'network-recievers' && changeEnabled) {
          console.log('network');

          setCurrentProduct('loud-speakers');
          setChangeEnabled(false);
          return;
        } else if (currentProduct == 'studio-art' && changeEnabled) {
          console.log('network');

          setCurrentProduct('network-recievers');
          setChangeEnabled(false);
          return;
        } else if (currentProduct == 'turntables' && changeEnabled) {
          setCurrentProduct('studio-art');
          setChangeEnabled(false);
          return;
        }
      }
    }
  }

  function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
      preventDefault(e);
      return false;
    }
  }

  const [currentProduct, setCurrentProduct] = React.useState('loud-speakers');
  const [direction, setDirection] = React.useState('up');

  const [ultraWide, setUltraWide] = React.useState(
    window.matchMedia('(min-width: 3000px)').matches
  );

  // modern Chrome requires { passive: false } when adding event
  var supportsPassive = false;
  try {
    window.addEventListener(
      'test',
      null,
      Object.defineProperty({}, 'passive', {
        get: function () {
          supportsPassive = true;
        },
      })
    );
  } catch (e) {}

  const [aboutOpen, setAboutOpen] = React.useState(false);

  var wheelOpt = supportsPassive ? { passive: false } : false;
  var wheelEvent =
    'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

  // call this to Disable
  function disableScroll() {
    if (changeEnabled) {
      if (document.getElementById('carouselExampleControls')) {
        document
          .getElementById('carouselExampleControls')
          .addEventListener('DOMMouseScroll', preventDefault, false); // older FF
        document
          .getElementById('carouselExampleControls')
          .addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
        document
          .getElementById('carouselExampleControls')
          .addEventListener('touchmove', preventDefault, wheelOpt); // mobile
        document
          .getElementById('carouselExampleControls')
          .addEventListener('keydown', preventDefaultForScrollKeys, false);
      }

      const elements = Array.from(document.getElementsByClassName('grow'));

      elements.forEach((element) => {
        element.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
        element.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
        element.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
        element.addEventListener('keydown', preventDefaultForScrollKeys, false);
      });
    }
  }

  React.useEffect(() => {
    // disableScroll();
  }, [currentProduct, changeEnabled]);

  React.useEffect(() => {
    if (collection) {
      if (document.getElementById('speaker-nav')) {
        document.getElementById('speaker-nav').style.display = 'none';
      }
    } else {
      if (document.getElementById('speaker-nav')) {
        document.getElementById('speaker-nav').style.display = 'block';
      }
    }
  }, [collection]);
  React.useEffect(() => {
    if (collection) {
      if (document.getElementById('speaker-nav')) {
        document.getElementById('speaker-nav').style.display = 'none';
      }
    } else {
      if (document.getElementById('speaker-nav')) {
        document.getElementById('speaker-nav').style.display = 'block';
      }
    }
  }, [collection]);

  const useStyles = makeStyles(() =>
    createStyles({
      background: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100vw',
        zIndex: '-3',
        height: '100vh',

        objectFit: 'cover',
      },
      subHeading: {
        color: 'white',
        fontFamily: 'lato',
        fontWeight: '700',
        textAlign: 'center',
        marginTop: '8vh',
      },
      bottom: {
        width: '100vw',
        height: !isMobile ? '30vh' : '27vh',
        position: 'absolute',
        bottom: '0',
        background: 'rgba(0, 0, 0, 0.5)',
        overflow: 'auto',
      },
      smallBg: {
        position: 'fixed',
        width: '100vw',
        top: '130px',
        zIndex: '-1',
        display: 'block',
      },
      select: {
        '&:before': {
          borderColor: 'black',
        },
        '&:after': {
          borderColor: 'black',
        },
        '&:not(.Mui-disabled):hover::before': {
          borderColor: 'black',
        },
        display: 'block',
        margin: 'auto',
      },
      icon: {
        fill: 'black',
      },
      root: {
        color: 'black',
      },
    })
  );

  const classes = useStyles();

  const [brandDropDown, setBrandDropDown] = React.useState(false);

  React.useEffect(() => {
    if (collection) {
      if (document.querySelector('.carousel')) {
        document
          .querySelector('.carousel')
          .addEventListener('slid.bs.carousel', function () {
            if (
              document.querySelector('.active').id === 'loud-speakers' ||
              document.querySelector('.active').id === 'network-recievers' ||
              document.querySelector('.active').id === 'studio-art' ||
              document.querySelector('.active').id === 'network-audio'
            ) {
              setCurrentProduct(document.querySelector('.active').id);
            }
          });
      }
    }
  }, [collection]);
  React.useEffect(() => {
    if (collection) {
      if (currentProduct === 'loud-speakers') {
        document.querySelector('.active').classList.remove('active');
        document.getElementById('loud-speakers').classList.add('active');
      } else if (currentProduct === 'network-recievers') {
        document.querySelector('.active').classList.remove('active');
        document.getElementById('network-recievers').classList.add('active');
      } else if (currentProduct === 'studio-art') {
        document.querySelector('.active').classList.remove('active');
        document.getElementById('studio-art').classList.add('active');
      } else if (currentProduct === 'network-audio') {
        document.querySelector('.active').classList.remove('active');
        document.getElementById('network-audio').classList.add('active');
      }
    }
  }, [currentProduct]);

  const [audioVisualDropDown, setAudioVisualDropDown] = React.useState(false);

  return (
    <div>
      <Fade in={!collection} unmountOnExit>
        <div>
          <img src={revoxBg} alt='..' className={classes.background} />

          <img
            src={RevoxWhite}
            alt='..'
            style={{
              display: 'block',
              margin: 'auto',
              marginTop: !isVeryBigScreen
                ? !isMobile
                  ? '40px'
                  : '20px'
                : '120px',
              background: 'rgba(0, 0, 0, 0.4)',
              padding: '20px',
              borderRadius: '10px',
              width: !isVeryBigScreen ? '270px' : '420px',
            }}
          />

          <Button
            variant='contained'
            style={{
              color: 'white',
              background: 'rgba(0, 0, 0, 0.7)',
              display: 'block',
              margin: 'auto',
              marginTop: !isMobile ? '6vh' : '2vh',
              width: !isVeryBigScreen ? '280px' : '400px',
              height: !isVeryBigScreen ? '50px' : '90px',
              fontFamily: 'lato',
              fontWeight: '700',
              border: '1px solid white',
              fontSize: !isVeryBigScreen ? '1em' : '1.6em',
              transitionProperty: 'all',
              transitionDuration: '0.7s',
            }}
            onMouseEnter={(e) => {
              e.target.style.background = 'rgba(0, 0, 0, 0.9)';
            }}
            onMouseLeave={(e) => {
              e.target.style.background = 'rgba(0, 0, 0, 0.7)';
            }}
            onClick={(e) => {
              setCollection(true);
            }}
          >
            View Collection
          </Button>
          <div className={classes.bottom}>
            <h5
              className={classes.subHeading}
              style={{
                marginTop: '2%',
                fontSize: !isVeryBigScreen ? '1.3em' : '2.7em',
              }}
            >
              About
            </h5>
            <h6
              className={classes.subHeading}
              style={{
                width: !isMobile ? '50%' : '93%',
                maxWidth: '800px',
                margin: 'auto',
                marginTop: '2%',
                opacity: '80%',
                fontSize: !isVeryBigScreen ? '1em' : '1.8em',
              }}
            >
              Saul Marantz, the legend behind our audio experiences, was a true
              pioneer of Hi-Fi. Surrounded by music, Marantz found that a home
              music system that met his exacting standards in the early 1950s
              just did not exist. He envisioned a completely new architectural
              idea for sound reproduction, and employing his skill in electrical
              engineering, built the proof of his concept - the Audio
              Consolette, a Hi-Fi pre-amplifier designed for the home.
            </h6>
          </div>
        </div>
      </Fade>

      <Fade in={collection} unmountOnExit>
        <div>
          <div
            className='topNav'
            style={{
              display: 'flex',
              width: '100vw',
              height: !isVeryBigScreen ? '70px' : '120px',
              justifyContent: 'center',
              marginTop: !isMobile ? '10px' : '-5px',
            }}
          >
            <div
              className='left'
              style={{
                marginRight: 'auto',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: '30px',
                height: '100%',
                display: !isMobile ? 'flex' : 'none',
                justifyContent: 'center',
              }}
            >
              <img
                src={logo}
                alt=''
                style={{
                  display: 'block',
                  margin: 'auto',
                  width: !isVeryBigScreen ? '250px' : '350px',
                  cursor: 'pointer',
                }}
                onClick={() => (window.location = '/')}
              />
            </div>
            <div
              className='left'
              style={{
                display: 'flex',
                marginLeft: 'auto',
                marginRight: !isMobile ? '50px' : 'auto',
                height: '100%',
                justifyContent: 'center',
              }}
            >
              <h5
                style={{
                  fontFamily: 'lato',
                  fontWeight: '700',
                  margin: 'auto',
                }}
              >
                <span
                  style={{
                    color: 'rgb(140, 140, 140)',
                    fontSize: !isMobile
                      ? !isVeryBigScreen
                        ? '1em'
                        : '1.8em'
                      : '0.6em',
                    cursor: 'pointer',
                    marginLeft: !isMobile ? '0px' : '-1.5vw',
                  }}
                  onClick={() => (window.location = '/')}
                >
                  &lt;
                </span>
                <span
                  style={{
                    color: 'rgb(140, 140, 140)',
                    fontSize: !isMobile
                      ? !isVeryBigScreen
                        ? '1em'
                        : '1.8em'
                      : '0.6em',
                    cursor: 'pointer',
                    marginLeft: !isMobile ? '10px' : '1vw',
                  }}
                  onClick={() => (window.location = '/')}
                >
                  Home
                </span>
                <span
                  style={{
                    color: 'rgb(140, 140, 140)',
                    fontSize: !isMobile
                      ? !isVeryBigScreen
                        ? '1em'
                        : '1.8em'
                      : '0.6em',
                    cursor: 'pointer',
                    marginLeft: !isMobile ? '40px' : '2vw',
                  }}
                  onClick={() => (window.location = '/')}
                >
                  |
                </span>
                <span
                  style={{
                    marginLeft: !isMobile
                      ? !isVeryBigScreen
                        ? '40px'
                        : '45px'
                      : '3vw',
                    fontSize: !isMobile
                      ? !isVeryBigScreen
                        ? '0.8em'
                        : '1.3em'
                      : '0.6em',
                    opacity: '70%',
                    whiteSpace: 'pre',
                  }}
                  onMouseEnter={() => {
                    if (isMobile) {
                      setAudioVisualDropDown(true);
                    }
                  }}
                  onMouseLeave={() => {
                    if (isMobile) {
                      setAudioVisualDropDown(false);
                    }
                  }}
                >
                  {!isMobile
                    ? 'Solutions    >    Residential    >    Audio/Visual    >'
                    : 'Solutions > Residential > Audio/Visual >'}
                </span>
                <span
                  style={{
                    marginLeft: !isMobile
                      ? !isVeryBigScreen
                        ? '30px'
                        : '60px'
                      : '1vw',
                    fontSize: !isMobile
                      ? !isVeryBigScreen
                        ? '1.2em'
                        : '2em'
                      : '0.7em',
                  }}
                  onMouseEnter={() => {
                    setAudioVisualDropDown(true);
                  }}
                  onMouseLeave={() => {
                    setAudioVisualDropDown(false);
                  }}
                >
                  {'Amplifiers '}
                </span>
              </h5>
              <Fade in={audioVisualDropDown}>
                <div
                  style={{
                    display: 'flex',
                    marginLeft: !pcScreen
                      ? !isVerySmallScreen
                        ? '500px'
                        : !isSmallScreen || isVerySmallScreen
                        ? !isMobile
                          ? '38.5vw'
                          : '73vw'
                        : '40vw'
                      : !isVeryBigScreen
                      ? '23vw'
                      : !ultraWide
                      ? '28.7vw'
                      : '21.5vw',
                    height: !isVeryBigScreen
                      ? !isMobile
                        ? '220px'
                        : '150px'
                      : '350px',
                    width: !isVeryBigScreen
                      ? !isMobile
                        ? '150px'
                        : '100px'
                      : '320px',
                    background: 'rgba(0, 0, 0, 0.7)',

                    border: 'none',
                    position: 'absolute',
                    top: !isVeryBigScreen
                      ? !isMobile
                        ? '65px'
                        : '123px'
                      : '110px',
                    border: 'none',
                    borderRadius: 'none',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    zIndex: '10',
                  }}
                  onMouseEnter={() => {
                    setAudioVisualDropDown(true);
                  }}
                  onMouseLeave={() => {
                    setAudioVisualDropDown(false);
                  }}
                >
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      color: 'white',
                    }}
                    to='/solutions/residential/audio/speakers/revox'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Speakers
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                    }}
                    to='/solutions/residential/audio/amplifiers/arcam'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Amplifiers
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      opacity: '70%',
                    }}
                    to='/solutions/residential/audio/screens/elite'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Screens
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                    }}
                    to='/solutions/residential/audio/projectors/benq'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Projectors
                  </Link>
                </div>
              </Fade>
            </div>
          </div>
          {!isMobile ? (
            <div
              className='nav2'
              style={{
                display: 'flex',
                height: '60px',
                justifyContent: 'center',
                marginTop: '40px',
                marginBottom: '-20px',
              }}
            >
              <p
                style={{
                  fontFamily: 'lato',
                  color: 'black',
                  opacity: '80%',
                  display: 'block',
                  margin: 'auto',
                  marginRight: 'auto',
                  marginLeft: '10vw',
                  fontWeight: '700',
                  fontSize: !isVeryBigScreen ? '1em' : '1.7em',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setCollection(false);
                  document.getElementById('speaker-nav').style.display =
                    'block';
                }}
              >
                &lt; Back to Audio Visual
              </p>
              <div
                className='right'
                style={{
                  display: 'flex',
                  margin: 'auto',
                  marginLeft: 'auto',
                  marginRight: '10vw',
                  width: '65%',
                  height: '100%',
                  maxWidth: '1200px',
                }}
              >
                <p
                  style={{
                    fontFamily: 'lato',
                    fontWeight: '500',
                    textAlign: 'center',
                    fontSize: !isVeryBigScreen ? '1.1em' : '1.7em',
                    display: 'block',
                    margin: 'auto',
                    textDecoration: 'none',
                    fontWeight: '400',
                    color:
                      currentProduct === 'loud-speakers'
                        ? 'black'
                        : 'rgb(120,120, 120)',
                    cursor: 'pointer',
                  }}
                  onClick={() => setCurrentProduct('loud-speakers')}
                >
                  Amplifiers
                </p>
                <p
                  style={{
                    fontFamily: 'lato',
                    fontWeight: '500',
                    textAlign: 'center',
                    fontSize: !isVeryBigScreen ? '1.1em' : '1.7em',
                    display: 'block',
                    margin: 'auto',
                    fontWeight: '400',

                    color:
                      currentProduct === 'network-recievers'
                        ? 'black'
                        : 'rgb(120,120, 120)',
                    cursor: 'pointer',

                    textDecoration: 'none',
                  }}
                  onClick={() => setCurrentProduct('network-recievers')}
                >
                  AV Recievers
                </p>
                <p
                  style={{
                    fontFamily: 'lato',
                    fontWeight: '500',
                    textAlign: 'center',
                    fontSize: !isVeryBigScreen ? '1.1em' : '1.7em',
                    display: 'block',
                    margin: 'auto',
                    fontWeight: '400',

                    color:
                      currentProduct === 'studio-art'
                        ? 'black'
                        : 'rgb(120,120, 120)',
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                  onClick={() => setCurrentProduct('studio-art')}
                >
                  Turn Tables
                </p>
                <p
                  style={{
                    fontFamily: 'lato',
                    fontWeight: '500',
                    textAlign: 'center',
                    fontSize: !isVeryBigScreen ? '1.1em' : '1.7em',
                    display: 'block',
                    margin: 'auto',
                    fontWeight: '400',

                    color:
                      currentProduct === 'network-audio'
                        ? 'black'
                        : 'rgb(120,120, 120)',
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                  onClick={() => setCurrentProduct('network-audio')}
                >
                  Network Audio Players
                </p>

                <img
                  src={RevoxLogo}
                  alt='..'
                  style={{
                    display: 'block',
                    margin: 'auto',
                    width: !isVeryBigScreen ? '130px' : '170px',
                    marginRight: '0',
                    borderBottom: '1px solid black',
                    paddingBottom: '5px',
                  }}
                  onMouseEnter={() => setBrandDropDown(true)}
                  onMouseLeave={() => setBrandDropDown(false)}
                />
                <ExpandMoreIcon
                  style={{
                    display: 'block',
                    margin: 'auto',
                    marginLeft: '0',
                    marginRight: '0',
                  }}
                ></ExpandMoreIcon>
                <Fade in={brandDropDown}>
                  <div
                    style={{
                      display: 'flex',
                      // marginLeft: '100vw',
                      height: !isVeryBigScreen ? '270px' : '420px',
                      width: !isVeryBigScreen ? '150px' : '320px',
                      background: 'rgba(0, 0, 0, 0.7)',
                      right: '10.5vw',
                      border: 'none',
                      position: 'absolute',
                      top: !isVeryBigScreen ? '170px' : '200px',
                      border: 'none',
                      borderRadius: 'none',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      paddingTop: '20px',
                      paddingBottom: '20px',
                      zIndex: '20',
                    }}
                    onMouseEnter={() => {
                      setBrandDropDown(true);
                    }}
                    onMouseLeave={() => {
                      setBrandDropDown(false);
                    }}
                  >
                    <Link
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        display: 'block',
                        margin: 'auto',
                        textDecoration: 'none',
                        fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                        opacity: '70%',
                        color: 'white',
                        fontWeight: '700',
                      }}
                      to='/solutions/residential/audio/amplifiers/arcam?collection=true'
                      onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                      onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                    >
                      Arcam
                    </Link>
                    <Link
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        display: 'block',
                        margin: 'auto',
                        textDecoration: 'none',
                        fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                        opacity: '70%',
                        fontWeight: '700',
                      }}
                      to='/solutions/residential/audio/amplifiers/marantz?collection=true'
                      onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                      onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                    >
                      Marantz
                    </Link>
                    <Link
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        display: 'block',
                        margin: 'auto',
                        textDecoration: 'none',
                        fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                        opacity: '70%',
                        opacity: '70%',
                        fontWeight: '700',
                      }}
                      to='/solutions/residential/audio/amplifiers/yamaha?collection=true'
                      onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                      onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                    >
                      Yamaha
                    </Link>
                    <Link
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        display: 'block',
                        margin: 'auto',
                        textDecoration: 'none',
                        fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                        opacity: '70%',
                        fontWeight: '700',
                      }}
                      to='/solutions/residential/audio/amplifiers/audioControl?collection=true'
                      onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                      onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                    >
                      Audio Control
                    </Link>

                    <Link
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        display: 'block',
                        margin: 'auto',
                        textDecoration: 'none',
                        fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                        opacity: '70%',
                        fontWeight: '700',
                      }}
                      to='/solutions/residential/audio/amplifiers/rotel?collection=true'
                      onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                      onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                    >
                      Rotel
                    </Link>
                  </div>
                </Fade>
              </div>
            </div>
          ) : (
            <div>
              <p
                style={{
                  fontFamily: 'lato',
                  color: 'black',
                  opacity: '70%',
                  fontSize: !isMobile
                    ? !isVeryBigScreen
                      ? '1.2em'
                      : '2em'
                    : '1em',
                  textAlign: 'center',
                  fontWeight: '700',
                  marginTop: '-5px',
                  marginBottom: '15px',
                }}
                onClick={() => setCollection(false)}
              >
                &lt; Back to Audio Visual
              </p>
              <div style={{ display: 'flex' }}>
                <img
                  src={RevoxLogo}
                  alt='..'
                  style={{
                    display: 'block',
                    margin: 'auto',
                    width: !isVeryBigScreen ? '130px' : '170px',
                    // marginRight: '0',
                    borderBottom: '1px solid black',
                    paddingBottom: '5px',
                  }}
                  onClick={() => setBrandDropDown(true)}
                  onMouseLeave={() => setBrandDropDown(false)}
                />
              </div>
              <Fade in={brandDropDown}>
                <div
                  style={{
                    display: 'flex',
                    // marginLeft: '100vw',
                    height: !isVeryBigScreen ? '270px' : '420px',
                    width: !isVeryBigScreen ? '150px' : '320px',
                    background: 'rgba(0, 0, 0, 0.7)',
                    // right: '10.5vw',
                    border: 'none',
                    // position: 'absolute',
                    // top: !isVeryBigScreen ? '170px' : '200px',
                    border: 'none',
                    borderRadius: 'none',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    zIndex: '20',
                    marginLeft: '30vw',
                    position: 'absolute',
                  }}
                  onMouseEnter={() => {
                    setBrandDropDown(true);
                  }}
                  onMouseLeave={() => {
                    setBrandDropDown(false);
                  }}
                >
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      fontWeight: '700',
                    }}
                    to='/solutions/residential/audio/amplifiers/arcam?collection=true'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Arcam
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      opacity: '70%',
                      fontWeight: '700',
                    }}
                    to='/solutions/residential/audio/amplifiers/marantz?collection=true'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Marantz
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      fontWeight: '700',
                    }}
                    to='/solutions/residential/audio/amplifiers/yamaha?collection=true'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Yamaha
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      fontWeight: '700',
                    }}
                    to='/solutions/residential/audio/amplifiers/audioControl?collection=true'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Audio Control
                  </Link>

                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      fontWeight: '700',
                    }}
                    to='/solutions/residential/audio/amplifiers/rotel?collection=true'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Rotel
                  </Link>
                </div>
              </Fade>
              <FormControl
                style={{
                  width: '200px',
                  display: 'block',
                  margin: 'auto',
                  marginTop: '20px',
                  color: 'black',
                }}
              >
                <InputLabel id='demo-simple-select-label'>Product</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={currentProduct}
                  label='Age'
                  onChange={(e) => {
                    setCurrentProduct(e.target.value);
                  }}
                  className={classes.select}
                  inputProps={{
                    classes: {
                      icon: classes.icon,
                      root: classes.root,
                    },
                  }}
                  style={{ color: 'black', display: 'block', margin: 'auto' }}
                >
                  <MenuItem value={'loud-speakers'}>Amplifiers</MenuItem>
                  <MenuItem value={'network-recievers'}>AV Recievers</MenuItem>
                  <MenuItem value={'studio-art'}>Turn Tables</MenuItem>
                  <MenuItem value={'network-audio'}>
                    Network Audio Player
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          )}

          <Slide in={true} direction={direction} unmountOnExit>
            <div>
              <div
                className='part1'
                style={{ marginTop: '4vh', width: '100vw' }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '30px',
                    width: !isMobile ? '80vw' : '95vw',
                    height: !isMobile ? '80vh' : smallPhone ? '45vh' : '75vh',
                    width: '100vw',
                  }}
                >
                  <button
                    className='carousel-control-prev'
                    type='button'
                    data-bs-target='#carouselExampleControls'
                    data-bs-slide='prev'
                    style={{
                      color: 'black',
                      width: '80px',
                      height: '60px',
                      background: 'rgb(0, 0, 0)',
                      position: 'static',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginLeft: '0',
                      display: isMobile ? 'none' : 'inline-block',
                    }}
                  >
                    <span
                      className='carousel-control-prev-icon'
                      aria-hidden='true'
                      style={{ color: 'black' }}
                    ></span>
                    <span
                      className='visually-hidden secondary'
                      style={{ color: 'black' }}
                    >
                      Previous
                    </span>
                  </button>

                  <div
                    id='carouselExampleControls'
                    className='carousel slide'
                    data-interval='false'
                    onMouseEnter={(e) => {
                      setShowSpeakers(true);
                      console.log('mouse enter');
                    }}
                    onClick={(e) => {
                      setShowSpeakers(true);
                    }}
                    style={{
                      zIndex: '-1',
                      borderRadius: 'none',
                      width: !isMobile ? '80vw' : '95vw',
                      height: !isMobile ? '80vh' : smallPhone ? '45vh' : '75vh',
                      touchAction: 'none',
                      zIndex: '9',
                      marginLeft: '5px',
                      marginRight: '5px',
                      maxHeight: !isMobile ? 'none' : '250px',
                    }}
                    onMouseLeave={() => {
                      setShowSpeakers(false);
                      console.log('mouse leave');
                    }}
                  >
                    <div
                      className='carousel-inner'
                      style={{
                        height: !isMobile
                          ? '80vh'
                          : smallPhone
                          ? '45vh'
                          : '75vh',
                        marginBottom: '20px',
                        maxHeight: !isMobile ? 'none' : '250px',
                      }}
                    >
                      <div
                        className={`carousel-item active 1`}
                        id='loud-speakers'
                        style={{ width: '100%', height: '100%' }}
                      >
                        <Modal
                          open={
                            aboutOpen &&
                            (document.querySelector('.active').classList
                              .value === 'carousel-item active 1' ||
                              document.querySelector('.active').classList
                                .value === 'carousel-item 1 active')
                          }
                          onClose={() => setAboutOpen(false)}
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                Exquisite, perfectly pure power for connecting
                                sources and driving loudspeakers. Amplifiers are
                                the beating heart of a Hi-Fi system. Get the
                                most musical sound possible from the Model 30
                                amplifier with its custom designed HDAM that
                                delivers exquisite detail and unforgettable
                                musicality from any style of music. Features:
                                designed to last, tuned with passion, EISA Best
                                Product Award 2021-2022 in the stereo system
                                category, fully discreet and accurate detailing,
                                extraordinary sensitivity.
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              Amplifiers
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <img
                                  src={amplifiersProduct}
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '70%' : '45%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: '-23%',
                                    marginRight: '-23%',

                                    margin: 'auto',
                                  }}
                                  alt='..'
                                />
                              </div>

                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '15%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                Amplifiers
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '6%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  width: '80%',
                                  opacity: '70%',

                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.4em'
                                      : !isVerySmallScreen
                                      ? '1.2em'
                                      : '1em'
                                    : '1.8em',
                                }}
                              >
                                Exquisite, perfectly pure power for connecting
                                sources and driving loudspeakers. Amplifiers are
                                the beating heart of a Hi-Fi system. Get the
                                most musical sound possible from the Model 30
                                amplifier with its custom designed HDAM that
                                delivers exquisite detail and unforgettable
                                musicality from any style of music. Features:
                                designed to last, tuned with passion, EISA Best
                                Product Award 2021-2022 in the stereo system
                                category, fully discreet and accurate detailing,
                                extraordinary sensitivity.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={marantzAmplifierBanner}
                          alt='...'
                          style={{
                            width: '100%',
                            height: !isMobile ? '100%' : '100%',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                        <h1
                          style={{
                            background: 'rgba(0, 0, 0, 0.7)',
                            fontFamily: 'coldiac',
                            color: 'white',
                            paddingLeft: '10px',
                            paddingRight: '20px',
                            fontWeight: '400',
                            marginRight: 'auto',
                            marginLeft: '0',
                            marginBottom: '100%',
                            marginTop: '0',
                            position: 'absolute',
                            top: '0',
                            paddingTop: '15px',
                            paddingBottom: '15px',
                          }}
                        >
                          Amplifiers
                        </h1>
                      </div>
                      <div
                        className={`carousel-item 2`}
                        id='network-recievers'
                        style={{ width: '100%', height: '100%' }}
                      >
                        <Modal
                          open={
                            aboutOpen &&
                            (document.querySelector('.active').classList
                              .value === 'carousel-item active 2' ||
                              document.querySelector('.active').classList
                                .value === 'carousel-item 2 active')
                          }
                          onClose={() => setAboutOpen(false)}
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                AV Receivers are the master and commander of a
                                home theater. Use an AVR to connect devices to
                                your TV and feel every moment of excitement and
                                subtlety. The latest content exquisitely brought
                                to life. Deliver exquisitely detailed home
                                cinema with the Marantz SR8015 11.2 channel 8K
                                AV receiver and enjoy the most musical sound in
                                all of your entertainment. Features: 8K or
                                4K/120Hz for the latest entertainment needs,
                                premium power performing amplification, most
                                advanced object-based audio formats, complete
                                audio immersion.
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              AV Recievers
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <img
                                  src={AVRecieversProduct}
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '70%' : '45%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: '-23%',
                                    marginRight: '-23%',

                                    margin: 'auto',
                                  }}
                                  alt='..'
                                />
                              </div>

                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '10%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                AV Recievers
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '6%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  width: '80%',
                                  opacity: '70%',

                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.4em'
                                      : !isVerySmallScreen
                                      ? '1.2em'
                                      : '1em'
                                    : '1.8em',
                                }}
                              >
                                AV Receivers are the master and commander of a
                                home theater. Use an AVR to connect devices to
                                your TV and feel every moment of excitement and
                                subtlety. The latest content exquisitely brought
                                to life. Deliver exquisitely detailed home
                                cinema with the Marantz SR8015 11.2 channel 8K
                                AV receiver and enjoy the most musical sound in
                                all of your entertainment. Features: 8K or
                                4K/120Hz for the latest entertainment needs,
                                premium power performing amplification, most
                                advanced object-based audio formats, complete
                                audio immersion.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={AVRecieversBanner}
                          alt='...'
                          style={{
                            width: '100%',
                            height: !isMobile ? '100%' : '100%',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                        <h1
                          style={{
                            background: 'rgba(0, 0, 0, 0.7)',
                            fontFamily: 'coldiac',
                            color: 'white',
                            paddingLeft: '10px',
                            paddingRight: '20px',
                            fontWeight: '400',
                            marginRight: 'auto',
                            marginLeft: '0',
                            marginBottom: '100%',
                            marginTop: '0',
                            position: 'absolute',
                            top: '0',
                            paddingTop: '15px',
                            paddingBottom: '15px',
                          }}
                        >
                          AV Recievers
                        </h1>
                      </div>
                      <div
                        className={`carousel-item 3`}
                        id='studio-art'
                        style={{ width: '100%', height: '100%' }}
                      >
                        <Modal
                          open={
                            aboutOpen &&
                            (document.querySelector('.active').classList
                              .value === 'carousel-item active 3' ||
                              document.querySelector('.active').classList
                                .value === 'carousel-item 3 active')
                          }
                          onClose={() => setAboutOpen(false)}
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                Products for the everyday audiophile offer the
                                outstanding Marantz sound quality you've come to
                                expect. Pegged as the premier “bang for your
                                buck” product for the mid-level audiophile, the
                                TT-15S1 belt drive turntable can produce sounds
                                that similar products at more luxury pricing can
                                produce. Features: floating motors,
                                low-resonance, asynchronous, belt drive system.
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              Turn Table
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <img
                                  src={marantzTurnTableProduct}
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '70%' : '45%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: '-23%',
                                    marginRight: '-23%',

                                    margin: 'auto',
                                  }}
                                  alt='..'
                                />
                              </div>

                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '15%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                Turn Tables
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '6%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  width: '80%',
                                  opacity: '70%',

                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.4em'
                                      : !isVerySmallScreen
                                      ? '1.2em'
                                      : '1em'
                                    : '1.8em',
                                }}
                              >
                                Products for the everyday audiophile offer the
                                outstanding Marantz sound quality you've come to
                                expect. Pegged as the premier “bang for your
                                buck” product for the mid-level audiophile, the
                                TT-15S1 belt drive turntable can produce sounds
                                that similar products at more luxury pricing can
                                produce. Features: floating motors,
                                low-resonance, asynchronous, belt drive system.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={turnTableBanner}
                          alt='...'
                          style={{
                            width: '100%',
                            height: !isMobile ? '100%' : '100%',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                        <h1
                          style={{
                            background: 'rgba(0, 0, 0, 0.7)',
                            fontFamily: 'coldiac',
                            color: 'white',
                            paddingLeft: '10px',
                            paddingRight: '20px',
                            fontWeight: '400',
                            marginRight: 'auto',
                            marginLeft: '0',
                            marginBottom: '100%',
                            marginTop: '0',
                            position: 'absolute',
                            top: '0',
                            paddingTop: '15px',
                            paddingBottom: '15px',
                          }}
                        >
                          Turn Tables
                        </h1>
                      </div>
                      <div
                        className={`carousel-item 4`}
                        id='network-audio'
                        style={{ width: '100%', height: '100%' }}
                      >
                        <Modal
                          open={
                            aboutOpen &&
                            (document.querySelector('.active').classList
                              .value === 'carousel-item active 4' ||
                              document.querySelector('.active').classList
                                .value === 'carousel-item 4 active')
                          }
                          onClose={() => setAboutOpen(false)}
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                Peerless Marantz streaming - from music servers
                                or from the cloud. Crafted to deliver the
                                quality and sound you expect from Marantz. The
                                M-CR 612 is designed for those spaces that
                                demand all Marantz offers in a single unit. A
                                rich sound combined with many source options
                                make this a great Marantz starter package.
                                Features: power and connectivity to suit your
                                audio preference, features a CD drive and AM/FM
                                tuner, stream music from your favorite sources,
                                voice control capability available.
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              Network Audio Players
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <img
                                  src={networkAudioProduct}
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '70%' : '45%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: '-23%',
                                    marginRight: '-23%',

                                    margin: 'auto',
                                  }}
                                  alt='..'
                                />
                              </div>

                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '12%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                Network Audio Players
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '6%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  width: '80%',
                                  opacity: '70%',

                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.4em'
                                      : !isVerySmallScreen
                                      ? '1.2em'
                                      : '1em'
                                    : '1.8em',
                                }}
                              >
                                Peerless Marantz streaming - from music servers
                                or from the cloud. Crafted to deliver the
                                quality and sound you expect from Marantz. The
                                M-CR 612 is designed for those spaces that
                                demand all Marantz offers in a single unit. A
                                rich sound combined with many source options
                                make this a great Marantz starter package.
                                Features: power and connectivity to suit your
                                audio preference, features a CD drive and AM/FM
                                tuner, stream music from your favorite sources,
                                voice control capability available.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={networkAudioBanner}
                          alt='...'
                          style={{
                            width: '100%',
                            height: !isMobile ? '100%' : '100%',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                        <h1
                          style={{
                            background: 'rgba(0, 0, 0, 0.7)',
                            fontFamily: 'coldiac',
                            color: 'white',
                            paddingLeft: '10px',
                            paddingRight: '20px',
                            fontWeight: '400',
                            marginRight: 'auto',
                            marginLeft: '0',
                            marginBottom: '100%',
                            marginTop: '0',
                            position: 'absolute',
                            top: '0',
                            paddingTop: '15px',
                            paddingBottom: '15px',
                          }}
                        >
                          Network Audio Players
                        </h1>
                      </div>
                    </div>

                    <button
                      class='carousel-control-prev'
                      type='button'
                      data-bs-target='#carouselExampleControls'
                      data-bs-slide='prev'
                      style={{ display: !isMobile ? 'none' : 'block' }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.cancelBubble = true;
                        if (e.stopPropagation) e.stopPropagation();
                        console.log('hoi');
                        setShowSpeakers(false);
                      }}
                    >
                      <span
                        class='carousel-control-prev-icon'
                        aria-hidden='true'
                      ></span>
                      <span class='visually-hidden'>Previous</span>
                    </button>
                    <button
                      class='carousel-control-next'
                      type='button'
                      data-bs-target='#carouselExampleControls'
                      data-bs-slide='next'
                      style={{ display: !isMobile ? 'none' : 'block' }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.cancelBubble = true;
                        if (e.stopPropagation) e.stopPropagation();
                        console.log('hoi');
                        setShowSpeakers(false);
                      }}
                    >
                      <span
                        class='carousel-control-next-icon'
                        aria-hidden='true'
                      ></span>
                      <span class='visually-hidden'>Next</span>
                    </button>
                  </div>
                  <button
                    className='carousel-control-next'
                    type='button'
                    data-bs-target='#carouselExampleControls'
                    data-bs-slide='next'
                    style={{
                      color: 'black',
                      width: '80px',
                      height: '60px',
                      background: 'rgb(0, 0, 0)',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      position: 'static',

                      display: isMobile ? 'none' : 'inline-block',
                    }}
                  >
                    <span
                      className='carousel-control-next-icon'
                      aria-hidden='true'
                    ></span>
                    <span className='visually-hidden'>Next</span>
                  </button>
                </div>
              </div>
            </div>
          </Slide>

          <div
            style={{
              width: '100vw',
              height: '70px',
              display: 'flex',
              justifyContent: 'center',
              background: 'rgb(35, 35, 35)',

              marginTop: '50px',
            }}
          >
            <p
              style={{
                margin: 'auto',
                marginLeft: '3%',
                fontFamily: 'lato',
                fontWeight: '700',
                fontSize: '0.7em',
                color: 'white',
              }}
            >
              © 2022 Thar Technologies
            </p>
            <p
              style={{
                margin: 'auto',
                marginRight: '3%',
                fontFamily: 'lato',
                fontWeight: '700',
                fontSize: !isBigScreen
                  ? !isMobile
                    ? '0.9em'
                    : '0.5em'
                  : '1.6em',
                color: 'white',
                opacity: '70%',
              }}
            >
              Designed & Developed by Workplay Studio Pvt. Ltd.
            </p>
          </div>
        </div>
      </Fade>
      {/* {disableScroll()} */}
    </div>
  );
};

export default Speakers;
