import React from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import bg from '../assets/our-projects-bg.svg';
const OurProjects = () => {
  const [clients, setClients] = React.useState([
    {
      name: 'Mr. Shibulal',
      address: 'Brunton road',
      solution: 'Lutron Homeworks QS lighting and shades solution',
    },
    {
      name: 'Mr. Rehman Khan',
      address: 'Hsr Layout, MP',
      solution: 'Lighting and shades solution',
    },
    {
      name: 'Mr. Rakshit Shetty ',
      address: 'Turahalli Foresy ',
      solution: 'Lutron Homeworks QS lighting and shades solution',
    },
    {
      name: 'Mrc Builders',
      address: 'Banashankari',
      solution: 'Lutron Homeworks QS lighting and shades solution',
    },
    {
      name: 'Prestige Golfshire',
      address: 'Near Nandi Hills',
      solution:
        'Lutron Homeworks QS lighting and shades control, Multi room audio & Home cinema.',
    },
    {
      name: 'Bowring Institute',
      address: 'St. Marks Road',
      solution:
        'Lutron Quantum lighting management system & Bose professional audio solution for indoor and outdoor.',
    },
    {
      name: 'Idea Infinity IT Services pvt ltd.',
      address: 'Infantry Road',
      solution:
        'Lutron Homeworks QS lighting , shades control & Multi room audio.',
    },
    {
      name: 'Mr. Kumar Patel',
      address: 'HRBR layout',
      solution: 'Lutron Homeworks QS lighting, shades control & Home cinema.',
    },
    {
      name: 'Dr. Ramesh',
      address: 'Bsk 2nd Stage',
      solution: 'Lutron Homeworks QS lighting and shades solution',
    },

    {
      name: 'Dr. Sharan Patil',
      address: 'Dollars colony',
      solution:
        'Lutron Homeworks QS lighting and shades control, Multi room audio & Home cinema.',
    },
    {
      name: 'Mr. Udyan Kumar Singh ',
      address: 'Kumar Organics',
      solution:
        'Lutron Homeworks QS lighting and shades control with control system.',
    },
  ]);

  const [isMobile, setIsMobile] = React.useState(
    window.matchMedia('(max-width: 768px)').matches
  );

  const [isVeryBigScreen, setIsVeryBigScreen] = React.useState(
    window.matchMedia('(min-width: 2000px)').matches
  );

  const [isSmallScreen, setIsSmallScreen] = React.useState(
    window.matchMedia('(max-width: 1100px)').matches
  );

  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    var body = document.body,
      html = document.documentElement;

    setHeight(
      Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      )
    );
  }, []);

  React.useEffect(() => {
    console.log(height);
  }, [height]);

  const [isSmallPhone, setSmallPhone] = React.useState(
    window.matchMedia('(max-width: 360px)').matches
  );

  const RenderClients = () => {
    if (!isMobile) {
      const elements = [];

      clients.forEach((client) => {
        const element = (
          <div
            style={{
              width: !isSmallScreen ? '85vw' : '98vw',
              height: !isVeryBigScreen ? '140px' : '210px',
              background: 'rgba(0, 0, 0, 0.6)',
              marginLeft: !isSmallScreen ? 'auto' : 'auto',
              marginRight: 'auto',
              marginTop: '40px',
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '9px',
              borderRadius: '10px',
            }}
          >
            <div
              className='left'
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                width: '10%',
                marginRight: 'auto',
                marginLeft: '5%',
              }}
            >
              <h4
                style={{
                  fontFamily: 'lato',
                  color: 'white',
                  opacity: '70%',
                  margin: 'auto',
                  fontWeight: '700',
                  width: '100%',
                  fontSize: !isVeryBigScreen ? '1.5em' : '2em',
                }}
              >
                Client:{' '}
              </h4>
              <h4
                style={{
                  fontFamily: 'lato',
                  color: 'white',
                  opacity: '70%',
                  margin: 'auto',
                  fontWeight: '700',
                  width: '100%',
                  fontSize: !isVeryBigScreen ? '1.5em' : '2em',
                }}
              >
                Solution:
              </h4>
            </div>
            <div
              className='left'
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                marginRight: 'auto',
                marginLeft: '-10%',
              }}
            >
              <h4
                style={{
                  fontFamily: 'lato',
                  color: 'white',
                  margin: 'auto',
                  fontWeight: '700',
                  width: '100%',
                  fontSize: !isVeryBigScreen ? '1.5em' : '2em',
                  width: '290px',
                }}
              >
                {client.name}
              </h4>
              <h4
                style={{
                  fontFamily: 'lato',
                  color: 'white',
                  margin: 'auto',
                  fontWeight: '700',
                  width: '290px',
                  fontSize: !isVeryBigScreen ? '1.2em' : '1.5em',
                  maxWidth: '290px',
                }}
              >
                {client.solution}
              </h4>
            </div>
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                marginRight: 'auto',
                marginLeft: '0%',
                justifyContent: 'center',
                width: '290px',
              }}
            >
              <h4
                style={{
                  fontFamily: 'lato',
                  color: 'white',
                  // margin: 'auto',
                  fontWeight: '700',
                  width: '100%',
                  fontSize: !isVeryBigScreen ? '1.5em' : '2em',
                }}
              >
                <LocationOnIcon
                  style={{
                    color: '#FB9248',
                    marginRight: '10px',
                    width: '40px',
                    objectFit: 'cover',
                  }}
                ></LocationOnIcon>
                {client.address}
              </h4>
            </div>
          </div>
        );
        elements.push(element);
      });

      return elements;
    } else {
      const elements = [];

      clients.forEach((client) => {
        const element = (
          <div
            style={{
              width: !isSmallScreen ? '85vw' : '98vw',
              height: !isVeryBigScreen ? '170px' : '210px',
              background: 'rgba(0, 0, 0, 0.6)',
              marginLeft: !isSmallScreen ? 'auto' : 'auto',
              marginRight: 'auto',
              marginTop: '40px',
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '9px',
              borderRadius: '10px',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}
          >
            <div
              style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '60%',
              }}
            >
              <div
                className='left'
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  marginRight: 'auto',
                  marginLeft: '5%',
                }}
              >
                <h4
                  style={{
                    fontFamily: 'lato',
                    color: 'white',
                    opacity: '70%',
                    margin: 'auto',
                    fontWeight: '700',
                    fontSize: !isVeryBigScreen ? '1em' : '2em',
                  }}
                >
                  Client:{' '}
                </h4>
              </div>
              <div
                className='left'
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  marginRight: 'auto',
                  marginLeft: '5%',
                }}
              >
                <h4
                  style={{
                    fontFamily: 'lato',
                    color: 'white',
                    margin: 'auto',
                    fontWeight: '700',
                    fontSize: !isVeryBigScreen ? '1em' : '2em',
                  }}
                >
                  {client.name}
                </h4>
              </div>
              <div
                className='left'
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  marginRight: 'auto',
                  marginLeft: '5%',
                }}
              >
                <h4
                  style={{
                    fontFamily: 'lato',
                    color: 'white',
                    opacity: '70%',
                    margin: 'auto',
                    fontWeight: '700',
                    fontSize: !isVeryBigScreen ? '1em' : '2em',
                  }}
                >
                  Solution:
                </h4>
              </div>
              <div
                className='left'
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  marginRight: 'auto',
                  marginLeft: !isSmallPhone ? '-6px' : '3px',
                  // width: '105%',
                  marginLeft: '5%',
                }}
              >
                <h4
                  style={{
                    fontFamily: 'lato',
                    color: 'white',
                    margin: 'auto',
                    fontWeight: '700',
                    fontSize: !isVeryBigScreen ? '0.8em' : '2em',
                  }}
                >
                  {client.solution}
                </h4>
              </div>
            </div>

            <div
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                marginRight: 'auto',
                marginLeft: '-10px',
                justifyContent: 'center',
              }}
            >
              <h4
                style={{
                  fontFamily: 'lato',
                  color: 'white',
                  margin: 'auto',
                  fontWeight: '700',
                  width: '100%',
                  fontSize: !isVeryBigScreen ? '1em' : '2em',
                }}
              >
                <LocationOnIcon
                  style={{
                    color: '#FB9248',
                    marginRight: '3px',
                    width: '20px',
                    objectFit: 'cover',
                  }}
                ></LocationOnIcon>
                {client.address}
              </h4>
            </div>
          </div>
        );
        elements.push(element);
      });

      return elements;
    }
  };

  React.useEffect(() => {
    if (document.getElementById('nav')) {
      document.getElementById('nav').style.backgroundColor = 'black';
    }
  }, []);

  return (
    <div>
      <img
        src={bg}
        alt=''
        style={{
          position: 'absolute',
          top: '0',
          width: '100vw',
          zIndex: '-1',
          height: `${height}px`,
          objectFit: 'cover',
        }}
      />
      <h1
        style={{
          fontFamily: 'lato',
          color: 'white',
          fontWeight: '700',
          marginRight: 'auto',
          marginTop: !isMobile ? '21vh' : '2vh',
          marginLeft: !isMobile ? 'auto' : 'auto',

          textAlign: 'center',
          fontSize: !isVeryBigScreen ? '2em' : '4em',
        }}
      >
        Few Of Our Clientele
      </h1>
      <RenderClients />
    </div>
  );
};

export default OurProjects;
