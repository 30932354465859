import React from 'react';
import { useLocation } from 'react-router-dom';
import SpeakerNav from './SpeakerNav';
import RevoxSpeakers from './brands/RevoxSpeakers';
import Sonus from './brands/Sonus';
import TruAudio from './brands/TruAudio';
import Bowers from './brands/Bowers';
import PMC from './brands/PMC';
import Sonos from './brands/Sonos';

const Speakers = () => {
  const [brandName, setBrandName] = React.useState('');

  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname.endsWith('/revox')) {
      setBrandName('revox');
    } else if (location.pathname.endsWith('/sonus')) {
      setBrandName('sonus');
    } else if (location.pathname.endsWith('/truAudio')) {
      setBrandName('truAudio');
    } else if (location.pathname.endsWith('/bowers')) {
      setBrandName('bowers');
    } else if (location.pathname.endsWith('/bose')) {
      setBrandName('bose');
    } else if (location.pathname.endsWith('/pmc')) {
      setBrandName('pmc');
    } else if (location.pathname.endsWith('/sonos')) {
      setBrandName('sonos');
    }
  }, [location]);

  React.useEffect(() => {
    if (document.getElementById('nav')) {
      document.getElementById('nav').style.display = 'none';
    }
  });

  return (
    <>
      <SpeakerNav></SpeakerNav>
      {brandName === 'revox' ? (
        <RevoxSpeakers />
      ) : brandName === 'sonus' ? (
        <Sonus />
      ) : brandName === 'truAudio' ? (
        <TruAudio /> // *
      ) : brandName === 'bowers' ? (
        <Bowers />
      ) : brandName === 'pmc' ? (
        <PMC />
      ) : brandName === 'sonos' ? (
        <Sonos />
      ) : (
        ''
      )}
    </>
  );
};

export default Speakers;
