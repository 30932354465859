import React from 'react';
import background from '../assets/HomeBackground.png';
import { useLocation } from 'react-router-dom';
import lightManagement from '../assets/tile-lux.jpg';
import commercialAudio from '../assets/scala.jpg';
import ipod from '../assets/brior.jpg';
import About from './About';
import img2 from '../assets/scala.jpg';
import img3 from '../assets/brior.jpg';

const Home = () => {
  const [isMobile, setIsMobile] = React.useState(
    window.matchMedia('(max-width: 768px)').matches
  );

  const [lastScroll, setLastScroll] = React.useState(0);

  const location = useLocation();

  React.useEffect(() => {
    if (isMobile) {
      // window.addEventListener('scroll', function (e) {
      //   console.log(document.body.scrollTop);
      //   if (window.scrollY > 35) {
      //     window.location = '/#/aboutUs';
      //   }
      // });
    }
  }, []);

  return (
    <div>
      <div>
        <div
          id='carouselExampleControls'
          className='carousel slide'
          data-bs-ride='carousel'
          data-interval='700'
          style={{
            position: 'absolute',
            top: '0',
            zIndex: '-1',
            borderRadius: 'none',
            width: '100vw',
            height: '100vh',
          }}
        >
          <div className='carousel-inner'>
            <div className={`carousel-item active`} id='commercialAudio'>
              <img
                src={img2}
                alt='...'
                style={{
                  width: '100vw',
                  height: '100vh',
                  objectFit: 'cover',
                }}
              />
              <h1
                style={{
                  position: 'absolute',
                  background: 'rgba(0, 0, 0, 0.7)',
                  width: !isMobile ? '450px' : '80vw',
                  bottom: '0',
                  left: '0',
                  height: '150px',
                  padding: '30px',
                  fontFamily: 'lato',
                  color: 'white',
                }}
              >
                Lifestyle Solutions For Luxury Homes
              </h1>
            </div>
            <div className={`carousel-item`} id='ipod'>
              <img
                src={img3}
                alt='...'
                style={{
                  width: '100vw',
                  height: '100vh',
                  objectFit: 'cover',
                }}
              />
              <h1
                style={{
                  position: 'absolute',
                  background: 'rgba(0, 0, 0, 0.7)',
                  width: !isMobile ? '450px' : '80vw',
                  bottom: '0',
                  left: '0',
                  height: '150px',
                  padding: '30px',
                  fontFamily: 'lato',
                  color: 'white',
                }}
              >
                Lifestyle Solutions For Luxury Homes
              </h1>
            </div>
          </div>

          <button
            className='carousel-control-prev'
            type='button'
            data-bs-target='#carouselExampleControls'
            data-bs-slide='prev'
          >
            <span
              className='carousel-control-prev-icon'
              aria-hidden='true'
            ></span>
            <span className='visually-hidden'>Previous</span>
          </button>
          <button
            className='carousel-control-next'
            type='button'
            data-bs-target='#carouselExampleControls'
            data-bs-slide='next'
          >
            <span
              className='carousel-control-next-icon'
              aria-hidden='true'
            ></span>
            <span className='visually-hidden'>Next</span>
          </button>
        </div>
      </div>
      {isMobile ? <About /> : ''}
    </div>
  );
};

export default Home;
