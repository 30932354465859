import React from 'react';
import RevoxLogo from '../../../../../../assets/PMCLogo.svg';
import Fade from '@mui/material/Fade';
import Grow from '@mui/material/Grow';
import { Fade as Slide } from '@mui/material';
import revoxBg from '../../../../../../assets/pmcBg.png';
import { createStyles, makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import RevoxWhite from '../../../../../../assets/PMCLogo_tagline.png';
import logo from '../../../../../../assets/Logo Black.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link, useLocation } from 'react-router-dom';
import twenty5iBanner from '../../../../../../assets/twenty5iBanner.jpg';
import twenty5iProduct from '../../../../../../assets/twentysixProduct.jpeg';
import factBanner from '../../../../../../assets/factBanner.jpg';
import factProduct from '../../../../../../assets/factProduct.jpg';
import ciBanner from '../../../../../../assets/ciBanner.jpg';
import ciProduct from '../../../../../../assets/ciProduct.jpg';
import waferBanner from '../../../../../../assets/waferBanner.jpg';
import waferProduct from '../../../../../../assets/waferProduct.jpg';
import goldBanner from '../../../../../../assets/pmcGold.jpg';
import goldProduct from '../../../../../../assets/goldProduct.jpg';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import twenty3Product from '../../../../../../assets/23i.jpeg';
import twenty2Product from '../../../../../../assets/22i.jpeg';
import ArrowBackIos from '@mui/icons-material/ArrowBackIosNew';
import NavigateNextIcon from '@mui/icons-material/ArrowForwardIos';
import descriptionImage from '../../../../../../assets/pmcDes.png';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Speakers = () => {
  const [isBigScreen, setIsBigScreen] = React.useState(
    window.matchMedia('(min-width: 2000px)').matches
  );
  const [collection, setCollection] = React.useState(false);

  const location = useLocation();

  const query = useQuery();

  React.useEffect(() => {
    console.log(query.get('collection'));

    if (query.get('collection') === 'true') {
      setCollection(true);
    } else {
      setCollection(false);
    }
  }, [location]);

  React.useEffect(() => {
    console.log(query.get('collection'));

    if (query.get('collection') === 'true') {
      setCollection(true);
    } else {
      setCollection(false);
    }
  }, [query]);

  function detectMouseWheelDirection(e) {
    var delta = null,
      direction = false;
    if (!e) {
      // if the event is not provided, we get it from the window object
      e = window.event;
    }
    if (e.wheelDelta) {
      // will work in most cases
      delta = e.wheelDelta / 60;
    } else if (e.detail) {
      // fallback for Firefox
      delta = -e.detail / 2;
    }
    if (delta !== null) {
      direction = delta > 0 ? 'up' : 'down';
    }

    return direction;
  }

  React.useEffect(() => {
    if (document.getElementById('nav')) {
      document.getElementById('nav').style.display = 'none';
    }
    document.body.style.overflowY = 'visible';
  }, []);

  const [isMobile, setIsMobile] = React.useState(
    window.matchMedia('(max-width: 768px)').matches
  );

  const [showSpeakers, setShowSpeakers] = React.useState(false);

  const [currentColor, setCurrentColor] = React.useState('Twenty6');

  const [isVeryBigScreen, setIsVeryBigScreen] = React.useState(
    window.matchMedia('(min-width: 2000px)').matches
  );

  const [aboutOpen, setAboutOpen] = React.useState(false);

  const [smallPhone, setSmallPhone] = React.useState(
    window.matchMedia('(max-width: 420px)').matches
  );

  const [isSmallScreen, setIsSmallScreen] = React.useState(
    window.matchMedia('(max-width: 1300px)').matches
  );

  const [isVerySmallScreen, setIsVerySmallScreen] = React.useState(
    window.matchMedia('(max-width: 1200px)').matches
  );
  const [pcScreen, setPcScreen] = React.useState(
    window.matchMedia('(min-width: 1900px)').matches
  );

  const [lastY, setLastY] = React.useState(window.scrollY);

  var currentScrollNumber = 0;

  const [changeEnabled, setChangeEnabled] = React.useState(true);

  var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

  // React.useEffect(() => {
  //   if (!changeEnabled) {
  //     setTimeout(() => {
  //       setChangeEnabled(true);
  //       disableScroll();
  //     }, 100);
  //   }
  // }, [changeEnabled]);

  function preventDefault(e) {
    e.preventDefault();
    e.preventDefault();
    console.log(changeEnabled);

    console.log('scroll number valid: ' + (currentScrollNumber % 15) == 0);
    console.log('scroll number ' + currentScrollNumber);
    if (currentScrollNumber < 7) {
      currentScrollNumber++;
    } else {
      currentScrollNumber = 0;
    }
    if (
      currentScrollNumber == 3 ||
      currentScrollNumber == 7 ||
      currentScrollNumber == 5
    ) {
      if (detectMouseWheelDirection(e) == 'down') {
        if (currentProduct == 'loud-speakers' && changeEnabled) {
          console.log('network');
          setCurrentProduct('network-recievers');
          setChangeEnabled(false);
          return;
        } else if (currentProduct == 'network-recievers' && changeEnabled) {
          console.log('studio');
          setCurrentProduct('studio-art');

          setChangeEnabled(false);
          return;
        } else if (currentProduct == 'studio-art' && changeEnabled) {
          console.log('turntables');
          setCurrentProduct('turntables');
          setChangeEnabled(false);
          return;
        }
      } else {
        if (currentProduct == 'network-recievers' && changeEnabled) {
          console.log('network');

          setCurrentProduct('loud-speakers');
          setChangeEnabled(false);
          return;
        } else if (currentProduct == 'studio-art' && changeEnabled) {
          console.log('network');

          setCurrentProduct('network-recievers');
          setChangeEnabled(false);
          return;
        } else if (currentProduct == 'turntables' && changeEnabled) {
          setCurrentProduct('studio-art');
          setChangeEnabled(false);
          return;
        }
      }
    }
  }

  function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
      preventDefault(e);
      return false;
    }
  }

  const [currentProduct, setCurrentProduct] = React.useState('loud-speakers');
  const [direction, setDirection] = React.useState('up');

  // modern Chrome requires { passive: false } when adding event
  var supportsPassive = false;
  try {
    window.addEventListener(
      'test',
      null,
      Object.defineProperty({}, 'passive', {
        get: function () {
          supportsPassive = true;
        },
      })
    );
  } catch (e) {}

  var wheelOpt = supportsPassive ? { passive: false } : false;
  var wheelEvent =
    'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

  // call this to Disable
  function disableScroll() {
    if (changeEnabled) {
      if (document.getElementById('carouselExampleControls')) {
        document
          .getElementById('carouselExampleControls')
          .addEventListener('DOMMouseScroll', preventDefault, false); // older FF
        document
          .getElementById('carouselExampleControls')
          .addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
        document
          .getElementById('carouselExampleControls')
          .addEventListener('touchmove', preventDefault, wheelOpt); // mobile
        document
          .getElementById('carouselExampleControls')
          .addEventListener('keydown', preventDefaultForScrollKeys, false);
      }

      const elements = Array.from(document.getElementsByClassName('grow'));

      elements.forEach((element) => {
        element.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
        element.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
        element.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
        element.addEventListener('keydown', preventDefaultForScrollKeys, false);
      });
    }
  }

  React.useEffect(() => {
    // disableScroll();
  }, [currentProduct, changeEnabled]);

  React.useEffect(() => {
    if (collection) {
      if (document.getElementById('speaker-nav')) {
        document.getElementById('speaker-nav').style.display = 'none';
      }
    } else {
      if (document.getElementById('speaker-nav')) {
        document.getElementById('speaker-nav').style.display = 'block';
      }
    }
  }, [collection]);

  const useStyles = makeStyles(() =>
    createStyles({
      background: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100vw',
        height: '100vh',
        zIndex: '-3',
        objectFit: 'cover',
      },
      subHeading: {
        color: 'white',
        fontFamily: 'lato',
        fontWeight: '700',
        textAlign: 'center',
        marginTop: '8vh',
      },
      bottom: {
        width: '100vw',
        height: !isMobile ? '30vh' : '28vh',
        position: 'absolute',
        bottom: '0',
        background: 'rgba(0, 0, 0, 0.5)',
        overflow: 'auto',
      },
      smallBg: {
        position: 'fixed',
        width: '100vw',
        top: '130px',
        zIndex: '-1',
        display: 'block',
      },
      select: {
        '&:before': {
          borderColor: 'black',
        },
        '&:after': {
          borderColor: 'black',
        },
        '&:not(.Mui-disabled):hover::before': {
          borderColor: 'black',
        },
        display: 'block',
        margin: 'auto',
      },
      icon: {
        fill: 'black',
      },
      root: {
        color: 'black',
      },
    })
  );
  const classes = useStyles();

  const [brandDropDown, setBrandDropDown] = React.useState(false);

  React.useEffect(() => {
    if (collection) {
      if (document.querySelector('.carousel')) {
        document
          .querySelector('.carousel')
          .addEventListener('slid.bs.carousel', function () {
            if (
              document.querySelector('.active').id === 'loud-speakers' ||
              document.querySelector('.active').id === 'network-recievers' ||
              document.querySelector('.active').id === 'studio-art' ||
              document.querySelector('.active').id === 'turntables' ||
              document.querySelector('.active').id === 'gold'
            ) {
              setCurrentProduct(document.querySelector('.active').id);
            }
          });
      }
    }
  }, [collection]);
  React.useEffect(() => {
    if (collection) {
      if (currentProduct === 'loud-speakers') {
        document.querySelector('.active').classList.remove('active');
        document.getElementById('loud-speakers').classList.add('active');
      } else if (currentProduct === 'network-recievers') {
        document.querySelector('.active').classList.remove('active');
        document.getElementById('network-recievers').classList.add('active');
      } else if (currentProduct === 'studio-art') {
        document.querySelector('.active').classList.remove('active');
        document.getElementById('studio-art').classList.add('active');
      } else if (currentProduct === 'turntables') {
        document.querySelector('.active').classList.remove('active');
        document.getElementById('turntables').classList.add('active');
      } else if (currentProduct === 'gold') {
        document.querySelector('.active').classList.remove('active');
        document.getElementById('gold').classList.add('active');
      }
    }
  }, [currentProduct]);

  const [ultraWide, setUltraWide] = React.useState(
    window.matchMedia('(min-width: 3000px)').matches
  );

  const [audioVisualDropDown, setAudioVisualDropDown] = React.useState(false);

  return (
    <div>
      <Fade in={!collection} unmountOnExit>
        <div>
          <img src={revoxBg} alt='..' className={classes.background} />

          <img
            src={RevoxWhite}
            alt='..'
            style={{
              display: 'block',
              margin: 'auto',
              marginTop: !isVeryBigScreen
                ? !isMobile
                  ? '40px'
                  : '20px'
                : '120px',
              background: 'rgba(0, 0, 0, 0.4)',
              padding: '20px',
              borderRadius: '10px',
              width: !isVeryBigScreen ? '250px' : '400px',
            }}
          />

          <Button
            variant='contained'
            style={{
              color: 'white',
              background: 'rgba(0, 0, 0, 0.7)',
              display: 'block',
              margin: 'auto',
              marginTop: !isMobile ? '3vh' : '3vh',
              width: !isVeryBigScreen ? '280px' : '400px',
              height: !isVeryBigScreen ? '50px' : '90px',
              fontFamily: 'lato',
              fontWeight: '700',
              border: '1px solid white',
              fontSize: !isVeryBigScreen ? '1em' : '1.6em',
              transitionProperty: 'all',
              transitionDuration: '0.7s',
            }}
            onMouseEnter={(e) => {
              e.target.style.background = 'rgba(0, 0, 0, 0.9)';
            }}
            onMouseLeave={(e) => {
              e.target.style.background = 'rgba(0, 0, 0, 0.7)';
            }}
            onClick={(e) => {
              setCollection(true);
            }}
          >
            View Collection
          </Button>
          <div className={classes.bottom}>
            <h5
              className={classes.subHeading}
              style={{
                marginTop: '2%',
                fontSize: !isVeryBigScreen ? '1.3em' : '2.7em',
              }}
            >
              About
            </h5>
            <h6
              className={classes.subHeading}
              style={{
                width: '80%',
                maxWidth: '1300px',
                margin: 'auto',
                marginTop: '2%',
                opacity: '80%',
                fontSize: !isVeryBigScreen ? '1em' : '1.8em',
              }}
            >
              Founded in 1991 by Peter Thomas of the BBC and Adrian Loader of
              FWO Bauch. The combination of knowledge and a life-long passion
              for music led to the production of PMC’s first product, the BB5-A,
              an active main studio monitor. PMC are one of the very few
              companies that have been awarded an Emmy® for their contribution
              to recording excellence. With film, it would be easier to list the
              blockbuster movies which did not use PMC during the process of
              music production and post! But titles include: Titanic, Mission
              Impossible, Captain Phillips, Pearl Harbor, Game of Thrones – TV,
              Iron Man 1&2, and many more.
            </h6>
          </div>
        </div>
      </Fade>

      <Fade in={collection} unmountOnExit>
        <div>
          <div
            className='topNav'
            style={{
              display: 'flex',
              width: '100vw',
              height: !isVeryBigScreen ? '70px' : '120px',
              justifyContent: 'center',
              marginTop: '10px',
            }}
          >
            <div
              className='left'
              style={{
                marginRight: 'auto',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: '30px',
                height: '100%',
                display: !isMobile ? 'flex' : 'none',
                justifyContent: 'center',
              }}
            >
              <img
                src={logo}
                alt=''
                style={{
                  display: 'block',
                  margin: 'auto',
                  width: !isVeryBigScreen ? '250px' : '350px',
                  cursor: 'pointer',
                }}
                onClick={() => (window.location = '/')}
              />
            </div>
            <div
              className='left'
              style={{
                display: 'flex',
                marginLeft: 'auto',
                marginRight: '50px',
                height: '100%',
                justifyContent: 'center',
              }}
            >
              <h5
                style={{
                  fontFamily: 'lato',
                  fontWeight: '700',
                  margin: 'auto',
                }}
              >
                <span
                  style={{
                    color: 'rgb(140, 140, 140)',
                    fontSize: !isMobile
                      ? !isVeryBigScreen
                        ? '1em'
                        : '1.8em'
                      : '0.6em',
                    cursor: 'pointer',
                    marginLeft: !isMobile ? '0px' : '-1vw',
                  }}
                  onClick={() => (window.location = '/')}
                >
                  &lt;
                </span>
                <span
                  style={{
                    color: 'rgb(140, 140, 140)',
                    fontSize: !isMobile
                      ? !isVeryBigScreen
                        ? '1em'
                        : '1.8em'
                      : '0.6em',
                    cursor: 'pointer',
                    marginLeft: '10px',
                  }}
                  onClick={() => (window.location = '/')}
                >
                  Home
                </span>
                <span
                  style={{
                    color: 'rgb(140, 140, 140)',
                    fontSize: !isMobile
                      ? !isVeryBigScreen
                        ? '1em'
                        : '1.8em'
                      : '0.6em',
                    cursor: 'pointer',
                    marginLeft: !isMobile ? '40px' : '2vw',
                  }}
                  onClick={() => (window.location = '/')}
                >
                  |
                </span>
                <span
                  style={{
                    marginLeft: !isMobile
                      ? !isVeryBigScreen
                        ? '40px'
                        : '45px'
                      : '3vw',
                    fontSize: !isMobile
                      ? !isVeryBigScreen
                        ? '0.8em'
                        : '1.3em'
                      : '0.6em',
                    opacity: '70%',
                    whiteSpace: 'pre',
                  }}
                  onMouseEnter={() => {
                    if (isMobile) {
                      setAudioVisualDropDown(true);
                    }
                  }}
                  onMouseLeave={() => {
                    if (isMobile) {
                      setAudioVisualDropDown(false);
                    }
                  }}
                >
                  {!isMobile
                    ? 'Solutions    >    Residential    >    Audio/Visual    >'
                    : 'Solutions > Residential > Audio/Visual >'}
                </span>
                <span
                  style={{
                    marginLeft: !isMobile
                      ? !isVeryBigScreen
                        ? '30px'
                        : '60px'
                      : '2vw',
                    fontSize: !isMobile
                      ? !isVeryBigScreen
                        ? '1.2em'
                        : '2em'
                      : '0.8em',
                  }}
                  onMouseEnter={() => {
                    setAudioVisualDropDown(true);
                  }}
                  onMouseLeave={() => {
                    setAudioVisualDropDown(false);
                  }}
                >
                  {'Speakers '}
                </span>
              </h5>
              <Fade in={audioVisualDropDown}>
                <div
                  style={{
                    display: 'flex',
                    marginLeft: !pcScreen
                      ? !isVerySmallScreen
                        ? '500px'
                        : !isSmallScreen || isVerySmallScreen
                        ? !isMobile
                          ? '38.5vw'
                          : '73vw'
                        : '40vw'
                      : !isVeryBigScreen
                      ? '23vw'
                      : !ultraWide
                      ? '28.7vw'
                      : '21.5vw',
                    height: !isVeryBigScreen
                      ? !isMobile
                        ? '220px'
                        : '150px'
                      : '350px',
                    width: !isVeryBigScreen
                      ? !isMobile
                        ? '150px'
                        : '100px'
                      : '320px',
                    background: 'rgba(0, 0, 0, 0.7)',

                    border: 'none',
                    position: 'absolute',
                    top: !isVeryBigScreen
                      ? !isMobile
                        ? '65px'
                        : '123px'
                      : '110px',
                    border: 'none',
                    borderRadius: 'none',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    zIndex: '10',
                  }}
                  onMouseEnter={() => {
                    setAudioVisualDropDown(true);
                  }}
                  onMouseLeave={() => {
                    setAudioVisualDropDown(false);
                  }}
                >
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      color: 'white',
                    }}
                    to='/solutions/residential/audio/speakers/revox'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Speakers
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                    }}
                    to='/solutions/residential/audio/amplifiers/arcam'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Amplifiers
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      opacity: '70%',
                    }}
                    to='/solutions/residential/audio/screens/elite'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Screens
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                    }}
                    to='/solutions/residential/audio/projectors/benq'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Projectors
                  </Link>
                </div>
              </Fade>
            </div>
          </div>
          {!isMobile ? (
            <div
              className='nav2'
              style={{
                display: 'flex',
                height: '60px',
                justifyContent: 'center',
                marginTop: '40px',
                marginBottom: '-20px',
              }}
            >
              <p
                style={{
                  fontFamily: 'lato',
                  color: 'black',
                  opacity: '80%',
                  display: 'block',
                  margin: 'auto',
                  marginRight: 'auto',
                  marginLeft: '10vw',
                  fontWeight: '700',
                  fontSize: !isVeryBigScreen ? '1em' : '1.7em',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setCollection(false);
                  document.getElementById('speaker-nav').style.display =
                    'block';
                }}
              >
                &lt; Back to Audio Visual
              </p>
              <div
                className='right'
                style={{
                  display: 'flex',
                  margin: 'auto',
                  marginLeft: 'auto',
                  marginRight: '10vw',
                  width: '65%',
                  height: '100%',
                  maxWidth: '1200px',
                }}
              >
                <p
                  style={{
                    fontFamily: 'lato',
                    fontWeight: '500',
                    textAlign: 'center',
                    fontSize: !isVeryBigScreen
                      ? !isSmallScreen
                        ? '1.1em'
                        : '0.8em'
                      : '1.7em',
                    display: 'block',
                    margin: 'auto',
                    textDecoration: 'none',
                    fontWeight: '700',
                    color:
                      currentProduct === 'loud-speakers'
                        ? 'black'
                        : 'rgb(120,120, 120)',
                    cursor: 'pointer',
                  }}
                  onClick={() => setCurrentProduct('loud-speakers')}
                >
                  Twenty5i
                </p>
                <p
                  style={{
                    fontFamily: 'lato',
                    fontWeight: '500',
                    textAlign: 'center',
                    fontSize: !isVeryBigScreen
                      ? !isSmallScreen
                        ? '1.1em'
                        : '0.8em'
                      : '1.7em',
                    display: 'block',
                    margin: 'auto',
                    fontWeight: '700',

                    color:
                      currentProduct === 'studio-art'
                        ? 'black'
                        : 'rgb(120,120, 120)',
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                  onClick={() => setCurrentProduct('studio-art')}
                >
                  Ci
                </p>
                <p
                  style={{
                    fontFamily: 'lato',
                    fontWeight: '500',
                    textAlign: 'center',
                    fontSize: !isVeryBigScreen
                      ? !isSmallScreen
                        ? '1.1em'
                        : '0.8em'
                      : '1.7em',
                    display: 'block',
                    margin: 'auto',
                    fontWeight: '700',

                    color:
                      currentProduct === 'network-recievers'
                        ? 'black'
                        : 'rgb(120,120, 120)',
                    cursor: 'pointer',

                    textDecoration: 'none',
                  }}
                  onClick={() => setCurrentProduct('network-recievers')}
                >
                  Fact
                </p>

                <p
                  style={{
                    fontFamily: 'lato',
                    fontWeight: '500',
                    textAlign: 'center',
                    fontSize: !isVeryBigScreen
                      ? !isSmallScreen
                        ? '1.1em'
                        : '0.8em'
                      : '1.7em',
                    display: 'block',
                    margin: 'auto',
                    fontWeight: '700',

                    color:
                      currentProduct === 'turntables'
                        ? 'black'
                        : 'rgb(120,120, 120)',
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                  onClick={() => setCurrentProduct('turntables')}
                >
                  Wafer
                </p>

                <p
                  style={{
                    fontFamily: 'lato',
                    fontWeight: '500',
                    textAlign: 'center',
                    fontSize: !isVeryBigScreen
                      ? !isSmallScreen
                        ? '1.1em'
                        : '0.8em'
                      : '1.7em',
                    display: 'block',
                    margin: 'auto',
                    fontWeight: '700',

                    color:
                      currentProduct === 'gold' ? 'black' : 'rgb(120,120, 120)',
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                  onClick={() => setCurrentProduct('gold')}
                >
                  Gold
                </p>
                <img
                  src={RevoxLogo}
                  alt='..'
                  style={{
                    display: 'block',
                    margin: 'auto',
                    width: !isVeryBigScreen ? '130px' : '170px',
                    marginRight: '0',
                    borderBottom: '1px solid black',
                    paddingBottom: '5px',
                  }}
                  onMouseEnter={() => setBrandDropDown(true)}
                  onMouseLeave={() => setBrandDropDown(false)}
                />
                <ExpandMoreIcon
                  style={{
                    display: 'block',
                    margin: 'auto',
                    marginLeft: '0',
                    marginRight: '0',
                  }}
                ></ExpandMoreIcon>
                <Fade in={brandDropDown}>
                  <div
                    style={{
                      display: 'flex',
                      // marginLeft: '100vw',
                      height: !isVeryBigScreen ? '270px' : '420px',
                      width: !isVeryBigScreen ? '150px' : '320px',
                      background: 'rgba(0, 0, 0, 0.7)',
                      right: '10.5vw',
                      border: 'none',
                      position: 'absolute',
                      top: !isVeryBigScreen ? '170px' : '200px',
                      border: 'none',
                      borderRadius: 'none',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      paddingTop: '20px',
                      paddingBottom: '20px',
                      zIndex: '20',
                    }}
                    onMouseEnter={() => {
                      setBrandDropDown(true);
                    }}
                    onMouseLeave={() => {
                      setBrandDropDown(false);
                    }}
                  >
                    <Link
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        display: 'block',
                        margin: 'auto',
                        textDecoration: 'none',
                        fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                        opacity: '70%',
                        color: 'white',
                        fontWeight: '700',
                      }}
                      to='/solutions/residential/audio/speakers/revox?collection=true'
                      onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                      onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                    >
                      Revox
                    </Link>
                    <Link
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        display: 'block',
                        margin: 'auto',
                        textDecoration: 'none',
                        fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                        opacity: '70%',
                        fontWeight: '700',
                      }}
                      to='/solutions/residential/audio/speakers/sonus?collection=true'
                      onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                      onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                    >
                      Sonus Faber
                    </Link>
                    <Link
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        display: 'block',
                        margin: 'auto',
                        textDecoration: 'none',
                        fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                        opacity: '70%',
                        opacity: '70%',
                        fontWeight: '700',
                      }}
                      to='/solutions/residential/audio/speakers/truAudio?collection=true'
                      onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                      onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                    >
                      Truaudio
                    </Link>
                    <Link
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        display: 'block',
                        margin: 'auto',
                        textDecoration: 'none',
                        fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                        opacity: '70%',
                        fontWeight: '700',
                      }}
                      to='/solutions/residential/audio/speakers/bowers?collection=true'
                      onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                      onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                    >
                      Bowers & Wilkins
                    </Link>

                    <Link
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        display: 'block',
                        margin: 'auto',
                        textDecoration: 'none',
                        fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                        opacity: '70%',
                        fontWeight: '700',
                      }}
                      to='/solutions/residential/audio/speakers/pmc?collection=true'
                      onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                      onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                    >
                      PMC
                    </Link>
                    <Link
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        display: 'block',
                        margin: 'auto',
                        textDecoration: 'none',
                        fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                        opacity: '70%',
                        fontWeight: '700',
                      }}
                      to='/solutions/residential/audio/speakers/sonos?collection=true'
                      onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                      onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                    >
                      Sonos
                    </Link>
                  </div>
                </Fade>
              </div>
            </div>
          ) : (
            <div>
              <p
                style={{
                  fontFamily: 'lato',
                  color: 'black',
                  opacity: '70%',
                  fontSize: !isMobile
                    ? !isVeryBigScreen
                      ? '1.2em'
                      : '2em'
                    : '1em',
                  textAlign: 'center',
                  fontWeight: '700',
                  marginTop: '-5px',
                  marginBottom: '15px',
                }}
                onClick={() => setCollection(false)}
              >
                &lt; Back to Audio Visual
              </p>
              <div style={{ display: 'flex' }}>
                <img
                  src={RevoxLogo}
                  alt='..'
                  style={{
                    display: 'block',
                    margin: 'auto',
                    width: !isVeryBigScreen ? '130px' : '170px',
                    // marginRight: '0',
                    borderBottom: '1px solid black',
                    paddingBottom: '5px',
                  }}
                  onClick={() => setBrandDropDown(true)}
                  onMouseLeave={() => setBrandDropDown(false)}
                />
              </div>
              <Fade in={brandDropDown}>
                <div
                  style={{
                    display: 'flex',
                    // marginLeft: '100vw',
                    height: !isVeryBigScreen ? '270px' : '420px',
                    width: !isVeryBigScreen ? '150px' : '320px',
                    background: 'rgba(0, 0, 0, 0.7)',
                    // right: '10.5vw',
                    border: 'none',
                    // position: 'absolute',
                    // top: !isVeryBigScreen ? '170px' : '200px',
                    border: 'none',
                    borderRadius: 'none',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    zIndex: '20',
                    marginLeft: '30vw',
                    position: 'absolute',
                  }}
                  onMouseEnter={() => {
                    setBrandDropDown(true);
                  }}
                  onMouseLeave={() => {
                    setBrandDropDown(false);
                  }}
                >
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      color: 'white',
                      fontWeight: '700',
                    }}
                    to='/solutions/residential/audio/speakers/revox?collection=true'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Revox
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      fontWeight: '700',
                    }}
                    to='/solutions/residential/audio/speakers/sonus?collection=true'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Sonus Faber
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      opacity: '70%',
                      fontWeight: '700',
                    }}
                    to='/solutions/residential/audio/speakers/truAudio?collection=true'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Truaudio
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      fontWeight: '700',
                    }}
                    to='/solutions/residential/audio/speakers/bowers?collection=true'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Bowers & Wilkins
                  </Link>

                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      fontWeight: '700',
                    }}
                    to='/solutions/residential/audio/speakers/pmc?collection=true'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    PMC
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      fontWeight: '700',
                    }}
                    to='/solutions/residential/audio/speakers/sonos?collection=true'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Sonos
                  </Link>
                </div>
              </Fade>
              <FormControl
                style={{
                  width: '200px',
                  display: 'block',
                  margin: 'auto',
                  marginTop: '20px',
                }}
              >
                <InputLabel id='demo-simple-select-label'>Category</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={currentProduct}
                  label='Category'
                  onChange={(e) => {
                    setCurrentProduct(e.target.value);
                  }}
                  className={classes.select}
                  inputProps={{
                    classes: {
                      icon: classes.icon,
                      root: classes.root,
                    },
                  }}
                  style={{ color: 'black', display: 'block', margin: 'auto' }}
                >
                  <MenuItem value={'loud-speakers'}>twenty5i</MenuItem>
                  <MenuItem value={'studio-art'}>Ci</MenuItem>

                  <MenuItem value={'network-recievers'}>Fact</MenuItem>
                  <MenuItem value={'turntables'}>Wafer</MenuItem>
                  <MenuItem value={'gold'}>Gold</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}

          <Slide in={true} direction={direction} unmountOnExit>
            <div>
              <div
                className='part1'
                style={{ marginTop: '4vh', width: '100vw' }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '30px',
                    width: !isMobile ? '80vw' : '95vw',
                    height: !isMobile ? '80vh' : smallPhone ? '45vh' : '75vh',
                    width: '100vw',
                  }}
                >
                  <button
                    className='carousel-control-prev'
                    type='button'
                    data-bs-target='#carouselExampleControls'
                    data-bs-slide='prev'
                    style={{
                      color: 'black',
                      width: '80px',
                      height: '60px',
                      background: 'rgb(0, 0, 0)',
                      position: 'static',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginLeft: '0',
                      display: isMobile ? 'none' : 'inline-block',
                    }}
                  >
                    <span
                      className='carousel-control-prev-icon'
                      aria-hidden='true'
                      style={{ color: 'black' }}
                    ></span>
                    <span
                      className='visually-hidden secondary'
                      style={{ color: 'black' }}
                    >
                      Previous
                    </span>
                  </button>

                  <div
                    id='carouselExampleControls'
                    className='carousel slide'
                    data-interval='false'
                    onMouseEnter={(e) => {
                      setShowSpeakers(true);
                      console.log('mouse enter');
                    }}
                    onClick={(e) => {
                      setShowSpeakers(true);
                    }}
                    style={{
                      zIndex: '-1',
                      borderRadius: 'none',
                      width: !isMobile ? '80vw' : '95vw',
                      height: !isMobile ? '80vh' : smallPhone ? '45vh' : '75vh',
                      touchAction: 'none',
                      zIndex: '9',
                      marginLeft: '5px',
                      marginRight: '5px',
                      maxHeight: !isMobile ? 'none' : '250px',
                    }}
                    onMouseLeave={() => {
                      setShowSpeakers(false);
                      console.log('mouse leave');
                    }}
                  >
                    <div
                      className='carousel-inner'
                      style={{
                        height: !isMobile
                          ? '80vh'
                          : smallPhone
                          ? '45vh'
                          : '75vh',
                        marginBottom: '20px',
                        maxHeight: !isMobile ? 'none' : '250px',
                      }}
                    >
                      <div
                        className={`carousel-item active 1`}
                        id='loud-speakers'
                        style={{ width: '100%', height: '100%' }}
                      >
                        <Modal
                          open={
                            aboutOpen &&
                            (document.querySelector('.active').classList
                              .value === 'carousel-item active 1' ||
                              document.querySelector('.active').classList
                                .value === 'carousel-item 1 active')
                          }
                          onClose={() => setAboutOpen(false)}
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                The REV Series was designed to transport you to
                                the movie theater with powerful cinema surround
                                sound and professional, quality audio. These
                                home cinema speakers blend seamlessly into your
                                home's interior with our Ghost-style frameless
                                grill. Engineered with an angled housing that
                                rotates and an adjustable mid-range tweeter
                                bridge provides directionality of sound to the
                                preferred listening area, or "sweet spot".
                                Following its debut, the REV Series was awarded
                                several design awards, including “Product of the
                                Year” by Electronic House for innovation and
                                value. To create a Dolby Atmos home theater, add
                                our G91 speakers into the ceiling and feel the
                                powerful, moving audio that flows around you.
                                Heard but not seen™. Features: powerful cinema
                                surround sound, Ghost-style frameless grill,
                                capable of directing sound to preferred
                                listening area, “Product of the Year” by
                                Electronic House for innovation and value.
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              Twenty5
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <Button
                                  variant='outlined'
                                  style={{
                                    display: 'flex',

                                    height: '60px',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    // marginRight: '-10%',

                                    margin: 'auto',
                                    marginLeft: !isMobile ? '10%' : 'auto',
                                    border: '1px solid white',
                                  }}
                                  onClick={() => {
                                    if (currentColor === 'Twenty2') {
                                      setCurrentColor('Twenty3');
                                    } else if (currentColor === 'Twenty3') {
                                      setCurrentColor('Twenty6');
                                    } else if (currentColor === 'Twenty6') {
                                      setCurrentColor('Twenty2');
                                    }
                                  }}
                                >
                                  <ArrowBackIos
                                    style={{ color: 'white' }}
                                  ></ArrowBackIos>
                                </Button>
                                <img
                                  src={
                                    currentColor === 'Twenty6'
                                      ? twenty5iProduct
                                      : currentColor === 'Twenty3'
                                      ? twenty3Product
                                      : currentColor === 'Twenty2'
                                      ? twenty2Product
                                      : ''
                                  }
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '65%' : '30%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: '5%',
                                    marginRight: '5%',

                                    // margin: 'auto',
                                  }}
                                  alt='..'
                                />
                                <Button
                                  variant='outlined'
                                  style={{
                                    display: 'flex',
                                    height: '60px',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    border: '1px solid white',

                                    margin: 'auto',
                                  }}
                                  onClick={() => {
                                    if (currentColor === 'Twenty6') {
                                      setCurrentColor('Twenty3');
                                    } else if (currentColor === 'Twenty3') {
                                      setCurrentColor('Twenty2');
                                    } else if (currentColor === 'Twenty2') {
                                      setCurrentColor('Twenty6');
                                    }
                                  }}
                                >
                                  <NavigateNextIcon
                                    style={{ color: 'white' }}
                                  ></NavigateNextIcon>
                                </Button>
                              </div>

                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '5%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                Twenty5
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '2%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  width: '85%',
                                  opacity: '70%',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.2em'
                                      : '1.0em'
                                    : '1.6em',
                                }}
                              >
                                The brand’s design team never stops looking for
                                ways to hone their designs, using new
                                technologies, materials, or discoveries,
                                including innovations from outside the audio
                                industry. This quest has led to the creation of
                                the twenty5i series — where ‘i’ stands for
                                improved. The latest range offers everything
                                that made the twenty5 series legendary and so
                                sought-after, but with even greater realism,
                                transparency, and musicality. The range consists
                                of four two-way loudspeakers ranging from the
                                compact twenty5i.21 to the floorstanding
                                twenty5i.24, plus the flagship three-way
                                twenty5i.26. There’s also a centre channel model
                                and a proven subwoofer for use in surround
                                systems. And remember PMC loudspeakers are
                                employed at every stage of music-making, from
                                film soundtrack composers’ music rooms to
                                recording studio through to the home, preserving
                                the essence of the original performance exactly
                                as the artist intended. Features: perfect for
                                that cinema surround sound experience, used by
                                professionals in the audio visual industry,
                                original performance from recording studio to
                                living room.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={twenty5iBanner}
                          alt='...'
                          style={{
                            width: '100%',
                            height: !isMobile ? '100%' : '100%',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                        <h1
                          style={{
                            background: 'rgba(0, 0, 0, 0.7)',
                            fontFamily: 'coldiac',
                            color: 'white',
                            paddingLeft: '10px',
                            paddingRight: '20px',
                            fontWeight: '400',
                            marginRight: 'auto',
                            marginLeft: '0',
                            marginBottom: '100%',
                            marginTop: '0',
                            position: 'absolute',
                            top: '0',
                            paddingTop: '15px',
                            paddingBottom: '15px',
                          }}
                        >
                          Twenty5
                        </h1>
                        <img
                          src={descriptionImage}
                          alt='..'
                          style={{
                            display: 'block',
                            margin: 'auto',
                            width: !isMobile ? '180px' : '90px',
                            position: 'absolute',
                            bottom: !isMobile ? '60px' : '20px',
                            zIndex: '6',
                            right: !isMobile ? '30px' : '10px',
                          }}
                        />
                      </div>

                      <div
                        className={`carousel-item 2`}
                        id='studio-art'
                        style={{ width: '100%', height: '100%' }}
                      >
                        <Modal
                          open={
                            aboutOpen &&
                            (document.querySelector('.active').classList
                              .value === 'carousel-item active 2' ||
                              document.querySelector('.active').classList
                                .value === 'carousel-item 2 active')
                          }
                          onClose={() => setAboutOpen(false)}
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                The new Custom Install (ci) series from PMC
                                brings all the magic of the movies and music to
                                the home. Since 1991, when the company was
                                founded, the brand has been the speaker brand
                                used to create hit music as well as the biggest
                                Hollywood movie soundtracks, from Titanic to
                                Skyfall and Iron Man to Spiderman, and have been
                                awarded an Emmy® for their contribution to
                                recording excellence. To recreate the
                                awe-inspiring realism and stunning dynamics of
                                any type of music or movie, then there is no
                                better choice of loudspeaker than those used to
                                create it. Features: magic of movies and music
                                inside your home, awarded an Emmy for recording
                                excellence, awe-inspiring realism, stunning
                                dynamics.
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              Ci
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <img
                                  src={ciProduct}
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '70%' : '22%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: '-23%',
                                    marginRight: '-23%',

                                    margin: 'auto',
                                  }}
                                  alt='..'
                                />
                              </div>

                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '8%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                Ci
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '3%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  width: '80%',
                                  opacity: '70%',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.2em'
                                      : '1.0em'
                                    : '1.6em',
                                }}
                              >
                                The new Custom Install (ci) series from PMC
                                brings all the magic of the movies and music to
                                the home. Since 1991, when the company was
                                founded, the brand has been the speaker brand
                                used to create hit music as well as the biggest
                                Hollywood movie soundtracks, from Titanic to
                                Skyfall and Iron Man to Spiderman, and have been
                                awarded an Emmy® for their contribution to
                                recording excellence. To recreate the
                                awe-inspiring realism and stunning dynamics of
                                any type of music or movie, then there is no
                                better choice of loudspeaker than those used to
                                create it. Features: magic of movies and music
                                inside your home, awarded an Emmy for recording
                                excellence, awe-inspiring realism, stunning
                                dynamics.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={ciBanner}
                          alt='...'
                          style={{
                            width: '100%',
                            height: !isMobile ? '100%' : '100%',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                        <h1
                          style={{
                            background: 'rgba(0, 0, 0, 0.7)',
                            fontFamily: 'coldiac',
                            color: 'white',
                            paddingLeft: '10px',
                            paddingRight: '20px',
                            fontWeight: '400',
                            marginRight: 'auto',
                            marginLeft: '0',
                            marginBottom: '100%',
                            marginTop: '0',
                            position: 'absolute',
                            top: '0',
                            paddingTop: '15px',
                            paddingBottom: '15px',
                          }}
                        >
                          Ci
                        </h1>
                        <img
                          src={descriptionImage}
                          alt='..'
                          style={{
                            display: 'block',
                            margin: 'auto',
                            width: !isMobile ? '180px' : '90px',
                            position: 'absolute',
                            bottom: !isMobile ? '60px' : '20px',
                            zIndex: '6',
                            right: !isMobile ? '30px' : '10px',
                          }}
                        ></img>
                      </div>
                      <div
                        className={`carousel-item 3`}
                        id='network-recievers'
                        style={{ width: '100%', height: '100%' }}
                      >
                        <Modal
                          open={
                            aboutOpen &&
                            (document.querySelector('.active').classList
                              .value === 'carousel-item active 3' ||
                              document.querySelector('.active').classList
                                .value === 'carousel-item 3 active')
                          }
                          onClose={() => setAboutOpen(false)}
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                Series PMC’s vast knowledge of acoustic
                                engineering gained from developing the world’s
                                leading professional loudspeakers, has been
                                distilled into the fact® range. Only this level
                                of understanding of the creative process, room
                                acoustics and supreme engineering make fact
                                possible. Features: superior acoustic
                                engineering, professional quality loudspeakers
                                for home use, room acoustics considered to
                                create the perfect listening experience.
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              Fact
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <img
                                  src={factProduct}
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '70%' : '22%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: '-23%',
                                    marginRight: '-23%',

                                    margin: 'auto',
                                  }}
                                  alt='..'
                                />
                              </div>

                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '18%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                Fact
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '3%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  width: '80%',
                                  opacity: '70%',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.2em'
                                      : '1.0em'
                                    : '1.6em',
                                }}
                              >
                                Series PMC’s vast knowledge of acoustic
                                engineering gained from developing the world’s
                                leading professional loudspeakers, has been
                                distilled into the fact® range. Only this level
                                of understanding of the creative process, room
                                acoustics and supreme engineering make fact
                                possible. Features: superior acoustic
                                engineering, professional quality loudspeakers
                                for home use, room acoustics considered to
                                create the perfect listening experience.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={factBanner}
                          alt='...'
                          style={{
                            width: '100%',
                            height: !isMobile ? '100%' : '100%',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                        <h1
                          style={{
                            background: 'rgba(0, 0, 0, 0.7)',
                            fontFamily: 'coldiac',
                            color: 'white',
                            paddingLeft: '10px',
                            paddingRight: '20px',
                            fontWeight: '400',
                            marginRight: 'auto',
                            marginLeft: '0',
                            marginBottom: '100%',
                            marginTop: '0',
                            position: 'absolute',
                            top: '0',
                            paddingTop: '15px',
                            paddingBottom: '15px',
                          }}
                        >
                          Fact
                        </h1>
                        <img
                          src={descriptionImage}
                          alt='..'
                          style={{
                            display: 'block',
                            margin: 'auto',
                            width: !isMobile ? '180px' : '90px',
                            position: 'absolute',
                            bottom: !isMobile ? '60px' : '20px',
                            zIndex: '6',
                            right: !isMobile ? '30px' : '10px',
                          }}
                        ></img>
                      </div>
                      <div
                        className={`carousel-item 4`}
                        id='turntables'
                        style={{ width: '100%', height: '100%' }}
                      >
                        <Modal
                          open={
                            aboutOpen &&
                            (document.querySelector('.active').classList
                              .value === 'carousel-item active 4' ||
                              document.querySelector('.active').classList
                                .value === 'carousel-item 4 active')
                          }
                          onClose={() => setAboutOpen(false)}
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                PMC has brought its ATL technology, found in the
                                world’s leading studios to a range of discreet,
                                stylish in-wall and on-wall loudspeakers. The
                                slim-line wafer series are capable of astounding
                                room-filling richness and clarity, ideal to
                                recreate the surround sound cinema experience or
                                an audiophile two channel system. The two size
                                options allow for perfect visual and sound
                                balance for both modest and more substantial
                                rooms. Features: discreet and stylish, capable
                                of room-filling richness and clarity, ideal for
                                a surround sound cinema experience.
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              wafer
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <img
                                  src={waferProduct}
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '70%' : '22%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: '-23%',
                                    marginRight: '-23%',

                                    margin: 'auto',
                                  }}
                                  alt='..'
                                />
                              </div>

                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '16%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                Wafer
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '3%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  width: '80%',
                                  opacity: '70%',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.2em'
                                      : '1.0em'
                                    : '1.6em',
                                }}
                              >
                                PMC has brought its ATL technology, found in the
                                world’s leading studios to a range of discreet,
                                stylish in-wall and on-wall loudspeakers. The
                                slim-line wafer series are capable of astounding
                                room-filling richness and clarity, ideal to
                                recreate the surround sound cinema experience or
                                an audiophile two channel system. The two size
                                options allow for perfect visual and sound
                                balance for both modest and more substantial
                                rooms. Features: discreet and stylish, capable
                                of room-filling richness and clarity, ideal for
                                a surround sound cinema experience.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={waferBanner}
                          alt='...'
                          style={{
                            width: '100%',
                            height: !isMobile ? '100%' : '100%',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                        <h1
                          style={{
                            background: 'rgba(0, 0, 0, 0.7)',
                            fontFamily: 'coldiac',
                            color: 'white',
                            paddingLeft: '10px',
                            paddingRight: '20px',
                            fontWeight: '400',
                            marginRight: 'auto',
                            marginLeft: '0',
                            marginBottom: '100%',
                            marginTop: '0',
                            position: 'absolute',
                            top: '0',
                            paddingTop: '15px',
                            paddingBottom: '15px',
                          }}
                        >
                          Wafer
                        </h1>
                        <img
                          src={descriptionImage}
                          alt='..'
                          style={{
                            display: 'block',
                            margin: 'auto',
                            width: !isMobile ? '180px' : '90px',
                            position: 'absolute',
                            bottom: !isMobile ? '60px' : '20px',
                            zIndex: '6',
                            right: !isMobile ? '30px' : '10px',
                            padding: !isMobile ? '20px' : '5px',
                            background: 'rgba(255, 255, 255, 0.4)',
                          }}
                        ></img>
                      </div>
                      <div
                        className={`carousel-item 5`}
                        id='gold'
                        style={{ width: '100%', height: '100%' }}
                      >
                        <Modal
                          open={
                            aboutOpen &&
                            (document.querySelector('.active').classList
                              .value === 'carousel-item active 5' ||
                              document.querySelector('.active').classList
                                .value === 'carousel-item 5 active')
                          }
                          onClose={() => setAboutOpen(false)}
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                In celebration of 25 years’ production of the
                                world’s leading professional reference monitors,
                                PMC launched the hand built, limited edition,
                                DB1 Gold - the finest version of the DB1 studio
                                monitor made to date. This updated model
                                provides the discerning music lover with a
                                window into the professional world, giving
                                access to the identical sound balance and vibe
                                intended by the recording artist, without
                                missing a single nuance. Features: hand-built
                                and limited edition, window into professional
                                world for the discerning listener.
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              DB1 Gold
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <img
                                  src={goldProduct}
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '70%' : '22%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: '-23%',
                                    marginRight: '-23%',

                                    margin: 'auto',
                                  }}
                                  alt='..'
                                />
                              </div>

                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '18%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                DB1 Gold
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '3%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  width: '80%',
                                  opacity: '70%',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.2em'
                                      : '1.0em'
                                    : '1.6em',
                                }}
                              >
                                In celebration of 25 years’ production of the
                                world’s leading professional reference monitors,
                                PMC launched the hand built, limited edition,
                                DB1 Gold - the finest version of the DB1 studio
                                monitor made to date. This updated model
                                provides the discerning music lover with a
                                window into the professional world, giving
                                access to the identical sound balance and vibe
                                intended by the recording artist, without
                                missing a single nuance. Features: hand-built
                                and limited edition, window into professional
                                world for the discerning listener.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={goldBanner}
                          alt='...'
                          style={{
                            width: '100%',
                            height: !isMobile ? '100%' : '100%',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                        <h1
                          style={{
                            background: 'rgba(0, 0, 0, 0.7)',
                            fontFamily: 'coldiac',
                            color: 'white',
                            paddingLeft: '10px',
                            paddingRight: '20px',
                            fontWeight: '400',
                            marginRight: 'auto',
                            marginLeft: '0',
                            marginBottom: '100%',
                            marginTop: '0',
                            position: 'absolute',
                            top: '0',
                            paddingTop: '15px',
                            paddingBottom: '15px',
                          }}
                        >
                          DB1 Gold
                        </h1>

                        <img
                          src={descriptionImage}
                          alt='..'
                          style={{
                            display: 'block',
                            margin: 'auto',
                            width: !isMobile ? '180px' : '90px',
                            position: 'absolute',
                            bottom: !isMobile ? '60px' : '20px',
                            zIndex: '6',
                            right: !isMobile ? '30px' : '10px',
                            padding: !isMobile ? '20px' : '5px',
                            background: 'rgba(255, 255, 255, 0.4)',
                          }}
                        ></img>
                      </div>
                    </div>

                    <button
                      class='carousel-control-prev'
                      type='button'
                      data-bs-target='#carouselExampleControls'
                      data-bs-slide='prev'
                      style={{ display: !isMobile ? 'none' : 'block' }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.cancelBubble = true;
                        if (e.stopPropagation) e.stopPropagation();
                        console.log('hoi');
                        setShowSpeakers(false);
                      }}
                    >
                      <span
                        class='carousel-control-prev-icon'
                        aria-hidden='true'
                      ></span>
                      <span class='visually-hidden'>Previous</span>
                    </button>
                    <button
                      class='carousel-control-next'
                      type='button'
                      data-bs-target='#carouselExampleControls'
                      data-bs-slide='next'
                      style={{ display: !isMobile ? 'none' : 'block' }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.cancelBubble = true;
                        if (e.stopPropagation) e.stopPropagation();
                        console.log('hoi');
                        setShowSpeakers(false);
                      }}
                    >
                      <span
                        class='carousel-control-next-icon'
                        aria-hidden='true'
                      ></span>
                      <span class='visually-hidden'>Next</span>
                    </button>
                  </div>
                  <button
                    className='carousel-control-next'
                    type='button'
                    data-bs-target='#carouselExampleControls'
                    data-bs-slide='next'
                    style={{
                      color: 'black',
                      width: '80px',
                      height: '60px',
                      background: 'rgb(0, 0, 0)',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      position: 'static',

                      display: isMobile ? 'none' : 'inline-block',
                    }}
                  >
                    <span
                      className='carousel-control-next-icon'
                      aria-hidden='true'
                    ></span>
                    <span className='visually-hidden'>Next</span>
                  </button>
                </div>
              </div>
            </div>
          </Slide>

          <div
            style={{
              width: '100vw',
              height: '70px',
              display: 'flex',
              justifyContent: 'center',
              background: 'rgb(35, 35, 35)',
              marginTop: '50px',
            }}
          >
            <p
              style={{
                margin: 'auto',
                marginLeft: '3%',
                fontFamily: 'lato',
                fontWeight: '700',
                fontSize: '0.7em',
                color: 'white',
              }}
            >
              © 2022 Thar Technologies
            </p>
            <p
              style={{
                margin: 'auto',
                marginRight: '3%',
                fontFamily: 'lato',
                fontWeight: '700',
                fontSize: !isBigScreen
                  ? !isMobile
                    ? '0.9em'
                    : '0.5em'
                  : '1.6em',
                color: 'white',
                opacity: '70%',
              }}
            >
              Designed & Developed by Workplay Studio Pvt. Ltd.
            </p>
          </div>
          <hr style={{ color: 'white', margin: '0' }} />
        </div>
      </Fade>
      {/* {disableScroll()} */}
    </div>
  );
};

export default Speakers;
