import React from 'react';
import audioVisualBackground from '../../assets/living-room.jpg';
import { useLocation } from 'react-router-dom';
import lightManagement from '../../assets/lightManagement.png';
import commercialAudio from '../../assets/commercialAudio.png';
import ipod from '../../assets/ipod.png';
import mirrorTv from '../../assets/mirrortv.png';
import img1 from '../../assets/tile-lux.jpg';
import img2 from '../../assets/scala.jpg';
import img3 from '../../assets/brior.jpg';
import HomeAutomation from './residential/home-automation/HomeAutomation';
import CommercialAutomation from './commercial/CommercialAutomation';
import CommercialAudio from './commercial/CommercialAudio';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Solutions = () => {
  const location = useLocation();

  const [subCategory, setSubCategory] = React.useState('');

  const [activeElement, setActiveElement] = React.useState('');

  const [isVeryBigScreen, setIsVeryBigScreen] = React.useState(
    window.matchMedia('(min-width: 2000px)').matches
  );

  const [isSmallScreen, setIsSmallScreen] = React.useState(
    window.matchMedia('(max-width: 1100px)').matches
  );

  const [isMobile, setIsMobile] = React.useState(
    window.matchMedia('(max-width: 768px)').matches
  );

  const query = useQuery();

  function checkActiveElement() {
    if (subCategory === 'residential') {
      if (query.get('active') === 'homeDecoration') {
        setActiveElement('homeDecoration');
      } else if (query.get('active') === 'audioVisual') {
        setActiveElement('audioVisual');
      } else {
        setActiveElement('');
      }
    } else if (subCategory === 'commercial') {
      if (query.get('active') === 'homeDecoration') {
        setActiveElement('homeDecoration');
      } else if (query.get('active') === 'audioVisual') {
        setActiveElement('audioVisual');
      } else {
        setActiveElement('');
      }
    }
  }

  React.useEffect(() => {
    console.log(location.pathname);
    if (location.pathname === '/solutions/residential') {
      console.log('residential');
      setSubCategory('residential');
    } else if (location.pathname === '/solutions/commercial') {
      setSubCategory('commercial');
    }
    checkActiveElement();
  }, [location]);

  React.useEffect(() => {
    checkActiveElement();
  }, [subCategory]);

  if (subCategory === 'residential') {
    console.log('active: ' + activeElement);

    if (activeElement !== 'homeDecoration') {
      return (
        <div>
          <div
            id='carouselExampleControls'
            className='carousel slide'
            data-bs-ride='carousel'
            data-interval='700'
            style={{
              position: 'absolute',
              top: '0',
              zIndex: '-1',
              borderRadius: 'none',
              width: '100vw',
              height: '100vh',
            }}
          >
            <div className='carousel-inner'>
              <div className={`carousel-item active`} id='commercialAudio'>
                <img
                  src={img2}
                  alt='...'
                  style={{
                    width: '100vw',
                    height: '100vh',
                    objectFit: 'cover',
                  }}
                />
              </div>
              <div className={`carousel-item`} id='ipod'>
                <img
                  src={img3}
                  alt='...'
                  style={{
                    width: '100vw',
                    height: '100vh',
                    objectFit: 'cover',
                  }}
                />
              </div>
            </div>

            <button
              className='carousel-control-prev'
              type='button'
              data-bs-target='#carouselExampleControls'
              data-bs-slide='prev'
            >
              <span
                className='carousel-control-prev-icon'
                aria-hidden='true'
              ></span>
              <span className='visually-hidden'>Previous</span>
            </button>
            <button
              className='carousel-control-next'
              type='button'
              data-bs-target='#carouselExampleControls'
              data-bs-slide='next'
            >
              <span
                className='carousel-control-next-icon'
                aria-hidden='true'
              ></span>
              <span className='visually-hidden'>Next</span>
            </button>
          </div>
        </div>
      );
    } else if (activeElement === 'homeDecoration') {
      return <HomeAutomation />;
    }
  } else if (subCategory === 'commercial') {
    console.log('commercial');

    if (activeElement !== 'homeDecoration' && activeElement !== 'audioVisual') {
      return (
        <div>
          <div
            id='carouselExampleControls'
            className='carousel slide'
            data-bs-ride='carousel'
            data-interval='700'
            style={{
              position: 'absolute',
              top: '0',
              zIndex: '-1',
              borderRadius: 'none',
              width: '100vw',
              height: '100vh',
            }}
          >
            <div className='carousel-inner'>
              <div className={`carousel-item active`} id='commercialAudio'>
                <img
                  src={img2}
                  alt='...'
                  style={{
                    width: '100vw',
                    height: '100vh',
                    objectFit: 'cover',
                  }}
                />
              </div>
              <div className={`carousel-item`} id='ipod'>
                <img
                  src={img3}
                  alt='...'
                  style={{
                    width: '100vw',
                    height: '100vh',
                    objectFit: 'cover',
                  }}
                />
              </div>
            </div>

            <button
              className='carousel-control-prev'
              type='button'
              data-bs-target='#carouselExampleControls'
              data-bs-slide='prev'
            >
              <span
                className='carousel-control-prev-icon'
                aria-hidden='true'
              ></span>
              <span className='visually-hidden'>Previous</span>
            </button>
            <button
              className='carousel-control-next'
              type='button'
              data-bs-target='#carouselExampleControls'
              data-bs-slide='next'
            >
              <span
                className='carousel-control-next-icon'
                aria-hidden='true'
              ></span>
              <span className='visually-hidden'>Next</span>
            </button>
          </div>
        </div>
      );
    } else if (activeElement === 'homeDecoration') {
      return <CommercialAutomation />;
    } else if (activeElement === 'audioVisual') {
      return <CommercialAudio />;
    }
  } else {
    return '';
  }
};

export default Solutions;
