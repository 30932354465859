import React from 'react';
import RevoxLogo from '../../../../../../assets/sonyBlack.png';
import Fade from '@mui/material/Fade';
import Grow from '@mui/material/Grow';
import { Fade as Slide } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import RevoxWhite from '../../../../../../assets/sonyWhite.png';
import logo from '../../../../../../assets/Logo Black.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link, useLocation } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import bannerBenq1 from '../../../../../../assets/sonyBanner.jpeg';
import bannerBenq2 from '../../../../../../assets/bannerBenq2.jpeg';
import backgroundImage from '../../../../../../assets/sonyLanding.jpeg';
import product from '../../../../../../assets/sonyProduct.png';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Speakers = () => {
  const [collection, setCollection] = React.useState(false);

  const location = useLocation();

  const query = useQuery();

  React.useEffect(() => {
    console.log(query.get('collection'));

    if (query.get('collection') === 'true') {
      setCollection(true);
    } else {
      setCollection(false);
    }
  }, [location]);

  React.useEffect(() => {
    console.log(query.get('collection'));

    if (query.get('collection') === 'true') {
      setCollection(true);
    } else {
      setCollection(false);
    }
  }, [query]);

  function detectMouseWheelDirection(e) {
    var delta = null,
      direction = false;
    if (!e) {
      // if the event is not provided, we get it from the window object
      e = window.event;
    }
    if (e.wheelDelta) {
      // will work in most cases
      delta = e.wheelDelta / 60;
    } else if (e.detail) {
      // fallback for Firefox
      delta = -e.detail / 2;
    }
    if (delta !== null) {
      direction = delta > 0 ? 'up' : 'down';
    }

    return direction;
  }

  const [smallPhone, setSmallPhone] = React.useState(
    window.matchMedia('(max-width: 420px)').matches
  );

  const [ultraWide, setUltraWide] = React.useState(
    window.matchMedia('(min-width: 3000px)').matches
  );

  const [audioVisualDropDown, setAudioVisualDropDown] = React.useState(false);

  React.useEffect(() => {
    if (document.getElementById('nav')) {
      document.getElementById('nav').style.display = 'none';
    }
    document.body.style.overflowY = 'visible';
  }, []);

  const [isMobile, setIsMobile] = React.useState(
    window.matchMedia('(max-width: 768px)').matches
  );

  const [showSpeakers, setShowSpeakers] = React.useState(false);

  const [currentColor, setCurrentColor] = React.useState('black');

  const [isVeryBigScreen, setIsVeryBigScreen] = React.useState(
    window.matchMedia('(min-width: 2000px)').matches
  );

  const [isSmallScreen, setIsSmallScreen] = React.useState(
    window.matchMedia('(max-width: 1300px)').matches
  );

  const [isVerySmallScreen, setIsVerySmallScreen] = React.useState(
    window.matchMedia('(max-width: 1200px)').matches
  );
  const [pcScreen, setPcScreen] = React.useState(
    window.matchMedia('(min-width: 1900px)').matches
  );

  const [lastY, setLastY] = React.useState(window.scrollY);

  var currentScrollNumber = 0;

  const [changeEnabled, setChangeEnabled] = React.useState(true);

  var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

  // React.useEffect(() => {
  //   if (!changeEnabled) {
  //     setTimeout(() => {
  //       setChangeEnabled(true);
  //       disableScroll();
  //     }, 100);
  //   }
  // }, [changeEnabled]);

  function preventDefault(e) {
    e.preventDefault();
    e.preventDefault();
    console.log(changeEnabled);

    console.log('scroll number valid: ' + (currentScrollNumber % 15) == 0);
    console.log('scroll number ' + currentScrollNumber);
    if (currentScrollNumber < 7) {
      currentScrollNumber++;
    } else {
      currentScrollNumber = 0;
    }
    if (
      currentScrollNumber == 3 ||
      currentScrollNumber == 7 ||
      currentScrollNumber == 5
    ) {
      if (detectMouseWheelDirection(e) == 'down') {
        if (currentProduct == 'loud-speakers' && changeEnabled) {
          console.log('network');
          setCurrentProduct('network-recievers');
          setChangeEnabled(false);
          return;
        } else if (currentProduct == 'network-recievers' && changeEnabled) {
          console.log('studio');
          setCurrentProduct('studio-art');

          setChangeEnabled(false);
          return;
        } else if (currentProduct == 'studio-art' && changeEnabled) {
          console.log('turntables');
          setCurrentProduct('turntables');
          setChangeEnabled(false);
          return;
        }
      } else {
        if (currentProduct == 'network-recievers' && changeEnabled) {
          console.log('network');

          setCurrentProduct('loud-speakers');
          setChangeEnabled(false);
          return;
        } else if (currentProduct == 'studio-art' && changeEnabled) {
          console.log('network');

          setCurrentProduct('network-recievers');
          setChangeEnabled(false);
          return;
        } else if (currentProduct == 'turntables' && changeEnabled) {
          setCurrentProduct('studio-art');
          setChangeEnabled(false);
          return;
        }
      }
    }
  }

  function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
      preventDefault(e);
      return false;
    }
  }

  const [currentProduct, setCurrentProduct] = React.useState('loud-speakers');
  const [direction, setDirection] = React.useState('up');

  // modern Chrome requires { passive: false } when adding event
  var supportsPassive = false;
  try {
    window.addEventListener(
      'test',
      null,
      Object.defineProperty({}, 'passive', {
        get: function () {
          supportsPassive = true;
        },
      })
    );
  } catch (e) {}

  const [aboutOpen, setAboutOpen] = React.useState(false);

  var wheelOpt = supportsPassive ? { passive: false } : false;
  var wheelEvent =
    'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

  // call this to Disable
  function disableScroll() {
    if (changeEnabled) {
      if (document.getElementById('carouselExampleControls')) {
        document
          .getElementById('carouselExampleControls')
          .addEventListener('DOMMouseScroll', preventDefault, false); // older FF
        document
          .getElementById('carouselExampleControls')
          .addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
        document
          .getElementById('carouselExampleControls')
          .addEventListener('touchmove', preventDefault, wheelOpt); // mobile
        document
          .getElementById('carouselExampleControls')
          .addEventListener('keydown', preventDefaultForScrollKeys, false);
      }

      const elements = Array.from(document.getElementsByClassName('grow'));

      elements.forEach((element) => {
        element.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
        element.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
        element.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
        element.addEventListener('keydown', preventDefaultForScrollKeys, false);
      });
    }
  }

  React.useEffect(() => {
    // disableScroll();
  }, [currentProduct, changeEnabled]);

  React.useEffect(() => {
    if (collection) {
      if (document.getElementById('speaker-nav')) {
        document.getElementById('speaker-nav').style.display = 'none';
      }
    } else {
      if (document.getElementById('speaker-nav')) {
        document.getElementById('speaker-nav').style.display = 'block';
      }
    }
  }, [collection]);
  React.useEffect(() => {
    if (collection) {
      if (document.getElementById('speaker-nav')) {
        document.getElementById('speaker-nav').style.display = 'none';
      }
    } else {
      if (document.getElementById('speaker-nav')) {
        document.getElementById('speaker-nav').style.display = 'block';
      }
    }
  }, [collection]);

  const useStyles = makeStyles(() =>
    createStyles({
      background: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100vw',
        zIndex: '-3',
        height: '100vh',

        objectFit: 'cover',
      },
      subHeading: {
        color: 'white',
        fontFamily: 'lato',
        fontWeight: '700',
        textAlign: 'center',
        marginTop: '8vh',
      },
      bottom: {
        width: '100vw',
        height: !isMobile ? '35vh' : '27vh',
        position: 'absolute',
        bottom: '0',
        background: 'rgba(0, 0, 0, 0.5)',
        overflow: 'auto',
      },
      smallBg: {
        position: 'fixed',
        width: '100vw',
        top: '130px',
        zIndex: '-1',
        display: 'block',
      },
      select: {
        '&:before': {
          borderColor: 'black',
        },
        '&:after': {
          borderColor: 'black',
        },
        '&:not(.Mui-disabled):hover::before': {
          borderColor: 'black',
        },
        display: 'block',
        margin: 'auto',
      },
      icon: {
        fill: 'black',
      },
      root: {
        color: 'black',
      },
    })
  );

  const classes = useStyles();

  const [brandDropDown, setBrandDropDown] = React.useState(false);

  return (
    <div>
      <Fade in={!collection} unmountOnExit>
        <div>
          <img src={backgroundImage} alt='..' className={classes.background} />

          <img
            src={RevoxWhite}
            alt='..'
            style={{
              display: 'block',
              margin: 'auto',
              marginTop: !isVeryBigScreen
                ? !isMobile
                  ? '40px'
                  : '20px'
                : '120px',
              background: 'rgba(0, 0, 0, 0.4)',
              padding: '20px',
              borderRadius: '10px',
              width: !isVeryBigScreen ? '200px' : '350px',
            }}
          />

          <Button
            variant='contained'
            style={{
              color: 'white',
              background: 'rgba(0, 0, 0, 0.7)',
              display: 'block',
              margin: 'auto',
              marginTop: !isMobile ? '6vh' : '2vh',
              width: !isVeryBigScreen ? '280px' : '400px',
              height: !isVeryBigScreen ? '50px' : '90px',
              fontFamily: 'lato',
              fontWeight: '700',
              border: '1px solid white',
              fontSize: !isVeryBigScreen ? '1em' : '1.6em',
              transitionProperty: 'all',
              transitionDuration: '0.7s',
            }}
            onMouseEnter={(e) => {
              e.target.style.background = 'rgba(0, 0, 0, 0.9)';
            }}
            onMouseLeave={(e) => {
              e.target.style.background = 'rgba(0, 0, 0, 0.7)';
            }}
            onClick={(e) => {
              setCollection(true);
            }}
          >
            View Collection
          </Button>
          <div className={classes.bottom}>
            <h5
              className={classes.subHeading}
              style={{
                marginTop: '2%',
                fontSize: !isVeryBigScreen ? '1.3em' : '2.7em',
              }}
            >
              About
            </h5>
            <h6
              className={classes.subHeading}
              style={{
                width: !isMobile ? '50%' : '93%',
                maxWidth: '800px',
                margin: 'auto',
                marginTop: '2%',
                opacity: '80%',
                fontSize: !isVeryBigScreen ? '1em' : '1.8em',
              }}
            >
              Standing for “Bringing Enjoyment ‘N’ Quality to Life”, the BenQ
              brand continues to involve in the areas of life that matter most
              to people – that have the greatest impact on the enjoyment and
              quality of LIFE today. BenQ believes that design and technology
              function best when they serve the real needs and wants of people.
              This concept is at the core of their people-driven approach to
              design and technology. They listen to everybody: from business
              professionals to gamers, continually asking questions to
              understand how they can best use design and technology to serve
              their needs. Because using design and technology to serve the real
              needs and wants of people matters.
            </h6>
          </div>
        </div>
      </Fade>

      <Fade in={collection} unmountOnExit>
        <div>
          <div
            className='topNav'
            style={{
              display: 'flex',
              width: '100vw',
              height: !isVeryBigScreen ? '70px' : '120px',
              justifyContent: 'center',
              marginTop: !isMobile ? '10px' : '-5px',
            }}
          >
            <div
              className='left'
              style={{
                marginRight: 'auto',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: '30px',
                height: '100%',
                display: !isMobile ? 'flex' : 'none',
                justifyContent: 'center',
              }}
            >
              <img
                src={logo}
                alt=''
                style={{
                  display: 'block',
                  margin: 'auto',
                  width: !isVeryBigScreen ? '250px' : '350px',
                  cursor: 'pointer',
                }}
                onClick={() => (window.location = '/')}
              />
            </div>
            <div
              className='left'
              style={{
                display: 'flex',
                marginLeft: 'auto',
                marginRight: !isMobile ? '50px' : 'auto',
                height: '100%',
                justifyContent: 'center',
              }}
            >
              <h5
                style={{
                  fontFamily: 'lato',
                  fontWeight: '700',
                  margin: 'auto',
                }}
              >
                <span
                  style={{
                    color: 'rgb(140, 140, 140)',
                    fontSize: !isMobile
                      ? !isVeryBigScreen
                        ? '1em'
                        : '1.8em'
                      : '0.6em',
                    cursor: 'pointer',
                    marginLeft: !isMobile ? '0px' : '-1.5vw',
                  }}
                  onClick={() => (window.location = '/')}
                >
                  &lt;
                </span>
                <span
                  style={{
                    color: 'rgb(140, 140, 140)',
                    fontSize: !isMobile
                      ? !isVeryBigScreen
                        ? '1em'
                        : '1.8em'
                      : '0.6em',
                    cursor: 'pointer',
                    marginLeft: !isMobile ? '10px' : '1vw',
                  }}
                  onClick={() => (window.location = '/')}
                >
                  Home
                </span>
                <span
                  style={{
                    color: 'rgb(140, 140, 140)',
                    fontSize: !isMobile
                      ? !isVeryBigScreen
                        ? '1em'
                        : '1.8em'
                      : '0.6em',
                    cursor: 'pointer',
                    marginLeft: !isMobile ? '40px' : '2vw',
                  }}
                  onClick={() => (window.location = '/')}
                >
                  |
                </span>
                <span
                  style={{
                    marginLeft: !isMobile
                      ? !isVeryBigScreen
                        ? '40px'
                        : '45px'
                      : '3vw',
                    fontSize: !isMobile
                      ? !isVeryBigScreen
                        ? '0.8em'
                        : '1.3em'
                      : '0.6em',
                    opacity: '70%',
                    whiteSpace: 'pre',
                  }}
                  onMouseEnter={() => {
                    if (isMobile) {
                      setAudioVisualDropDown(true);
                    }
                  }}
                  onMouseLeave={() => {
                    if (isMobile) {
                      setAudioVisualDropDown(false);
                    }
                  }}
                >
                  {!isMobile
                    ? 'Solutions    >    Residential    >    Audio/Visual    >'
                    : 'Solutions > Residential > Audio/Visual >'}
                </span>
                <span
                  style={{
                    marginLeft: !isMobile
                      ? !isVeryBigScreen
                        ? '30px'
                        : '60px'
                      : '1vw',
                    fontSize: !isMobile
                      ? !isVeryBigScreen
                        ? '1.2em'
                        : '2em'
                      : '0.7em',
                  }}
                  onMouseEnter={() => {
                    setAudioVisualDropDown(true);
                  }}
                  onMouseLeave={() => {
                    setAudioVisualDropDown(false);
                  }}
                >
                  {'Projectors '}
                </span>
              </h5>
              <Fade in={audioVisualDropDown}>
                <div
                  style={{
                    display: 'flex',
                    marginLeft: !pcScreen
                      ? !isVerySmallScreen
                        ? '500px'
                        : !isSmallScreen || isVerySmallScreen
                        ? !isMobile
                          ? '38.5vw'
                          : '73vw'
                        : '40vw'
                      : !isVeryBigScreen
                      ? '23vw'
                      : !ultraWide
                      ? '28.7vw'
                      : '21.5vw',
                    height: !isVeryBigScreen
                      ? !isMobile
                        ? '220px'
                        : '150px'
                      : '350px',
                    width: !isVeryBigScreen
                      ? !isMobile
                        ? '150px'
                        : '100px'
                      : '320px',
                    background: 'rgba(0, 0, 0, 0.7)',

                    border: 'none',
                    position: 'absolute',
                    top: !isVeryBigScreen
                      ? !isMobile
                        ? '65px'
                        : '123px'
                      : '110px',
                    border: 'none',
                    borderRadius: 'none',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    zIndex: '10',
                  }}
                  onMouseEnter={() => {
                    setAudioVisualDropDown(true);
                  }}
                  onMouseLeave={() => {
                    setAudioVisualDropDown(false);
                  }}
                >
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      color: 'white',
                    }}
                    to='/solutions/residential/audio/speakers/revox'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Speakers
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                    }}
                    to='/solutions/residential/audio/amplifiers/arcam'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Amplifiers
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      opacity: '70%',
                    }}
                    to='/solutions/residential/audio/screens/elite'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Screens
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                    }}
                    to='/solutions/residential/audio/projectors/benq'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Projectors
                  </Link>
                </div>
              </Fade>
            </div>
          </div>
          {!isMobile ? (
            <div
              className='nav2'
              style={{
                display: 'flex',
                height: '60px',
                justifyContent: 'center',
                marginTop: '40px',
                marginBottom: '-20px',
              }}
            >
              <p
                style={{
                  fontFamily: 'lato',
                  color: 'black',
                  opacity: '80%',
                  display: 'block',
                  margin: 'auto',
                  marginRight: 'auto',
                  marginLeft: '10vw',
                  fontWeight: '700',
                  fontSize: !isVeryBigScreen ? '1em' : '1.7em',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setCollection(false);
                  document.getElementById('speaker-nav').style.display =
                    'block';
                }}
              >
                &lt; Back to Audio Visual
              </p>
              <div
                className='right'
                style={{
                  display: 'flex',
                  margin: 'auto',
                  marginLeft: 'auto',
                  marginRight: '10vw',
                  width: '65%',
                  height: '100%',
                  maxWidth: '1200px',
                }}
              >
                <p
                  style={{
                    fontFamily: 'lato',
                    fontWeight: '500',
                    textAlign: 'center',
                    fontSize: !isVeryBigScreen ? '1.1em' : '1.7em',
                    display: 'block',
                    margin: 'auto',
                    textDecoration: 'none',
                    fontWeight: '700',
                    color:
                      currentProduct === 'loud-speakers'
                        ? 'black'
                        : 'rgb(120,120, 120)',
                    cursor: 'pointer',
                  }}
                  onClick={() => setCurrentProduct('loud-speakers')}
                >
                  Home Cinema Projectors
                </p>

                <img
                  src={RevoxLogo}
                  alt='..'
                  style={{
                    display: 'block',
                    margin: 'auto',
                    width: !isVeryBigScreen ? '130px' : '170px',
                    marginRight: '0',
                    borderBottom: '1px solid black',
                    paddingBottom: '5px',
                  }}
                  onMouseEnter={() => setBrandDropDown(true)}
                  onMouseLeave={() => setBrandDropDown(false)}
                />
                <ExpandMoreIcon
                  style={{
                    display: 'block',
                    margin: 'auto',
                    marginLeft: '0',
                    marginRight: '0',
                  }}
                ></ExpandMoreIcon>
                <Fade in={brandDropDown}>
                  <div
                    style={{
                      display: 'flex',
                      // marginLeft: '100vw',
                      height: !isVeryBigScreen ? '200px' : '350px',
                      width: !isVeryBigScreen ? '150px' : '320px',
                      background: 'rgba(0, 0, 0, 0.7)',
                      right: '10.5vw',
                      border: 'none',
                      position: 'absolute',
                      top: !isVeryBigScreen ? '170px' : '200px',
                      border: 'none',
                      borderRadius: 'none',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      paddingTop: '20px',
                      paddingBottom: '20px',
                      zIndex: '20',
                    }}
                    onMouseEnter={() => {
                      setBrandDropDown(true);
                    }}
                    onMouseLeave={() => {
                      setBrandDropDown(false);
                    }}
                  >
                    <Link
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        display: 'block',
                        margin: 'auto',
                        textDecoration: 'none',
                        fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                        opacity: '70%',
                        color: 'white',
                        fontWeight: '700',
                      }}
                      to='/solutions/residential/audio/projectors/benq?collection=true'
                      onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                      onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                    >
                      Benq
                    </Link>
                    <Link
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        display: 'block',
                        margin: 'auto',
                        textDecoration: 'none',
                        fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                        opacity: '70%',
                        fontWeight: '700',
                      }}
                      to='/solutions/residential/audio/projectors/sony?collection=true'
                      onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                      onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                    >
                      Sony
                    </Link>
                    <Link
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        display: 'block',
                        margin: 'auto',
                        textDecoration: 'none',
                        fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                        opacity: '70%',
                        opacity: '70%',
                        fontWeight: '700',
                      }}
                      to='/solutions/residential/audio/projectors/jvc?collection=true'
                      onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                      onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                    >
                      JVC
                    </Link>
                  </div>
                </Fade>
              </div>
            </div>
          ) : (
            <div>
              <p
                style={{
                  fontFamily: 'lato',
                  color: 'black',
                  opacity: '70%',
                  fontSize: !isMobile
                    ? !isVeryBigScreen
                      ? '1.2em'
                      : '2em'
                    : '1em',
                  textAlign: 'center',
                  fontWeight: '700',
                  marginTop: '-5px',
                  marginBottom: '15px',
                }}
                onClick={() => setCollection(false)}
              >
                &lt; Back to Audio Visual
              </p>
              <div style={{ display: 'flex' }}>
                <img
                  src={RevoxLogo}
                  alt='..'
                  style={{
                    display: 'block',
                    margin: 'auto',
                    width: !isVeryBigScreen ? '130px' : '170px',
                    // marginRight: '0',
                    borderBottom: '1px solid black',
                    paddingBottom: '5px',
                  }}
                  onClick={() => setBrandDropDown(true)}
                  onMouseLeave={() => setBrandDropDown(false)}
                />
              </div>
              <Fade in={brandDropDown}>
                <div
                  style={{
                    display: 'flex',
                    // marginLeft: '100vw',
                    height: !isVeryBigScreen ? '270px' : '420px',
                    width: !isVeryBigScreen ? '150px' : '320px',
                    background: 'rgba(0, 0, 0, 0.7)',
                    // right: '10.5vw',
                    border: 'none',
                    // position: 'absolute',
                    // top: !isVeryBigScreen ? '170px' : '200px',
                    border: 'none',
                    borderRadius: 'none',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    zIndex: '20',
                    marginLeft: '30vw',
                    position: 'absolute',
                  }}
                  onMouseEnter={() => {
                    setBrandDropDown(true);
                  }}
                  onMouseLeave={() => {
                    setBrandDropDown(false);
                  }}
                >
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      fontWeight: '700',
                    }}
                    to='/solutions/residential/audio/projectors/sony?collection=true'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Sony
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      opacity: '70%',
                      fontWeight: '700',
                    }}
                    to='/solutions/residential/audio/projectors/benq?collection=true'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    benq
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      fontWeight: '700',
                    }}
                    to='/solutions/residential/audio/projectors/jvc?collection=true'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    jvc
                  </Link>
                </div>
              </Fade>
              <FormControl
                style={{
                  width: '300px',
                  display: 'block',
                  margin: 'auto',
                  marginTop: '20px',
                  color: 'black',
                }}
              >
                <InputLabel id='demo-simple-select-label'>Product</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={currentProduct}
                  label='Age'
                  onChange={(e) => {
                    setCurrentProduct(e.target.value);
                  }}
                  className={classes.select}
                  inputProps={{
                    classes: {
                      icon: classes.icon,
                      root: classes.root,
                    },
                  }}
                  style={{ color: 'black', display: 'block', margin: 'auto' }}
                >
                  <MenuItem value={'loud-speakers'}>
                    Home Cinema Projectors
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          )}

          <Slide
            in={currentProduct === 'loud-speakers'}
            direction={direction}
            unmountOnExit
          >
            <div>
              <div
                className='part1'
                style={{ marginTop: '4vh', width: '100vw' }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '30px',
                    width: !isMobile ? '80vw' : '95vw',
                    height: !isMobile ? '80vh' : smallPhone ? '45vh' : '75vh',
                    width: '100vw',
                  }}
                >
                  <button
                    className='carousel-control-prev'
                    type='button'
                    data-bs-target='#carouselExampleControls'
                    data-bs-slide='prev'
                    style={{
                      color: 'black',
                      width: '120px',
                      height: '90px',
                      background: 'rgb(0, 0, 0)',
                      position: 'static',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginLeft: '0',
                      display: isMobile ? 'none' : 'inline-block',
                    }}
                  >
                    <span
                      className='carousel-control-prev-icon'
                      aria-hidden='true'
                      style={{ color: 'black' }}
                    ></span>
                    <span
                      className='visually-hidden secondary'
                      style={{ color: 'black' }}
                    >
                      Previous
                    </span>
                  </button>

                  <div
                    id='carouselExampleControls'
                    className='carousel slide'
                    data-interval='false'
                    onMouseEnter={(e) => {
                      setShowSpeakers(true);
                      console.log('mouse enter');
                    }}
                    onClick={(e) => {
                      setShowSpeakers(true);
                    }}
                    style={{
                      zIndex: '-1',
                      borderRadius: 'none',
                      width: !isMobile ? '80vw' : '95vw',
                      height: !isMobile ? '80vh' : smallPhone ? '45vh' : '75vh',
                      touchAction: 'none',
                      zIndex: '9',
                      marginLeft: '5px',
                      marginRight: '5px',
                      maxHeight: !isMobile ? 'none' : '250px',
                    }}
                    onMouseLeave={() => {
                      setShowSpeakers(false);
                      console.log('mouse leave');
                    }}
                  >
                    <div
                      className='carousel-inner'
                      style={{
                        height: !isMobile
                          ? '80vh'
                          : smallPhone
                          ? '45vh'
                          : '75vh',
                        marginBottom: '20px',
                        maxHeight: !isMobile ? 'none' : '250px',
                      }}
                    >
                      <div
                        className={`carousel-item active`}
                        id='prestige'
                        style={{ width: '100%', height: '100%' }}
                      >
                        <Modal
                          open={aboutOpen}
                          onClose={() => setAboutOpen(false)}
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Nesciunt, in. Beatae, enim
                                magni? Recusandae earum dolore animi pariatur
                                placeat praesentium. Quas ratione saepe ut earum
                                placeat, ipsa dolore velit voluptates. Velit
                                vitae explicabo nihil esse dignissimos
                                cupiditate cumque perferendis facere, corporis
                                doloremque modi dicta, a voluptatibus quod
                                harum, tempore nesciunt laborum. Quam, ducimus
                                optio repellendus voluptatum veniam impedit?
                                Blanditiis, aperiam! Vitae provident doloremque
                                consequuntur quos est eligendi quaerat facere
                                maxime recusandae fugiat adipisci sed quas enim
                                quia magni ea soluta velit eius obcaecati
                                molestias similique, quisquam vel repudiandae
                                quod. Vitae?
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginRight: 'auto',
                                marginLeft: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              Home Cinema Projector
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <img
                                  src={product}
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '70%' : '45%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: '-23%',
                                    marginRight: '-23%',

                                    margin: 'auto',
                                  }}
                                  alt='..'
                                />
                              </div>

                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '15%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                Home Cinema Projector
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '6%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  width: '90%',
                                  opacity: '70%',

                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.4em'
                                      : !isVerySmallScreen
                                      ? '1.2em'
                                      : '1em'
                                    : '1.8em',
                                }}
                              >
                                ⦁ Beautifully styled home cinema projectors that
                                bring movies, sport, games and more to life in
                                detail-packed 4K HDR with rich, vibrant colours
                                and spectacular contrast. The brand’s latest
                                home cinema projectors blend cutting-edge
                                picture technologies with flexible installation,
                                easy set-up and minimal maintenance. As featured
                                in our professional cinema projectors, advanced
                                SXRD (Silicon X-tal Reflective Display) panel
                                technology delivers native 4K (4096 x 2160)
                                resolution images with more than four times the
                                detail of Full HD – and no upscaling or other
                                fancy tricks.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={bannerBenq1}
                          alt='...'
                          style={{
                            width: '100%',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                        <h1
                          style={{
                            background: 'rgba(0, 0, 0, 0.7)',
                            fontFamily: 'coldiac',
                            color: 'white',
                            paddingLeft: '10px',
                            paddingRight: '20px',
                            fontWeight: '400',
                            marginRight: 'auto',
                            marginLeft: '0',
                            marginBottom: '100%',
                            marginTop: '0',
                            position: 'absolute',
                            top: '0',
                            paddingTop: '15px',
                            paddingBottom: '15px',
                          }}
                        >
                          Home Cinema Projectors
                        </h1>
                      </div>
                    </div>

                    <button
                      class='carousel-control-prev'
                      type='button'
                      data-bs-target='#carouselExampleControls'
                      data-bs-slide='prev'
                      style={{ display: !isMobile ? 'none' : 'block' }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.cancelBubble = true;
                        if (e.stopPropagation) e.stopPropagation();
                        console.log('hoi');
                        setShowSpeakers(false);
                      }}
                    >
                      <span
                        class='carousel-control-prev-icon'
                        aria-hidden='true'
                      ></span>
                      <span class='visually-hidden'>Previous</span>
                    </button>
                    <button
                      class='carousel-control-next'
                      type='button'
                      data-bs-target='#carouselExampleControls'
                      data-bs-slide='next'
                      style={{ display: !isMobile ? 'none' : 'block' }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.cancelBubble = true;
                        if (e.stopPropagation) e.stopPropagation();
                        console.log('hoi');
                        setShowSpeakers(false);
                      }}
                    >
                      <span
                        class='carousel-control-next-icon'
                        aria-hidden='true'
                      ></span>
                      <span class='visually-hidden'>Next</span>
                    </button>
                  </div>
                  <button
                    className='carousel-control-next'
                    type='button'
                    data-bs-target='#carouselExampleControls'
                    data-bs-slide='next'
                    style={{
                      color: 'black',
                      width: '120px',
                      height: '90px',
                      background: 'rgb(0, 0, 0)',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      position: 'static',

                      display: isMobile ? 'none' : 'inline-block',
                    }}
                  >
                    <span
                      className='carousel-control-next-icon'
                      aria-hidden='true'
                    ></span>
                    <span className='visually-hidden'>Next</span>
                  </button>
                </div>
              </div>
            </div>
          </Slide>
          <Slide
            in={currentProduct === 'network-recievers'}
            direction={direction}
            unmountOnExit
          >
            <div>
              <div
                className='part1'
                style={{ marginTop: '4vh', width: '100vw' }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '30px',
                    width: !isMobile ? '80vw' : '95vw',
                    height: !isMobile ? '80vh' : smallPhone ? '45vh' : '75vh',
                    width: '100vw',
                  }}
                >
                  <button
                    className='carousel-control-prev'
                    type='button'
                    data-bs-target='#carouselExampleControls'
                    data-bs-slide='prev'
                    style={{
                      color: 'black',
                      width: '120px',
                      height: '90px',
                      background: 'rgb(0, 0, 0)',
                      position: 'static',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginLeft: '0',
                      display: isMobile ? 'none' : 'inline-block',
                    }}
                  >
                    <span
                      className='carousel-control-prev-icon'
                      aria-hidden='true'
                      style={{ color: 'black' }}
                    ></span>
                    <span
                      className='visually-hidden secondary'
                      style={{ color: 'black' }}
                    >
                      Previous
                    </span>
                  </button>

                  <div
                    id='carouselExampleControls'
                    className='carousel slide'
                    data-interval='false'
                    onMouseEnter={(e) => {
                      setShowSpeakers(true);
                      console.log('mouse enter');
                    }}
                    onClick={(e) => {
                      setShowSpeakers(true);
                    }}
                    style={{
                      zIndex: '-1',
                      borderRadius: 'none',
                      width: !isMobile ? '80vw' : '95vw',
                      height: !isMobile ? '80vh' : smallPhone ? '45vh' : '75vh',
                      touchAction: 'none',
                      zIndex: '9',
                      marginLeft: '5px',
                      marginRight: '5px',
                    }}
                    onMouseLeave={() => {
                      setShowSpeakers(false);
                      console.log('mouse leave');
                    }}
                  >
                    <div
                      className='carousel-inner'
                      style={{
                        height: !isMobile
                          ? '80vh'
                          : smallPhone
                          ? '45vh'
                          : '75vh',
                        marginBottom: '20px',
                      }}
                    >
                      <div
                        className={`carousel-item active`}
                        id='prestige'
                        style={{ width: '100%', height: '100%' }}
                      >
                        <Modal
                          open={aboutOpen}
                          onClose={() => setAboutOpen(false)}
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                Delivering images in 2160p true 4K Ultra HD and
                                featuring THX-certified DCI-P3 HDR, the BenQ
                                CinePro series is the ultimate choice for those
                                who desire a luxurious film festival experience
                                right in the comfort of your own home theater.
                                True colors manifest true cinema. Employing the
                                full range of the DCI-P3 color space, BenQ’s
                                CinematicColor™ ensures your home cinema has the
                                color accuracy that goes beyond the industry
                                standards. BenQ is also the only brand that
                                provides an individualized calibration report
                                for every unit. Discerning eyes will appreciate
                                the expanded dynamic range that CinePro Series
                                delivers. BenQ’s exclusive HDR-PRO™ technology
                                ensures cinema connoisseurs reproduce the same
                                spectacular dynamic range as the director’s
                                envisioned. 4K UHD clarity will give your home
                                cinema lifelike image clarity that even many
                                commercial cinemas can’t match. Employing 4
                                times as many pixels as 1080p, the CinePro
                                Series’ 4K resolution creates amazingly clear
                                and detailed images for ultimate enjoyment.
                                Reserved only for best-in-class AV systems, THX
                                Certification guarantees accurate content
                                reproduction as directors intended. Luxury isn’t
                                just aesthetics. Featuring a lens array composed
                                of 14 individual elements, the CinePro Series
                                delivers true 4K clarity that far outstrips what
                                ordinary projectors are capable of. Features:
                                deep details with ultimate colours, 8.3 million
                                pixels of perfection, THX certified performance,
                                class-leading 4K lens architecture.
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginRight: 'auto',
                                marginLeft: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              Home Series Projectors
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <img
                                  src={product}
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '70%' : '45%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: '-23%',
                                    marginRight: '-23%',

                                    margin: 'auto',
                                  }}
                                  alt='..'
                                />
                              </div>

                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '2%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                Home Series Projectors
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '3%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  width: '98%',
                                  opacity: '70%',

                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.4em'
                                      : !isVerySmallScreen
                                      ? '1.2em'
                                      : '1em'
                                    : '1.8em',
                                }}
                              >
                                Delivering images in 2160p true 4K Ultra HD and
                                featuring THX-certified DCI-P3 HDR, the BenQ
                                CinePro series is the ultimate choice for those
                                who desire a luxurious film festival experience
                                right in the comfort of your own home theater.
                                True colors manifest true cinema. Employing the
                                full range of the DCI-P3 color space, BenQ’s
                                CinematicColor™ ensures your home cinema has the
                                color accuracy that goes beyond the industry
                                standards. BenQ is also the only brand that
                                provides an individualized calibration report
                                for every unit. Discerning eyes will appreciate
                                the expanded dynamic range that CinePro Series
                                delivers. BenQ’s exclusive HDR-PRO™ technology
                                ensures cinema connoisseurs reproduce the same
                                spectacular dynamic range as the director’s
                                envisioned. 4K UHD clarity will give your home
                                cinema lifelike image clarity that even many
                                commercial cinemas can’t match. Employing 4
                                times as many pixels as 1080p, the CinePro
                                Series’ 4K resolution creates amazingly clear
                                and detailed images for ultimate enjoyment.
                                Reserved only for best-in-class AV systems, THX
                                Certification guarantees accurate content
                                reproduction as directors intended. Luxury isn’t
                                just aesthetics. Featuring a lens array composed
                                of 14 individual elements, the CinePro Series
                                delivers true 4K clarity that far outstrips what
                                ordinary projectors are capable of.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={bannerBenq2}
                          alt='...'
                          style={{
                            width: '100%',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                        <h1
                          style={{
                            background: 'rgba(0, 0, 0, 0.7)',
                            fontFamily: 'lato',
                            color: 'white',
                            paddingLeft: '10px',
                            paddingRight: '20px',
                            fontWeight: '700',
                            marginRight: 'auto',
                            marginLeft: '0',
                            marginBottom: '100%',
                            marginTop: '0',
                            position: 'absolute',
                            top: '0',
                            paddingTop: '15px',
                            paddingBottom: '15px',
                          }}
                        >
                          Home Series Projectors
                        </h1>
                      </div>
                    </div>

                    <button
                      class='carousel-control-prev'
                      type='button'
                      data-bs-target='#carouselExampleControls'
                      data-bs-slide='prev'
                      style={{ display: !isMobile ? 'none' : 'block' }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.cancelBubble = true;
                        if (e.stopPropagation) e.stopPropagation();
                        console.log('hoi');
                        setShowSpeakers(false);
                      }}
                    >
                      <span
                        class='carousel-control-prev-icon'
                        aria-hidden='true'
                      ></span>
                      <span class='visually-hidden'>Previous</span>
                    </button>
                    <button
                      class='carousel-control-next'
                      type='button'
                      data-bs-target='#carouselExampleControls'
                      data-bs-slide='next'
                      style={{ display: !isMobile ? 'none' : 'block' }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.cancelBubble = true;
                        if (e.stopPropagation) e.stopPropagation();
                        console.log('hoi');
                        setShowSpeakers(false);
                      }}
                    >
                      <span
                        class='carousel-control-next-icon'
                        aria-hidden='true'
                      ></span>
                      <span class='visually-hidden'>Next</span>
                    </button>
                  </div>
                  <button
                    className='carousel-control-next'
                    type='button'
                    data-bs-target='#carouselExampleControls'
                    data-bs-slide='next'
                    style={{
                      color: 'black',
                      width: '120px',
                      height: '90px',
                      background: 'rgb(0, 0, 0)',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      position: 'static',

                      display: isMobile ? 'none' : 'inline-block',
                    }}
                  >
                    <span
                      className='carousel-control-next-icon'
                      aria-hidden='true'
                    ></span>
                    <span className='visually-hidden'>Next</span>
                  </button>
                </div>
              </div>
            </div>
          </Slide>
          <div
            style={{
              width: '100vw',
              height: '70px',
              display: 'flex',
              justifyContent: 'center',
              background: 'rgb(35, 35, 35)',
              marginTop: '50px',
            }}
          >
            <p
              style={{
                margin: 'auto',
                marginLeft: '3%',
                fontFamily: 'lato',
                fontWeight: '700',
                fontSize: '0.7em',
                color: 'white',
              }}
            >
              © 2022 Thar Technologies
            </p>
            <p
              style={{
                margin: 'auto',
                marginRight: '3%',
                fontFamily: 'lato',
                fontWeight: '700',
                fontSize: '0.9em',
                color: 'white',
                opacity: '70%',
              }}
            >
              Designed & Developed by Workplay Studio Pvt. Ltd.
            </p>
          </div>
          <hr style={{ color: 'white', margin: '0' }} />
        </div>
      </Fade>
      {/* {disableScroll()} */}
    </div>
  );
};

export default Speakers;
