import React from 'react';
import image1 from '../../../../assets/worksWithAsset1.png';
import image2 from '../../../../assets/worksWithAsset2.png';
import image3 from '../../../../assets/worksWithAsset3.png';
import image4 from '../../../../assets/worksWithAsset4.jpg';

const WorksWith = () => {
  const [isMobile, setIsMobile] = React.useState(
    window.matchMedia('(max-width: 768px)').matches
  );

  return (
    <div>
      <h2
        style={{
          fontFamily: 'lato',
          color: 'black',
          textAlign: 'left',
          marginTop: '5vh',
          marginLeft: '5vw',
          fontWeight: '700',
        }}
      >
        Expand your smart home system
      </h2>
      <h5 style={{ marginLeft: '5vw', marginTop: '40px', fontFamily: 'lato' }}>
        HomeWorks works with smart home solutions from other best-in-class
        brands, for voice, audio, temperature control and more
      </h5>
      <h6
        style={{
          color: 'crimson',
          fontFamily: 'lato',
          whiteSpace: 'pre-wrap',
          fontWeight: '700',
          marginTop: '40px',
          marginLeft: '5vw',
        }}
      >
        Voice Control {'    '}
        <span style={{ color: 'black' }}>|</span>
        {'     '}
        Audio Control {'    '}
        <span style={{ color: 'black' }}>|</span>
      </h6>
      <h3
        style={{
          fontFamily: 'lato',
          color: 'black',
          textAlign: 'left',
          marginTop: '120px',
          marginLeft: '5vw',
          fontWeight: '700',
        }}
      >
        Voice Control
      </h3>
      <div
        style={{
          display: !isMobile ? 'flex' : 'block',
          width: '80vw',
          marginLeft: '5vw',
        }}
      >
        <div
          className='1'
          style={{
            width: !isMobile ? '25%' : '85%',
            display: 'block',
            margin: 'auto',
          }}
        >
          <img src={image1} alt='' />
          <p
            style={{ marginTop: '5px', fontFamily: 'lato', fontWeight: '700' }}
          >
            Amazon Alexa
          </p>
          <p
            style={{ marginTop: '0px', fontFamily: 'lato', fontWeight: '400' }}
          >
            Alexa will get the lights for you. Just say the word.
          </p>
        </div>
        <div
          className='1'
          style={{
            width: !isMobile ? '25%' : '85%',
            display: 'block',
            margin: 'auto',
          }}
        >
          <img
            src={image2}
            alt=''
            style={{ marginBottom: '27px', position: 'relative', top: '27px' }}
          />
          <p
            style={{
              fontFamily: 'lato',
              fontWeight: '700',
              position: 'relative',
            }}
          >
            Google Assistant
          </p>
          <p
            style={{ marginTop: '0px', fontFamily: 'lato', fontWeight: '400' }}
          >
            Enjoy hands-free control of the lights in your home with the Google
            Home voice-enabled speaker powered by the Google Assistant.
          </p>
        </div>
        <div
          className='1'
          style={{
            width: !isMobile ? '25%' : '85%',
            display: 'block',
            margin: 'auto',
            marginTop: !isMobile ? 'auto' : '20px',
            position: 'relative',
            top: !isMobile ? '0' : '50px',
          }}
        >
          <img
            src={image3}
            alt=''
            style={{
              marginBottom: '-10px',
              position: 'relative',
              top: !isMobile ? '-10px' : '-10px',
            }}
          />
          <p style={{ fontFamily: 'lato', fontWeight: '700' }}>
            Apple® HomeKit
          </p>
          <p
            style={{ marginTop: '0px', fontFamily: 'lato', fontWeight: '400' }}
          >
            The Smart Bridge contains Apple HomeKit technology, so you can use
            Siri ® to control your lights, shades, and more!
          </p>
        </div>
      </div>
      <h3
        style={{
          fontFamily: 'lato',
          color: 'black',
          textAlign: 'left',
          marginTop: '50px',
          marginLeft: '5vw',
          fontWeight: '700',
        }}
      >
        Audio Control
      </h3>
      <div
        style={{
          display: !isMobile ? 'flex' : 'block',
          width: '80vw',
          marginLeft: '5vw',
          marginTop: '60px',
        }}
      >
        <div
          className='1'
          style={{
            width: !isMobile ? '25%' : '85%',
            display: 'block',
            margin: 'auto',
          }}
        >
          <img src={image4} alt='' />
          <p
            style={{ marginTop: '5px', fontFamily: 'lato', fontWeight: '700' }}
          >
            Sonos
          </p>
          <p
            style={{ marginTop: '0px', fontFamily: 'lato', fontWeight: '400' }}
          >
            Control your lights, shades and Sonos Home Sound System from
            anywhere.
          </p>
        </div>

        <div
          className='1'
          style={{ width: '25%', display: 'block', margin: 'auto' }}
        ></div>
        <div
          className='1'
          style={{ width: '25%', display: 'block', margin: 'auto' }}
        ></div>
      </div>
    </div>
  );
};

export default WorksWith;
