import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import logo from '../assets/Logo.png';
import Zoom from '@mui/material/Zoom';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { HashLink } from 'react-router-hash-link';

const Nav = () => {
  const [solutionsOn, setSolutionsOn] = React.useState(false);

  const [solutionsSubCategory, setSolutionsSubCategory] = React.useState('');

  const [residentialDropDownOn, setResidentialDropDownOn] =
    React.useState(false);

  const [commercialDropDownOn, setCommercialDropDownOn] = React.useState(false);

  const [residentialMouse, setResidentialMouse] = React.useState(false);

  const [commercialMouse, setCommercialMouse] = React.useState(false);

  const [residentialDropDownMouse, setResidentialDropDownMouse] =
    React.useState(false);

  const [commercialDropDownMouse, setCommercialDropDownMouse] =
    React.useState(false);

  const location = useLocation();

  const [audioVisualDropDownOn, setAudioVisualDropDownOn] =
    React.useState(false);

  const [audioVisualMouse, setAudioVisualMouse] = React.useState(false);

  const [audioVisualDropDownMouse, setAudioVisualDropDownMouse] =
    React.useState(false);

  const [isVeryBigScreen] = React.useState(
    window.matchMedia('(min-width: 2000px)').matches
  );

  const [pcScreen, setPcScreen] = React.useState(
    window.matchMedia('(min-width: 1900px)').matches
  );

  const [isSmallScreen, setIsSmallScreen] = React.useState(
    window.matchMedia('(max-width: 1300px)').matches
  );

  const [isVerySmallScreen, setIsVerySmallScreen] = React.useState(
    window.matchMedia('(max-width: 1150px)').matches
  );

  const [path, setPath] = React.useState('');

  const [aboutPath, setAboutPath] = React.useState('');

  const [homeAutomationOn, setHomeAutomationOn] = React.useState(false);

  const [isMobile, setIsMobile] = React.useState(
    window.matchMedia('(max-width: 768px)').matches
  );

  const [isUltraWide, setIsUltraWide] = React.useState(
    window.matchMedia('(min-width: 2800px)').matches
  );

  React.useEffect(() => {
    if (!isMobile) {
    }
  }, []);

  React.useEffect(() => {
    if (location.pathname.startsWith('/solutions')) {
      setPath('solutions');
      setSolutionsOn(true);
      if (
        location.pathname !== '/solutions/residential?active=homeDecoration' &&
        location.pathname !== '/contact' &&
        location.path !== '/projects'
      ) {
        console.log('No Home Automation');

        if (!isMobile) {
          document.getElementById('nav').style.background =
            'rgba(0, 0, 0, 0.7)';
          document.getElementById('nav').style.display = 'flex';
        }
      } else {
        setHomeAutomationOn(true);
      }
      if (location.pathname === '/solutions/residential') {
        setSolutionsSubCategory('residential');
      } else if (location.pathname === '/solutions/commercial') {
        setSolutionsSubCategory('commercial');
      } else {
        setSolutionsSubCategory('');
      }
    } else {
      setSolutionsSubCategory('');
      setSolutionsOn(false);
    }
    if (
      location.pathname.startsWith('/home') ||
      location.pathname === '/' ||
      location.pathname === ''
    ) {
      setPath('home');
    } else if (location.pathname.startsWith('/projects')) {
      setPath('projects');
    } else if (location.pathname.startsWith('/inTheMedia')) {
      setPath('media');
    } else if (location.pathname.startsWith('/aboutUs')) {
      setPath('about');
      console.log('hoi ' + window.location);

      if (location.pathname === '/aboutUs/who-we-are') {
        console.log('who-we-are');
        setAboutPath('whoWeAre');
      } else if (location.pathname === '/aboutUs/what-we-accomplished') {
        console.log('what-we-accomplished');

        setAboutPath('whatWeAccomplished');
      } else if (location.pathname === '/aboutUs/experience') {
        console.log('experience-center');

        setAboutPath('experienceCenter');
      } else {
        setAboutPath('whoWeAre');
      }
    } else if (location.pathname.startsWith('/contact')) {
      setPath('contact');
    } else if (location.pathname.startsWith('/brands')) {
      setPath('brands');
    }
  }, [location]);

  React.useEffect(() => {
    if (residentialMouse) {
      setResidentialDropDownOn(true);
    } else {
      if (residentialDropDownMouse) {
        setResidentialDropDownOn(true);
      } else {
        if (audioVisualDropDownMouse) {
          setResidentialDropDownOn(true);
        } else {
          setResidentialDropDownOn(false);
        }
      }
    }
  }, [residentialMouse]);
  React.useEffect(() => {
    if (residentialMouse) {
      setResidentialDropDownOn(true);
    } else {
      if (residentialDropDownMouse) {
        setResidentialDropDownOn(true);
      } else {
        if (audioVisualDropDownMouse) {
          setResidentialDropDownOn(true);
        } else {
          setResidentialDropDownOn(false);
        }
      }
    }
  }, [residentialDropDownMouse]);

  React.useEffect(() => {
    if (audioVisualMouse) {
      setAudioVisualDropDownOn(true);
    } else {
      if (!residentialDropDownMouse) {
        setResidentialDropDownOn(false);
        setAudioVisualDropDownOn(false);
      }
      if (audioVisualDropDownMouse) {
        setAudioVisualDropDownOn(true);
      } else {
        setAudioVisualDropDownOn(false);
      }
    }
  }, [audioVisualMouse]);

  React.useEffect(() => {
    if (audioVisualMouse) {
      setAudioVisualDropDownOn(true);
    } else {
      if (audioVisualDropDownMouse) {
        setAudioVisualDropDownOn(true);
      } else {
        setAudioVisualDropDownOn(false);
        setResidentialDropDownOn(false);
      }
    }
  }, [audioVisualDropDownMouse]);

  React.useEffect(() => {
    if (commercialMouse) {
      setCommercialDropDownOn(true);
    } else {
      if (commercialDropDownMouse) {
        setCommercialDropDownOn(true);
      } else {
        setCommercialDropDownOn(false);
      }
    }
  }, [commercialMouse]);

  React.useEffect(() => {
    if (commercialMouse) {
      setCommercialDropDownOn(true);
    } else {
      if (commercialDropDownMouse) {
        setCommercialDropDownOn(true);
      } else {
        setCommercialDropDownOn(false);
      }
    }
  }, [commercialDropDownMouse]);

  React.useState(() => {
    console.log(window.matchMedia('(max-width: 1300px)').matches);
  }, []);

  // mobile stuff
  const [navOpen, setNavOpen] = React.useState(false);
  const [solutionsClicked, setSolutionsClicked] = React.useState(false);

  const [residentialOpen, setResidentialOpen] = React.useState(false);

  const [audioVisualOpen, setAudioVisualOpen] = React.useState(false);

  const [commercialOpen, setCommercialOpen] = React.useState(false);

  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  React.useEffect(() => {
    if (solutionsClicked) {
      const elements = document.getElementsByClassName('links');

      Array.from(elements).forEach((element) => {
        element.style.display = 'none';
      });

      if (document.getElementById('mobile-solution-main')) {
        document.getElementById('mobile-solution-main').style.marginTop =
          '-23vh';
        document.getElementById('mobile-solution-main').style.fontSize =
          '1.4em';
      }

      setTimeout(() => {
        if (document.getElementById('solution-links')) {
          document.getElementById('solution-links').style.display = 'flex';
          document.getElementById('solution-links').style.opacity = '100%';
        }
      }, 1000);
    } else {
      const elements = document.getElementsByClassName('links');

      Array.from(elements).forEach((element) => {
        element.style.display = 'block';
      });
      if (document.getElementById('mobile-solution-main')) {
        document.getElementById('mobile-solution-main').style.marginTop =
          '11vh';
        document.getElementById('mobile-solution-main').style.fontSize =
          '1.1em';
      }
      if (document.getElementById('solution-links')) {
        document.getElementById('solution-links').style.display = 'none';
        document.getElementById('solution-links').style.opacity = '100%';
      }
    }
  }, [solutionsClicked]);

  React.useEffect(() => {
    if (!navOpen) {
      setSolutionsClicked(false);
      setResidentialOpen(false);
    }
  }, [navOpen]);

  if (!isMobile) {
    return (
      <div
        style={{
          width: '100vw',
          height: !isVeryBigScreen
            ? solutionsOn || path === 'about'
              ? '150px'
              : '100px'
            : solutionsOn || path === 'about'
            ? '220px'
            : '160px',
          background: 'rgba(0, 0, 0, 0.7)',
          display: 'flex',
          marginBottom: !isVeryBigScreen
            ? solutionsOn || path === 'about'
              ? '150px'
              : '100px'
            : solutionsOn || path === 'about'
            ? '220px'
            : '160px',
          position: 'fixed',
          top: '0',
          zIndex: '10',
          justifyContent: 'center',
          transitionProperty: 'all',
          transitionDuration: '0.7s',
        }}
        id='nav'
      >
        <img
          src={logo}
          alt='..'
          style={
            !isVeryBigScreen
              ? {
                  display: 'block',
                  margin: 'auto',
                  marginLeft: '2vw',
                  width: '17vw',
                  cursor: 'pointer',
                }
              : {
                  display: 'block',
                  margin: 'auto',
                  marginLeft: '1vw',
                  width: '15vw',
                  cursor: 'pointer',
                }
          }
          onClick={() => (window.location = '/')}
        />
        <div
          className='links-desktop'
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: !isSmallScreen ? '51%' : !isVerySmallScreen ? '55%' : '69%',
            marginLeft: 'auto',
            height: '100%',
            marginRight: '2%',
          }}
        >
          <div
            className='topLinks'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '90px',
            }}
          >
            <Link
              to='/home'
              style={{
                textAlign: 'center',
                color: 'white',
                textDecoration: 'none',
                display: 'block',
                margin: 'auto',
                fontFamily: 'Lato',
                fontSize: !isVeryBigScreen ? '1.1em' : '2em',
                opacity: path === 'home' ? '100%' : '60%',
                fontWeight: '400',
              }}
              onMouseEnter={(e) => (e.target.style.opacity = '100%')}
              onMouseLeave={(e) => {
                if (path !== 'home') {
                  e.target.style.opacity = '60%';
                }
              }}
            >
              Home
            </Link>

            <Link
              to='/solutions/residential'
              style={{
                textAlign: 'center',
                color: 'white',
                textDecoration: 'none',
                display: 'block',
                margin: 'auto',
                fontFamily: 'Lato',
                fontSize: !isVeryBigScreen ? '1.1em' : '2em',
                opacity: path === 'solutions' ? '100%' : '60%',
                fontWeight: '400',
              }}
              onMouseEnter={(e) => (e.target.style.opacity = '100%')}
              onMouseLeave={(e) => {
                if (path !== 'solutions') {
                  e.target.style.opacity = '60%';
                }
              }}
            >
              Solutions
            </Link>
            <Link
              to='/brands'
              style={{
                textAlign: 'center',
                color: 'white',
                textDecoration: 'none',
                display: 'block',
                margin: 'auto',
                fontFamily: 'Lato',
                fontSize: !isVeryBigScreen ? '1.1em' : '2em',
                opacity: path === 'brands' ? '100%' : '60%',
                fontWeight: '400',
              }}
              onMouseEnter={(e) => (e.target.style.opacity = '100%')}
              onMouseLeave={(e) => {
                if (path !== 'brands') {
                  e.target.style.opacity = '60%';
                }
              }}
            >
              Brands
            </Link>
            <Link
              to='/aboutUs'
              style={{
                textAlign: 'center',
                color: 'white',
                textDecoration: 'none',
                display: 'block',
                margin: 'auto',
                fontFamily: 'Lato',
                fontSize: !isVeryBigScreen ? '1.1em' : '2em',
                opacity: path === 'about' ? '100%' : '60%',
                fontWeight: '400',
              }}
              onMouseEnter={(e) => (e.target.style.opacity = '100%')}
              onMouseLeave={(e) => {
                if (path !== 'about') {
                  e.target.style.opacity = '60%';
                }
              }}
            >
              About Us
            </Link>
            <Link
              to='/projects'
              style={{
                textAlign: 'center',
                color: 'white',
                textDecoration: 'none',
                display: 'block',
                margin: 'auto',
                fontFamily: 'Lato',
                fontSize: !isVeryBigScreen ? '1.1em' : '2em',
                opacity: path === 'projects' ? '100%' : '60%',
                fontWeight: '400',
              }}
              onMouseEnter={(e) => (e.target.style.opacity = '100%')}
              onMouseLeave={(e) => {
                if (path !== 'projects') {
                  e.target.style.opacity = '60%';
                }
              }}
            >
              Our Projects
            </Link>
            <Link
              to='/inTheMedia'
              style={{
                textAlign: 'center',
                color: 'white',
                textDecoration: 'none',
                display: 'block',
                margin: 'auto',
                fontFamily: 'Lato',
                fontSize: !isVeryBigScreen ? '1.1em' : '2em',
                opacity: path === 'media' ? '100%' : '60%',
                fontWeight: '400',
              }}
              onMouseEnter={(e) => (e.target.style.opacity = '100%')}
              onMouseLeave={(e) => {
                if (path !== 'media') {
                  e.target.style.opacity = '60%';
                }
              }}
            >
              In The Media
            </Link>

            <Link
              to='/contact'
              style={{
                textAlign: 'center',
                color: 'white',
                textDecoration: 'none',
                display: 'block',
                margin: 'auto',
                fontFamily: 'Lato',
                fontSize: !isVeryBigScreen ? '1.1em' : '2em',
                opacity: path === 'contact' ? '100%' : '60%',
                fontWeight: '400',
              }}
              onMouseEnter={(e) => (e.target.style.opacity = '100%')}
              onMouseLeave={(e) => {
                if (path !== 'contact') {
                  e.target.style.opacity = '60%';
                }
              }}
            >
              Contact Us
            </Link>
          </div>
          <p
            style={{
              display: solutionsOn ? 'block' : 'none',
              marginLeft: 'auto',
              marginRight: !pcScreen
                ? '82%'
                : isVeryBigScreen
                ? !isUltraWide
                  ? '82%'
                  : '63%'
                : '81%',
              fontFamily: 'Lato',
              color: 'white',
              marginTop: '-20px',
              marginBottom: '12px',
              fontSize: !isVeryBigScreen ? '1.1em' : '2em',
              fontWeight: '400',
            }}
          >
            ^
          </p>
          <div
            className='bottomLinks'
            style={{
              display: solutionsOn ? 'flex' : 'none',
              width: '60%',
              height: '25px',
              marginLeft: 'auto',
              marginRight: !pcScreen
                ? '36%'
                : isVeryBigScreen
                ? !isUltraWide
                  ? '36%'
                  : '16.8%'
                : '35.5%',
              marginTop: '-15px',
            }}
          >
            <Link
              to='/solutions/residential'
              style={{
                textAlign: 'center',
                color: 'white',
                textDecoration: 'none',
                display: 'block',
                margin: 'auto',
                fontFamily: 'Lato',
                fontSize: !isVeryBigScreen ? '1.1em' : '2em',
                opacity:
                  solutionsSubCategory === 'residential' ? '100%' : '65%',
                fontWeight: '400',
              }}
              onMouseEnter={() => setResidentialMouse(true)}
              onMouseLeave={() => setResidentialMouse(false)}
            >
              Residential
            </Link>
            <Link
              to='/solutions/commercial'
              style={{
                textAlign: 'center',
                color: 'white',
                textDecoration: 'none',
                display: 'block',
                margin: 'auto',
                fontFamily: 'Lato',
                fontSize: !isVeryBigScreen ? '1.1em' : '2em',
                opacity: solutionsSubCategory === 'commercial' ? '100%' : '65%',
                fontWeight: '400',
              }}
              onMouseEnter={() => setCommercialMouse(true)}
              onMouseLeave={() => setCommercialMouse(false)}
            >
              Commercial
            </Link>
          </div>
          <p
            style={{
              display: path === 'about' ? 'block' : 'none',
              marginLeft: 'auto',
              marginRight: '57%',
              fontFamily: 'Lato',
              color: 'white',
              marginTop: '-20px',
              marginBottom: '12px',
              fontSize: !isVeryBigScreen ? '1.1em' : '2em',
              fontWeight: '400',
            }}
          >
            ^
          </p>
          <div
            className='bottomLinks'
            style={{
              display: path === 'about' ? 'flex' : 'none',
              width: '85%',
              height: '25px',
              marginLeft: '-1%',
              marginRight: '7%',
              marginTop: '-15px',
            }}
          >
            <HashLink
              to='/aboutUs/who-we-are'
              style={{
                textAlign: 'center',
                color: 'white',
                textDecoration: 'none',
                display: 'block',
                margin: 'auto',
                fontFamily: 'Lato',
                fontSize: !isVeryBigScreen ? '1.1em' : '2em',
                opacity:
                  aboutPath === 'whoWeAre' || aboutPath === '' ? '100%' : '65%',
                fontWeight: '400',
              }}
              id='who-we-are-link'
              onMouseEnter={(e) => (e.target.style.opacity = '100%')}
              onMouseLeave={(e) => {
                if (aboutPath !== 'whoWeAre' && aboutPath !== '') {
                  e.target.style.opacity = '65%';
                }
              }}
            >
              Who We Are
            </HashLink>
            <HashLink
              to='/aboutUs/what-we-accomplished'
              style={{
                textAlign: 'center',
                color: 'white',
                textDecoration: 'none',
                display: 'block',
                margin: 'auto',
                fontFamily: 'Lato',
                fontSize: !isVeryBigScreen ? '1.1em' : '2em',
                opacity: aboutPath === 'whatWeAccomplished' ? '100%' : '65%',
                fontWeight: '400',
              }}
              onMouseEnter={(e) => (e.target.style.opacity = '100%')}
              onMouseLeave={(e) => {
                if (aboutPath !== 'whatWeAccomplished') {
                  e.target.style.opacity = '65%';
                }
              }}
            >
              What We Accomplished
            </HashLink>
            <HashLink
              to='/aboutUs/experience'
              style={{
                textAlign: 'center',
                color: 'white',
                textDecoration: 'none',
                display: 'block',
                margin: 'auto',
                fontFamily: 'Lato',
                fontSize: !isVeryBigScreen ? '1.1em' : '2em',
                opacity: aboutPath === 'experienceCenter' ? '100%' : '65%',
                fontWeight: '400',
              }}
              onMouseEnter={(e) => (e.target.style.opacity = '100%')}
              onMouseLeave={(e) => {
                if (aboutPath !== 'experienceCenter') {
                  e.target.style.opacity = '65%';
                }
              }}
            >
              Experience Center
            </HashLink>
          </div>
          <Zoom in={solutionsSubCategory === 'residential'}>
            <div
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: !isVeryBigScreen
                  ? isSmallScreen
                    ? isVerySmallScreen
                      ? '74.6%'
                      : '74.8%'
                    : !pcScreen
                    ? '75%'
                    : '76%'
                  : '75.4%',
                height: !isVeryBigScreen ? '1px' : '2px',
                width: !isVeryBigScreen ? '87px' : '150px',
                background: 'white',
                marginTop: !isVeryBigScreen ? '3px' : '22px',
              }}
            ></div>
          </Zoom>
          <Zoom in={solutionsSubCategory === 'commercial'}>
            <div
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: !isVerySmallScreen ? '45%' : '28%',
                height: !isVeryBigScreen ? '1.1px' : '2px',
                width: !isVeryBigScreen ? '88px' : '150px',
                background: 'white',
                marginTop: !isVeryBigScreen ? '0' : '4px',
              }}
            ></div>
          </Zoom>
          <Fade
            in={residentialDropDownOn}
            style={{ transitionDuration: '0.6s' }}
          >
            <div
              style={{
                display: !isSafari ? 'flex' : 'inline-flex',
                marginLeft: 'auto',
                marginRight: !pcScreen
                  ? isSmallScreen
                    ? !isSafari
                      ? '30%'
                      : '15%'
                    : !isSafari
                    ? '32%'
                    : '16%'
                  : isVeryBigScreen
                  ? !isUltraWide
                    ? !isSafari
                      ? '13%'
                      : '6.5%'
                    : !isSafari
                    ? '11%'
                    : '5.5%'
                  : !isSafari
                  ? '31%'
                  : '15.5%',
                height: !isVeryBigScreen ? '150px' : '250px',
                width: !isVeryBigScreen ? '190px' : '350px',
                background: 'rgba(0, 0, 0, 0.7)',
                marginTop: !isVeryBigScreen
                  ? !isSafari
                    ? '299px'
                    : '150px'
                  : !isUltraWide
                  ? !isSafari
                    ? '470px'
                    : '235px'
                  : !isSafari
                  ? '465px'
                  : '233px',
                border: 'none',
                position: !isSafari ? 'absolute' : 'fixed',
                border: 'none',
                borderRadius: 'none',
                flexDirection: 'column',
                justifyContent: 'center',
                paddingTop: '20px',
                paddingBottom: '20px',
                right: !isSafari
                  ? 'auto'
                  : !pcScreen
                  ? isSmallScreen
                    ? !isSafari
                      ? '34%'
                      : '19%'
                    : !isSafari
                    ? '36%'
                    : '20%'
                  : isVeryBigScreen
                  ? !isUltraWide
                    ? !isSafari
                      ? '17%'
                      : '10.5%'
                    : !isSafari
                    ? '15%'
                    : '9.5%'
                  : !isSafari
                  ? '35%'
                  : '19.5%',
              }}
              onMouseEnter={() => setResidentialDropDownMouse(true)}
              onMouseLeave={() => setResidentialDropDownMouse(false)}
            >
              <Link
                style={{
                  fontFamily: 'lato',
                  color: 'white',
                  display: 'block',
                  margin: 'auto',
                  textDecoration: 'none',
                  fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                  opacity: homeAutomationOn ? '100%' : '70%',
                  fontWeight: '400',
                }}
                to='/solutions/residential?active=homeDecoration'
                onMouseEnter={(e) => {
                  e.target.style.opacity = '100%';
                }}
                onMouseLeave={(e) => {
                  if (!homeAutomationOn) {
                    e.target.style.opacity = '70%';
                  }
                }}
              >
                Home Automation
              </Link>
              <Link
                style={{
                  fontFamily: 'lato',
                  color: 'white',
                  display: 'block',
                  margin: 'auto',
                  textDecoration: 'none',
                  fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                  opacity: '70%',
                  fontWeight: '400',
                }}
                to='/solutions/residential?active=audioVisual'
                onMouseEnter={(e) => {
                  setAudioVisualMouse(true);
                  e.target.style.opacity = '100%';
                }}
                onMouseLeave={(e) => {
                  setAudioVisualMouse(false);
                  e.target.style.opacity = '70%';
                }}
              >
                Audio Visual
              </Link>
            </div>
          </Fade>
          <Fade
            in={commercialDropDownOn}
            style={{ transitionDuration: '0.6s' }}
          >
            <div
              style={{
                display: 'flex',
                marginLeft: 'auto',
                marginRight: !pcScreen
                  ? isSmallScreen
                    ? '0%'
                    : '2%'
                  : isVeryBigScreen
                  ? !isUltraWide
                    ? '-23%'
                    : '-21%'
                  : '1%',
                height: !isVeryBigScreen ? '150px' : '250px',
                width: !isVeryBigScreen ? '190px' : '350px',
                background: 'rgba(0, 0, 0, 0.7)',
                marginTop: !isVeryBigScreen
                  ? !isSafari
                    ? '299px'
                    : '150px'
                  : !isUltraWide
                  ? !isSafari
                    ? '470px'
                    : '235px'
                  : !isSafari
                  ? '465px'
                  : '232px',
                border: 'none',
                position: 'absolute',
                border: 'none',
                borderRadius: 'none',
                flexDirection: 'column',
                justifyContent: 'center',
                paddingTop: '20px',
                paddingBottom: '20px',
              }}
              onMouseEnter={() => setCommercialDropDownMouse(true)}
              onMouseLeave={() => setCommercialDropDownMouse(false)}
            >
              <Link
                style={{
                  fontFamily: 'lato',
                  color: 'white',
                  display: 'block',
                  margin: 'auto',
                  textDecoration: 'none',
                  fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                  opacity: homeAutomationOn ? '100%' : '70%',
                  fontWeight: '400',
                }}
                to='/solutions/commercial?active=homeDecoration'
                onMouseEnter={(e) => {
                  e.target.style.opacity = '100%';
                }}
                onMouseLeave={(e) => {
                  if (!homeAutomationOn) {
                    e.target.style.opacity = '70%';
                  }
                }}
              >
                Commercial Automation
              </Link>
              <Link
                style={{
                  fontFamily: 'lato',
                  color: 'white',
                  display: 'block',
                  margin: 'auto',
                  textDecoration: 'none',
                  fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                  opacity: '70%',
                  fontWeight: '400',
                }}
                to='/solutions/commercial?active=audioVisual'
                onMouseEnter={(e) => {
                  e.target.style.opacity = '100%';
                }}
                onMouseLeave={(e) => {
                  e.target.style.opacity = '70%';
                }}
              >
                Commercial Audio
              </Link>
            </div>
          </Fade>

          <Fade in={false} style={{ transitionDuration: '0.6s' }}>
            <div
              style={{
                display: 'flex',
                marginLeft: 'auto',
                marginRight: !isSmallScreen ? '-18%' : '-20%',
                height: !isVeryBigScreen ? '230px' : '340px',
                width: !isVeryBigScreen ? '270px' : '420px',
                background: 'rgba(0, 0, 0, 0.7)',
                marginTop: !isVeryBigScreen ? '350px' : '530px',
                border: 'none',
                position: 'absolute',
                border: 'none',
                borderRadius: 'none',
                flexDirection: 'column',
                justifyContent: 'center',
                paddingTop: '20px',
                paddingBottom: '20px',
              }}
              onMouseEnter={() => setCommercialDropDownMouse(true)}
              onMouseLeave={() => setCommercialDropDownMouse(false)}
            >
              <Link
                style={{
                  fontFamily: 'lato',
                  color: 'white',
                  display: 'block',
                  margin: 'auto',
                  textDecoration: 'none',
                  fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                  opacity: '70%',
                  fontWeight: '400',
                }}
                to='/solutions/commercial?active=lightManagement'
                onMouseEnter={(e) => {
                  e.target.style.opacity = '100%';
                }}
                onMouseLeave={(e) => {
                  e.target.style.opacity = '70%';
                }}
              >
                Light Management System
              </Link>
              <Link
                style={{
                  fontFamily: 'lato',
                  color: 'white',
                  display: 'block',
                  margin: 'auto',
                  textDecoration: 'none',
                  fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                  opacity: '70%',
                  fontWeight: '400',
                }}
                to='/solutions/commercial?active=commercialAudio'
                onMouseEnter={(e) => {
                  e.target.style.opacity = '100%';
                }}
                onMouseLeave={(e) => {
                  e.target.style.opacity = '70%';
                }}
              >
                Commercial Audio Solutions
              </Link>
              <Link
                style={{
                  fontFamily: 'lato',
                  color: 'white',
                  display: 'block',
                  margin: 'auto',
                  textDecoration: 'none',
                  fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                  opacity: '70%',
                  fontWeight: '400',
                }}
                to='/solutions/commercial?active=ipod'
                onMouseEnter={(e) => {
                  e.target.style.opacity = '100%';
                }}
                onMouseLeave={(e) => {
                  e.target.style.opacity = '70%';
                }}
              >
                Ipod Docking Stations
              </Link>
              <Link
                style={{
                  fontFamily: 'lato',
                  color: 'white',
                  display: 'block',
                  margin: 'auto',
                  textDecoration: 'none',
                  fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                  opacity: '70%',
                  fontWeight: '400',
                }}
                to='/solutions/commercial?active=mirrorTv'
                onMouseEnter={(e) => {
                  e.target.style.opacity = '100%';
                }}
                onMouseLeave={(e) => {
                  e.target.style.opacity = '70%';
                }}
              >
                Proffesional Mirror Televisions
              </Link>
            </div>
          </Fade>

          <Fade
            in={audioVisualDropDownOn}
            style={{ transitionDuration: '0.6s' }}
          >
            <div
              style={{
                display: 'flex',
                marginLeft: 'auto',
                marginRight: !isVeryBigScreen
                  ? !isSmallScreen
                    ? !pcScreen
                      ? '6.9%'
                      : '10.4%'
                    : isVerySmallScreen
                    ? '-3.2%'
                    : '0.7%'
                  : !isUltraWide
                  ? '-6.2%'
                  : '1.15%',
                height: !isVeryBigScreen ? '220px' : '350px',
                width: !isVeryBigScreen ? '190px' : '370px',
                background: 'rgba(0, 0, 0, 0.7)',
                marginTop: !isVeryBigScreen
                  ? !isSafari
                    ? '390px'
                    : '195px'
                  : !isSafari
                  ? '570px'
                  : '285px',
                border: 'none',
                position: 'absolute',
                border: 'none',
                borderRadius: 'none',
                flexDirection: 'column',
                justifyContent: 'center',
                paddingTop: '20px',
                paddingBottom: '20px',
                right: !isSafari
                  ? 'auto'
                  : !isVeryBigScreen
                  ? !isSmallScreen
                    ? !pcScreen
                      ? '15.7%'
                      : '19.2%'
                    : isVerySmallScreen
                    ? '2%'
                    : '3.5%'
                  : !isUltraWide
                  ? '-6%'
                  : '0.95%',
              }}
              onMouseEnter={() => {
                setAudioVisualDropDownMouse(true);
              }}
              onMouseLeave={() => {
                setAudioVisualDropDownMouse(false);
              }}
            >
              <Link
                style={{
                  fontFamily: 'lato',
                  color: 'white',
                  display: 'block',
                  margin: 'auto',
                  textDecoration: 'none',
                  fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                  opacity: '70%',
                  fontWeight: '400',
                }}
                to='/solutions/residential/audio/speakers/revox'
                onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                onMouseLeave={(e) => (e.target.style.opacity = '70%')}
              >
                Speakers
              </Link>
              <Link
                style={{
                  fontFamily: 'lato',
                  color: 'white',
                  display: 'block',
                  margin: 'auto',
                  textDecoration: 'none',
                  fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                  opacity: '70%',
                  fontWeight: '400',
                }}
                to='/solutions/residential/audio/amplifiers/arcam'
                onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                onMouseLeave={(e) => (e.target.style.opacity = '70%')}
              >
                Amplifiers
              </Link>
              <Link
                style={{
                  fontFamily: 'lato',
                  color: 'white',
                  display: 'block',
                  margin: 'auto',
                  textDecoration: 'none',
                  fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                  opacity: '70%',
                  opacity: '70%',
                  fontWeight: '400',
                }}
                to='/solutions/residential/audio/screens/elite'
                onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                onMouseLeave={(e) => (e.target.style.opacity = '70%')}
              >
                Screens
              </Link>
              <Link
                style={{
                  fontFamily: 'lato',
                  color: 'white',
                  display: 'block',
                  margin: 'auto',
                  textDecoration: 'none',
                  fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                  opacity: '70%',
                  fontWeight: '400',
                }}
                to='/solutions/residential/audio/projectors/benq'
                onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                onMouseLeave={(e) => (e.target.style.opacity = '70%')}
              >
                Projectors
              </Link>
            </div>
          </Fade>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <Drawer anchor='right' open={navOpen} onClose={() => setNavOpen(false)}>
          <div
            style={{
              backgroundColor: 'rgb(10, 10, 10)',
              width: '65vw',
              height: '100vh',
            }}
          >
            <Fade
              in={navOpen}
              style={{ transitionDelay: '0.4s', transitionDuration: '1s' }}
            >
              <div>
                <div
                  className='top'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '8%',
                  }}
                >
                  <Button
                    style={{
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: '3%',
                    }}
                    onClick={() => {
                      if (solutionsClicked) {
                        setSolutionsClicked(false);
                      } else {
                        setNavOpen(false);
                      }
                    }}
                  >
                    {solutionsClicked ? (
                      <ArrowBackIosIcon style={{ color: 'white' }} />
                    ) : (
                      <CloseIcon style={{ color: 'white' }} />
                    )}
                  </Button>
                </div>
                <div
                  style={{
                    marginTop: '4%',
                    width: '100%',
                    height: !solutionsClicked ? '60%' : '0',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '15%',
                  }}
                >
                  <Link
                    style={{
                      color: 'white',
                      textDecoration: 'none',
                      fontFamily: 'lato',
                      display: 'block',
                      marginTop: '50px',
                      transitionProperty: 'all',
                      transitionDelay: '0.2s',
                      transitionDuration: '1.5s',
                    }}
                    to='/'
                    className='links'
                    onClick={() => setNavOpen(false)}
                  >
                    Home
                  </Link>

                  <div>
                    <Link
                      style={{
                        display: 'block',
                        color: 'white',
                        textDecoration: 'none',
                        fontFamily: 'lato',
                        marginTop: !solutionsClicked ? '50px' : '110px',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                      to='/solutions/residential'
                      id='mobile-solutions'
                      onClick={(e) => {
                        setSolutionsClicked(true);
                      }}
                    >
                      {solutionsClicked ? '' : 'Solutions'}
                    </Link>
                    <Link
                      style={{
                        display: solutionsClicked ? 'block' : 'none',
                        color: 'white',
                        textDecoration: 'none',
                        fontFamily: 'lato',
                        marginTop: '-24px',
                        marginBottom: '10px',
                        position: 'sticky',
                        fontSize: '1.1em',
                        transitionProperty: 'all',
                        transitionDelay: '0.2s',
                        transitionDuration: '0.6s',
                      }}
                      to='/solutions/residential'
                      id='mobile-solution-main'
                    >
                      Solutions
                    </Link>
                  </div>
                  <Link
                    style={{
                      color: 'white',
                      textDecoration: 'none',
                      fontFamily: 'lato',
                      display: 'block',
                      marginTop: '50px',
                      transitionProperty: 'all',
                      transitionDelay: '0.2s',
                      transitionDuration: '1.5s',
                    }}
                    to='/brands'
                    className='links'
                    onClick={() => setNavOpen(false)}
                  >
                    Brands
                  </Link>
                  <Link
                    style={{
                      display: !solutionsClicked ? 'block' : 'none',
                      color: 'white',
                      textDecoration: 'none',
                      fontFamily: 'lato',
                      marginTop: '50px',
                      transitionProperty: 'all',
                      transitionDelay: '0.2s',
                      transitionDuration: '1.5s',
                    }}
                    to='/contact'
                    className='links'
                    onClick={() => setNavOpen(false)}
                  >
                    Contact Us
                  </Link>
                  <Link
                    style={{
                      display: 'block',
                      color: 'white',
                      textDecoration: 'none',
                      fontFamily: 'lato',
                      marginTop: '50px',
                      transitionProperty: 'all',
                      transitionDelay: '0.2s',
                      transitionDuration: '1.5s',
                    }}
                    to='/inTheMedia'
                    className='links'
                    onClick={() => setNavOpen(false)}
                  >
                    In The Media
                  </Link>
                  <Link
                    style={{
                      display: 'block',
                      color: 'white',
                      textDecoration: 'none',
                      fontFamily: 'lato',
                      marginTop: '50px',
                      transitionProperty: 'all',
                      transitionDelay: '0.2s',
                      transitionDuration: '1.5s',
                    }}
                    to='/projects'
                    className='links'
                    onClick={() => setNavOpen(false)}
                  >
                    Our Projects
                  </Link>
                </div>
                <div
                  id='solution-links'
                  style={{
                    display: 'none',
                    height: '80%',
                    marginTop: '22%',
                    flexDirection: 'column',
                    paddingLeft: '5%',
                    opacity: '0',
                    transitionProperty: 'all',
                    transitionDuration: '1s',
                    transitionDelay: '0.2s',
                  }}
                >
                  <div
                    class='accordion accordion-flush'
                    id='accordionFlushExample'
                  >
                    <div
                      class='accordion-item'
                      style={{ background: 'transparent', color: 'white' }}
                    >
                      <h2
                        class='accordion-header'
                        id='flush-headingOne'
                        style={{ background: 'transparent', color: 'white' }}
                      >
                        <button
                          class='accordion-button collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#flush-collapseOne'
                          aria-expanded='false'
                          aria-controls='flush-collapseOne'
                          style={{ background: 'transparent', color: 'white' }}
                        >
                          Residential
                        </button>
                      </h2>
                      <div
                        id='flush-collapseOne'
                        class='accordion-collapse collapse'
                        aria-labelledby='flush-headingOne'
                        data-bs-parent='#accordionFlushExample'
                      >
                        <div
                          class='accordion-body'
                          style={{
                            padding: '0',
                            paddingTop: '10px',
                            backgroundColor: '#292929',
                            width: '102%',
                            marginLeft: '-5%',
                            paddingBottom: '20px',
                            paddingLeft: '5%',
                            paddingTop: '20px',
                          }}
                        >
                          <Link
                            to='/solutions/residential?active=homeDecoration'
                            style={{
                              textAlign: 'center',
                              fontFamily: 'lato',
                              color: 'white',
                              textDecoration: 'none',
                              fontWeight: 'lighter',
                              marginTop: '30px',
                              marginBottom: '15px',
                              marginLeft: '8%',
                              marginBottom: '15px',
                            }}
                            onClick={() => setNavOpen(false)}
                          >
                            Home Automation
                          </Link>
                          <div
                            class='accordion accordion-flush'
                            id='accordion2'
                            style={{ marginTop: '15px' }}
                          >
                            <div
                              class='accordion-item'
                              style={{ background: 'transparent' }}
                            >
                              <h2
                                class='accordion-header'
                                id='flush-sdsd'
                                style={{ background: 'transparent' }}
                              >
                                <button
                                  class='accordion-button collapsed'
                                  type='button'
                                  data-bs-toggle='collapse'
                                  data-bs-target='#flush-sd'
                                  aria-expanded='false'
                                  aria-controls='flush-collapseOne'
                                  style={{
                                    background: 'transparent',
                                    color: 'white',
                                    fontFamily: 'lato',
                                  }}
                                >
                                  Audio Visual
                                </button>
                              </h2>
                              <div
                                id='flush-sd'
                                class='accordion-collapse collapse'
                                aria-labelledby='flush-sfsfd'
                                data-bs-parent='#accordion2'
                              >
                                <div
                                  class='accordion-body'
                                  style={{
                                    paddingLeft: '17%',
                                    paddingTop: '10px',
                                    background: '#313030',
                                    width: '105%',
                                    marginLeft: '-5%',
                                    paddingBottom: '20px',
                                  }}
                                >
                                  <div>
                                    <Link
                                      style={{
                                        display: 'block',
                                        margin: 'auto',
                                        textDecoration: 'none',
                                        color: 'white',
                                        fontWeight: 'lighter',
                                      }}
                                      to='/solutions/residential/audio/speakers/revox'
                                      onClick={() => setNavOpen(false)}
                                    >
                                      Speakers
                                    </Link>
                                    <Link
                                      style={{
                                        display: 'block',
                                        margin: 'auto',
                                        textDecoration: 'none',
                                        color: 'white',
                                        fontWeight: 'lighter',
                                        marginTop: '10px',
                                      }}
                                      to='/solutions/residential/audio/amplifiers/arcam'
                                      onClick={() => setNavOpen(false)}
                                    >
                                      Amplifiers
                                    </Link>
                                    <Link
                                      style={{
                                        display: 'block',
                                        margin: 'auto',
                                        textDecoration: 'none',
                                        color: 'white',
                                        fontWeight: 'lighter',
                                        marginTop: '10px',
                                      }}
                                      to='/solutions/residential/audio/projectors/benq'
                                      onClick={() => setNavOpen(false)}
                                    >
                                      Projectors
                                    </Link>
                                    <Link
                                      style={{
                                        display: 'block',
                                        margin: 'auto',
                                        textDecoration: 'none',
                                        color: 'white',
                                        fontWeight: 'lighter',
                                        marginTop: '10px',
                                      }}
                                      to='/solutions/residential/audio/screens/elite'
                                      onClick={() => setNavOpen(false)}
                                    >
                                      Screens
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class='accordion-item'
                      style={{
                        marginTop: '10px',
                        background: 'transparent',
                        color: 'white',
                      }}
                    >
                      <h2
                        class='accordion-header'
                        id='flush-headingTwo'
                        style={{ background: 'transparent', color: 'white' }}
                      >
                        <button
                          class='accordion-button collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#flush-collapseTwo'
                          aria-expanded='false'
                          aria-controls='flush-collapseTwo'
                          style={{ background: 'transparent', color: 'white' }}
                        >
                          Commercial
                        </button>
                      </h2>
                      <div
                        id='flush-collapseTwo'
                        class='accordion-collapse collapse'
                        aria-labelledby='flush-headingTwo'
                        data-bs-parent='#accordionFlushExample'
                      >
                        <div
                          class='accordion-body'
                          style={{
                            padding: '0',
                            paddingTop: '20px',
                            backgroundColor: '#292929',
                            width: '105%',
                            marginLeft: '-5%',
                            paddingBottom: '20px',
                            paddingLeft: '13%',
                          }}
                        >
                          <div>
                            <Link
                              style={{
                                display: 'block',
                                margin: 'auto',
                                textDecoration: 'none',
                                color: 'white',
                                fontWeight: 'lighter',
                              }}
                              to='/solutions/commercial?active=homeDecoration'
                              onClick={() => setNavOpen(false)}
                            >
                              Commercial Automation
                            </Link>
                            <Link
                              style={{
                                display: 'block',
                                margin: 'auto',
                                textDecoration: 'none',
                                color: 'white',
                                fontWeight: 'lighter',
                                marginTop: '20px',
                              }}
                              to='/solutions/commercial?active=audioVisual'
                              onClick={() => setNavOpen(false)}
                            >
                              Commercial Audio
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </Drawer>

        <div
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            width: '100vw',
            height: '80px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={logo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '180px',
              top: '20px',
              position: 'absolute',
            }}
          />
          <Button
            style={{ display: 'block', marginLeft: 'auto', marginRight: '2vw' }}
            onClick={() => setNavOpen(true)}
          >
            <MenuIcon style={{ color: 'white' }} />
          </Button>
        </div>
      </>
    );
  }
};

export default Nav;
