import React from 'react';
import RevoxLogo from '../../../../../../assets/SonusFaber.svg';
import Fade from '@mui/material/Fade';
import Grow from '@mui/material/Grow';
import { Fade as Slide } from '@mui/material';
import revoxBg from '../../../../../../assets/sonusBg.png';
import { createStyles, makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import RevoxWhite from '../../../../../../assets/sonus-faber-logo-tagline.png';
import logo from '../../../../../../assets/Logo Black.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link, useLocation } from 'react-router-dom';
import olympicaBanner from '../../../../../../assets/olympicaBanner.png';
import olympicaProduct from '../../../../../../assets/olympicaProduct.jpg';
import luminaProduct from '../../../../../../assets/luminaProduct.jpg';
import luminaBanner from '../../../../../../assets/luminaBanner.png';
import minimaProduct from '../../../../../../assets/minimaProduct.jpg';
import minimaBanner from '../../../../../../assets/minimaBanner.jpg';
import sonnetoBanner from '../../../../../../assets/sonettoBanner.png';
import sonnetoProduct from '../../../../../../assets/sonettoProduct.jpg';
import palladioProduct from '../../../../../../assets/palladioProduct.jpeg';
import palladioBanner from '../../../../../../assets/palladioBanner.jpg';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import amatiBanner from '../../../../../../assets/amatiBanner.jpg';
import amatiProduct from '../../../../../../assets/amatiProduct.jpg';
import serafinoProduct from '../../../../../../assets/serafinoProduct.png';
import serafinoBanner from '../../../../../../assets/serafinoBanner.jpg';
import sf16Banner from '../../../../../../assets/sf16Banner.jpg';
import sf16Product from '../../../../../../assets/sf16Product.jpg';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Speakers = () => {
  const [isBigScreen, setIsBigScreen] = React.useState(
    window.matchMedia('(min-width: 2000px)').matches
  );

  const [collection, setCollection] = React.useState(false);

  const [aboutOpen, setAboutOpen] = React.useState(false);

  const location = useLocation();

  const query = useQuery();

  const [smallPhone, setSmallPhone] = React.useState(
    window.matchMedia('(max-width: 420px)').matches
  );

  React.useEffect(() => {
    console.log(query.get('collection'));

    if (query.get('collection') === 'true') {
      setCollection(true);
    } else {
      setCollection(false);
    }
  }, [location]);

  React.useEffect(() => {
    console.log(query.get('collection'));

    if (query.get('collection') === 'true') {
      setCollection(true);
    } else {
      setCollection(false);
    }
  }, [query]);

  const [ultraWide, setUltraWide] = React.useState(
    window.matchMedia('(min-width: 3000px)').matches
  );

  const [audioVisualDropDown, setAudioVisualDropDown] = React.useState(false);

  function detectMouseWheelDirection(e) {
    var delta = null,
      direction = false;
    if (!e) {
      // if the event is not provided, we get it from the window object
      e = window.event;
    }
    if (e.wheelDelta) {
      // will work in most cases
      delta = e.wheelDelta / 60;
    } else if (e.detail) {
      // fallback for Firefox
      delta = -e.detail / 2;
    }
    if (delta !== null) {
      direction = delta > 0 ? 'up' : 'down';
    }

    return direction;
  }

  React.useEffect(() => {
    if (document.getElementById('nav')) {
      document.getElementById('nav').style.display = 'none';
    }
    document.body.style.overflowY = 'visible';
  }, []);

  const [isMobile, setIsMobile] = React.useState(
    window.matchMedia('(max-width: 768px)').matches
  );

  const [showSpeakers, setShowSpeakers] = React.useState(false);

  const [currentColor, setCurrentColor] = React.useState('black');

  const [isVeryBigScreen, setIsVeryBigScreen] = React.useState(
    window.matchMedia('(min-width: 2000px)').matches
  );

  const [isSmallScreen, setIsSmallScreen] = React.useState(
    window.matchMedia('(max-width: 1300px)').matches
  );

  const [isVerySmallScreen, setIsVerySmallScreen] = React.useState(
    window.matchMedia('(max-width: 1200px)').matches
  );
  const [pcScreen, setPcScreen] = React.useState(
    window.matchMedia('(min-width: 1900px)').matches
  );

  const [lastY, setLastY] = React.useState(window.scrollY);

  var currentScrollNumber = 0;

  const [changeEnabled, setChangeEnabled] = React.useState(true);

  var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

  // React.useEffect(() => {
  //   if (!changeEnabled) {
  //     setTimeout(() => {
  //       setChangeEnabled(true);
  //       disableScroll();
  //     }, 100);
  //   }
  // }, [changeEnabled]);

  function preventDefault(e) {
    e.preventDefault();
    e.preventDefault();
    console.log(changeEnabled);

    console.log('scroll number valid: ' + (currentScrollNumber % 15) == 0);
    console.log('scroll number ' + currentScrollNumber);
    if (currentScrollNumber < 7) {
      currentScrollNumber++;
    } else {
      currentScrollNumber = 0;
    }
    if (
      currentScrollNumber == 3 ||
      currentScrollNumber == 7 ||
      currentScrollNumber == 5
    ) {
      if (detectMouseWheelDirection(e) == 'down') {
        if (currentProduct == 'loud-speakers' && changeEnabled) {
          console.log('network');
          setCurrentProduct('network-recievers');
          setChangeEnabled(false);
          return;
        } else if (currentProduct == 'network-recievers' && changeEnabled) {
          console.log('studio');
          setCurrentProduct('studio-art');

          setChangeEnabled(false);
          return;
        } else if (currentProduct == 'studio-art' && changeEnabled) {
          console.log('turntables');
          setCurrentProduct('turntables');
          setChangeEnabled(false);
          return;
        }
      } else {
        if (currentProduct == 'network-recievers' && changeEnabled) {
          console.log('network');

          setCurrentProduct('loud-speakers');
          setChangeEnabled(false);
          return;
        } else if (currentProduct == 'studio-art' && changeEnabled) {
          console.log('network');

          setCurrentProduct('network-recievers');
          setChangeEnabled(false);
          return;
        } else if (currentProduct == 'turntables' && changeEnabled) {
          setCurrentProduct('studio-art');
          setChangeEnabled(false);
          return;
        }
      }
    }
  }

  function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
      preventDefault(e);
      return false;
    }
  }

  const [currentProduct, setCurrentProduct] = React.useState('loud-speakers');
  const [direction, setDirection] = React.useState('up');

  // modern Chrome requires { passive: false } when adding event
  var supportsPassive = false;
  try {
    window.addEventListener(
      'test',
      null,
      Object.defineProperty({}, 'passive', {
        get: function () {
          supportsPassive = true;
        },
      })
    );
  } catch (e) {}

  var wheelOpt = supportsPassive ? { passive: false } : false;
  var wheelEvent =
    'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

  // call this to Disable
  function disableScroll() {
    if (changeEnabled) {
      if (document.getElementById('carouselExampleControls')) {
        document
          .getElementById('carouselExampleControls')
          .addEventListener('DOMMouseScroll', preventDefault, false); // older FF
        document
          .getElementById('carouselExampleControls')
          .addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
        document
          .getElementById('carouselExampleControls')
          .addEventListener('touchmove', preventDefault, wheelOpt); // mobile
        document
          .getElementById('carouselExampleControls')
          .addEventListener('keydown', preventDefaultForScrollKeys, false);
      }

      const elements = Array.from(document.getElementsByClassName('grow'));

      elements.forEach((element) => {
        element.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
        element.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
        element.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
        element.addEventListener('keydown', preventDefaultForScrollKeys, false);
      });
    }
  }

  React.useEffect(() => {
    // disableScroll();
  }, [currentProduct, changeEnabled]);

  React.useEffect(() => {
    if (collection) {
      if (document.getElementById('speaker-nav')) {
        document.getElementById('speaker-nav').style.display = 'none';
      }
    } else {
      if (document.getElementById('speaker-nav')) {
        document.getElementById('speaker-nav').style.display = 'block';
      }
    }
  }, [collection]);

  const useStyles = makeStyles(() =>
    createStyles({
      background: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100vw',
        zIndex: '-3',
        height: '100vh',

        objectFit: 'cover',
      },
      subHeading: {
        color: 'white',
        fontFamily: 'lato',
        fontWeight: '700',
        textAlign: 'center',
        marginTop: '8vh',
      },
      bottom: {
        width: '100vw',
        height: !isMobile ? '30vh' : '25vh',
        position: 'absolute',
        bottom: '0',
        background: 'rgba(0, 0, 0, 0.5)',
        overflow: 'auto',
      },
      smallBg: {
        position: 'fixed',
        width: '100vw',
        top: '130px',
        zIndex: '-1',
        display: 'block',
      },
      select: {
        display: 'block',
        margin: 'auto',
        '&:before': {
          borderColor: 'black',
        },
        '&:after': {
          borderColor: 'black',
        },
        '&:not(.Mui-disabled):hover::before': {
          borderColor: 'black',
        },
      },
      icon: {
        fill: 'black',
      },
      root: {
        color: 'black',
      },
    })
  );
  const classes = useStyles();

  const [brandDropDown, setBrandDropDown] = React.useState(false);

  React.useEffect(() => {
    if (collection) {
      if (document.querySelector('.carousel')) {
        document
          .querySelector('.carousel')
          .addEventListener('slid.bs.carousel', function () {
            if (
              document.querySelector('.active').id === 'loud-speakers' ||
              document.querySelector('.active').id === 'network-recievers' ||
              document.querySelector('.active').id === 'studio-art' ||
              document.querySelector('.active').id === 'turntables' ||
              document.querySelector('.active').id === 'palladio' ||
              document.querySelector('.active').id === 'homage'
            ) {
              setCurrentProduct(document.querySelector('.active').id);
            }
          });
      }
    }
  }, [collection]);
  React.useEffect(() => {
    if (collection) {
      if (currentProduct === 'loud-speakers') {
        document.querySelector('.active').classList.remove('active');
        document.getElementById('loud-speakers').classList.add('active');
      } else if (currentProduct === 'network-recievers') {
        document.querySelector('.active').classList.remove('active');
        document.getElementById('network-recievers').classList.add('active');
      } else if (currentProduct === 'studio-art') {
        document.querySelector('.active').classList.remove('active');
        document.getElementById('studio-art').classList.add('active');
      } else if (currentProduct === 'turntables') {
        document.querySelector('.active').classList.remove('active');
        document.getElementById('turntables').classList.add('active');
      } else if (currentProduct === 'palladio') {
        document.querySelector('.active').classList.remove('active');
        document.getElementById('palladio').classList.add('active');
      } else if (currentProduct === 'homage') {
        document.querySelector('.active').classList.remove('active');
        document.getElementById('homage').classList.add('active');
      }
    }
  }, [currentProduct]);

  return (
    <div>
      <Fade in={!collection} unmountOnExit>
        <div>
          <img src={revoxBg} alt='..' className={classes.background} />

          <img
            src={RevoxWhite}
            alt='..'
            style={{
              display: 'block',
              margin: 'auto',
              marginTop: !isVeryBigScreen
                ? !isMobile
                  ? '40px'
                  : '20px'
                : '120px',
              background: 'rgba(0, 0, 0, 0.4)',
              padding: '20px',
              borderRadius: '10px',
              width: !isVeryBigScreen ? '240px' : '400px',
            }}
          />

          <Button
            variant='contained'
            style={{
              color: 'white',
              background: 'rgba(0, 0, 0, 0.7)',
              display: 'block',
              margin: 'auto',
              marginTop: '6vh',
              width: !isVeryBigScreen ? '280px' : '400px',
              height: !isVeryBigScreen ? '50px' : '90px',
              fontFamily: 'lato',
              fontWeight: '700',
              border: '1px solid white',
              fontSize: !isVeryBigScreen ? '1em' : '1.6em',
              transitionProperty: 'all',
              transitionDuration: '0.7s',
            }}
            onMouseEnter={(e) => {
              e.target.style.background = 'rgba(0, 0, 0, 0.9)';
            }}
            onMouseLeave={(e) => {
              e.target.style.background = 'rgba(0, 0, 0, 0.7)';
            }}
            onClick={(e) => {
              setCollection(true);
            }}
          >
            View Collection
          </Button>
          <div className={classes.bottom}>
            <h5
              className={classes.subHeading}
              style={{
                marginTop: '2%',
                fontSize: !isVeryBigScreen ? '1.3em' : '2.7em',
              }}
            >
              About
            </h5>
            <h6
              className={classes.subHeading}
              style={{
                width: !isMobile ? '50%' : '93%',
                maxWidth: '800px',
                margin: 'auto',
                marginTop: '2%',
                opacity: '80%',
                fontSize: !isVeryBigScreen ? '1em' : '1.8em',
              }}
            >
              Sonus Faber is one of the highest quality audio brands on the
              market. Sonus Faber is a Latin phrase that translates to
              “handcrafted sound,” which is an accurate title for the company.
              Since it's founding in 1983, the Italian-based brand has grown
              into one of the most popular loudspeaker organizations, delivering
              intricate, handmade products worldwide.
            </h6>
          </div>
        </div>
      </Fade>

      <Fade in={collection} unmountOnExit>
        <div>
          <div
            className='topNav'
            style={{
              display: 'flex',
              width: '100vw',
              height: !isVeryBigScreen ? '70px' : '120px',
              justifyContent: 'center',
              marginTop: '10px',
            }}
          >
            <div
              className='left'
              style={{
                marginRight: 'auto',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: '30px',
                height: '100%',
                display: !isMobile ? 'flex' : 'none',
                justifyContent: 'center',
              }}
            >
              <img
                src={logo}
                alt=''
                style={{
                  display: 'block',
                  margin: 'auto',
                  width: !isVeryBigScreen ? '250px' : '350px',
                  cursor: 'pointer',
                }}
                onClick={() => (window.location = '/')}
              />
            </div>
            <div
              className='left'
              style={{
                display: 'flex',
                marginLeft: 'auto',
                marginRight: '50px',
                height: '100%',
                justifyContent: 'center',
              }}
            >
              <h5
                style={{
                  fontFamily: 'lato',
                  fontWeight: '700',
                  margin: 'auto',
                }}
              >
                <span
                  style={{
                    color: 'rgb(140, 140, 140)',
                    fontSize: !isMobile
                      ? !isVeryBigScreen
                        ? '1em'
                        : '1.8em'
                      : '0.6em',
                    cursor: 'pointer',
                    marginLeft: !isMobile ? '20px' : '-1vw',
                  }}
                  onClick={() => (window.location = '/')}
                >
                  &lt;
                </span>
                <span
                  style={{
                    color: 'rgb(140, 140, 140)',
                    fontSize: !isMobile
                      ? !isVeryBigScreen
                        ? '1em'
                        : '1.8em'
                      : '0.6em',
                    cursor: 'pointer',
                    marginLeft: '10px',
                  }}
                  onClick={() => (window.location = '/')}
                >
                  Home
                </span>
                <span
                  style={{
                    color: 'rgb(140, 140, 140)',
                    fontSize: !isMobile
                      ? !isVeryBigScreen
                        ? '1em'
                        : '1.8em'
                      : '0.6em',
                    cursor: 'pointer',
                    marginLeft: !isMobile ? '40px' : '2vw',
                  }}
                  onClick={() => (window.location = '/')}
                >
                  |
                </span>
                <span
                  style={{
                    marginLeft: !isMobile
                      ? !isVeryBigScreen
                        ? '40px'
                        : '45px'
                      : '3vw',
                    fontSize: !isMobile
                      ? !isVeryBigScreen
                        ? '0.8em'
                        : '1.3em'
                      : '0.6em',
                    opacity: '70%',
                    whiteSpace: 'pre',
                  }}
                  onMouseEnter={() => {
                    if (isMobile) {
                      setAudioVisualDropDown(true);
                    }
                  }}
                  onMouseLeave={() => {
                    if (isMobile) {
                      setAudioVisualDropDown(false);
                    }
                  }}
                >
                  {!isMobile
                    ? 'Solutions    >    Residential    >    Audio/Visual    >'
                    : 'Solutions > Residential > Audio/Visual >'}
                </span>
                <span
                  style={{
                    marginLeft: !isMobile
                      ? !isVeryBigScreen
                        ? '30px'
                        : '60px'
                      : '2vw',
                    fontSize: !isMobile
                      ? !isVeryBigScreen
                        ? '1.2em'
                        : '2em'
                      : '0.8em',
                  }}
                  onMouseEnter={() => {
                    setAudioVisualDropDown(true);
                  }}
                  onMouseLeave={() => {
                    setAudioVisualDropDown(false);
                  }}
                >
                  {'Speakers '}
                </span>
              </h5>
              <Fade in={audioVisualDropDown}>
                <div
                  style={{
                    display: 'flex',
                    marginLeft: !pcScreen
                      ? !isVerySmallScreen
                        ? '500px'
                        : !isSmallScreen || isVerySmallScreen
                        ? !isMobile
                          ? '38.5vw'
                          : '73vw'
                        : '40vw'
                      : !isVeryBigScreen
                      ? '23vw'
                      : !ultraWide
                      ? '28.7vw'
                      : '21.5vw',
                    height: !isVeryBigScreen
                      ? !isMobile
                        ? '220px'
                        : '150px'
                      : '350px',
                    width: !isVeryBigScreen
                      ? !isMobile
                        ? '150px'
                        : '100px'
                      : '320px',
                    background: 'rgba(0, 0, 0, 0.7)',

                    border: 'none',
                    position: 'absolute',
                    top: !isVeryBigScreen
                      ? !isMobile
                        ? '65px'
                        : '123px'
                      : '110px',
                    border: 'none',
                    borderRadius: 'none',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    zIndex: '10',
                  }}
                  onMouseEnter={() => {
                    setAudioVisualDropDown(true);
                  }}
                  onMouseLeave={() => {
                    setAudioVisualDropDown(false);
                  }}
                >
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      color: 'white',
                    }}
                    to='/solutions/residential/audio/speakers/revox'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Speakers
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                    }}
                    to='/solutions/residential/audio/amplifiers/arcam'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Amplifiers
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      opacity: '70%',
                    }}
                    to='/solutions/residential/audio/screens/elite'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Screens
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                    }}
                    to='/solutions/residential/audio/projectors/benq'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Projectors
                  </Link>
                </div>
              </Fade>
            </div>
          </div>
          {!isMobile ? (
            <div
              className='nav2'
              style={{
                display: 'flex',
                height: '60px',
                justifyContent: 'center',
                marginTop: '40px',
                marginBottom: '-20px',
              }}
            >
              <p
                style={{
                  fontFamily: 'lato',
                  color: 'black',
                  opacity: '80%',
                  display: 'block',
                  margin: 'auto',
                  marginRight: 'auto',
                  marginLeft: '10vw',
                  fontWeight: '700',
                  fontSize: !isVeryBigScreen ? '1em' : '1.7em',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setCollection(false);
                  document.getElementById('speaker-nav').style.display =
                    'block';
                }}
              >
                &lt; Back to Audio Visual
              </p>
              <div
                className='right'
                style={{
                  display: 'flex',
                  margin: 'auto',
                  marginLeft: 'auto',
                  marginRight: '10vw',
                  width: '65%',
                  height: '100%',
                  maxWidth: '1200px',
                }}
              >
                <p
                  style={{
                    fontFamily: 'lato',
                    fontWeight: '500',
                    textAlign: 'center',
                    fontSize: !isVeryBigScreen
                      ? !isSmallScreen
                        ? '1.1em'
                        : '0.8em'
                      : '1.7em',
                    display: 'block',
                    margin: 'auto',
                    textDecoration: 'none',
                    fontWeight: '400',
                    color:
                      currentProduct === 'loud-speakers'
                        ? 'black'
                        : 'rgb(120,120, 120)',
                    cursor: 'pointer',
                  }}
                  onClick={() => setCurrentProduct('loud-speakers')}
                >
                  Lumina
                </p>
                <p
                  style={{
                    fontFamily: 'lato',
                    fontWeight: '500',
                    textAlign: 'center',
                    fontSize: !isVeryBigScreen
                      ? !isSmallScreen
                        ? '1.1em'
                        : '0.8em'
                      : '1.7em',
                    display: 'block',
                    margin: 'auto',
                    fontWeight: '400',

                    color:
                      currentProduct === 'network-recievers'
                        ? 'black'
                        : 'rgb(120,120, 120)',
                    cursor: 'pointer',

                    textDecoration: 'none',
                  }}
                  onClick={() => setCurrentProduct('network-recievers')}
                >
                  Sonetto
                </p>
                <p
                  style={{
                    fontFamily: 'lato',
                    fontWeight: '500',
                    textAlign: 'center',
                    fontSize: !isVeryBigScreen
                      ? !isSmallScreen
                        ? '1.1em'
                        : '0.8em'
                      : '1.7em',
                    display: 'block',
                    margin: 'auto',
                    fontWeight: '400',

                    color:
                      currentProduct === 'studio-art'
                        ? 'black'
                        : 'rgb(120,120, 120)',
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                  onClick={() => setCurrentProduct('studio-art')}
                >
                  Olympica
                </p>
                <p
                  style={{
                    fontFamily: 'lato',
                    fontWeight: '500',
                    textAlign: 'center',
                    fontSize: !isVeryBigScreen
                      ? !isSmallScreen
                        ? '1.1em'
                        : '0.8em'
                      : '1.7em',
                    display: 'block',
                    margin: 'auto',
                    fontWeight: '400',

                    color:
                      currentProduct === 'turntables'
                        ? 'black'
                        : 'rgb(120,120, 120)',
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                  onClick={() => setCurrentProduct('turntables')}
                >
                  Minima
                </p>
                <p
                  style={{
                    fontFamily: 'lato',
                    fontWeight: '500',
                    textAlign: 'center',
                    fontSize: !isVeryBigScreen
                      ? !isSmallScreen
                        ? '1.1em'
                        : '0.8em'
                      : '1.7em',
                    display: 'block',
                    margin: 'auto',
                    fontWeight: '400',

                    color:
                      currentProduct === 'palladio'
                        ? 'black'
                        : 'rgb(120,120, 120)',
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                  onClick={() => setCurrentProduct('palladio')}
                >
                  Palladio
                </p>
                <p
                  style={{
                    fontFamily: 'lato',
                    fontWeight: '500',
                    textAlign: 'center',
                    fontSize: !isVeryBigScreen
                      ? !isSmallScreen
                        ? '1.1em'
                        : '0.8em'
                      : '1.7em',
                    display: 'block',
                    margin: 'auto',
                    fontWeight: '400',

                    color:
                      currentProduct === 'homage'
                        ? 'black'
                        : 'rgb(120,120, 120)',
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                  onClick={() => setCurrentProduct('homage')}
                >
                  Homage Tradition
                </p>
                <img
                  src={RevoxLogo}
                  alt='..'
                  style={{
                    display: 'block',
                    margin: 'auto',
                    width: !isVeryBigScreen ? '130px' : '170px',
                    marginRight: '0',
                    borderBottom: '1px solid black',
                    paddingBottom: '5px',
                  }}
                  onMouseEnter={() => setBrandDropDown(true)}
                  onMouseLeave={() => setBrandDropDown(false)}
                />
                <ExpandMoreIcon
                  style={{
                    display: 'block',
                    margin: 'auto',
                    marginLeft: '0',
                    marginRight: '0',
                  }}
                ></ExpandMoreIcon>
                <Fade in={brandDropDown}>
                  <div
                    style={{
                      display: 'flex',
                      // marginLeft: '100vw',
                      height: !isVeryBigScreen ? '270px' : '420px',
                      width: !isVeryBigScreen ? '150px' : '320px',
                      background: 'rgba(0, 0, 0, 0.7)',
                      right: '10.5vw',
                      border: 'none',
                      position: 'absolute',
                      top: !isVeryBigScreen ? '170px' : '200px',
                      border: 'none',
                      borderRadius: 'none',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      paddingTop: '20px',
                      paddingBottom: '20px',
                      zIndex: '20',
                    }}
                    onMouseEnter={() => {
                      setBrandDropDown(true);
                    }}
                    onMouseLeave={() => {
                      setBrandDropDown(false);
                    }}
                  >
                    <Link
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        display: 'block',
                        margin: 'auto',
                        textDecoration: 'none',
                        fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                        opacity: '70%',
                        color: 'white',
                        fontWeight: '700',
                      }}
                      to='/solutions/residential/audio/speakers/revox?collection=true'
                      onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                      onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                    >
                      Revox
                    </Link>
                    <Link
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        display: 'block',
                        margin: 'auto',
                        textDecoration: 'none',
                        fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                        opacity: '70%',
                        fontWeight: '700',
                      }}
                      to='/solutions/residential/audio/speakers/sonus?collection=true'
                      onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                      onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                    >
                      Sonus Faber
                    </Link>
                    <Link
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        display: 'block',
                        margin: 'auto',
                        textDecoration: 'none',
                        fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                        opacity: '70%',
                        opacity: '70%',
                        fontWeight: '700',
                      }}
                      to='/solutions/residential/audio/speakers/truAudio?collection=true'
                      onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                      onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                    >
                      Truaudio
                    </Link>
                    <Link
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        display: 'block',
                        margin: 'auto',
                        textDecoration: 'none',
                        fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                        opacity: '70%',
                        fontWeight: '700',
                      }}
                      to='/solutions/residential/audio/speakers/bowers?collection=true'
                      onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                      onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                    >
                      Bowers & Wilkins
                    </Link>

                    <Link
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        display: 'block',
                        margin: 'auto',
                        textDecoration: 'none',
                        fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                        opacity: '70%',
                        fontWeight: '700',
                      }}
                      to='/solutions/residential/audio/speakers/pmc?collection=true'
                      onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                      onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                    >
                      PMC
                    </Link>
                    <Link
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        display: 'block',
                        margin: 'auto',
                        textDecoration: 'none',
                        fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                        opacity: '70%',
                        fontWeight: '700',
                      }}
                      to='/solutions/residential/audio/speakers/sonos?collection=true'
                      onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                      onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                    >
                      Sonos
                    </Link>
                  </div>
                </Fade>
              </div>
            </div>
          ) : (
            <div>
              <p
                style={{
                  fontFamily: 'lato',
                  color: 'black',
                  opacity: '70%',
                  fontSize: !isMobile
                    ? !isVeryBigScreen
                      ? '1.2em'
                      : '2em'
                    : '1em',
                  textAlign: 'center',
                  fontWeight: '700',
                  marginTop: '-5px',
                  marginBottom: '15px',
                }}
                onClick={() => setCollection(false)}
              >
                &lt; Back to Audio Visual
              </p>
              <div style={{ display: 'flex' }}>
                <img
                  src={RevoxLogo}
                  alt='..'
                  style={{
                    display: 'block',
                    margin: 'auto',
                    width: !isVeryBigScreen ? '130px' : '170px',
                    // marginRight: '0',
                    borderBottom: '1px solid black',
                    paddingBottom: '5px',
                  }}
                  onClick={() => setBrandDropDown(true)}
                  onMouseLeave={() => setBrandDropDown(false)}
                />
              </div>
              <Fade in={brandDropDown}>
                <div
                  style={{
                    display: 'flex',
                    // marginLeft: '100vw',
                    height: !isVeryBigScreen ? '270px' : '420px',
                    width: !isVeryBigScreen ? '150px' : '320px',
                    background: 'rgba(0, 0, 0, 0.7)',
                    // right: '10.5vw',
                    border: 'none',
                    // position: 'absolute',
                    // top: !isVeryBigScreen ? '170px' : '200px',
                    border: 'none',
                    borderRadius: 'none',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    zIndex: '20',
                    marginLeft: '30vw',
                    position: 'absolute',
                  }}
                  onMouseEnter={() => {
                    setBrandDropDown(true);
                  }}
                  onMouseLeave={() => {
                    setBrandDropDown(false);
                  }}
                >
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      color: 'white',
                      fontWeight: '700',
                    }}
                    to='/solutions/residential/audio/speakers/revox?collection=true'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Revox
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      fontWeight: '700',
                    }}
                    to='/solutions/residential/audio/speakers/sonus?collection=true'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Sonus Faber
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      opacity: '70%',
                      fontWeight: '700',
                    }}
                    to='/solutions/residential/audio/speakers/truAudio?collection=true'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Truaudio
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      fontWeight: '700',
                    }}
                    to='/solutions/residential/audio/speakers/bowers?collection=true'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Bowers & Wilkins
                  </Link>

                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      fontWeight: '700',
                    }}
                    to='/solutions/residential/audio/speakers/pmc?collection=true'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    PMC
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      fontWeight: '700',
                    }}
                    to='/solutions/residential/audio/speakers/sonos?collection=true'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Sonos
                  </Link>
                </div>
              </Fade>
              <FormControl
                style={{
                  width: '200px',
                  display: 'block',
                  margin: 'auto',
                  marginTop: '20px',
                }}
              >
                <InputLabel id='demo-simple-select-label'>Category</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={currentProduct}
                  label='Category'
                  onChange={(e) => {
                    setCurrentProduct(e.target.value);
                  }}
                  className={classes.select}
                  inputProps={{
                    classes: {
                      icon: classes.icon,
                      root: classes.root,
                    },
                  }}
                  style={{ color: 'black', display: 'block', margin: 'auto' }}
                >
                  <MenuItem value={'loud-speakers'}>Lumina</MenuItem>
                  <MenuItem value={'network-recievers'}>Sonetto</MenuItem>
                  <MenuItem value={'studio-art'}>Olympica</MenuItem>
                  <MenuItem value={'turntables'}>Minima</MenuItem>
                  <MenuItem value={'palladio'}>Palladio</MenuItem>
                  <MenuItem value={'homage'}>Homage Tradition</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}

          <Slide in={true} direction={direction} unmountOnExit>
            <div>
              <div
                className='part1'
                style={{ marginTop: '4vh', width: '100vw' }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '30px',
                    width: !isMobile ? '80vw' : '95vw',
                    height: !isMobile ? '80vh' : smallPhone ? '45vh' : '75vh',
                    width: '100vw',
                  }}
                >
                  <button
                    className='carousel-control-prev'
                    type='button'
                    data-bs-target='#carouselExampleControls'
                    data-bs-slide='prev'
                    style={{
                      color: 'black',
                      width: '80px',
                      height: '60px',
                      background: 'rgb(0, 0, 0)',
                      position: 'static',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginLeft: '0',
                      display: isMobile ? 'none' : 'inline-block',
                    }}
                  >
                    <span
                      className='carousel-control-prev-icon'
                      aria-hidden='true'
                      style={{ color: 'black' }}
                    ></span>
                    <span
                      className='visually-hidden secondary'
                      style={{ color: 'black' }}
                    >
                      Previous
                    </span>
                  </button>

                  <div
                    id='carouselExampleControls'
                    className='carousel slide'
                    data-interval='false'
                    onMouseEnter={(e) => {
                      setShowSpeakers(true);
                      console.log('mouse enter');
                    }}
                    onClick={(e) => {
                      setShowSpeakers(true);
                    }}
                    style={{
                      zIndex: '-1',
                      borderRadius: 'none',
                      width: !isMobile ? '80vw' : '95vw',
                      height: !isMobile ? '80vh' : smallPhone ? '45vh' : '75vh',
                      touchAction: 'none',
                      zIndex: '9',
                      maxHeight: !isMobile ? 'none' : '250px',

                      marginLeft: '5px',
                      marginRight: '5px',
                    }}
                    onMouseLeave={() => {
                      setShowSpeakers(false);
                      console.log('mouse leave');
                    }}
                  >
                    <div
                      className='carousel-inner'
                      style={{
                        height: !isMobile
                          ? '80vh'
                          : smallPhone
                          ? '45vh'
                          : '75vh',
                        marginBottom: '20px',
                        maxHeight: !isMobile ? 'none' : '250px',
                      }}
                    >
                      <div
                        className={`carousel-item active 1`}
                        id='loud-speakers'
                        style={{ width: '100%', height: '100%' }}
                      >
                        <Modal
                          open={
                            aboutOpen &&
                            (document.querySelector('.active').classList
                              .value === 'carousel-item active 1' ||
                              document.querySelector('.active').classList
                                .value === 'carousel-item 1 active')
                          }
                          onClose={() => setAboutOpen(false)}
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                The Lumina collection by Sonus faber presents an
                                exciting change, new to the typical Sonus faber
                                product design portfolio but still able to
                                embody the history and values of the brand.
                                “Simplicity” is central to the collection’s
                                identity that aims to reach a wide audience and
                                to deliver a luxury music experience in all
                                Sonus faber homes, which is facilitated through
                                our longstanding tradition of refined materials
                                and careful craftsmanship. Features: slender
                                models, high-power handling, fast transient
                                response, immersive listening experience.
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              Lumina
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <img
                                  src={luminaProduct}
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '70%' : '22%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: '-23%',
                                    marginRight: '-23%',

                                    margin: 'auto',
                                  }}
                                  alt='..'
                                />
                              </div>

                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '18%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                Lumina
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '3%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  width: '80%',
                                  opacity: '70%',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.2em'
                                      : '1.0em'
                                    : '1.6em',
                                }}
                              >
                                The Lumina collection by Sonus faber presents an
                                exciting change, new to the typical Sonus faber
                                product design portfolio but still able to
                                embody the history and values of the brand.
                                “Simplicity” is central to the collection’s
                                identity that aims to reach a wide audience and
                                to deliver a luxury music experience in all
                                Sonus faber homes, which is facilitated through
                                our longstanding tradition of refined materials
                                and careful craftsmanship. Features: slender
                                models, high-power handling, fast transient
                                response, immersive listening experience.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={luminaBanner}
                          alt='...'
                          style={{
                            width: '100%',
                            height: !isMobile ? '100%' : '100%',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                        <h1
                          style={{
                            background: 'rgba(0, 0, 0, 0.7)',
                            fontFamily: 'coldiac',
                            color: 'white',
                            paddingLeft: '10px',
                            paddingRight: '20px',
                            fontWeight: '400',
                            marginRight: 'auto',
                            marginLeft: '0',
                            marginBottom: '100%',
                            marginTop: '0',
                            position: 'absolute',
                            top: '0',
                            paddingTop: '15px',
                            paddingBottom: '15px',
                          }}
                        >
                          Lumina
                        </h1>
                      </div>
                      <div
                        className={`carousel-item 2`}
                        id='network-recievers'
                        style={{ width: '100%', height: '100%' }}
                      >
                        <Modal
                          open={
                            aboutOpen &&
                            (document.querySelector('.active').classList
                              .value === 'carousel-item active 2' ||
                              document.querySelector('.active').classList
                                .value === 'carousel-item 2 active')
                          }
                          onClose={() => setAboutOpen(false)}
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                The Sonetto (Sonnet) is the most ancient Italian
                                Poetic structure. Originally it meant “Sound,
                                Melody” and was traditionally used to accompany
                                music performances. The entire collection is a
                                true Sonus Faber that features all the iconic
                                elements of the brand: natural sound, natural
                                materials, the Lute shape, the excellent
                                finishes typical of Italian craftsmanship.
                                Features: natural sound, naturally sourced
                                materials, iconic Lute design, handcrafted
                                Italian finish.
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              Sonetto
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <img
                                  src={sonnetoProduct}
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '70%' : '22%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: '-23%',
                                    marginRight: '-23%',

                                    margin: 'auto',
                                  }}
                                  alt='..'
                                />
                              </div>
                              <h6
                                style={{
                                  display: !isMobile ? 'none' : 'block',
                                  textAlign: 'center',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  fontWeight: '400',
                                  position: 'relative',
                                  top: '-10px',
                                }}
                              >
                                {currentColor}
                              </h6>
                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '18%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                Sonetto
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '3%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  width: '80%',
                                  opacity: '70%',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.2em'
                                      : '1.0em'
                                    : '1.6em',
                                }}
                              >
                                The Sonetto (Sonnet) is the most ancient Italian
                                Poetic structure. Originally it meant “Sound,
                                Melody” and was traditionally used to accompany
                                music performances. The entire collection is a
                                true Sonus Faber that features all the iconic
                                elements of the brand: natural sound, natural
                                materials, the Lute shape, the excellent
                                finishes typical of Italian craftsmanship.
                                Features: natural sound, naturally sourced
                                materials, iconic Lute design, handcrafted
                                Italian finish.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={sonnetoBanner}
                          alt='...'
                          style={{
                            width: '100%',
                            height: !isMobile ? '100%' : '100%',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                        <h1
                          style={{
                            background: 'rgba(0, 0, 0, 0.7)',
                            fontFamily: 'coldiac',
                            color: 'white',
                            paddingLeft: '10px',
                            paddingRight: '20px',
                            fontWeight: '400',
                            marginRight: 'auto',
                            marginLeft: '0',
                            marginBottom: '100%',
                            marginTop: '0',
                            position: 'absolute',
                            top: '0',
                            paddingTop: '15px',
                            paddingBottom: '15px',
                          }}
                        >
                          Sonetto
                        </h1>
                      </div>
                      <div
                        className={`carousel-item 3`}
                        id='studio-art'
                        style={{ width: '100%', height: '100%' }}
                      >
                        <Modal
                          open={
                            aboutOpen &&
                            (document.querySelector('.active').classList
                              .value === 'carousel-item active 3' ||
                              document.querySelector('.active').classList
                                .value === 'carousel-item 3 active')
                          }
                          onClose={() => setAboutOpen(false)}
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                One of the first lines presented as a complete
                                family of products, but above all, the very
                                first one to feature original drivers, entirely
                                designed in Sonus faber. Olympica Nova is the
                                second generation of the iconic family of 2013,
                                improved in its form and substance, imbued with
                                the timeless values that inspired us in creating
                                the original line. The result is a new
                                Collection that embraces the most advanced
                                technologies that already enhance our most
                                prestigious creations. Features: improved form
                                and substance, original drivers designed by
                                Sonus Faber, original line, superior sound
                                quality.
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '400',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              Olympica
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <img
                                  src={olympicaProduct}
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '70%' : '22%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: '-23%',
                                    marginRight: '-23%',

                                    margin: 'auto',
                                  }}
                                  alt='..'
                                />
                              </div>

                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '18%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                Olympica
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '3%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  width: '80%',
                                  opacity: '70%',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.2em'
                                      : '1.0em'
                                    : '1.6em',
                                }}
                              >
                                One of the first lines presented as a complete
                                family of products, but above all, the very
                                first one to feature original drivers, entirely
                                designed in Sonus faber. Olympica Nova is the
                                second generation of the iconic family of 2013,
                                improved in its form and substance, imbued with
                                the timeless values that inspired us in creating
                                the original line. The result is a new
                                Collection that embraces the most advanced
                                technologies that already enhance our most
                                prestigious creations. Features: improved form
                                and substance, original drivers designed by
                                Sonus Faber, original line, superior sound
                                quality.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={olympicaBanner}
                          alt='...'
                          style={{
                            width: '100%',
                            height: !isMobile ? '100%' : '100%',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                        <h1
                          style={{
                            background: 'rgba(0, 0, 0, 0.7)',
                            fontFamily: 'coldiac',
                            color: 'white',
                            paddingLeft: '10px',
                            paddingRight: '20px',
                            fontWeight: '400',
                            marginRight: 'auto',
                            marginLeft: '0',
                            marginBottom: '100%',
                            marginTop: '0',
                            position: 'absolute',
                            top: '0',
                            paddingTop: '15px',
                            paddingBottom: '15px',
                          }}
                        >
                          Olympica
                        </h1>
                      </div>
                      <div
                        className={`carousel-item 4`}
                        id='turntables'
                        style={{ width: '100%', height: '100%' }}
                      >
                        <Modal
                          open={
                            aboutOpen &&
                            (document.querySelector('.active').classList
                              .value === 'carousel-item active 4' ||
                              document.querySelector('.active').classList
                                .value === 'carousel-item 4 active')
                          }
                          onClose={() => setAboutOpen(false)}
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                Some products are not meant to be tied to a
                                singular historical moment and some experiences
                                made the brand so unique and distinctive. Minima
                                Amator II was born from this awareness and gave
                                life to the Sonus Faber Heritage collection, in
                                which the brand collected its intangible values,
                                tradition and Italian expertise. Minima Amator
                                II is the restatement of a fundamental product
                                first introduced in 1992 when the company was
                                establishing key principles that would go on to
                                guide future productions: accurate design and
                                high-quality materials, combined to obtain a
                                sound reproduction as natural as possible.
                                Features: unique and distinctive, retestament of
                                an original product, accurate design,
                                high-quality materials.
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              Minima
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <img
                                  src={minimaProduct}
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '70%' : '22%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: '-23%',
                                    marginRight: '-23%',

                                    margin: 'auto',
                                  }}
                                  alt='..'
                                />
                              </div>

                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '8%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                Minima
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '3%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  width: '80%',
                                  opacity: '70%',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.2em'
                                      : '1.0em'
                                    : '1.6em',
                                }}
                              >
                                Some products are not meant to be tied to a
                                singular historical moment and some experiences
                                made the brand so unique and distinctive. Minima
                                Amator II was born from this awareness and gave
                                life to the Sonus Faber Heritage collection, in
                                which the brand collected its intangible values,
                                tradition and Italian expertise. Minima Amator
                                II is the restatement of a fundamental product
                                first introduced in 1992 when the company was
                                establishing key principles that would go on to
                                guide future productions: accurate design and
                                high-quality materials, combined to obtain a
                                sound reproduction as natural as possible.
                                Features: unique and distinctive, retestament of
                                an original product, accurate design,
                                high-quality materials.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={minimaBanner}
                          alt='...'
                          style={{
                            width: '100%',
                            height: !isMobile ? '100%' : '100%',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                        <h1
                          style={{
                            background: 'rgba(0, 0, 0, 0.7)',
                            fontFamily: 'coldiac',
                            color: 'white',
                            paddingLeft: '10px',
                            paddingRight: '20px',
                            fontWeight: '400',
                            marginRight: 'auto',
                            marginLeft: '0',
                            marginBottom: '100%',
                            marginTop: '0',
                            position: 'absolute',
                            top: '0',
                            paddingTop: '15px',
                            paddingBottom: '15px',
                          }}
                        >
                          Minima
                        </h1>
                      </div>
                      <div
                        className={`carousel-item 5`}
                        id='palladio'
                        style={{ width: '100%', height: '100%' }}
                      >
                        <Modal
                          open={
                            aboutOpen &&
                            (document.querySelector('.active').classList
                              .value === 'carousel-item active 5' ||
                              document.querySelector('.active').classList
                                .value === 'carousel-item 5 active')
                          }
                          onClose={() => setAboutOpen(false)}
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                This line of Custom installation speakers
                                in-wall and in-ceiling directly refer to the
                                Olympica Nova Collection for the choice of
                                materials, the electroacoustic project and
                                design. These models represent a unique choice
                                in the Custom Installation landscape: the first
                                speakers that even furnishes your home.
                                Developed as a solution to the lack of
                                high-quality craftsmanship and warmth of sound
                                in modern architectural speakers, the Palladio
                                collection works to expand the spaces of the
                                listening experience, beyond the boundaries of
                                traditional listening rooms. Palladio custom
                                installation speakers guarantees a total sound
                                immersion with versatile models, also
                                aesthetically pleasing in design. Features:
                                custom in-wall/in-ceiling speakers, first
                                speakers to furnish homes, modern architectural
                                speakers, total sound immersion.
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              Palladio
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <img
                                  src={palladioProduct}
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '70%' : '22%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: '-23%',
                                    marginRight: '-23%',

                                    margin: 'auto',
                                  }}
                                  alt='..'
                                />
                              </div>

                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '12%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                Palladio
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '3%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  width: '80%',
                                  opacity: '70%',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.2em'
                                      : '1.0em'
                                    : '1.6em',
                                }}
                              >
                                This line of Custom installation speakers
                                in-wall and in-ceiling directly refer to the
                                Olympica Nova Collection for the choice of
                                materials, the electroacoustic project and
                                design. These models represent a unique choice
                                in the Custom Installation landscape: the first
                                speakers that even furnishes your home.
                                Developed as a solution to the lack of
                                high-quality craftsmanship and warmth of sound
                                in modern architectural speakers, the Palladio
                                collection works to expand the spaces of the
                                listening experience, beyond the boundaries of
                                traditional listening rooms. Palladio custom
                                installation speakers guarantees a total sound
                                immersion with versatile models, also
                                aesthetically pleasing in design. Features:
                                custom in-wall/in-ceiling speakers, first
                                speakers to furnish homes, modern architectural
                                speakers, total sound immersion.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={palladioBanner}
                          alt='...'
                          style={{
                            width: '90%',
                            height: !isMobile ? 'auto' : 'auto',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                        <h1
                          style={{
                            background: 'rgba(0, 0, 0, 0.7)',
                            fontFamily: 'coldiac',
                            color: 'white',
                            paddingLeft: '10px',
                            paddingRight: '20px',
                            fontWeight: '400',
                            marginRight: 'auto',
                            marginLeft: '0',
                            marginBottom: '100%',
                            marginTop: '0',
                            position: 'absolute',
                            top: '0',
                            paddingTop: '15px',
                            paddingBottom: '15px',
                          }}
                        >
                          Palladio
                        </h1>
                      </div>
                      <div
                        className={`carousel-item 6`}
                        id='homage'
                        style={{ width: '100%', height: '100%' }}
                      >
                        <Modal
                          open={
                            aboutOpen &&
                            (document.querySelector('.active').classList
                              .value === 'carousel-item active 6' ||
                              document.querySelector('.active').classList
                                .value === 'carousel-item 6 active')
                          }
                          onClose={() => setAboutOpen(false)}
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                Flagship of Homage Tradition collection, Amati
                                is the new reference floor standing loudspeaker
                                in the Sonus Faber catalogue. Homage Tradition
                                collection is a new tribute to the mastery of
                                Italian violin-making; the now classic lute
                                shape, the hallmark of Sonus Faber production,
                                has been revisited and taken to new heights by
                                embodying both the classic lines of the Homage
                                collection and those of the Lilium cabinet, thus
                                giving new volumes to the speakers of this
                                collection. Features: tribute to Italian
                                violin-making, classic Lute shape, floor
                                standing speaker.
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              Amati
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <img
                                  src={amatiProduct}
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '70%' : '22%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: '-23%',
                                    marginRight: '-23%',

                                    margin: 'auto',
                                  }}
                                  alt='..'
                                />
                              </div>

                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '18%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                Amati
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '3%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  width: '80%',
                                  opacity: '70%',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.2em'
                                      : '1.0em'
                                    : '1.6em',
                                }}
                              >
                                Flagship of Homage Tradition collection, Amati
                                is the new reference floor standing loudspeaker
                                in the Sonus Faber catalogue. Homage Tradition
                                collection is a new tribute to the mastery of
                                Italian violin-making; the now classic lute
                                shape, the hallmark of Sonus Faber production,
                                has been revisited and taken to new heights by
                                embodying both the classic lines of the Homage
                                collection and those of the Lilium cabinet, thus
                                giving new volumes to the speakers of this
                                collection. Features: tribute to Italian
                                violin-making, classic Lute shape, floor
                                standing speaker.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={amatiBanner}
                          alt='...'
                          style={{
                            width: '100%',
                            height: !isMobile ? '100%' : '100%',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                        <h1
                          style={{
                            background: 'rgba(0, 0, 0, 0.7)',
                            fontFamily: 'coldiac',
                            color: 'white',
                            paddingLeft: '10px',
                            paddingRight: '20px',
                            fontWeight: '400',
                            marginRight: 'auto',
                            marginLeft: '0',
                            marginBottom: '100%',
                            marginTop: '0',
                            position: 'absolute',
                            top: '0',
                            paddingTop: '15px',
                            paddingBottom: '15px',
                          }}
                        >
                          Amati
                        </h1>
                      </div>
                      <div
                        className={`carousel-item 7`}
                        id='homage'
                        style={{ width: '100%', height: '100%' }}
                      >
                        <Modal
                          open={
                            aboutOpen &&
                            (document.querySelector('.active').classList
                              .value === 'carousel-item active 7' ||
                              document.querySelector('.active').classList
                                .value === 'carousel-item 7 active')
                          }
                          onClose={() => setAboutOpen(false)}
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                The Homage collection has been enriched with a
                                new element that complements the family;
                                Serafino, a floor speaker with a huge appeal and
                                a surprising power to be explored. The new
                                Homage Tradition collection inherits the
                                precious teachings of the masters of Italian
                                violin-making and the experience gained in 20
                                years of design and development of the Homage
                                line, with a classic and slightly retro
                                approach. Features: sleek design and powerful
                                sound, inspired by master violin makers, classic
                                retro approach with modern sensibilities.
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              Serafino
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <img
                                  src={serafinoProduct}
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '70%' : '22%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: '-23%',
                                    marginRight: '-23%',

                                    margin: 'auto',
                                  }}
                                  alt='..'
                                />
                              </div>

                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '18%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                Serafino
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '3%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  width: '80%',
                                  opacity: '70%',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.2em'
                                      : '1.0em'
                                    : '1.6em',
                                }}
                              >
                                The Homage collection has been enriched with a
                                new element that complements the family;
                                Serafino, a floor speaker with a huge appeal and
                                a surprising power to be explored. The new
                                Homage Tradition collection inherits the
                                precious teachings of the masters of Italian
                                violin-making and the experience gained in 20
                                years of design and development of the Homage
                                line, with a classic and slightly retro
                                approach. Features: sleek design and powerful
                                sound, inspired by master violin makers, classic
                                retro approach with modern sensibilities.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={serafinoBanner}
                          alt='...'
                          style={{
                            width: '100%',
                            height: !isMobile ? '100%' : '100%',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                        <h1
                          style={{
                            background: 'rgba(0, 0, 0, 0.7)',
                            fontFamily: 'coldiac',
                            color: 'white',
                            paddingLeft: '10px',
                            paddingRight: '20px',
                            fontWeight: '400',
                            marginRight: 'auto',
                            marginLeft: '0',
                            marginBottom: '100%',
                            marginTop: '0',
                            position: 'absolute',
                            top: '0',
                            paddingTop: '15px',
                            paddingBottom: '15px',
                          }}
                        >
                          Serafino
                        </h1>
                      </div>
                      <div
                        className={`carousel-item 8`}
                        style={{ width: '100%', height: '100%' }}
                        id='homage'
                      >
                        <Modal
                          open={
                            aboutOpen &&
                            (document.querySelector('.active').classList
                              .value === 'carousel-item active 8' ||
                              document.querySelector('.active').classList
                                .value === 'carousel-item 8 active')
                          }
                          onClose={() => setAboutOpen(false)}
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                2016 marked a new chapter in Sonus Faber’s rich
                                history with the re-birth of a classic icon
                                first pioneered in 1980: The Snail. The two
                                wooden enclosures that host the front and the
                                rear subwoofer are obtained by a 3D shaped
                                plywood structure. 3D curvature offers the
                                maximum of strength with a small thickness. The
                                inspiration for the concept of the revolutionary
                                SF16 project comes from evergreen icons from the
                                past; the “muscle cars” of the sixties and the
                                seventies and the classics of interior design of
                                the late fifties are the centers of the
                                imaginary merged with the Sonus Faber
                                recognizable touch, the Walnut wood finish.
                                Features: rebirth of a classic icon, inspired by
                                American muscle cars, maximum wood strength with
                                slim design.
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              SF16
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <img
                                  src={sf16Product}
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '70%' : '22%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: '-23%',
                                    marginRight: '-23%',

                                    margin: 'auto',
                                  }}
                                  alt='..'
                                />
                              </div>

                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '8%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                SF16
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '3%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  width: '80%',
                                  opacity: '70%',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.2em'
                                      : '1.0em'
                                    : '1.6em',
                                }}
                              >
                                2016 marked a new chapter in Sonus Faber’s rich
                                history with the re-birth of a classic icon
                                first pioneered in 1980: The Snail. The two
                                wooden enclosures that host the front and the
                                rear subwoofer are obtained by a 3D shaped
                                plywood structure. 3D curvature offers the
                                maximum of strength with a small thickness. The
                                inspiration for the concept the revolutionary
                                SF16 project comes from evergreen icons from the
                                past; the “muscle cars” of the sixties and the
                                seventies and the classics of interior design of
                                the late fifties are the center of the imaginary
                                merged with the Sonus Faber recognizable touch,
                                the Walnut wood finish. Features: rebirth of a
                                classic icon, inspired by American muscle cars,
                                maximum wood strength with slim design.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={sf16Banner}
                          alt='...'
                          style={{
                            width: '100%',
                            height: !isMobile ? '100%' : '100%',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                        <h1
                          style={{
                            background: 'rgba(0, 0, 0, 0.7)',
                            fontFamily: 'coldiac',
                            color: 'white',
                            paddingLeft: '10px',
                            paddingRight: '20px',
                            fontWeight: '400',
                            marginRight: 'auto',
                            marginLeft: '0',
                            marginBottom: '100%',
                            marginTop: '0',
                            position: 'absolute',
                            top: '0',
                            paddingTop: '15px',
                            paddingBottom: '15px',
                          }}
                        >
                          SF16
                        </h1>
                      </div>
                    </div>

                    <button
                      class='carousel-control-prev'
                      type='button'
                      data-bs-target='#carouselExampleControls'
                      data-bs-slide='prev'
                      style={{ display: !isMobile ? 'none' : 'block' }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.cancelBubble = true;
                        if (e.stopPropagation) e.stopPropagation();
                        console.log('hoi');
                        setShowSpeakers(false);
                      }}
                    >
                      <span
                        class='carousel-control-prev-icon'
                        aria-hidden='true'
                      ></span>
                      <span class='visually-hidden'>Previous</span>
                    </button>
                    <button
                      class='carousel-control-next'
                      type='button'
                      data-bs-target='#carouselExampleControls'
                      data-bs-slide='next'
                      style={{ display: !isMobile ? 'none' : 'block' }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.cancelBubble = true;
                        if (e.stopPropagation) e.stopPropagation();
                        console.log('hoi');
                        setShowSpeakers(false);
                      }}
                    >
                      <span
                        class='carousel-control-next-icon'
                        aria-hidden='true'
                      ></span>
                      <span class='visually-hidden'>Next</span>
                    </button>
                  </div>
                  <button
                    className='carousel-control-next'
                    type='button'
                    data-bs-target='#carouselExampleControls'
                    data-bs-slide='next'
                    style={{
                      color: 'black',
                      width: '80px',
                      height: '60px',
                      background: 'rgb(0, 0, 0)',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      position: 'static',

                      display: isMobile ? 'none' : 'inline-block',
                    }}
                  >
                    <span
                      className='carousel-control-next-icon'
                      aria-hidden='true'
                    ></span>
                    <span className='visually-hidden'>Next</span>
                  </button>
                </div>
              </div>
            </div>
          </Slide>

          <div
            style={{
              width: '100vw',
              height: '70px',
              display: 'flex',
              justifyContent: 'center',
              background: 'rgb(35, 35, 35)',
              marginTop: '50px',
            }}
          >
            <p
              style={{
                margin: 'auto',
                marginLeft: '3%',
                fontFamily: 'lato',
                fontWeight: '700',
                fontSize: '0.7em',
                color: 'white',
              }}
            >
              © 2022 Thar Technologies
            </p>
            <p
              style={{
                margin: 'auto',
                marginRight: '3%',
                fontFamily: 'lato',
                fontWeight: '700',
                fontSize: !isBigScreen
                  ? !isMobile
                    ? '0.9em'
                    : '0.5em'
                  : '1.6em',
                color: 'white',
                opacity: '70%',
              }}
            >
              Designed & Developed by Workplay Studio Pvt. Ltd.
            </p>
          </div>
          <hr style={{ color: 'white', margin: '0' }} />
        </div>
      </Fade>
      {/* {disableScroll()} */}
    </div>
  );
};

export default Speakers;
