import React from 'react';
import { useLocation } from 'react-router-dom';
import Benq from './brands/Benq';
import ProjectorsNav from './ProjectorsNav';
import Sony from './brands/Sony';
import JVC from './brands/JVC';

const Speakers = () => {
  const [brandName, setBrandName] = React.useState('');

  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname.endsWith('/benq')) {
      setBrandName('benq');
    } else if (location.pathname.endsWith('/jvc')) {
      setBrandName('jvc');
    } else if (location.pathname.endsWith('/sony')) {
      setBrandName('sony');
    }
  }, [location]);

  React.useEffect(() => {
    if (document.getElementById('nav')) {
      document.getElementById('nav').style.display = 'none';
    }
  });

  return (
    <>
      <ProjectorsNav></ProjectorsNav>
      {brandName === 'benq' ? (
        <Benq />
      ) : brandName === 'sony' ? (
        <Sony />
      ) : brandName === 'jvc' ? (
        <JVC /> // *
      ) : (
        ''
      )}
    </>
  );
};

export default Speakers;
