import React from 'react';
import RevoxLogo from '../../../../../../assets/REVOX-logo.svg';
import prestige from '../../../../../../assets/PRESTIGE.png';
import scala from '../../../../../../assets/scala.png';
import elegance from '../../../../../../assets/elegance.png';
import Fade from '@mui/material/Fade';
import Grow from '@mui/material/Grow';
import bookshelf from '../../../../../../assets/bookshelf.png';
import prestigeBlack from '../../../../../../assets/prestige-black.png';
import prestigeWhite from '../../../../../../assets/prestige-white.png';
import prestigeSilver from '../../../../../../assets/prestige-silver.png';
import scalaWhite from '../../../../../../assets/scala-white.png';
import scalaBlack from '../../../../../../assets/scala-black.png';
import scalaSilver from '../../../../../../assets/scala-silver.png';
import eleganceBlack from '../../../../../../assets/elegance-black.svg';
import eleganceSilver from '../../../../../../assets/elegance-silver.png';
import eleganceWhite from '../../../../../../assets/elegance-white.png';
import bookShelfBlack from '../../../../../../assets/bookshelf-black.svg';
import bookShelfWhite from '../../../../../../assets/book-shelf-white.png';
import bookShelfSilver from '../../../../../../assets/book-shelf-silver.png';
import { Fade as Slide } from '@mui/material';
import joy from '../../../../../../assets/joy.png';
import joyProduct from '../../../../../../assets/joy-product.png';
import s100 from '../../../../../../assets/s100.png';
import s100White from '../../../../../../assets/s100-white.png';
import s100Black from '../../../../../../assets/s100-black.png';
import a100 from '../../../../../../assets/a100.png';
import a100White from '../../../../../../assets/a100-white.png';
import p100 from '../../../../../../assets/p100.png';
import p100Product from '../../../../../../assets/p100-product.svg';
import b100 from '../../../../../../assets/b100.png';
import t700 from '../../../../../../assets/t700.png';
import t700Product from '../../../../../../assets/t700-product.png';
import revoxBg from '../../../../../../assets/revoxBg.png';
import Button from '@mui/material/Button';
import RevoxWhite from '../../../../../../assets/revox-white-logo.png';
import logo from '../../../../../../assets/Logo Black.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link, useLocation } from 'react-router-dom';
import ArrowBackIos from '@mui/icons-material/ArrowBackIosNew';
import NavigateNextIcon from '@mui/icons-material/ArrowForwardIos';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { createStyles, makeStyles } from '@mui/styles';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Speakers = () => {
  const [collection, setCollection] = React.useState(false);

  const [aboutOpen, setAboutOpen] = React.useState(false);

  const [audioVisualDropDown, setAudioVisualDropDown] = React.useState(false);

  const location = useLocation();

  const query = useQuery();

  React.useEffect(() => {
    if (query.get('collection') === 'true') {
      setCollection(true);
    } else {
      setCollection(false);
    }
  }, [location]);

  React.useEffect(() => {
    if (query.get('collection') === 'true') {
      setCollection(true);
    } else {
      setCollection(false);
    }
  }, [query]);

  function detectMouseWheelDirection(e) {
    var delta = null,
      direction = false;
    if (!e) {
      // if the event is not provided, we get it from the window object
      e = window.event;
    }
    if (e.wheelDelta) {
      // will work in most cases
      delta = e.wheelDelta / 60;
    } else if (e.detail) {
      // fallback for Firefox
      delta = -e.detail / 2;
    }
    if (delta !== null) {
      direction = delta > 0 ? 'up' : 'down';
    }

    return direction;
  }

  React.useEffect(() => {
    if (document.getElementById('nav')) {
      document.getElementById('nav').style.display = 'none';
    }
    document.body.style.overflowY = 'visible';
  }, []);

  const [isMobile, setIsMobile] = React.useState(
    window.matchMedia('(max-width: 768px)').matches
  );

  const [isBigScreen, setIsBigScreen] = React.useState(
    window.matchMedia('(min-width: 2000px)').matches
  );

  const [showSpeakers, setShowSpeakers] = React.useState(false);

  const [currentColor, setCurrentColor] = React.useState('black');

  const [isVeryBigScreen, setIsVeryBigScreen] = React.useState(
    window.matchMedia('(min-width: 2000px)').matches
  );

  const [isSmallScreen, setIsSmallScreen] = React.useState(
    window.matchMedia('(max-width: 1300px)').matches
  );

  const [isVerySmallScreen, setIsVerySmallScreen] = React.useState(
    window.matchMedia('(max-width: 1200px)').matches
  );
  const [pcScreen, setPcScreen] = React.useState(
    window.matchMedia('(min-width: 1900px)').matches
  );

  const [lastY, setLastY] = React.useState(window.scrollY);

  var currentScrollNumber = 0;

  const [changeEnabled, setChangeEnabled] = React.useState(true);

  const [ultraWide, setUltraWide] = React.useState(
    window.matchMedia('(min-width: 3000px)').matches
  );

  var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

  // React.useEffect(() => {
  //   if (!changeEnabled) {
  //     setTimeout(() => {
  //       setChangeEnabled(true);
  //       disableScroll();
  //     }, 100);
  //   }
  // }, [changeEnabled]);

  const [currentProduct, setCurrentProduct] = React.useState('loud-speakers');
  const [direction, setDirection] = React.useState('up');

  // modern Chrome requires { passive: false } when adding event
  var supportsPassive = false;
  try {
    window.addEventListener(
      'test',
      null,
      Object.defineProperty({}, 'passive', {
        get: function () {
          supportsPassive = true;
        },
      })
    );
  } catch (e) {}

  React.useEffect(() => {
    // disableScroll();
  }, [currentProduct, changeEnabled]);

  React.useEffect(() => {
    if (collection) {
      if (document.getElementById('speaker-nav')) {
        document.getElementById('speaker-nav').style.display = 'none';
      }
    } else {
      if (document.getElementById('speaker-nav')) {
        document.getElementById('speaker-nav').style.display = 'block';
      }
    }
  }, [collection]);

  const [smallPhone, setSmallPhone] = React.useState(
    window.matchMedia('(max-width: 420px)').matches
  );

  const useStyles = makeStyles(() =>
    createStyles({
      background: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100vw',
        height: '100vh',

        zIndex: '-3',
        objectFit: 'cover',
      },
      subHeading: {
        color: 'white',
        fontFamily: 'lato',
        fontWeight: '700',
        textAlign: 'center',
        marginTop: '8vh',
      },
      bottom: {
        width: '100vw',
        height: !isMobile ? '30vh' : '25vh',
        position: 'absolute',
        bottom: '0',
        background: 'rgba(0, 0, 0, 0.5)',
      },
      smallBg: {
        position: 'fixed',
        width: '100vw',
        top: '130px',
        zIndex: '-1',
        display: 'block',
      },
      select: {
        display: 'block',
        margin: 'auto',
        '&:before': {
          borderColor: 'black',
        },
        '&:after': {
          borderColor: 'black',
        },
        '&:not(.Mui-disabled):hover::before': {
          borderColor: 'black',
        },
      },
      icon: {
        fill: 'black',
      },
      root: {
        color: 'black',
      },
    })
  );
  const classes = useStyles();

  const [brandDropDown, setBrandDropDown] = React.useState(false);

  React.useEffect(() => {
    if (collection) {
      if (document.querySelector('.carousel')) {
        document
          .querySelector('.carousel')
          .addEventListener('slid.bs.carousel', function () {
            if (
              document.querySelector('.active').id === 'loud-speakers' ||
              document.querySelector('.active').id === 'network-recievers' ||
              document.querySelector('.active').id === 'studio-art' ||
              document.querySelector('.active').id === 'turntables'
            ) {
              setCurrentProduct(document.querySelector('.active').id);
            }
          });
      }
    }
  }, [collection]);
  React.useEffect(() => {
    if (collection) {
      if (currentProduct === 'loud-speakers') {
        document.querySelector('.active').classList.remove('active');
        document.getElementById('loud-speakers').classList.add('active');
      } else if (currentProduct === 'network-recievers') {
        document.querySelector('.active').classList.remove('active');
        document.getElementById('network-recievers').classList.add('active');
      } else if (currentProduct === 'studio-art') {
        document.querySelector('.active').classList.remove('active');
        document.getElementById('studio-art').classList.add('active');
      } else if (currentProduct === 'turntables') {
        document.querySelector('.active').classList.remove('active');
        document.getElementById('turntables').classList.add('active');
      }
    }
  }, [currentProduct]);

  React.useEffect(() => {
    if (aboutOpen) {
      console.log(document.querySelector('.active').classList);
    }
  }, [aboutOpen]);

  return (
    <div>
      <Fade in={!collection} unmountOnExit>
        <div>
          <img src={revoxBg} alt='..' className={classes.background} />

          <img
            src={RevoxWhite}
            alt='..'
            style={{
              display: 'block',
              margin: 'auto',
              marginTop: !isVeryBigScreen ? '40px' : '120px',
              background: 'rgba(0, 0, 0, 0.4)',
              padding: '20px',
              borderRadius: '10px',
              width: !isVeryBigScreen ? '320px' : '440px',
            }}
          />

          <Button
            variant='contained'
            style={{
              color: 'white',
              background: 'rgba(0, 0, 0, 0.7)',
              display: 'block',
              margin: 'auto',
              marginTop: !isMobile ? '6vh' : '3vh',
              width: !isVeryBigScreen ? '280px' : '400px',
              height: !isVeryBigScreen ? '50px' : '90px',
              fontFamily: 'lato',
              fontWeight: '700',
              border: '1px solid white',
              fontSize: !isVeryBigScreen ? '1em' : '1.6em',
              transitionProperty: 'all',
              transitionDuration: '0.7s',
            }}
            onMouseEnter={(e) => {
              e.target.style.background = 'rgba(0, 0, 0, 0.9)';
            }}
            onMouseLeave={(e) => {
              e.target.style.background = 'rgba(0, 0, 0, 0.7)';
            }}
            onClick={(e) => {
              setCollection(true);
            }}
          >
            View Collection
          </Button>
          <div className={classes.bottom}>
            <h5
              className={classes.subHeading}
              style={{
                marginTop: '2%',
                fontSize: !isVeryBigScreen ? '1.3em' : '2.7em',
              }}
            >
              About
            </h5>
            <h6
              className={classes.subHeading}
              style={{
                width: !isMobile ? '50%' : '93%',
                maxWidth: '800px',
                margin: 'auto',
                marginTop: '2%',
                opacity: '80%',
                fontSize: !isVeryBigScreen ? '1em' : '1.8em',
                fontWeight: '400',
              }}
            >
              The Revox name has stood for audio products of the greatest
              technical innovation for nearly 70 years. The brand a long
              tradition of music reproduction in perfect quality and this is
              reflected in their way of speaker development.
            </h6>
          </div>
        </div>
      </Fade>

      <Fade in={collection} unmountOnExit>
        <div>
          <div
            className='topNav'
            style={{
              display: 'flex',
              width: '100vw',
              height: !isVeryBigScreen ? '70px' : '120px',
              justifyContent: 'center',
              marginTop: !isMobile ? '10px' : '-5px',
            }}
          >
            <div
              className='left'
              style={{
                marginRight: 'auto',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: '30px',
                height: '100%',
                display: !isMobile ? 'flex' : 'none',
                justifyContent: 'center',
              }}
            >
              <img
                src={logo}
                alt=''
                style={{
                  display: 'block',
                  margin: 'auto',
                  width: !isVeryBigScreen ? '250px' : '350px',
                  cursor: 'pointer',
                }}
                onClick={() => (window.location = '/')}
              />
            </div>
            <div
              className='left'
              style={{
                display: 'flex',
                marginLeft: 'auto',
                marginRight: !isMobile ? '50px' : 'auto',
                height: '100%',
                justifyContent: 'center',
              }}
            >
              <h5
                style={{
                  fontFamily: 'lato',
                  fontWeight: '700',
                  margin: 'auto',
                }}
              >
                <span
                  style={{
                    color: 'rgb(140, 140, 140)',
                    fontSize: !isMobile
                      ? !isVeryBigScreen
                        ? '1em'
                        : '1.8em'
                      : '0.6em',
                    cursor: 'pointer',
                    marginLeft: !isMobile ? '0px' : '-1vw',
                  }}
                  onClick={() => (window.location = '/')}
                >
                  &lt;
                </span>
                <span
                  style={{
                    color: 'rgb(140, 140, 140)',
                    fontSize: !isMobile
                      ? !isVeryBigScreen
                        ? '1em'
                        : '1.8em'
                      : '0.6em',
                    cursor: 'pointer',
                    marginLeft: '10px',
                  }}
                  onClick={() => (window.location = '/')}
                >
                  Home
                </span>
                <span
                  style={{
                    color: 'rgb(140, 140, 140)',
                    fontSize: !isMobile
                      ? !isVeryBigScreen
                        ? '1em'
                        : '1.8em'
                      : '0.6em',
                    cursor: 'pointer',
                    marginLeft: !isMobile ? '40px' : '2vw',
                  }}
                  onClick={() => (window.location = '/')}
                >
                  |
                </span>
                <span
                  style={{
                    marginLeft: !isMobile
                      ? !isVeryBigScreen
                        ? '40px'
                        : '45px'
                      : '3vw',
                    fontSize: !isMobile
                      ? !isVeryBigScreen
                        ? '0.8em'
                        : '1.3em'
                      : '0.6em',
                    opacity: '70%',
                    whiteSpace: 'pre',
                  }}
                  onMouseEnter={() => {
                    if (isMobile) {
                      setAudioVisualDropDown(true);
                    }
                  }}
                  onMouseLeave={() => {
                    if (isMobile) {
                      setAudioVisualDropDown(false);
                    }
                  }}
                >
                  {!isMobile
                    ? 'Solutions    >    Residential    >    Audio/Visual    >'
                    : 'Solutions > Residential > Audio/Visual >'}
                </span>
                <span
                  style={{
                    marginLeft: !isMobile
                      ? !isVeryBigScreen
                        ? '30px'
                        : '60px'
                      : '2vw',
                    fontSize: !isMobile
                      ? !isVeryBigScreen
                        ? '1.2em'
                        : '2em'
                      : '0.8em',

                    cursor: 'default',
                  }}
                  onMouseEnter={() => {
                    setAudioVisualDropDown(true);
                  }}
                  onMouseLeave={() => {
                    setAudioVisualDropDown(false);
                  }}
                >
                  {'Speakers '}
                </span>
              </h5>
              <Fade in={audioVisualDropDown}>
                <div
                  style={{
                    display: 'flex',
                    marginLeft: !pcScreen
                      ? !isVerySmallScreen
                        ? '500px'
                        : !isSmallScreen || isVerySmallScreen
                        ? !isMobile
                          ? '38.5vw'
                          : '73vw'
                        : '40vw'
                      : !isVeryBigScreen
                      ? '23vw'
                      : !ultraWide
                      ? '28.7vw'
                      : '21.5vw',
                    height: !isVeryBigScreen
                      ? !isMobile
                        ? '220px'
                        : '150px'
                      : '350px',
                    width: !isVeryBigScreen
                      ? !isMobile
                        ? '150px'
                        : '100px'
                      : '320px',
                    background: 'rgba(0, 0, 0, 0.7)',

                    border: 'none',
                    position: 'absolute',
                    top: !isVeryBigScreen
                      ? !isMobile
                        ? '65px'
                        : '123px'
                      : '110px',
                    border: 'none',
                    borderRadius: 'none',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    zIndex: '10',
                  }}
                  onMouseEnter={() => {
                    setAudioVisualDropDown(true);
                  }}
                  onMouseLeave={() => {
                    setAudioVisualDropDown(false);
                  }}
                >
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      color: 'white',
                    }}
                    to='/solutions/residential/audio/speakers/revox'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Speakers
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                    }}
                    to='/solutions/residential/audio/amplifiers/arcam'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Amplifiers
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      opacity: '70%',
                    }}
                    to='/solutions/residential/audio/screens/elite'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Screens
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                    }}
                    to='/solutions/residential/audio/projectors/benq'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Projectors
                  </Link>
                </div>
              </Fade>
            </div>
          </div>
          {!isMobile ? (
            <div
              className='nav2'
              style={{
                display: 'flex',
                height: '60px',
                justifyContent: 'center',
                marginTop: '40px',
                marginBottom: '-20px',
              }}
            >
              <p
                style={{
                  fontFamily: 'lato',
                  color: 'black',
                  opacity: '80%',
                  display: 'block',
                  margin: 'auto',
                  marginRight: 'auto',
                  marginLeft: '10vw',
                  fontWeight: '700',
                  fontSize: !isVeryBigScreen ? '1em' : '1.7em',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setCollection(false);
                  document.getElementById('speaker-nav').style.display =
                    'block';
                }}
              >
                &lt; Back to Audio Visual
              </p>
              <div
                className='right'
                style={{
                  display: 'flex',
                  margin: 'auto',
                  marginLeft: 'auto',
                  marginRight: '10vw',
                  width: '65%',
                  height: '100%',
                  maxWidth: '1200px',
                }}
              >
                <p
                  style={{
                    fontFamily: 'lato',
                    fontWeight: '500',
                    textAlign: 'center',
                    fontSize: !isVeryBigScreen ? '1.1em' : '1.7em',
                    display: 'block',
                    margin: 'auto',
                    textDecoration: 'none',
                    fontWeight: '400',
                    color:
                      currentProduct === 'loud-speakers'
                        ? 'black'
                        : 'rgb(120,120, 120)',
                    cursor: 'pointer',
                  }}
                  onClick={() => setCurrentProduct('loud-speakers')}
                >
                  Loud Speakers
                </p>
                <p
                  style={{
                    fontFamily: 'lato',
                    fontWeight: '500',
                    textAlign: 'center',
                    fontSize: !isVeryBigScreen ? '1.1em' : '1.7em',
                    display: 'block',
                    margin: 'auto',
                    fontWeight: '400',

                    color:
                      currentProduct === 'network-recievers'
                        ? 'black'
                        : 'rgb(120,120, 120)',
                    cursor: 'pointer',

                    textDecoration: 'none',
                  }}
                  onClick={() => setCurrentProduct('network-recievers')}
                >
                  Network Receivers
                </p>
                <p
                  style={{
                    fontFamily: 'lato',
                    fontWeight: '500',
                    textAlign: 'center',
                    fontSize: !isVeryBigScreen ? '1.1em' : '1.7em',
                    display: 'block',
                    margin: 'auto',
                    fontWeight: '400',

                    color:
                      currentProduct === 'studio-art'
                        ? 'black'
                        : 'rgb(120,120, 120)',
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                  onClick={() => setCurrentProduct('studio-art')}
                >
                  Studio Art
                </p>
                <p
                  style={{
                    fontFamily: 'lato',
                    fontWeight: '500',
                    textAlign: 'center',
                    fontSize: !isVeryBigScreen ? '1.1em' : '1.7em',
                    display: 'block',
                    margin: 'auto',
                    fontWeight: '400',

                    color:
                      currentProduct === 'turntables'
                        ? 'black'
                        : 'rgb(120,120, 120)',
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                  onClick={() => setCurrentProduct('turntables')}
                >
                  Turn Tables
                </p>
                <img
                  src={RevoxLogo}
                  alt='..'
                  style={{
                    display: 'block',
                    margin: 'auto',
                    width: !isVeryBigScreen ? '130px' : '170px',
                    marginRight: '0',
                    borderBottom: '1px solid black',
                    paddingBottom: '5px',
                  }}
                  onMouseEnter={() => setBrandDropDown(true)}
                  onMouseLeave={() => setBrandDropDown(false)}
                />
                <ExpandMoreIcon
                  style={{
                    display: 'block',
                    margin: 'auto',
                    marginLeft: '0',
                    marginRight: '0',
                  }}
                ></ExpandMoreIcon>
                <Fade in={brandDropDown}>
                  <div
                    style={{
                      display: 'flex',
                      // marginLeft: '100vw',
                      height: !isVeryBigScreen ? '270px' : '420px',
                      width: !isVeryBigScreen ? '150px' : '320px',
                      background: 'rgba(0, 0, 0, 0.7)',
                      right: '10.5vw',
                      border: 'none',
                      position: 'absolute',
                      top: !isVeryBigScreen ? '170px' : '200px',
                      border: 'none',
                      borderRadius: 'none',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      paddingTop: '20px',
                      paddingBottom: '20px',
                      zIndex: '20',
                    }}
                    onMouseEnter={() => {
                      setBrandDropDown(true);
                    }}
                    onMouseLeave={() => {
                      setBrandDropDown(false);
                    }}
                  >
                    <Link
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        display: 'block',
                        margin: 'auto',
                        textDecoration: 'none',
                        fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                        opacity: '70%',
                        color: 'white',
                        fontWeight: '700',
                      }}
                      to='/solutions/residential/audio/speakers/revox?collection=true'
                      onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                      onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                    >
                      Revox
                    </Link>
                    <Link
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        display: 'block',
                        margin: 'auto',
                        textDecoration: 'none',
                        fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                        opacity: '70%',
                        fontWeight: '700',
                      }}
                      to='/solutions/residential/audio/speakers/sonus?collection=true'
                      onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                      onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                    >
                      Sonus Faber
                    </Link>
                    <Link
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        display: 'block',
                        margin: 'auto',
                        textDecoration: 'none',
                        fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                        opacity: '70%',
                        opacity: '70%',
                        fontWeight: '700',
                      }}
                      to='/solutions/residential/audio/speakers/truAudio?collection=true'
                      onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                      onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                    >
                      Truaudio
                    </Link>
                    <Link
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        display: 'block',
                        margin: 'auto',
                        textDecoration: 'none',
                        fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                        opacity: '70%',
                        fontWeight: '700',
                      }}
                      to='/solutions/residential/audio/speakers/bowers?collection=true'
                      onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                      onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                    >
                      Bowers & Wilkins
                    </Link>

                    <Link
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        display: 'block',
                        margin: 'auto',
                        textDecoration: 'none',
                        fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                        opacity: '70%',
                        fontWeight: '700',
                      }}
                      to='/solutions/residential/audio/speakers/pmc?collection=true'
                      onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                      onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                    >
                      PMC
                    </Link>
                    <Link
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        display: 'block',
                        margin: 'auto',
                        textDecoration: 'none',
                        fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                        opacity: '70%',
                        fontWeight: '700',
                      }}
                      to='/solutions/residential/audio/speakers/sonos?collection=true'
                      onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                      onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                    >
                      Sonos
                    </Link>
                  </div>
                </Fade>
              </div>
            </div>
          ) : (
            <div>
              <p
                style={{
                  fontFamily: 'lato',
                  color: 'black',
                  opacity: '70%',
                  fontSize: !isMobile
                    ? !isVeryBigScreen
                      ? '1.2em'
                      : '2em'
                    : '1em',
                  textAlign: 'center',
                  fontWeight: '700',
                  marginTop: '-5px',
                  marginBottom: '15px',
                }}
                onClick={() => setCollection(false)}
              >
                &lt; Back to Audio Visual
              </p>
              <div style={{ display: 'flex' }}>
                <img
                  src={RevoxLogo}
                  alt='..'
                  style={{
                    display: 'block',
                    margin: 'auto',
                    width: !isVeryBigScreen ? '130px' : '170px',
                    // marginRight: '0',
                    borderBottom: '1px solid black',
                    paddingBottom: '5px',
                  }}
                  onClick={() => setBrandDropDown(true)}
                  onMouseLeave={() => setBrandDropDown(false)}
                />
              </div>
              <Fade in={brandDropDown}>
                <div
                  style={{
                    display: 'flex',
                    // marginLeft: '100vw',
                    height: !isVeryBigScreen ? '270px' : '420px',
                    width: !isVeryBigScreen ? '150px' : '320px',
                    background: 'rgba(0, 0, 0, 0.7)',
                    // right: '10.5vw',
                    border: 'none',
                    // position: 'absolute',
                    // top: !isVeryBigScreen ? '170px' : '200px',
                    border: 'none',
                    borderRadius: 'none',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    zIndex: '20',
                    marginLeft: '30vw',
                    position: 'absolute',
                  }}
                  onMouseEnter={() => {
                    setBrandDropDown(true);
                  }}
                  onMouseLeave={() => {
                    setBrandDropDown(false);
                  }}
                >
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      color: 'white',
                      fontWeight: '700',
                    }}
                    to='/solutions/residential/audio/speakers/revox?collection=true'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Revox
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      fontWeight: '700',
                    }}
                    to='/solutions/residential/audio/speakers/sonus?collection=true'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Sonus Faber
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      opacity: '70%',
                      fontWeight: '700',
                    }}
                    to='/solutions/residential/audio/speakers/truAudio?collection=true'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Truaudio
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      fontWeight: '700',
                    }}
                    to='/solutions/residential/audio/speakers/bowers?collection=true'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Bowers & Wilkins
                  </Link>

                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      fontWeight: '700',
                    }}
                    to='/solutions/residential/audio/speakers/pmc?collection=true'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    PMC
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      fontWeight: '700',
                    }}
                    to='/solutions/residential/audio/speakers/sonos?collection=true'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Sonos
                  </Link>
                </div>
              </Fade>
              <FormControl
                style={{
                  width: '200px',
                  display: 'block',
                  margin: 'auto',
                  marginTop: '20px',
                  color: 'black',
                }}
              >
                <InputLabel
                  id='demo-simple-select-label'
                  style={{ color: 'black', fontFamily: 'lato' }}
                >
                  Category
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={currentProduct}
                  label='Category'
                  onChange={(e) => {
                    setCurrentProduct(e.target.value);
                  }}
                  className={classes.select}
                  inputProps={{
                    classes: {
                      icon: classes.icon,
                      root: classes.root,
                    },
                  }}
                  // style={{ color: 'black', display: 'block', margin: 'auto' }}
                >
                  <MenuItem value={'loud-speakers'}>Loud Speakers</MenuItem>
                  <MenuItem value={'network-recievers'}>
                    Network Recievers
                  </MenuItem>
                  <MenuItem value={'studio-art'}>Studio Art</MenuItem>
                  <MenuItem value={'turntables'}>Turn Tables</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}

          <Slide in={true} direction={direction} unmountOnExit>
            <div>
              <div
                className='part1'
                style={{ marginTop: '4vh', width: '100vw' }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '30px',
                    width: !isMobile ? '80vw' : '95vw',
                    height: !isMobile ? '80vh' : smallPhone ? '40vh' : '75vh',
                    width: '100vw',
                  }}
                >
                  <button
                    className='carousel-control-prev'
                    type='button'
                    data-bs-target='#carouselExampleControls'
                    data-bs-slide='prev'
                    style={{
                      color: 'black',
                      width: '80px',
                      height: '60px',
                      background: 'rgb(0, 0, 0)',
                      position: 'static',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginLeft: '0',
                      display: isMobile ? 'none' : 'inline-block',
                    }}
                  >
                    <span
                      className='carousel-control-prev-icon'
                      aria-hidden='true'
                      style={{ color: 'black' }}
                    ></span>
                    <span
                      className='visually-hidden secondary'
                      style={{ color: 'black' }}
                    >
                      Previous
                    </span>
                  </button>

                  <div
                    id='carouselExampleControls'
                    className='carousel slide'
                    data-interval='false'
                    onMouseEnter={(e) => {
                      setShowSpeakers(true);
                      console.log('mouse enter');
                    }}
                    style={{
                      zIndex: '-1',
                      borderRadius: 'none',
                      width: !isMobile ? '80vw' : '95vw',
                      height: !isMobile ? '80vh' : smallPhone ? '40vh' : '75vh',
                      touchAction: 'none',
                      zIndex: '9',
                      marginLeft: '5px',
                      marginRight: '5px',
                      maxHeight: !isMobile ? 'none' : '250px',
                    }}
                    onClick={() => setShowSpeakers(true)}
                    onMouseLeave={() => {
                      setShowSpeakers(false);
                      console.log('mouse leave');
                    }}
                  >
                    <div
                      className='carousel-inner'
                      style={{
                        height: !isMobile
                          ? '80vh'
                          : smallPhone
                          ? '40vh'
                          : '75vh',
                        marginBottom: '20px',
                        maxHeight: !isMobile ? 'none' : '250px',
                      }}
                    >
                      <div
                        className={`carousel-item active 1`}
                        style={{ width: '100%', height: '100%' }}
                        id='loud-speakers'
                      >
                        <Modal
                          open={
                            aboutOpen &&
                            (document.querySelector('.active').classList
                              .value === 'carousel-item active 1' ||
                              document.querySelector('.active').classList
                                .value === 'carousel-item 1 active')
                          }
                          onClose={() => setAboutOpen(false)}
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                The fully fledged Prestige G 3-way floor speaker
                                demonstrates true greatness. Four long-stroke
                                woofers, a low-distortion midrange driver and a
                                tweeter with large textile diaphragm ensure a
                                tremendously precise and powerful spatial sound.
                                The timeless design with a premium glass front
                                and polished edges adds visual accents to any
                                room. The floor speakers also feature an
                                elegant, glass base. The G series comes in
                                modern colors: completely in white, elegant
                                black or a silver and black combination.
                                Features: powerful spatial sound, premium glass
                                front, timeless design, modern colours.
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              Prestige
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <Button
                                  variant='outlined'
                                  style={{
                                    display: 'flex',

                                    height: '60px',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    // marginRight: '-10%',

                                    margin: 'auto',
                                    marginLeft: !isMobile ? '10%' : 'auto',
                                    border: '1px solid white',
                                  }}
                                  onClick={() => {
                                    if (currentColor === 'black') {
                                      setCurrentColor('white');
                                    } else if (currentColor === 'silver') {
                                      setCurrentColor('black');
                                    } else if (currentColor === 'white') {
                                      setCurrentColor('silver');
                                    }
                                  }}
                                >
                                  <ArrowBackIos
                                    style={{ color: 'white' }}
                                  ></ArrowBackIos>
                                </Button>
                                <img
                                  src={
                                    currentColor === 'black'
                                      ? prestigeBlack
                                      : currentColor === 'silver'
                                      ? prestigeSilver
                                      : currentColor === 'white'
                                      ? prestigeWhite
                                      : ''
                                  }
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '90%' : '30%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: '-23%',
                                    marginRight: '-23%',

                                    // margin: 'auto',
                                  }}
                                  alt='..'
                                />
                                <Button
                                  variant='outlined'
                                  style={{
                                    display: 'flex',
                                    height: '60px',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    border: '1px solid white',

                                    margin: 'auto',
                                  }}
                                  onClick={() => {
                                    if (currentColor === 'black') {
                                      setCurrentColor('silver');
                                    } else if (currentColor === 'silver') {
                                      setCurrentColor('white');
                                    } else if (currentColor === 'white') {
                                      setCurrentColor('black');
                                    }
                                  }}
                                >
                                  <NavigateNextIcon
                                    style={{ color: 'white' }}
                                  ></NavigateNextIcon>
                                </Button>
                              </div>
                              <h6
                                style={{
                                  display: !isMobile ? 'none' : 'block',
                                  textAlign: 'center',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                }}
                              >
                                {currentColor}
                              </h6>
                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '18%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                Prestige
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '3%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  width: '80%',
                                  opacity: '70%',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.2em'
                                      : '1.0em'
                                    : '1.6em',
                                }}
                              >
                                The fully fledged Prestige G 3-way floor speaker
                                demonstrates true greatness. Four long-stroke
                                woofers, a low-distortion midrange driver and a
                                tweeter with large textile diaphragm ensure a
                                tremendously precise and powerful spatial sound.
                                The timeless design with a premium glass front
                                and polished edges adds visual accents to any
                                room. The floor speakers also feature an
                                elegant, glass base. The G series comes in
                                modern colors: completely in white, elegant
                                black or a silver and black combination.
                                Features: powerful spatial sound, premium glass
                                front, timeless design, modern colours.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={prestige}
                          alt='...'
                          style={{
                            width: '100%',
                            height: !isMobile ? '100%' : '100%',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                      </div>
                      <div
                        className={`carousel-item 2`}
                        id='loud-speakers'
                        style={{ width: '100%', height: '100%' }}
                      >
                        <Modal
                          open={
                            aboutOpen &&
                            (document.querySelector('.active').classList
                              .value === 'carousel-item active 2' ||
                              document.querySelector('.active').classList
                                .value === 'carousel-item 2 active')
                          }
                          onClose={() => setAboutOpen(false)}
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                The Scala S120 features an elegant black housing
                                and deep black front cover. The minimalist
                                design is particularly clear in the understated
                                white version. This choice of classic colours
                                makes a clear statement and emphasizes the
                                one-of-a-kind form. A love for detail can also
                                be seen in the premium glass base. The Scala
                                S120 is full of power. However, it also invites
                                the listener to enjoy soft and refined tones.
                                The excellent way it handles quiet sound is owed
                                to the elaborate development and finishing of
                                the driver. Features: minimalist design, glass
                                base, black and white versions, refined tones.
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              Scala
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <Button
                                  variant='outlined'
                                  style={{
                                    display: 'flex',

                                    height: '60px',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    // marginRight: '-10%',

                                    margin: 'auto',
                                    marginLeft: !isMobile ? '10%' : 'auto',
                                    border: '1px solid white',
                                  }}
                                  onClick={() => {
                                    if (currentColor === 'black') {
                                      setCurrentColor('white');
                                    } else if (currentColor === 'silver') {
                                      setCurrentColor('black');
                                    } else if (currentColor === 'white') {
                                      setCurrentColor('silver');
                                    }
                                  }}
                                >
                                  <ArrowBackIos
                                    style={{ color: 'white' }}
                                  ></ArrowBackIos>
                                </Button>
                                <img
                                  src={
                                    currentColor === 'black'
                                      ? scalaBlack
                                      : currentColor === 'silver'
                                      ? scalaSilver
                                      : currentColor === 'white'
                                      ? scalaWhite
                                      : ''
                                  }
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '90%' : '30%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: '-23%',
                                    marginRight: '-23%',

                                    // margin: 'auto',
                                  }}
                                  alt='..'
                                />
                                <Button
                                  variant='outlined'
                                  style={{
                                    display: 'flex',
                                    height: '60px',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    margin: 'auto',
                                    border: '1px solid white',
                                  }}
                                  onClick={() => {
                                    if (currentColor === 'black') {
                                      setCurrentColor('silver');
                                    } else if (currentColor === 'silver') {
                                      setCurrentColor('white');
                                    } else if (currentColor === 'white') {
                                      setCurrentColor('black');
                                    }
                                  }}
                                >
                                  <NavigateNextIcon
                                    style={{ color: 'white' }}
                                  ></NavigateNextIcon>
                                </Button>
                              </div>
                              <h6
                                style={{
                                  display: !isMobile ? 'none' : 'block',
                                  textAlign: 'center',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                }}
                              >
                                {currentColor}
                              </h6>
                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '18%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                Scala
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '3%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  width: '80%',
                                  opacity: '70%',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.2em'
                                      : '1.0em'
                                    : '1.6em',
                                }}
                              >
                                The Scala S120 features an elegant black housing
                                and deep black front cover. The minimalist
                                design is particularly clear in the understated
                                white version. This choice of classic colours
                                makes a clear statement and emphasizes the
                                one-of-a-kind form. A love for detail can also
                                be seen in the premium glass base. The Scala
                                S120 is full of power. However, it also invites
                                the listener to enjoy soft and refined tones.
                                The excellent way it handles quiet sound is owed
                                to the elaborate development and finishing of
                                the driver. Features: minimalist design, glass
                                base, black and white versions, refined tones.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={scala}
                          alt='...'
                          style={{
                            width: '100%',
                            height: !isMobile ? '100%' : '100%',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                      </div>
                      <div
                        className={`carousel-item 3`}
                        id='loud-speakers'
                        style={{ width: '100%', height: '100%' }}
                      >
                        <Modal
                          open={
                            aboutOpen &&
                            (document.querySelector('.active').classList
                              .value === 'carousel-item active 3' ||
                              document.querySelector('.active').classList
                                .value === 'carousel-item 3 active')
                          }
                          onClose={() => setAboutOpen(false)}
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                The Elegance G offers greater depth with its
                                stronger bass and fits any circumstance thanks
                                to its well-rounded spatial sound. The timeless
                                design with a premium glass front and polished
                                edges adds visual accents to any room. The floor
                                speakers also feature an elegant, glass base.
                                The G series comes in modern colors: completely
                                in white, elegant black or a silver and black
                                combination. Features: stronger bass, premium
                                glass front, timeless design, modern colors.
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              Elegance
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <Button
                                  variant='outlined'
                                  style={{
                                    display: 'flex',

                                    height: '60px',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    // marginRight: '-10%',

                                    margin: 'auto',
                                    marginLeft: !isMobile ? '10%' : 'auto',
                                    border: '1px solid white',
                                  }}
                                  onClick={() => {
                                    if (currentColor === 'black') {
                                      setCurrentColor('white');
                                    } else if (currentColor === 'silver') {
                                      setCurrentColor('black');
                                    } else if (currentColor === 'white') {
                                      setCurrentColor('silver');
                                    }
                                  }}
                                >
                                  <ArrowBackIos
                                    style={{ color: 'white' }}
                                  ></ArrowBackIos>
                                </Button>
                                <img
                                  src={
                                    currentColor === 'black'
                                      ? eleganceBlack
                                      : currentColor === 'silver'
                                      ? eleganceSilver
                                      : currentColor === 'white'
                                      ? eleganceWhite
                                      : ''
                                  }
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '90%' : '30%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: '-23%',
                                    marginRight: '-23%',

                                    // margin: 'auto',
                                  }}
                                  alt='..'
                                />
                                <Button
                                  variant='outlined'
                                  style={{
                                    display: 'flex',
                                    height: '60px',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    margin: 'auto',
                                    border: '1px solid white',
                                  }}
                                  onClick={() => {
                                    if (currentColor === 'black') {
                                      setCurrentColor('silver');
                                    } else if (currentColor === 'silver') {
                                      setCurrentColor('white');
                                    } else if (currentColor === 'white') {
                                      setCurrentColor('black');
                                    }
                                  }}
                                >
                                  <NavigateNextIcon
                                    style={{ color: 'white' }}
                                  ></NavigateNextIcon>
                                </Button>
                              </div>
                              <h6
                                style={{
                                  display: !isMobile ? 'none' : 'block',
                                  textAlign: 'center',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                }}
                              >
                                {currentColor}
                              </h6>
                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '18%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                Elegance
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '3%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  width: '80%',
                                  opacity: '70%',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.2em'
                                      : '1.0em'
                                    : '1.6em',
                                }}
                              >
                                The Elegance G offers greater depth with its
                                stronger bass and fits any circumstance thanks
                                to its well-rounded spatial sound. The timeless
                                design with a premium glass front and polished
                                edges adds visual accents to any room. The floor
                                speakers also feature an elegant, glass base.
                                The G series comes in modern colors: completely
                                in white, elegant black or a silver and black
                                combination. Features: stronger bass, premium
                                glass front, timeless design, modern colors.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={elegance}
                          alt='...'
                          style={{
                            width: '100%',
                            height: !isMobile ? '100%' : '100%',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                      </div>
                      <div
                        className={`carousel-item 4`}
                        style={{
                          width: '100%',
                          height: '100%',
                          overflow: 'visible',
                        }}
                        id='loud-speakers'
                      >
                        <Modal
                          onClose={() => setAboutOpen(false)}
                          open={
                            aboutOpen &&
                            (document.querySelector('.active').classList
                              .value === 'carousel-item active 4' ||
                              document.querySelector('.active').classList
                                .value === 'carousel-item 4 active')
                          }
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                The Shelf G comes with significantly more bass.
                                About the size of a binder, it delivers
                                remarkable power from any shelf or sideboard.
                                The G series also features matching center
                                speaker to create multidimensional cinema sound
                                in your home. Features: minimalist design,
                                powerful sound, compact size, flexible usage.
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              Bookshelf
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <Button
                                  variant='outlined'
                                  style={{
                                    display: 'flex',

                                    height: '60px',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    // marginRight: '-10%',

                                    margin: 'auto',
                                    border: '1px solid white',

                                    marginLeft: !isMobile ? '10%' : 'auto',
                                  }}
                                  onClick={() => {
                                    if (currentColor === 'black') {
                                      setCurrentColor('white');
                                    } else if (currentColor === 'silver') {
                                      setCurrentColor('black');
                                    } else if (currentColor === 'white') {
                                      setCurrentColor('silver');
                                    }
                                  }}
                                >
                                  <ArrowBackIos
                                    style={{ color: 'white' }}
                                  ></ArrowBackIos>
                                </Button>
                                <img
                                  src={
                                    currentColor === 'black'
                                      ? bookShelfBlack
                                      : currentColor === 'silver'
                                      ? bookShelfSilver
                                      : currentColor === 'white'
                                      ? bookShelfWhite
                                      : ''
                                  }
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '70%' : '35%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: '-23%',
                                    marginRight: '-23%',

                                    margin: 'auto',
                                  }}
                                  alt='..'
                                />
                                <Button
                                  variant='outlined'
                                  style={{
                                    display: 'flex',
                                    height: '60px',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    margin: 'auto',
                                    border: '1px solid white',
                                  }}
                                  onClick={() => {
                                    if (currentColor === 'black') {
                                      setCurrentColor('silver');
                                    } else if (currentColor === 'silver') {
                                      setCurrentColor('white');
                                    } else if (currentColor === 'white') {
                                      setCurrentColor('black');
                                    }
                                  }}
                                >
                                  <NavigateNextIcon
                                    style={{ color: 'white' }}
                                  ></NavigateNextIcon>
                                </Button>
                              </div>
                              <h6
                                style={{
                                  display: !isMobile ? 'none' : 'block',
                                  textAlign: 'center',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                }}
                              >
                                {currentColor}
                              </h6>
                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '18%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                Bookshelf
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '3%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  width: '80%',
                                  opacity: '70%',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.2em'
                                      : '1.0em'
                                    : '1.6em',
                                }}
                              >
                                The Shelf G comes with significantly more bass.
                                About the size of a binder, it delivers
                                remarkable power from any shelf or sideboard.
                                The G series also features matching center
                                speaker to create multidimensional cinema sound
                                in your home. Features: minimalist design,
                                powerful sound, compact size, flexible usage.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={bookshelf}
                          alt='...'
                          style={{
                            width: '100%',
                            height: !isMobile ? '100%' : '100%',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                      </div>
                      <div
                        className={`carousel-item 5`}
                        style={{ width: '100%', height: '100%' }}
                        id='network-recievers'
                      >
                        <Modal
                          open={
                            aboutOpen &&
                            (document.querySelector('.active').classList
                              .value === 'carousel-item active 5' ||
                              document.querySelector('.active').classList
                                .value === 'carousel-item 5 active')
                          }
                          onClose={() => setAboutOpen(false)}
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                A one-of-a-kind music system. Choose your music
                                from a wide selection of sources. Via Wi-Fi,
                                Bluetooth, audio streaming with internet radio,
                                Tidal, Deezer, and USB storage, the Joy Network
                                Receiver provides access to music via various
                                analogue and digital audio inputs. It possesses
                                a DAB+/FM radio and the Bluetooth interface is
                                activated automatically when paired with the Joy
                                Receiver. Operation takes place on the device or
                                using the Revox JOY app. Features: high-quality
                                amplifier, solid aluminium cabinet and glass
                                front panel, multiple options for audio
                                streaming, wide range of connectivity options.
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              Joy
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <img
                                  src={joyProduct}
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '70%' : '25%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: '-23%',
                                    marginRight: '-23%',

                                    margin: 'auto',
                                  }}
                                  alt='..'
                                />
                              </div>
                              <h6
                                style={{
                                  display: !isMobile ? 'none' : 'block',
                                  textAlign: 'center',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                }}
                              >
                                {currentColor}
                              </h6>
                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '18%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                Joy
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '3%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  width: '80%',
                                  opacity: '70%',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.2em'
                                      : '1.0em'
                                    : '1.6em',
                                }}
                              >
                                A one-of-a-kind music system. Choose your music
                                from a wide selection of sources. Via Wi-Fi,
                                Bluetooth, audio streaming with internet radio,
                                Tidal, Deezer, and USB storage, the Joy Network
                                Receiver provides access to music via various
                                analogue and digital audio inputs. It possesses
                                a DAB+/FM radio and the Bluetooth interface is
                                activated automatically when paired with the Joy
                                Receiver. Operation takes place on the device or
                                using the Revox JOY app. Features: high-quality
                                amplifier, solid aluminium cabinet and glass
                                front panel, multiple options for audio
                                streaming, wide range of connectivity options.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={joy}
                          alt='...'
                          style={{
                            width: '100%',
                            height: !isMobile ? '100%' : '100%',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                      </div>
                      <div
                        className={`carousel-item 6`}
                        id='studio-art'
                        style={{ width: '100%', height: '100%' }}
                      >
                        <Modal
                          open={
                            aboutOpen &&
                            (document.querySelector('.active').classList
                              .value === 'carousel-item active 6' ||
                              document.querySelector('.active').classList
                                .value === 'carousel-item 6 active')
                          }
                          onClose={() => setAboutOpen(false)}
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                Unlike any other; the S100 is an active 3.1
                                loudspeaker with highest reproduction quality of
                                up to 192 kHz/24 bit and is not only convincing
                                as a TV/home cinema system but also as an
                                independent music system. Seven high-end
                                loudspeaker chassis – three full-range chassis
                                in a left | centre | right arrangement and four
                                bass chassis – combined with seven low-noise
                                audio amplifiers and the integrated dual-port
                                bass reflex technology ensure an impressive
                                sound experience and excellent speech
                                intelligibility. Features: highest reproduction
                                quality, works as home cinema and independent
                                music system, dual-port bass reflex technology,
                                sleek design.
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              S100
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <Button
                                  variant='outlined'
                                  style={{
                                    display: 'flex',

                                    height: '60px',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    // marginRight: '-10%',

                                    margin: 'auto',
                                    marginLeft: !isMobile ? '10%' : 'auto',
                                    position: 'relative',
                                    left: '-20px',
                                    border: '1px solid white',
                                  }}
                                  onClick={() => {
                                    if (currentColor === 'black') {
                                      setCurrentColor('white');
                                    } else if (currentColor === 'silver') {
                                      setCurrentColor('black');
                                    } else if (currentColor === 'white') {
                                      setCurrentColor('black');
                                    }
                                  }}
                                >
                                  <ArrowBackIos
                                    style={{ color: 'white' }}
                                  ></ArrowBackIos>
                                </Button>
                                <img
                                  src={
                                    currentColor === 'black'
                                      ? s100Black
                                      : currentColor === 'white'
                                      ? s100White
                                      : ''
                                  }
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '60%' : '30%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    // marginLeft: '-23%',
                                    // marginRight: '-23%',

                                    margin: 'auto',
                                  }}
                                  alt='..'
                                />
                                <Button
                                  variant='outlined'
                                  style={{
                                    display: 'flex',
                                    height: '60px',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    margin: 'auto',
                                    position: 'relative',
                                    left: '20px',
                                    border: '1px solid white',
                                  }}
                                  onClick={() => {
                                    if (currentColor === 'black') {
                                      setCurrentColor('white');
                                    } else if (currentColor === 'silver') {
                                      setCurrentColor('white');
                                    } else if (currentColor === 'white') {
                                      setCurrentColor('black');
                                    }
                                  }}
                                >
                                  <NavigateNextIcon
                                    style={{ color: 'white' }}
                                  ></NavigateNextIcon>
                                </Button>
                              </div>
                              <h6
                                style={{
                                  display: !isMobile ? 'none' : 'block',
                                  textAlign: 'center',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                }}
                              >
                                {currentColor}
                              </h6>
                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '18%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                S100
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '3%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  width: '80%',
                                  opacity: '70%',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.2em'
                                      : '1.0em'
                                    : '1.6em',
                                }}
                              >
                                Unlike any other; the S100 is an active 3.1
                                loudspeaker with highest reproduction quality of
                                up to 192 kHz/24 bit and is not only convincing
                                as a TV/home cinema system but also as an
                                independent music system. Seven high-end
                                loudspeaker chassis – three full-range chassis
                                in a left | centre | right arrangement and four
                                bass chassis – combined with seven low-noise
                                audio amplifiers and the integrated dual-port
                                bass reflex technology ensure an impressive
                                sound experience and excellent speech
                                intelligibility. Features: highest reproduction
                                quality, works as home cinema and independent
                                music system, dual-port bass reflex technology,
                                sleek design.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={s100}
                          alt='...'
                          style={{
                            width: '100%',
                            height: !isMobile ? '100%' : '100%',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                      </div>
                      <div
                        className={`carousel-item 7`}
                        id='studio-art'
                        style={{ width: '100%', height: '100%' }}
                      >
                        <Modal
                          open={
                            aboutOpen &&
                            (document.querySelector('.active').classList
                              .value === 'carousel-item active 7' ||
                              document.querySelector('.active').classList
                                .value === 'carousel-item 7 active')
                          }
                          onClose={() => setAboutOpen(false)}
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                Sometimes the A100 is a solo artist, sometimes
                                it is a band leader with its fellow players
                                P100, B100 and S100. Thanks to its built-in
                                rechargeable battery, it delivers up to eight
                                hours of music enjoyment. It plays studio sound
                                quality via WiFi, Bluetooth and Apple AirPlay.
                                It understands your music collection, but also
                                iRadio, Spotify, Tidal and Deezer. Features:
                                built-in rechargeable battery, studio sound
                                quality, sleek design, multiple app
                                connectivity.
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              A100
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <img
                                  src={a100White}
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '90%' : '27%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: '-23%',
                                    marginRight: '-23%',

                                    margin: 'auto',
                                  }}
                                  alt='..'
                                />
                              </div>
                              <h6
                                style={{
                                  display: !isMobile ? 'none' : 'block',
                                  textAlign: 'center',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                }}
                              >
                                White
                              </h6>
                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '18%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                A100
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '3%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  width: '80%',
                                  opacity: '70%',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.2em'
                                      : '1.0em'
                                    : '1.6em',
                                }}
                              >
                                Sometimes the A100 is a solo artist, sometimes
                                it is a band leader with its fellow players
                                P100, B100 and S100. Thanks to its built-in
                                rechargeable battery, it delivers up to eight
                                hours of music enjoyment. It plays studio sound
                                quality via WiFi, Bluetooth and Apple AirPlay.
                                It understands your music collection, but also
                                iRadio, Spotify, Tidal and Deezer. Features:
                                built-in rechargeable battery, studio sound
                                quality, sleek design, multiple app
                                connectivity.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={a100}
                          alt='...'
                          style={{
                            width: '100%',
                            height: !isMobile ? '100%' : '100%',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                      </div>
                      <div
                        className={`carousel-item 8`}
                        id='studio-art'
                        style={{ width: '100%', height: '100%' }}
                      >
                        <Modal
                          open={
                            aboutOpen &&
                            (document.querySelector('.active').classList
                              .value === 'carousel-item active 8' ||
                              document.querySelector('.active').classList
                                .value === 'carousel-item 8 active')
                          }
                          onClose={() => setAboutOpen(false)}
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                The P100 Room Speaker is the tailor-made and
                                wired addition to the active speaker A100. An
                                excellent passive speaker, it plays big and
                                enriches your sound world with a perfect stereo
                                experience. Features: passive wired speaker,
                                works best with the A100, sleek design,
                                uncompromised sound quality.
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              P100
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <img
                                  src={p100Product}
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '90%' : '30%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: '-23%',
                                    marginRight: '-23%',

                                    margin: 'auto',
                                  }}
                                  alt='..'
                                />
                              </div>
                              <h6
                                style={{
                                  display: !isMobile ? 'none' : 'block',
                                  textAlign: 'center',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                }}
                              >
                                Black
                              </h6>
                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '18%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                P100
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '3%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  width: '80%',
                                  opacity: '70%',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.2em'
                                      : '1.0em'
                                    : '1.6em',
                                }}
                              >
                                The P100 Room Speaker is the tailor-made and
                                wired addition to the active speaker A100. An
                                excellent passive speaker, it plays big and
                                enriches your sound world with a perfect stereo
                                experience. Features: passive wired speaker,
                                works best with the A100, sleek design,
                                uncompromised sound quality.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={p100}
                          alt='...'
                          style={{
                            width: '100%',
                            height: !isMobile ? '100%' : '100%',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                      </div>
                      <div
                        className={`carousel-item 9`}
                        id='studio-art'
                        style={{
                          width: '100%',
                          height: '100%',
                          overflow: 'visible',
                        }}
                      >
                        <Modal
                          open={
                            aboutOpen &&
                            (document.querySelector('.active').classList
                              .value === 'carousel-item active 9' ||
                              document.querySelector('.active').classList
                                .value === 'carousel-item 9 active')
                          }
                          onClose={() => setAboutOpen(false)}
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                The Shelf G comes with significantly more bass.
                                About the size of a binder, it delivers
                                remarkable power from any shelf or sideboard.
                                The G series also features matching center
                                speaker to create multidimensional cinema sound
                                in your home. Features: minimalist design,
                                powerful sound, compact size, flexible usage.
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              B100
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <img
                                  src={b100}
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '70%' : '35%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: '-23%',
                                    marginRight: '-23%',

                                    margin: 'auto',
                                  }}
                                  alt='..'
                                />
                              </div>
                              <h6
                                style={{
                                  display: !isMobile ? 'none' : 'block',
                                  textAlign: 'center',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                }}
                              >
                                Black
                              </h6>
                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '18%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                B100
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '3%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  width: '80%',
                                  opacity: '70%',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.2em'
                                      : '1.0em'
                                    : '1.6em',
                                }}
                              >
                                The B100 subwoofer is the perfect partner for
                                the A100, P100 and S100 if you want even more
                                impressive bass reproduction. The innovative
                                chassis and downfire principle (floor for
                                reflection) make it possible. Features:
                                impressive bass reproduction, works best with
                                S100, A100 and P100 speakers, sleek design,
                                innovative chassis design.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={b100}
                          alt='...'
                          style={{
                            width: '100%',
                            height: !isMobile ? '100%' : '100%',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                      </div>
                      <div
                        className={`carousel-item 10`}
                        id='turntables'
                        style={{ width: '100%', height: '100%' }}
                      >
                        <Modal
                          open={
                            aboutOpen &&
                            (document.querySelector('.active').classList
                              .value === 'carousel-item active 10' ||
                              document.querySelector('.active').classList
                                .value === 'carousel-item 10 active')
                          }
                          onClose={() => setAboutOpen(false)}
                        >
                          <div
                            style={{
                              display: 'block',
                              margin: 'auto',
                              width: '90vw',
                              height: '35vh',
                              background: 'rgba(0, 0, 0, 0.64)',
                              marginTop: '25vh',
                              borderRadius: '5px',
                              overflow: 'scroll',
                            }}
                          >
                            <div
                              style={{
                                height: '75%',
                                overflow: 'scroll',
                                paddingTop: '10px',
                                paddingLeft: '20px',
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: 'lato',
                                  color: 'white',
                                  width: '90%',
                                }}
                              >
                                The STUDIOMASTER T700 Turntable is a high-end
                                record player with a one-of-a-kind, integrated
                                MC phono preamplifier. The integration of the
                                preamp enables the best and shortest connection
                                between the pickup and preamp, thereby
                                delivering the highest signal quality. To ensure
                                the best sound quality, absolute speed accuracy
                                is quintessential. For this purpose, the T700
                                has a highly precise electronic PLL speed
                                control system via a contactless optical sensor.
                                Features: integrated preamplifier, highest
                                signal quality, accurate playback speed,
                                contactless optical sensor.
                              </p>
                            </div>
                            <div
                              className='sticky'
                              style={{
                                position: 'sticky',
                                bottom: '0',
                                display: 'flex',
                                width: '100%',
                                height: '25%',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                variant='contained'
                                style={{
                                  fontFamily: 'lato',
                                  fontWeight: '700',
                                  color: 'red',
                                  marginLeft: 'auto',
                                  width: '100px',
                                  height: '80%',
                                  marginTop: 'auto',
                                  marginBottom: 'auto',
                                  background: 'transparent',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => setAboutOpen(false)}
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </Modal>
                        <Grow
                          in={showSpeakers}
                          style={{ transitionDuration: '2s' }}
                          unmountOnExit
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              zIndex: '7',
                              background: 'rgba(0, 0, 0, 0.7)',
                              display: 'flex',
                              justifyContent: 'center',
                              position: 'absolute',
                              transitionDuration: '1s',
                            }}
                          >
                            <Button
                              variant='contained'
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: '-75%',
                                marginTop: '10px',
                                background: 'rgba(0, 0, 0, 0.7)',
                                border: '1px solid white',
                                zIndex: '12',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                console.log('hoi');
                                setShowSpeakers(false);
                              }}
                            >
                              <CloseIcon></CloseIcon>
                            </Button>
                            <h5
                              style={{
                                display: !isMobile ? 'none' : 'block',
                                position: 'absolute',
                                marginTop: '10px',
                                fontFamily: 'lato',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                                zIndex: '6',
                              }}
                            >
                              T700
                            </h5>
                            <div
                              className='left'
                              style={{
                                width: !isMobile ? '30%' : '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                marginRight: 'auto',
                              }}
                            >
                              <div
                                className='flex'
                                style={{
                                  width: '100%',
                                  height: !isMobile ? '100%' : '80%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  margin: 'auto',
                                  position: 'relative',
                                  top: '10px',
                                }}
                              >
                                <img
                                  src={t700Product}
                                  style={{
                                    display: 'block',
                                    width: !isMobile ? '70%' : '35%',
                                    height: !isMobile ? 'auto' : 'auto',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    marginLeft: '-23%',
                                    marginRight: '-23%',

                                    margin: 'auto',
                                  }}
                                  alt='..'
                                />
                              </div>
                              <h6
                                style={{
                                  display: !isMobile ? 'none' : 'block',
                                  textAlign: 'center',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                }}
                              >
                                Black
                              </h6>
                              <Button
                                variant='contained'
                                style={{
                                  width: '50%',
                                  background: 'rgba(0, 0, 0, 0.64)',
                                  fontFamily: 'lato',
                                  color: 'white',
                                  display: !isMobile ? 'none' : 'block',
                                  margin: 'auto',
                                  fontWeight: '700',
                                  position: 'relative',
                                  top: '-10px',
                                  textTransform: 'capitalize',
                                  border: '1px solid white',
                                }}
                                onClick={() => setAboutOpen(true)}
                              >
                                View About
                              </Button>
                            </div>

                            <div
                              style={{
                                display: !isMobile ? 'flex' : 'none',
                                flexDirection: 'column',
                                width: '60%',
                              }}
                            >
                              <h4
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '18%',
                                  textAlign: 'left',
                                  fontWeight: '700',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '2.2em'
                                      : '1.7em'
                                    : '3.2em',
                                }}
                              >
                                T700
                              </h4>
                              <h6
                                style={{
                                  color: 'white',
                                  fontFamily: 'lato',
                                  marginTop: '3%',
                                  textAlign: 'left',
                                  fontWeight: '400',
                                  width: '80%',
                                  opacity: '70%',
                                  fontSize: !isVeryBigScreen
                                    ? !isSmallScreen
                                      ? '1.2em'
                                      : '1.0em'
                                    : '1.6em',
                                }}
                              >
                                The STUDIOMASTER T700 Turntable is a high-end
                                record player with a one-of-a-kind, integrated
                                MC phono preamplifier. The integration of the
                                preamp enables the best and shortest connection
                                between the pickup and preamp, thereby
                                delivering the highest signal quality. To ensure
                                the best sound quality, absolute speed accuracy
                                is quintessential. For this purpose, the T700
                                has a highly precise electronic PLL speed
                                control system via a contactless optical sensor.
                                Features: integrated preamplifier, highest
                                signal quality, accurate playback speed,
                                contactless optical sensor.
                              </h6>
                            </div>
                          </div>
                        </Grow>
                        <img
                          src={t700}
                          alt='...'
                          style={{
                            width: '100%',
                            height: !isMobile ? '100%' : '100%',
                            objectFit: !isMobile ? 'fill' : 'cover',
                            zIndex: '6',
                            height: '100%',
                            objectPosition: '0 20%',
                          }}
                        />
                      </div>
                    </div>
                    <button
                      class='carousel-control-prev'
                      type='button'
                      data-bs-target='#carouselExampleControls'
                      data-bs-slide='prev'
                      style={{ display: !isMobile ? 'none' : 'block' }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.cancelBubble = true;
                      }}
                    >
                      <span
                        class='carousel-control-prev-icon'
                        aria-hidden='true'
                      ></span>
                      <span class='visually-hidden'>Previous</span>
                    </button>
                    <button
                      class='carousel-control-next'
                      type='button'
                      data-bs-target='#carouselExampleControls'
                      data-bs-slide='next'
                      style={{ display: !isMobile ? 'none' : 'block' }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.cancelBubble = true;
                        if (e.stopPropagation) e.stopPropagation();
                        console.log('hoi');
                        setShowSpeakers(false);
                      }}
                    >
                      <span
                        class='carousel-control-next-icon'
                        aria-hidden='true'
                      ></span>
                      <span class='visually-hidden'>Next</span>
                    </button>
                  </div>
                  <button
                    className='carousel-control-next'
                    type='button'
                    data-bs-target='#carouselExampleControls'
                    data-bs-slide='next'
                    style={{
                      color: 'black',
                      width: '80px',
                      height: '60px',
                      background: 'rgb(0, 0, 0)',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      position: 'static',
                      display: isMobile ? 'none' : 'inline-block',
                    }}
                    onClick={(e) => {
                      console.log(document.querySelector('.active'));
                    }}
                  >
                    <span
                      className='carousel-control-next-icon'
                      aria-hidden='true'
                    ></span>
                    <span className='visually-hidden'>Next</span>
                  </button>
                </div>
              </div>
            </div>
          </Slide>
          <Slide
            in={currentProduct === false}
            direction={direction}
            unmountOnExit
          >
            <div>
              <div
                className='part1'
                style={{ marginTop: '4vh', width: '100vw' }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '30px',
                    width: !isMobile ? '80vw' : '95vw',
                    height: !isMobile ? '80vh' : smallPhone ? '40vh' : '75vh',
                    width: '100vw',
                  }}
                >
                  <button
                    className='carousel-control-prev'
                    type='button'
                    data-bs-target='#carouselExampleControls'
                    data-bs-slide='prev'
                    style={{
                      color: 'black',
                      width: '80px',
                      height: '60px',
                      background: 'rgb(0, 0, 0)',
                      position: 'static',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginLeft: '0',
                      display: isMobile ? 'none' : 'inline-block',
                    }}
                  >
                    <span
                      className='carousel-control-prev-icon'
                      aria-hidden='true'
                      style={{ color: 'black' }}
                    ></span>
                    <span
                      className='visually-hidden secondary'
                      style={{ color: 'black' }}
                    >
                      Previous
                    </span>
                  </button>

                  <div
                    id='carouselExampleControls'
                    className='carousel slide'
                    data-interval='false'
                    onMouseEnter={(e) => {
                      setShowSpeakers(true);
                      console.log('mouse enter');
                    }}
                    onClick={(e) => {
                      setShowSpeakers(true);
                    }}
                    style={{
                      zIndex: '-1',
                      borderRadius: 'none',
                      width: !isMobile ? '80vw' : '95vw',
                      height: !isMobile ? '80vh' : smallPhone ? '40vh' : '75vh',
                      touchAction: 'none',
                      zIndex: '9',
                      marginLeft: '5px',
                      marginRight: '5px',
                    }}
                    onMouseLeave={() => {
                      setShowSpeakers(false);
                      console.log('mouse leave');
                    }}
                  >
                    <div
                      className='carousel-inner'
                      style={{
                        height: !isMobile
                          ? '80vh'
                          : smallPhone
                          ? '40vh'
                          : '75vh',
                        marginBottom: '20px',
                      }}
                    ></div>

                    <button
                      class='carousel-control-prev'
                      type='button'
                      data-bs-target='#carouselExampleControls'
                      data-bs-slide='prev'
                      style={{ display: !isMobile ? 'none' : 'block' }}
                    >
                      <span
                        class='carousel-control-prev-icon'
                        aria-hidden='true'
                      ></span>
                      <span class='visually-hidden'>Previous</span>
                    </button>
                    <button
                      class='carousel-control-next'
                      type='button'
                      data-bs-target='#carouselExampleControls'
                      data-bs-slide='next'
                      style={{ display: !isMobile ? 'none' : 'block' }}
                    >
                      <span
                        class='carousel-control-next-icon'
                        aria-hidden='true'
                      ></span>
                      <span class='visually-hidden'>Next</span>
                    </button>
                  </div>
                  <button
                    className='carousel-control-next'
                    type='button'
                    data-bs-target='#carouselExampleControls'
                    data-bs-slide='next'
                    style={{
                      color: 'black',
                      width: '80px',
                      height: '60px',
                      background: 'rgb(0, 0, 0)',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      position: 'static',

                      display: isMobile ? 'none' : 'inline-block',
                    }}
                  >
                    <span
                      className='carousel-control-next-icon'
                      aria-hidden='true'
                    ></span>
                    <span className='visually-hidden'>Next</span>
                  </button>
                </div>
              </div>
            </div>
          </Slide>
          <Slide in={false} direction={direction} unmountOnExit>
            <div>
              <div
                className='part1'
                style={{ marginTop: '4vh', width: '100vw' }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '30px',
                    width: !isMobile ? '80vw' : '95vw',
                    height: !isMobile ? '80vh' : smallPhone ? '40vh' : '75vh',
                    width: '100vw',
                  }}
                >
                  <button
                    className='carousel-control-prev'
                    type='button'
                    data-bs-target='#carouselExampleControls'
                    data-bs-slide='prev'
                    style={{
                      color: 'black',
                      width: '80px',
                      height: '60px',
                      background: 'rgb(0, 0, 0)',
                      position: 'static',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginLeft: '0',
                      display: isMobile ? 'none' : 'inline-block',
                    }}
                  >
                    <span
                      className='carousel-control-prev-icon'
                      aria-hidden='true'
                      style={{ color: 'black' }}
                    ></span>
                    <span
                      className='visually-hidden secondary'
                      style={{ color: 'black' }}
                    >
                      Previous
                    </span>
                  </button>

                  <div
                    id='carouselExampleControls'
                    className='carousel slide'
                    data-interval='false'
                    onMouseEnter={(e) => {
                      setShowSpeakers(true);
                      console.log('mouse enter');
                    }}
                    style={{
                      zIndex: '-1',
                      borderRadius: 'none',
                      width: !isMobile ? '80vw' : '95vw',
                      height: !isMobile ? '80vh' : smallPhone ? '40vh' : '75vh',
                      touchAction: 'none',
                      zIndex: '9',
                      marginLeft: '5px',
                      marginRight: '5px',
                    }}
                    onClick={() => setShowSpeakers(true)}
                    onMouseLeave={() => {
                      setShowSpeakers(false);
                      console.log('mouse leave');
                    }}
                  >
                    <div
                      className='carousel-inner'
                      style={{
                        height: !isMobile
                          ? '80vh'
                          : smallPhone
                          ? '40vh'
                          : '75vh',
                        marginBottom: '20px',
                      }}
                    ></div>
                    <button
                      class='carousel-control-prev'
                      type='button'
                      data-bs-target='#carouselExampleControls'
                      data-bs-slide='prev'
                      style={{ display: !isMobile ? 'none' : 'block' }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.cancelBubble = true;
                        if (e.stopPropagation) e.stopPropagation();
                        console.log('hoi');
                        setShowSpeakers(false);
                      }}
                    >
                      <span
                        class='carousel-control-prev-icon'
                        aria-hidden='true'
                      ></span>
                      <span class='visually-hidden'>Previous</span>
                    </button>
                    <button
                      class='carousel-control-next'
                      type='button'
                      data-bs-target='#carouselExampleControls'
                      data-bs-slide='next'
                      style={{ display: !isMobile ? 'none' : 'block' }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.cancelBubble = true;
                        if (e.stopPropagation) e.stopPropagation();
                        console.log('hoi');
                        setShowSpeakers(false);
                      }}
                    >
                      <span
                        class='carousel-control-next-icon'
                        aria-hidden='true'
                      ></span>
                      <span class='visually-hidden'>Next</span>
                    </button>
                  </div>
                  <button
                    className='carousel-control-next'
                    type='button'
                    data-bs-target='#carouselExampleControls'
                    data-bs-slide='next'
                    style={{
                      color: 'black',
                      width: '80px',
                      height: '60px',
                      background: 'rgb(0, 0, 0)',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      position: 'static',
                      display: isMobile ? 'none' : 'inline-block',
                    }}
                  >
                    <span
                      className='carousel-control-next-icon'
                      aria-hidden='true'
                    ></span>
                    <span className='visually-hidden'>Next</span>
                  </button>
                </div>
              </div>
            </div>
          </Slide>

          <div
            style={{
              width: '100vw',
              height: '70px',
              display: 'flex',
              justifyContent: 'center',
              background: 'rgb(35, 35, 35)',
              marginTop: '50px',
            }}
          >
            <p
              style={{
                margin: 'auto',
                marginLeft: '3%',
                fontFamily: 'lato',
                fontWeight: '700',
                fontSize: '0.7em',
                color: 'white',
              }}
            >
              © 2022 Thar Technologies
            </p>
            <p
              style={{
                margin: 'auto',
                marginRight: '3%',
                fontFamily: 'lato',
                fontWeight: '700',
                fontSize: !isBigScreen
                  ? !isMobile
                    ? '0.9em'
                    : '0.5em'
                  : '1.6em',
                color: 'white',
                opacity: '70%',
              }}
            >
              Designed & Developed by Workplay Studio Pvt. Ltd.
            </p>
          </div>
          <hr style={{ color: 'white', margin: '0' }} />
        </div>
      </Fade>
      {/* {disableScroll()} */}
    </div>
  );
};

export default Speakers;
