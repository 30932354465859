import React from 'react';
// import logo from '../assets/TharTechLogo(AudioVisualPage).png';
import logo from '../../../../../assets/Logo.png';
import { Link, useLocation } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Fade from '@mui/material/Fade';
import { createStyles, makeStyles } from '@mui/styles';

const SpeakerNav = () => {
  const [isVeryBigScreen, setIsVeryBigScreen] = React.useState(
    window.matchMedia('(min-width: 2000px)').matches
  );

  const [isSmallScreen, setIsSmallScreen] = React.useState(
    window.matchMedia('(max-width: 1300px)').matches
  );

  const [isVerySmallScreen, setIsVerySmallScreen] = React.useState(
    window.matchMedia('(max-width: 1200px)').matches
  );
  const [pcScreen, setPcScreen] = React.useState(
    window.matchMedia('(min-width: 1900px)').matches
  );
  const [audioVisualDropDown, setAudioVisualDropDown] = React.useState(false);

  const [brandName, setBrandName] = React.useState('');

  const location = useLocation();

  const [isMobile, setIsMobile] = React.useState(
    window.matchMedia('(max-width: 768px)').matches
  );

  const [residentialDropDown, setResidentialDropDown] = React.useState(false);

  React.useEffect(() => {
    if (location.pathname.endsWith('/benq')) {
      setBrandName('benq');
    } else if (location.pathname.endsWith('/jvc')) {
      setBrandName('jvc');
    } else if (location.pathname.endsWith('/sony')) {
      setBrandName('sony');
    }
  }, [location]);

  const useStyles = makeStyles(() =>
    createStyles({
      select: {
        '&:before': {
          borderColor: 'white',
        },
        '&:after': {
          borderColor: 'white',
        },
        '&:not(.Mui-disabled):hover::before': {
          borderColor: 'white',
        },
      },
      icon: {
        fill: 'white',
      },
      root: {
        color: 'white',
      },
    })
  );

  const classes = useStyles();

  if (!isMobile) {
    return (
      <div
        style={{
          background: 'rgba(0, 0, 0, 0.7)',
          marginTop: '-20px',
          paddingTop: '25px',
        }}
        id='speaker-nav'
      >
        <div
          className='topNav'
          style={{
            display: 'flex',
            width: '100vw',
            height: !isVeryBigScreen ? '70px' : '120px',
            justifyContent: 'center',
            marginTop: '10px',
          }}
        >
          <div
            className='left'
            style={{
              marginRight: 'auto',
              marginTop: 'auto',
              marginBottom: 'auto',
              marginLeft: '40px',
              height: '100%',
              display: !isMobile ? 'flex' : 'none',
              justifyContent: 'center',
            }}
          >
            <img
              src={logo}
              alt=''
              style={{
                display: 'block',
                margin: 'auto',
                width: !isVeryBigScreen ? '250px' : '350px',
                cursor: 'pointer',
              }}
              onClick={() => (window.location = '/')}
            />
          </div>
          <div
            className='left'
            style={{
              display: 'flex',
              marginLeft: 'auto',
              marginRight: !isVeryBigScreen ? '50px' : '120px',
              height: '100%',
              justifyContent: 'center',
            }}
          >
            <h5
              style={{
                fontFamily: 'lato',
                fontWeight: '400',
                margin: 'auto',
              }}
            >
              <span
                style={{
                  color: 'rgb(220, 220, 220)',
                  fontSize: !isMobile
                    ? !isVeryBigScreen
                      ? '1em'
                      : '1.8em'
                    : '0.6em',
                  cursor: 'pointer',
                  marginLeft: !isMobile ? '20px' : '-1vw',
                }}
                onClick={() => (window.location = '/')}
                onMouseEnter={(e) => {
                  e.target.style.opacity = '100%';
                  document.getElementById('home').style.opacity = '100%';
                }}
                onMouseLeave={(e) => {
                  e.target.style.opacity = '70%';
                  document.getElementById('home').style.opacity = '70%';
                }}
                id='back'
              >
                &lt;
              </span>
              <span
                style={{
                  color: 'rgb(220, 220, 220)',
                  fontSize: !isMobile
                    ? !isVeryBigScreen
                      ? '1em'
                      : '1.8em'
                    : '0.6em',
                  cursor: 'pointer',
                  marginLeft: '10px',
                  opacity: '70%',
                }}
                onClick={() => (window.location = '/')}
                id='home'
                onMouseEnter={(e) => {
                  e.target.style.opacity = '100%';
                  document.getElementById('back').style.opacity = '100%';
                }}
                onMouseLeave={(e) => {
                  e.target.style.opacity = '70%';
                  document.getElementById('back').style.opacity = '70%';
                }}
              >
                Home
              </span>
              <span
                style={{
                  color: 'rgb(220, 220, 220)',
                  fontSize: !isMobile
                    ? !isVeryBigScreen
                      ? '1em'
                      : '1.8em'
                    : '0.6em',
                  cursor: 'pointer',
                  marginLeft: !isMobile ? '40px' : '2vw',
                }}
                onClick={() => (window.location = '/')}
              >
                |
              </span>
              <span
                style={{
                  marginLeft: !isMobile
                    ? !isVeryBigScreen
                      ? '40px'
                      : '45px'
                    : '3vw',
                  fontSize: !isMobile
                    ? !isVeryBigScreen
                      ? '0.8em'
                      : '1.3em'
                    : '0.6em',
                  opacity: '70%',
                  whiteSpace: 'pre',
                  color: 'white',
                }}
                onMouseEnter={() => {
                  setResidentialDropDown(true);
                }}
                onMouseLeave={() => {
                  setResidentialDropDown(false);
                }}
              >
                {!isMobile
                  ? 'Solutions    >    Residential    >    Audio/Visual    >'
                  : 'Solutions > Residential > Audio/Visual >'}
              </span>
              <span
                style={{
                  marginLeft: !isMobile
                    ? !isVeryBigScreen
                      ? '20px'
                      : '50px'
                    : '2vw',
                  fontSize: !isMobile
                    ? !isVeryBigScreen
                      ? '1.2em'
                      : '2em'
                    : '0.8em',
                  color: 'white',
                  background: 'rgba(0, 0, 0, 0.4)',
                  border: '1px solid white',
                  padding: '7px',
                  borderRadius: '5px',
                }}
                onMouseEnter={() => {
                  setAudioVisualDropDown(true);
                }}
                onMouseLeave={() => {
                  setAudioVisualDropDown(false);
                }}
              >
                {'Projectors '}
              </span>
              <Fade in={residentialDropDown}>
                <div
                  style={{
                    display: 'flex',
                    marginLeft: !pcScreen
                      ? !isVerySmallScreen
                        ? '250px'
                        : !isSmallScreen || isVerySmallScreen
                        ? '24vw'
                        : '32vw'
                      : !isVeryBigScreen
                      ? '14vw'
                      : '14vw',
                    height: !isVeryBigScreen ? '100px' : '210px',
                    width: !isVeryBigScreen ? '150px' : '320px',
                    background: 'rgba(0, 0, 0, 0.9)',

                    border: 'none',
                    position: 'absolute',
                    top: !isVeryBigScreen ? '65px' : '100px',
                    border: 'none',
                    borderRadius: 'none',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    zIndex: '9',
                  }}
                  onMouseEnter={() => {
                    setResidentialDropDown(true);
                  }}
                  onMouseLeave={() => {
                    setResidentialDropDown(false);
                  }}
                >
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.7em' : '1.5em',
                      opacity: '70%',
                      color: 'white',
                    }}
                    to='/solutions/residential/audio/speakers/revox'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Audio Visual
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.7em' : '1.5em',
                      opacity: '70%',
                    }}
                    to='/solutions/residential?active=homeDecoration'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Home Automation
                  </Link>
                </div>
              </Fade>
              <Fade in={audioVisualDropDown}>
                <div
                  style={{
                    display: 'flex',
                    marginLeft: !pcScreen
                      ? !isVerySmallScreen
                        ? '500px'
                        : !isSmallScreen || isVerySmallScreen
                        ? '38.5vw'
                        : '40vw'
                      : !isVeryBigScreen
                      ? '23vw'
                      : '29vw',
                    height: !isVeryBigScreen ? '220px' : '350px',
                    width: !isVeryBigScreen ? '150px' : '320px',
                    background: 'rgba(0, 0, 0, 0.7)',

                    border: 'none',
                    position: 'absolute',
                    top: !isVeryBigScreen ? '65px' : '100px',
                    border: 'none',
                    borderRadius: 'none',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                  }}
                  onMouseEnter={() => {
                    setAudioVisualDropDown(true);
                  }}
                  onMouseLeave={() => {
                    setAudioVisualDropDown(false);
                  }}
                >
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      color: 'white',
                    }}
                    to='/solutions/residential/audio/speakers/revox'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Speakers
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                    }}
                    to='/solutions/residential/audio/amplifiers/arcam'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Amplifiers
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                      opacity: '70%',
                    }}
                    to='/solutions/residential/audio/screens/elite'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Screens
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.9em' : '1.7em',
                      opacity: '70%',
                    }}
                    to='/solutions/residential/audio/projectors/benq'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Projectors
                  </Link>
                </div>
              </Fade>
            </h5>
          </div>
        </div>
        <div
          className='bottomNav'
          style={{
            display: 'flex',
            margin: 'auto',
            width: '79vw',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80px',
            marginTop: !isVeryBigScreen ? '20px' : '50px',
            marginBottom: !isVeryBigScreen ? 'auto' : '40px',
            position: 'relative',
            top: !isVeryBigScreen ? '0px' : '-20px',
          }}
        >
          <Link
            style={{
              fontFamily: 'Roboto',
              fontWeight: '400',
              textAlign: 'center',
              fontSize: !isVeryBigScreen ? '1.3em' : '1.7em',
              display: 'block',
              margin: 'auto',
              textDecoration: 'none',
              color: brandName === 'benq' ? 'white' : 'rgb(200,200, 200)',
            }}
            to='/solutions/residential/audio/projectors/benq'
          >
            Benq
          </Link>
          <Link
            style={{
              fontFamily: 'Roboto',
              fontWeight: '400',
              textAlign: 'center',
              fontSize: !isVeryBigScreen ? '1.3em' : '1.7em',
              display: 'block',
              margin: 'auto',
              color: brandName === 'jvc' ? 'white' : 'rgb(200,200, 200)',

              textDecoration: 'none',
            }}
            to='/solutions/residential/audio/projectors/jvc'
          >
            JVC
          </Link>
          <Link
            style={{
              fontFamily: 'Roboto',
              fontWeight: '400',
              textAlign: 'center',
              fontSize: !isVeryBigScreen ? '1.3em' : '1.7em',
              display: 'block',
              margin: 'auto',
              color: brandName === 'sony' ? 'white' : 'rgb(200,200, 200)',
              textDecoration: 'none',
            }}
            to='/solutions/residential/audio/projectors/sony'
          >
            Sony
          </Link>
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{ background: 'rgba(0, 0, 0, 0.7)', paddingBottom: '10px' }}
        id='speaker-nav'
      >
        <div
          className='top'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '60px',
          }}
        >
          <p
            style={{
              fontFamily: 'lato',
              color: 'black',
              display: 'block',
              margin: 'auto',
              fontSize: '0.9em',
              color: 'white',
            }}
            onClick={() => (window.location = '/')}
          >
            Home |
          </p>
          <p
            style={{
              fontFamily: 'lato',
              color: 'black',
              display: 'block',
              margin: 'auto',
              fontSize: '0.7em',
              color: 'white',
            }}
          >
            Solutions &gt;
          </p>
          <p
            style={{
              fontFamily: 'lato',
              color: 'black',
              display: 'block',
              margin: 'auto',

              color: 'white',

              fontSize: '0.7em',
            }}
          >
            Residential &gt;
          </p>
          <p
            style={{
              fontFamily: 'lato',
              color: 'black',
              display: 'block',
              margin: 'auto',
              fontSize: '0.7em',
              color: 'white',
            }}
          >
            Audio/Visual &gt;
          </p>
          <p
            style={{
              fontFamily: 'lato',
              color: 'black',
              display: 'block',
              margin: 'auto',
              fontSize: '0.9em',
              color: 'white',
              position: 'relative',
              left: '-1%',
            }}
          >
            Projectors
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <h6
            style={{
              fontFamily: 'lato',
              display: 'block',
              margin: 'auto',
              position: 'absolute',
              textAlign: 'left',
              color: 'white',

              width: '100%',
              paddingLeft: '7%',
            }}
          ></h6>
          <FormControl variant='filled' sx={{ m: 1, minWidth: 120 }}>
            <InputLabel
              id='demo-simple-select-filled-label'
              style={{ color: 'white' }}
            >
              Brand
            </InputLabel>

            <Select
              labelId='demo-simple-select-filled-label'
              id='demo-simple-select'
              value={brandName}
              label='Brand Name'
              style={{
                fontFamily: 'lato',
                color: 'white',
              }}
              className={classes.select}
              inputProps={{
                classes: {
                  icon: classes.icon,
                  root: classes.root,
                },
              }}
              onChange={(e) => {
                window.location = `/#/solutions/residential/audio/projectors/${e.target.value}`;
              }}
            >
              <MenuItem
                value={'benq'}
                style={{
                  textDecoration: 'none',
                  color: 'black',
                }}
              >
                Benq
              </MenuItem>
              <MenuItem
                value={'sony'}
                style={{
                  textDecoration: 'none',
                  color: 'black',
                }}
              >
                Sony
              </MenuItem>
              <MenuItem
                value={'jvc'}
                style={{
                  textDecoration: 'none',
                  color: 'black',
                }}
              >
                JVC
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    );
  }
};

export default SpeakerNav;
