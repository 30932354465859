import React from 'react';
import cediaDesigner from '../assets/cedia-designer.png';
import cediaMember from '../assets/cediamember.png';
import lutronCertified from '../assets/lutron-certified.png';
import image1 from '../assets/1.JPG';
import image2 from '../assets/2.jpg';
import image3 from '../assets/3.jpg';
import image4 from '../assets/4.jpg';
import image5 from '../assets/5.jpg';
import image6 from '../assets/6.jpg';
import image7 from '../assets/7.jpg';
import { useLocation } from 'react-router-dom';
import Fade from '@mui/material/Fade';
import background from '../assets/about-background.svg';
import Grow from '@mui/material/Grow';
import Collapse from '@mui/material/Collapse';
import phoneIcon from '../assets/phone-call-icon.svg';
import locationIcon from '../assets/location-icon.svg';
import emailIcon from '../assets/email-icon.svg';
import './css/about-style.css';
import facebookIcon from '../assets/facebook.svg';
import instaIcon from '../assets/insta.svg';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from 'react-router-dom';

const About = () => {
  const location = useLocation();

  React.useEffect(() => {
    if (document.getElementById('nav')) {
      document.getElementById('nav').style.backgroundColor = 'black';
    }
  }, []);

  const [aboutPath, setAboutPath] = React.useState('whoWeAre');

  const [isBigScreen, setIsBigScreen] = React.useState(
    window.matchMedia('(min-width: 2000px)').matches
  );
  const [isSmallScreen, setIsSmallScreen] = React.useState(
    window.matchMedia('(max-width: 1200px)').matches
  );

  const [cedia, setCedia] = React.useState(false);

  React.useEffect(() => {
    if (!isMobile) {
      document
        .getElementById('who-we-are-link')
        .addEventListener('click', () => {
          setCedia(false);
        });
    }
  }, []);

  const [isMobile, setIsMobile] = React.useState(
    window.matchMedia('(max-width: 768px)').matches
  );

  React.useEffect(() => {
    console.log(location.pathname);

    if (location.pathname === '/aboutUs/who-we-are') {
      console.log('who-we-are');
      setAboutPath('whoWeAre');
    } else if (location.pathname === '/aboutUs/what-we-accomplished') {
      console.log('what-we-accomplished');
      setAboutPath('whatWeAccomplished');
    } else if (location.pathname === '/aboutUs/experience') {
      console.log('experience-center');

      setAboutPath('experienceCenter');
    } else {
      console.log('who-we-are');

      setAboutPath('whoWeAre');
    }
  }, [location]);

  React.useEffect(() => {
    console.log(aboutPath);

    document.getElementById('background').style.width = '100vw';
    document.getElementById('background').style.transform = 'rotate(0deg)';
    document.getElementById('background').style.top = '0px';

    if (!isMobile ? aboutPath === 'whatWeAccomplished' : true) {
      document.getElementById('vr1').style.height = '0px';
      document.getElementById('vr2').style.height = '0px';

      setTimeout(() => {
        document.getElementById('vr1').style.height = !isBigScreen
          ? '50px'
          : '70px';
        setTimeout(() => {
          document.getElementById('important-mile').style.left = '0';
          document.getElementById('important-mile').style.visibility =
            'visible';

          setTimeout(() => {
            document.getElementById('vr2').style.height = !isBigScreen
              ? '50px'
              : '70px';

            setTimeout(() => {
              document.getElementById('mile-container').style.visibility =
                'visible';
            }, 400);
          }, 500);
        }, 500);
      }, 200);
    } else if (aboutPath === 'experienceCenter') {
      document.getElementById('background').style.width = '125vw';
      // document.getElementById('background').style.top = '300px';
      // document.getElementById('background').style.left = '40px';

      // document.getElementById('background').style.transform = 'rotate(60deg)';
    }
  }, [aboutPath]);

  const [pcScreen, setPcScreen] = React.useState(
    window.matchMedia('(min-width: 1900px)').matches
  );

  return (
    <div
      style={{
        position: isMobile ? 'absolute' : 'static',
        top: !isMobile ? '0' : '102vh',
      }}
    >
      <img
        src={background}
        alt=''
        id='background'
        style={{
          width: '100vw',
          height: '107vh',
          position: 'absolute',
          zIndex: '-1',
          top: !isMobile ? '0' : 'auto',
          transitionProperty: 'all',
          transitionDuration: '0.7s',
        }}
      />
      {!isMobile ? '' : ''}
      <Fade in={!isMobile ? aboutPath === 'whoWeAre' : true} unmountOnExit>
        <div
          style={{
            marginTop: isMobile ? '-0' : '0px',
          }}
        >
          <Fade in={!cedia} unmountOnExit>
            <div style={{ marginTop: '30px', paddingTop: '40px' }}>
              <h1
                style={{
                  textAlign: 'left',
                  fontFamily: 'lato',
                  color: 'black',
                  display: isMobile ? 'none' : 'none',
                  width: '100vw',
                  marginTop: '150px',
                  marginLeft: '4vw',
                  fontWeight: 'bolder',
                  fontSize: '2.3em',
                }}
              >
                About us
              </h1>
              <h2
                style={{
                  fontFamily: 'lato',
                  marginTop: !isMobile
                    ? !isBigScreen
                      ? '-140px'
                      : '60px'
                    : '0',
                  fontWeight: '700',
                  textAlign: 'center',
                }}
              >
                Who We Are
              </h2>
              <h5
                style={{
                  margin: 'auto',
                  fontFamily: 'lato',
                  marginTop: '30px',
                  maxWidth: '1190px',
                  opacity: '70%',
                  fontWeight: '700',
                  textAlign: 'center',
                  paddingLeft: !isMobile ? '0' : '16px',
                  paddingRight: !isMobile ? '0' : '16px',
                }}
              >
                Thar Technologies was incorporated in 2008 to integrate
                cutting-edge products, that leverage the latest technologies,
                for the betterment of mankind. From security surveillance and AV
                solutions to light design and other motorized innovations, our
                team specializes in executing innovative solutions across
                multiple industries including the residential, commercial, and
                hospitality spaces.
              </h5>
              <h5
                style={{
                  margin: 'auto',

                  fontFamily: 'lato',
                  marginTop: '20px',
                  maxWidth: '1190px',
                  opacity: '70%',
                  fontWeight: '700',
                  textAlign: 'center',
                  marginBottom: '30px',
                  paddingLeft: !isMobile ? '0' : '16px',
                  paddingRight: !isMobile ? '0' : '16px',
                }}
              >
                We are driven by our vision to create value in every sector by
                undertaking end-to-end projects, built on the expertise of
                countless successful engagements over the years. While we are
                headquartered in Bangalore, we serve clients across India and
                globally
              </h5>
              <h4
                style={{
                  fontFamily: 'lato',
                  marginTop: '30px',
                  textAlign: 'center',
                  margin: 'auto',
                  paddingLeft: !isMobile ? '0' : '16px',
                  paddingRight: !isMobile ? '0' : '16px',
                }}
              >
                We are CEDIA Members, CEDIA Certified Designers & LUTRON
                Certified partners
              </h4>
              <div
                className='flex'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: !isMobile ? '90vw' : '100vw',
                  maxWidth: '1600px',
                  margin: 'auto',
                  marginTop: '30px',
                }}
              >
                <img
                  src={cediaMember}
                  alt=''
                  style={{
                    display: 'block',
                    margin: 'auto',
                    cursor: 'pointer',
                    width: !isMobile ? 'auto' : '25vw',
                  }}
                  onClick={() => window.open('https://cedia.net/#1')}
                />
                <img
                  src={cediaDesigner}
                  alt=''
                  style={{
                    display: 'block',
                    margin: 'auto',
                    cursor: 'pointer',
                    width: !isMobile ? 'auto' : '25vw',
                  }}
                  onClick={() => window.open('https://cedia.net/tcd/tcd-home')}
                />
                <img
                  src={lutronCertified}
                  alt=''
                  style={{
                    display: 'block',
                    margin: 'auto',
                    cursor: 'pointer',
                    width: !isMobile ? 'auto' : '25vw',
                  }}
                  onClick={() =>
                    window.open(
                      'https://www.lutron.com/asia/pages/default.aspx'
                    )
                  }
                />
              </div>

              <p
                href='https://cedia.net/'
                style={{
                  textAlign: 'center',
                  fontFamily: 'lato',
                  color: 'black',
                  fontSize: '1.2em',
                  display: 'block',
                  marginTop: '10px',
                  marginBottom: '0px',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={() => setCedia(true)}
              >
                Learn More About Cedia
              </p>
            </div>
          </Fade>

          <Fade in={cedia} unmountOnExit>
            <div style={{ paddingTop: isBigScreen ? '220px' : '0px' }}>
              <h4
                style={{
                  fontFamily: 'lato',
                  marginTop: '40px',
                  textAlign: 'center',
                }}
                id='cedia'
              >
                CEDIA - Custom Electronic Design & Installation Association
              </h4>
              <h5
                style={{
                  margin: 'auto',

                  fontFamily: 'lato',
                  marginTop: '40px',
                  maxWidth: '1190px',
                  opacity: '70%',
                  fontWeight: '700',
                  textAlign: 'center',
                  paddingLeft: !isMobile ? '0' : '16px',
                  paddingRight: !isMobile ? '0' : '16px',
                }}
              >
                CEDIA is the international trade organisation for the home
                technology industry with nearly 4,000 member companies
                worldwide. Built upon a strong volunteer foundation, CEDIA
                provides access to industry-leading education, certification,
                research and raises consumer awareness.
              </h5>
              <h5
                style={{
                  margin: 'auto',

                  fontFamily: 'lato',
                  marginTop: '20px',
                  maxWidth: '1190px',
                  opacity: '70%',
                  fontWeight: '700',
                  textAlign: 'center',
                  paddingLeft: !isMobile ? '0' : '16px',
                  paddingRight: !isMobile ? '0' : '16px',
                }}
              >
                Established in the USA in 1989, CEDIA is the leading global
                authority in the industry. The Association serves as the go-to
                home technology source for consumers and professionals alike.
              </h5>
              <h5
                style={{
                  margin: 'auto',
                  fontFamily: 'lato',
                  marginTop: '40px',
                  maxWidth: '1190px',
                  opacity: '70%',
                  fontWeight: '700',
                  textAlign: 'center',
                  paddingLeft: !isMobile ? '0' : '16px',
                  paddingRight: !isMobile ? '0' : '16px',
                }}
              >
                We are active members of the CEDIA community and we strive,
                alongside our fellow members to raise standards across the
                industry. We strongly support and engage with the detailed
                training schedule which CEDIA offers it’s members and we utilise
                this to ensure our engineers are educated and skilled.
              </h5>
              <a
                href='https://cedia.net/'
                style={{
                  textAlign: 'center',
                  fontFamily: 'lato',
                  color: 'black',
                  fontSize: '1.3em',
                  display: 'block',
                  marginTop: '30px',
                  marginBottom: '10px',
                }}
              >
                Go to Cedia's website &gt;
              </a>
            </div>
          </Fade>
        </div>
      </Fade>

      <Fade
        in={!isMobile ? aboutPath === 'whatWeAccomplished' : true}
        unmountOnExit
      >
        <div>
          <h1
            style={{
              fontFamily: 'lato',
              marginTop: !pcScreen ? (!isMobile ? '-65px' : '40px') : '27vh',
              fontWeight: '700',
              textAlign: 'center',
              fontSize: !isBigScreen ? '2em' : '3.4em',
              marginBottom: !isBigScreen ? '0' : '7vh',
            }}
          >
            What We Accomplished
          </h1>
          <div
            id='vr1'
            style={{
              // height: '50px',
              width: '2px',
              background: 'black',
              opacity: '40%',
              display: 'block',
              margin: 'auto',
              marginTop: '20px',
              transitionProperty: 'all',
              transitionDelay: '0s',
              transitionDuration: '0.7s',
            }}
          ></div>
          <h3
            id='important-mile'
            style={{
              textAlign: 'center',

              fontFamily: 'lato',
              margin: 'auto',
              marginTop: !isMobile ? '10px' : '10px',
              opacity: '70%',
              position: 'relative',
              width: '100vw',
              top: '10px',
              left: '-50px',
              fontSize: !isBigScreen ? '1.5em' : '2.3em',
              visibility: !isMobile ? 'hidden' : 'visible',
              fontWeight: '700',
              transitionProperty: 'all',
              transitionDuration: '0.7s',
            }}
          >
            Important Milestones
          </h3>
          <div
            id='vr2'
            style={{
              width: '2px',
              background: 'black',
              opacity: '40%',
              display: 'block',
              margin: 'auto',
              marginTop: '30px',
              marginBottom: !isBigScreen ? 'auto' : '7vh',

              transitionProperty: 'all',
              transitionDelay: '0s',
              transitionDuration: '0.7s',
            }}
          ></div>
          <div
            classNameName='flex'
            id='mile-container'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '90vw',
              maxWidth: '1600px',
              margin: 'auto',
              marginTop: !isMobile ? '40px' : '30px',
              marginBottom: pcScreen ? '42vh' : '0',
              position: 'relative',
              top: '0',
              visibility: 'hidden',
              transitionProperty: 'all',
              transitionDuration: '0.8s',
            }}
          >
            <div
              style={{
                display: 'block',
                margin: 'auto',
                width: '30%',
              }}
            >
              <h1
                style={{
                  textAlign: 'center',
                  fontFamily: 'lato',
                  opacity: '80%',
                  fontWeight: 'bolder',
                  fontSize: !isMobile
                    ? !isBigScreen
                      ? '2.5em'
                      : '3em'
                    : '1.4em',
                }}
              >
                3000+
              </h1>
              <h1
                style={{
                  textAlign: 'center',
                  fontFamily: 'lato',
                  opacity: '80%',
                  marginTop: '10px',
                  fontSize: !isMobile
                    ? !isBigScreen
                      ? '2em'
                      : '2.5em'
                    : '1.4em',
                }}
              >
                Products
              </h1>
            </div>
            <div style={{ display: 'block', margin: 'auto', width: '30%' }}>
              <h1
                style={{
                  textAlign: 'center',
                  fontFamily: 'lato',
                  opacity: '80%',
                  fontWeight: 'bolder',
                  fontSize: !isMobile
                    ? !isBigScreen
                      ? '2.5em'
                      : '2.5em'
                    : '1.4em',
                }}
              >
                100+
              </h1>
              <h1
                style={{
                  textAlign: 'center',
                  fontFamily: 'lato',
                  opacity: '80%',
                  marginTop: '10px',
                  fontSize: !isMobile
                    ? !isBigScreen
                      ? '2em'
                      : '2.5em'
                    : '1.4em',
                }}
              >
                Projects
              </h1>
            </div>
            <div style={{ display: 'block', margin: 'auto', width: '30%' }}>
              <h1
                style={{
                  textAlign: 'center',
                  fontFamily: 'lato',
                  opacity: '80%',
                  fontWeight: 'bolder',
                  fontSize: !isMobile
                    ? !isBigScreen
                      ? '2.5em'
                      : '2.5em'
                    : '1.4em',
                }}
              >
                12
              </h1>
              <h1
                style={{
                  textAlign: 'center',
                  fontFamily: 'lato',
                  opacity: '80%',
                  marginTop: '10px',
                  fontSize: !isMobile
                    ? !isBigScreen
                      ? '2em'
                      : '2.5em'
                    : '1.4em',
                }}
              >
                Years In Industry
              </h1>
            </div>
          </div>
        </div>
      </Fade>
      <Fade
        in={!isMobile ? aboutPath === 'experienceCenter' : true}
        unmountOnExit
      >
        <div
          style={{
            paddingLeft: !isMobile ? '0' : '0px',
            paddingRight: !isMobile ? '0' : '0px',
          }}
        >
          <h1
            style={{
              margin: 'auto',

              fontFamily: 'lato',
              marginTop: !isMobile ? '-70px' : '60px',
              fontWeight: '700',
              textAlign: 'center',
              width: '100vw',
            }}
          >
            Experience Center
          </h1>
          <h2
            style={{
              margin: 'auto',

              fontFamily: 'lato',
              marginTop: !isMobile ? '26px' : '40px',
              textAlign: 'center',
              width: '90vw',
            }}
          >
            Welcome to our charming Experience Center
          </h2>
          <h5
            style={{
              margin: 'auto',

              fontFamily: 'lato',
              marginTop: '26px',
              maxWidth: '1000px',
              opacity: '70%',
              fontWeight: '700',
              textAlign: 'center',
              width: '100vw',
              paddingLeft: !isMobile ? '0' : '16px',
              paddingRight: !isMobile ? '0' : '16px',
            }}
          >
            We are proud to present our work. These projects are built using
            latest technologies and the best talent. The benefits of our
            experience guarantee quality for many years to come
          </h5>
          <div
            id='carouselExampleControls'
            className='carousel slide'
            data-bs-ride='carousel'
            style={{
              display: 'block',
              margin: 'auto',
              width: !isMobile ? '50vw' : '90vw',
              marginTop: '50px',
              marginBottom: '20px',
            }}
          >
            <div
              className='carousel-inner'
              style={{
                width: !isMobile ? '100%' : '100%`',
                height: !isMobile ? '56vh' : '40vh',
              }}
            >
              <div className='carousel-item active'>
                <img
                  src={image1}
                  className='d-block w-100 h-100'
                  alt='...'
                  style={{
                    display: 'block',
                    margin: 'auto',
                    objectFit: 'cover',
                    width: 'auto',
                    height: 'auto',
                  }}
                />
              </div>
              <div className='carousel-item'>
                <img
                  src={image2}
                  className='d-block w-100 h-100\'
                  alt='...'
                  style={{
                    display: 'block',
                    margin: 'auto',
                    objectFit: 'cover',
                    width: 'auto',
                    height: 'auto',
                  }}
                />
              </div>
              <div className='carousel-item'>
                <img
                  src={image3}
                  className='d-block w-100'
                  alt='...'
                  style={{
                    display: 'block',
                    margin: 'auto',
                    objectFit: 'cover',
                    width: 'auto',
                    height: 'auto',
                  }}
                />
              </div>
              <div className='carousel-item'>
                <img
                  src={image4}
                  className='d-block w-100'
                  alt='...'
                  style={{
                    display: 'block',
                    margin: 'auto',
                    objectFit: 'cover',
                    width: 'auto',
                    height: 'auto',
                  }}
                />
              </div>
              <div className='carousel-item'>
                <img
                  src={image5}
                  className='d-block w-100'
                  alt='...'
                  style={{
                    display: 'block',
                    margin: 'auto',
                    objectFit: 'cover',
                    width: 'auto',
                    height: 'auto',
                  }}
                />
              </div>
            </div>
            <button
              className='carousel-control-prev'
              type='button'
              data-bs-target='#carouselExampleControls'
              data-bs-slide='prev'
            >
              <span
                className='carousel-control-prev-icon'
                aria-hidden='true'
              ></span>
              <span className='visually-hidden'>Previous</span>
            </button>
            <button
              className='carousel-control-next'
              type='button'
              data-bs-target='#carouselExampleControls'
              data-bs-slide='next'
            >
              <span
                className='carousel-control-next-icon'
                aria-hidden='true'
              ></span>
              <span className='visually-hidden'>Next</span>
            </button>
          </div>
          <div
            style={{
              display: !isMobile ? 'flex' : 'none',
              width: '99vw',
              height: !isSmallScreen
                ? !isBigScreen
                  ? '250px'
                  : '350px'
                : '260px',
              background: 'rgb(30, 30, 30)',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              margin: 'auto',
            }}
          >
            <div
              className='1'
              style={{
                width: '70%',
                display: 'block',
                marginTop: '2%',
              }}
            >
              <div id='main-heading-footer'>
                <hr className='hr-footer' />
                <h4 id='heading-tag-footer'>Contact Info</h4>
                <hr className='hr-footer' />
              </div>
              <div className='footer-container'>
                <div
                  className='part1'
                  style={{
                    display: 'block',
                    margin: 'auto',
                    width: '100%',
                    height: '100%',
                    paddingLeft: '4%',
                    marginTop: '20px',
                  }}
                >
                  <div className='heading' style={{ display: 'flex' }}>
                    <img
                      src={phoneIcon}
                      alt=''
                      style={{
                        width: !isBigScreen ? '13px' : '25px',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                      }}
                    />
                    <p
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        opacity: '90%',
                        fontWeight: '700',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        marginLeft: '10px',
                        fontSize: !isBigScreen ? '1.3em' : '2em',
                      }}
                    >
                      Phone
                    </p>
                  </div>
                  <p
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      opacity: '50%',
                      fontWeight: '700',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginLeft: '10px',
                      fontSize: !isBigScreen ? '1em' : '1.7em',
                      marginTop: '8px',
                    }}
                  >
                    +91 80-4111 4599
                  </p>
                  <p
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      opacity: '50%',
                      fontWeight: '700',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginLeft: '10px',
                      fontSize: !isBigScreen ? '1em' : '1.7em',
                      marginTop: '4px',
                    }}
                  >
                    +91 98450 40642
                  </p>
                  <p
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      opacity: '70%',
                      fontWeight: '700',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginLeft: '10px',
                      fontSize: !isBigScreen ? '0.8em' : '1.2em',
                      marginTop: '24px',
                    }}
                  >
                    Call us any time from 08:00 till 17:00 Mon-Fri
                  </p>
                </div>
                <div
                  className='part1'
                  style={{
                    display: 'block',
                    margin: 'auto',
                    width: '100%',
                    height: '100%',
                    paddingLeft: '4%',
                    marginTop: '20px',
                  }}
                >
                  <div className='heading' style={{ display: 'flex' }}>
                    <img
                      src={emailIcon}
                      alt=''
                      style={{
                        width: !isBigScreen ? '23px' : '35px',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                      }}
                    />
                    <p
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        opacity: '90%',
                        fontWeight: '700',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        marginLeft: '10px',
                        fontSize: !isBigScreen ? '1.3em' : '2em',
                      }}
                    >
                      Email
                    </p>
                  </div>
                  <p
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      opacity: '50%',
                      fontWeight: '700',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginLeft: '10px',
                      fontSize: !isBigScreen ? '1em' : '1.7em',
                      marginTop: '18px',
                    }}
                  >
                    info@thartechnologies.com
                  </p>
                  <p
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      opacity: '50%',
                      fontWeight: '700',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginLeft: '10px',
                      fontSize: !isBigScreen ? '1em' : '1.7em',
                      marginTop: '0px',
                      visibility: 'hidden',
                    }}
                  >
                    +91 80-4111 4599
                  </p>
                  <p
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      opacity: '70%',
                      fontWeight: '700',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginLeft: '10px',
                      fontSize: !isBigScreen ? '0.8em' : '1.2em',
                      marginTop: '18px',
                    }}
                  >
                    Email us any time, we usually reply within 24 hours.
                  </p>
                </div>
                <div
                  className='part1'
                  style={{
                    display: 'block',
                    margin: 'auto',
                    width: '100%',
                    height: '100%',
                    paddingLeft: '4%',
                    marginTop: '20px',
                  }}
                >
                  <div className='heading' style={{ display: 'flex' }}>
                    <img
                      src={locationIcon}
                      alt=''
                      style={{
                        width: !isBigScreen ? '20px' : '32px',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                      }}
                    />
                    <p
                      style={{
                        fontFamily: 'lato',
                        color: 'white',
                        opacity: '90%',
                        fontWeight: '700',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        marginLeft: '10px',
                        fontSize: !isBigScreen ? '1.3em' : '2em',
                      }}
                    >
                      Adress
                    </p>
                  </div>
                  <p
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      opacity: '50%',
                      fontWeight: '700',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginLeft: '10px',
                      fontSize: !isBigScreen ? '1em' : '1.7em',
                      marginTop: '8px',
                    }}
                  >
                    # 615, 1st Floor , 2nd main ,
                  </p>
                  <p
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      opacity: '50%',
                      fontWeight: '700',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginLeft: '10px',
                      fontSize: !isBigScreen ? '1em' : '1.7em',
                      marginTop: '4px',
                    }}
                  >
                    Indranagar 1st Stage , Bangalore 560 038
                  </p>
                  <p
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      opacity: '70%',
                      fontWeight: '700',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginLeft: '10px',
                      fontSize: !isBigScreen ? '0.8em' : '1.2em',
                      marginTop: '24px',
                    }}
                  >
                    Schedule your meeting before visiting
                  </p>
                </div>
              </div>
            </div>
            <div className='2' style={{ width: '30%', marginTop: '2%' }}>
              <div
                id='main-heading-footer'
                style={{
                  display: 'flex',
                  margin: 'auto',
                  alignItems: 'center',
                  width: !isBigScreen ? '280px' : '320px',
                }}
              >
                <hr
                  className='hr-footer'
                  style={{ width: '50px', margin: 'auto' }}
                />
                <h4 id='heading-tag-footer'>Social Media</h4>
                <hr
                  className='hr-footer'
                  style={{ width: '50px', margin: 'auto' }}
                />
              </div>
              <img
                src={facebookIcon}
                alt='..'
                style={{
                  display: 'block',
                  margin: 'auto',
                  marginTop: '40px',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  window.open('https://www.facebook.com/Thartechnologies/')
                }
              />
              <img
                src={instaIcon}
                alt='..'
                style={{
                  display: 'block',
                  margin: 'auto',
                  marginTop: '20px',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  window.open(
                    'https://instagram.com/thartechnologies?utm_medium=copy_link'
                  )
                }
              />
            </div>
          </div>
          <div
            style={{
              width: '100vw',
              height: '20px',
              display: 'flex',
              justifyContent: 'center',
              background: 'rgb(10, 10, 10)',
            }}
          ></div>
          <div
            style={{
              width: '100vw',
              height: !isBigScreen ? '70px' : '120px',
              display: 'flex',
              justifyContent: 'center',
              background: 'rgb(35, 35, 35)',
            }}
          >
            <p
              style={{
                margin: 'auto',
                marginLeft: '3%',
                fontFamily: 'lato',
                fontWeight: '700',
                fontSize: !isBigScreen
                  ? !isMobile
                    ? '1.1em'
                    : '0.7em'
                  : '1.8em',
                color: 'white',
              }}
            >
              © 2022 Thar Technologies
            </p>
            <p
              style={{
                margin: 'auto',
                marginRight: '3%',
                fontFamily: 'lato',
                fontWeight: '700',
                fontSize: !isBigScreen
                  ? !isMobile
                    ? '0.9em'
                    : '0.5em'
                  : '1.6em',
                color: 'white',
                opacity: '70%',
              }}
            >
              Designed & Developed by Workplay Studio Pvt. Ltd.
            </p>
          </div>
          <hr style={{ color: 'white', margin: '0' }} />
        </div>
      </Fade>
    </div>
  );
};

export default About;
