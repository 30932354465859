import React from 'react';
import phoneIcon from '../assets/phone-call-icon.svg';
import locationIcon from '../assets/location-icon.svg';
import emailIcon from '../assets/email-icon.svg';
import facebookIcon from '../assets/facebook.svg';
import instaIcon from '../assets/insta.svg';
import aboutBackground from '../assets/aboutBackground.svg';
import './css/contact-style.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import image1 from '../assets/1.JPG';
import image2 from '../assets/2.jpg';
import image3 from '../assets/3.jpg';
import image4 from '../assets/4.jpg';
import image5 from '../assets/5.jpg';
import image6 from '../assets/6.jpg';
import image7 from '../assets/7.jpg';
import Button from '@mui/material/Button';
import axios from '../helpers/axios';
import { makeStyles } from '@mui/styles';

const Contact = () => {
  React.useEffect(() => {
    if (document.getElementById('nav')) {
      document.getElementById('nav').style.backgroundColor = 'black';
    }
  }, []);

  const [isSmallScreen, setIsSmallScreen] = React.useState(
    window.matchMedia('(max-width: 1200px)').matches
  );

  const [isBigScreen, setIsBigScreen] = React.useState(
    window.matchMedia('(min-width: 2000px)').matches
  );

  const [isMobile, setIsMobile] = React.useState(
    window.matchMedia('(max-width: 768px)').matches
  );

  const [subject, setSubject] = React.useState('');

  const [name, setName] = React.useState('');

  const [emailId, setEmailId] = React.useState('');

  const [message, setMessage] = React.useState('');

  React.useState(() => {
    console.log(document.getElementById('home-auto-option'));
    if (document.getElementById('home-auto-option')) {
      console.log('hoi');
      document.getElementById(
        'home-auto-option'
      ).parentElement.style.background = 'rgba(0, 0, 0, 0.7)';

      document.getElementById('home-auto-option').parentElement.style.width =
        !isBigScreen ? '430px' : '530px';
    }
  });

  const useStyles = makeStyles((theme) => ({
    //other part of the code//
    paper: {
      background: 'red',
      color: 'white',
    },
    input: {
      color: 'red',
      backgroundColor: 'rgba(255, 0, 0, 0.4)',
      '&:focus': {
        borderRadius: 4,
        borderColor: 'rgba(255,255,255,0.2)',
        boxShadow: '0 0 0 0.2rem rgba(0,190,255,0.6)',
        background: 'rgba(0,0,0,0)',
      },
    },
  }));

  const classes = useStyles();

  const sendRequest = () => {
    if (name !== '' && emailId !== '' && message !== '' && subject !== '') {
      axios
        .post('/contactRequest', { name, emailId, subject, text: message })
        .then((value) => {
          alert('Sent Message');
        })
        .catch((err) => {
          alert('An Error Occured');
        });
    } else {
      alert('Fill All Credentials');
    }
  };

  if (!isMobile) {
    return (
      <div>
        <div
          className='part1'
          style={{
            width: '100vw',
            height: '105vh',
            marginTop: '-160px',
            marginBottom: '20px',
          }}
        >
          <img
            src={aboutBackground}
            alt='..'
            style={{
              width: '100vw',
              height: '100vh',
              position: 'absolute',
              top: '100px',
              zIndex: '-1',
              objectFit: 'cover',
              opacity: '85%',
            }}
          />
          <h1
            style={{
              fontFamily: 'lato',
              color: 'white',
              textAlign: 'center',
              marginTop: !isBigScreen ? '280px' : '390px',
              fontWeight: '700',
              fontSize: !isBigScreen ? '2em' : '3em',
            }}
          >
            Creative Project ?
          </h1>
          <h3
            style={{
              fontFamily: 'lato',
              color: 'white',
              textAlign: 'center',
              marginTop: '20px',
              fontWeight: '700',
              fontSize: !isBigScreen ? '1.7em' : '2.3em',
            }}
          >
            Write To Us
          </h3>
          <input
            type='text'
            placeholder='Name'
            style={{
              width: !isBigScreen ? '430px' : '530px',
              height: !isBigScreen ? '50px' : '70px',
              display: 'block',
              margin: 'auto',
              paddingLeft: '20px',
              marginTop: '50px',
              outline: 'none',
              color: 'white',
              background: 'rgba(0, 0, 0, 0.7)',
              border: 'none',
            }}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type='text'
            placeholder='Email Id'
            style={{
              width: !isBigScreen ? '430px' : '530px',
              height: !isBigScreen ? '50px' : '70px',
              display: 'block',
              margin: 'auto',
              paddingLeft: '20px',
              marginTop: '20px',
              outline: 'none',
              color: 'white',
              background: 'rgba(0, 0, 0, 0.7)',
              border: 'none',
            }}
            value={emailId}
            onChange={(e) => setEmailId(e.target.value)}
          />
          <FormControl
            style={{
              display: 'flex',
              margin: 'auto',
              width: !isBigScreen ? '430px' : '530px',
              marginTop: '20px',
            }}
            fullWidth
          >
            <InputLabel
              id='subject-label'
              style={{
                fontFamily: 'lato',
                color: 'white',
                margin: 'auto',
              }}
            >
              Subject
            </InputLabel>

            <Select
              id='Subject'
              // labelId='subject-label'
              value={subject}
              label='Subject'
              onChange={(e) => setSubject(e.target.value)}
              style={{
                fontFamily: 'lato',
                background: 'rgba(0, 0, 0, 0.7)',
                // margin: 'auto',
                color: 'white',
                display: 'block',

                // marginTop: '20px',
                outline: 'none',
                border: 'none',
              }}
              MenuProps={{
                classes: {
                  paper: classes.paper,
                },
              }}
              inputProps={{
                classes: {
                  root: classes.input,
                },
              }}
            >
              <MenuItem
                value={'home-auto'}
                style={{
                  color: 'white',
                  background: 'rgba(0, 0, 0, 0.7)',
                  marginTop: '-10px',
                }}
                id='home-auto-option'
              >
                Home Automation
              </MenuItem>
              <MenuItem
                value={'audio-visual'}
                style={{
                  color: 'white',
                  background: 'rgba(0, 0, 0, 0.7)',
                }}
              >
                Audio Visual
              </MenuItem>
              <MenuItem
                value={'commercial'}
                style={{
                  color: 'white',
                  background: 'rgba(0, 0, 0, 0.7)',
                  position: 'relative',
                  top: '0px',
                  marginBottom: '-7px',
                }}
              >
                Commercial
              </MenuItem>
            </Select>
          </FormControl>
          <textarea
            name=''
            id=''
            cols='30'
            rows='10'
            style={{
              width: !isBigScreen ? '430px' : '530px',
              height: !isBigScreen ? '120px' : '170px',
              display: 'block',
              margin: 'auto',
              paddingLeft: '20px',
              marginTop: '20px',
              outline: 'none',
              color: 'white',
              background: 'rgba(0, 0, 0, 0.7)',
              border: 'none',
              paddingTop: '20px',
            }}
            placeholder='Enter Your Message'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>

          <Button
            variant='contained'
            style={{
              color: 'white',
              background: 'rgba(0, 0, 0, 0.7)',
              display: 'block',
              margin: 'auto',
              width: '280px',
              height: '50px',
              fontFamily: 'lato',
              fontWeight: '700',
              border: '1px solid white',
              transitionProperty: 'all',
              transitionDuration: '0.7s',
              marginTop: '40px',
            }}
            onMouseEnter={(e) => {
              e.target.style.background = 'rgba(0, 0, 0, 0.9)';
            }}
            onMouseLeave={(e) => {
              e.target.style.background = 'rgba(0, 0, 0, 0.7)';
            }}
            onClick={() => sendRequest()}
          >
            Submit
          </Button>
        </div>
        <div className='2' style={{ width: '100vw', paddingBottom: '40px' }}>
          <h2
            style={{
              fontFamily: 'lato',
              color: 'black',
              textAlign: 'center',
              marginTop: '-20px',
              fontWeight: '700',
              opacity: '70%',
              fontSize: !isBigScreen ? '1.5em' : '2.5em',
            }}
          >
            Visit Us
          </h2>
          <h5
            style={{
              fontFamily: 'lato',
              color: 'black',
              textAlign: 'center',
              marginTop: '30px',
              fontWeight: '700',
              opacity: '70%',
              fontSize: !isBigScreen ? '0.83em' : '1.83em',
            }}
          >
            At Our Charming
          </h5>
          <h1
            style={{
              fontFamily: 'lato',
              color: 'black',
              textAlign: 'center',
              marginTop: '30px',
              fontWeight: '700',
              opacity: '90%',
              fontSize: !isBigScreen ? '2em' : '3em',
            }}
          >
            Experience Center
          </h1>
          <h5
            style={{
              fontFamily: 'lato',
              color: 'black',
              textAlign: 'center',
              marginTop: '50px',
              fontWeight: '700',
              opacity: '70%',
              width: '80vw',
              maxWidth: '1000px',
              margin: 'auto',
              fontSize: !isBigScreen ? '0.83em' : '1.83em',
            }}
          >
            We are proud to present our work. These projects are built using
            latest technologies and the best talent. The benefits of our
            experience guarantee quality for many years to come
          </h5>
          <div
            id='carouselExampleControls'
            className='carousel slide'
            data-bs-ride='carousel'
            style={{
              display: 'block',
              margin: 'auto',
              width: !isMobile ? '50vw' : '90vw',
              marginTop: '50px',
              marginBottom: '20px',
            }}
          >
            <div
              className='carousel-inner'
              style={{
                width: !isMobile ? '100%' : '100%`',
                height: !isMobile ? '70vh' : '40vh',
              }}
            >
              <div className='carousel-item active'>
                <img src={image1} className='d-block w-100' alt='...' />
              </div>
              <div className='carousel-item'>
                <img src={image2} className='d-block w-100' alt='...' />
              </div>
              <div className='carousel-item'>
                <img src={image3} className='d-block w-100' alt='...' />
              </div>
              <div className='carousel-item'>
                <img src={image4} className='d-block w-100' alt='...' />
              </div>
              <div className='carousel-item'>
                <img src={image5} className='d-block w-100' alt='...' />
              </div>
            </div>
            <button
              className='carousel-control-prev'
              type='button'
              data-bs-target='#carouselExampleControls'
              data-bs-slide='prev'
            >
              <span
                className='carousel-control-prev-icon'
                aria-hidden='true'
              ></span>
              <span className='visually-hidden'>Previous</span>
            </button>
            <button
              className='carousel-control-next'
              type='button'
              data-bs-target='#carouselExampleControls'
              data-bs-slide='next'
            >
              <span
                className='carousel-control-next-icon'
                aria-hidden='true'
              ></span>
              <span className='visually-hidden'>Next</span>
            </button>
          </div>
          <h1
            style={{
              fontFamily: 'lato',
              color: 'black',
              textAlign: 'center',
              marginTop: !isMobile ? '150px' : '30px',
              fontWeight: '700',
              opacity: '90%',
            }}
          >
            Location
          </h1>
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7775.665090611164!2d77.639665!3d12.98256!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7d017bc7833555b6!2sThar%20Technologies!5e0!3m2!1sen!2sin!4v1642011456688!5m2!1sen!2sin'
            width='600'
            height='450'
            allowfullscreen=''
            loading='lazy'
            style={{
              display: 'block',
              margin: 'auto',
              width: '80vw',
              maxWidth: '1000px',
              height: '85vh',
              maxHeight: '800px',
              marginTop: '30px',
              border: '0',
            }}
          ></iframe>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100vw',
            margin: 'auto',
            height: !isSmallScreen
              ? !isBigScreen
                ? '250px'
                : '350px'
              : '260px',
            background: 'rgb(30, 30, 30)',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
          }}
        >
          <div
            className='1'
            style={{
              width: '70%',
              display: 'block',
              marginTop: '2%',
            }}
          >
            <div id='main-heading-footer'>
              <hr className='hr-footer' />
              <h4 id='heading-tag-footer'>Contact Info</h4>
              <hr className='hr-footer' />
            </div>
            <div className='footer-container'>
              <div
                className='part1'
                style={{
                  display: 'block',
                  margin: 'auto',
                  width: '100%',
                  height: '100%',
                  paddingLeft: '4%',
                  marginTop: '20px',
                }}
              >
                <div className='heading' style={{ display: 'flex' }}>
                  <img
                    src={phoneIcon}
                    alt=''
                    style={{
                      width: !isBigScreen ? '13px' : '25px',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}
                  />
                  <p
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      opacity: '90%',
                      fontWeight: '700',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginLeft: '10px',
                      fontSize: !isBigScreen ? '1.3em' : '2em',
                    }}
                  >
                    Phone
                  </p>
                </div>
                <p
                  style={{
                    fontFamily: 'lato',
                    color: 'white',
                    opacity: '50%',
                    fontWeight: '700',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    marginLeft: '10px',
                    fontSize: !isBigScreen ? '1em' : '1.7em',
                    marginTop: '8px',
                  }}
                >
                  +91 80-4111 4599
                </p>
                <p
                  style={{
                    fontFamily: 'lato',
                    color: 'white',
                    opacity: '50%',
                    fontWeight: '700',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    marginLeft: '10px',
                    fontSize: !isBigScreen ? '1em' : '1.7em',
                    marginTop: '4px',
                  }}
                >
                  +91 98450 40642
                </p>
                <p
                  style={{
                    fontFamily: 'lato',
                    color: 'white',
                    opacity: '70%',
                    fontWeight: '700',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    marginLeft: '10px',
                    fontSize: !isBigScreen ? '0.8em' : '1.2em',
                    marginTop: '24px',
                  }}
                >
                  Call us any time from 08:00 till 17:00 Mon-Fri
                </p>
              </div>
              <div
                className='part1'
                style={{
                  display: 'block',
                  margin: 'auto',
                  width: '100%',
                  height: '100%',
                  paddingLeft: '4%',
                  marginTop: '20px',
                }}
              >
                <div className='heading' style={{ display: 'flex' }}>
                  <img
                    src={emailIcon}
                    alt=''
                    style={{
                      width: !isBigScreen ? '23px' : '35px',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}
                  />
                  <p
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      opacity: '90%',
                      fontWeight: '700',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginLeft: '10px',
                      fontSize: !isBigScreen ? '1.3em' : '2em',
                    }}
                  >
                    Email
                  </p>
                </div>
                <p
                  style={{
                    fontFamily: 'lato',
                    color: 'white',
                    opacity: '50%',
                    fontWeight: '700',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    marginLeft: '10px',
                    fontSize: !isBigScreen ? '1em' : '1.7em',
                    marginTop: '18px',
                  }}
                >
                  info@thartechnologies.com
                </p>
                <p
                  style={{
                    fontFamily: 'lato',
                    color: 'white',
                    opacity: '50%',
                    fontWeight: '700',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    marginLeft: '10px',
                    fontSize: !isBigScreen ? '1em' : '1.7em',
                    marginTop: '0px',
                    visibility: 'hidden',
                  }}
                >
                  +91 80-4111 4599
                </p>
                <p
                  style={{
                    fontFamily: 'lato',
                    color: 'white',
                    opacity: '70%',
                    fontWeight: '700',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    marginLeft: '10px',
                    fontSize: !isBigScreen ? '0.8em' : '1.2em',
                    marginTop: '18px',
                  }}
                >
                  Email us any time, we usually reply within 24 hours.
                </p>
              </div>
              <div
                className='part1'
                style={{
                  display: 'block',
                  margin: 'auto',
                  width: '100%',
                  height: '100%',
                  paddingLeft: '4%',
                  marginTop: '20px',
                }}
              >
                <div className='heading' style={{ display: 'flex' }}>
                  <img
                    src={locationIcon}
                    alt=''
                    style={{
                      width: !isBigScreen ? '20px' : '32px',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}
                  />
                  <p
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      opacity: '90%',
                      fontWeight: '700',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginLeft: '10px',
                      fontSize: !isBigScreen ? '1.3em' : '2em',
                    }}
                  >
                    Address
                  </p>
                </div>
                <p
                  style={{
                    fontFamily: 'lato',
                    color: 'white',
                    opacity: '50%',
                    fontWeight: '700',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    marginLeft: '10px',
                    fontSize: !isBigScreen ? '1em' : '1.7em',
                    marginTop: '8px',
                  }}
                >
                  # 615, 1st Floor , 2nd main ,
                </p>
                <p
                  style={{
                    fontFamily: 'lato',
                    color: 'white',
                    opacity: '50%',
                    fontWeight: '700',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    marginLeft: '10px',
                    fontSize: !isBigScreen ? '1em' : '1.7em',
                    marginTop: '4px',
                  }}
                >
                  Indranagar 1st Stage , Bangalore 560 038
                </p>
                <p
                  style={{
                    fontFamily: 'lato',
                    color: 'white',
                    opacity: '70%',
                    fontWeight: '700',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    marginLeft: '10px',
                    fontSize: !isBigScreen ? '0.8em' : '1.2em',
                    marginTop: '24px',
                  }}
                >
                  Schedule your meeting before visiting
                </p>
              </div>
            </div>
          </div>
          <div className='2' style={{ width: '30%', marginTop: '2%' }}>
            <div
              id='main-heading-footer'
              style={{
                display: 'flex',
                margin: 'auto',
                alignItems: 'center',
                width: !isBigScreen ? '280px' : '320px',
              }}
            >
              <hr
                className='hr-footer'
                style={{ width: '50px', margin: 'auto' }}
              />
              <h4 id='heading-tag-footer'>Social Media</h4>
              <hr
                className='hr-footer'
                style={{ width: '50px', margin: 'auto' }}
              />
            </div>
            <img
              src={facebookIcon}
              alt='..'
              style={{
                display: 'block',
                margin: 'auto',
                marginTop: '40px',
                cursor: 'pointer',
              }}
              onClick={() =>
                window.open('https://www.facebook.com/Thartechnologies/')
              }
            />
            <img
              src={instaIcon}
              alt='..'
              style={{
                display: 'block',
                margin: 'auto',
                marginTop: '20px',
                cursor: 'pointer',
              }}
              onClick={() =>
                window.open(
                  'https://instagram.com/thartechnologies?utm_medium=copy_link'
                )
              }
            />
          </div>
        </div>
        <div
          style={{
            width: '100vw',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            background: 'rgb(10, 10, 10)',
          }}
        ></div>
        <div
          style={{
            width: '100vw',
            height: !isBigScreen ? '70px' : '120px',
            display: 'flex',
            justifyContent: 'center',
            background: 'rgb(35, 35, 35)',
          }}
        >
          <p
            style={{
              margin: 'auto',
              marginLeft: '3%',
              fontFamily: 'lato',
              fontWeight: '700',
              fontSize: !isBigScreen ? '1.1em' : '1.8em',
              color: 'white',
            }}
          >
            © 2022 Thar Technologies
          </p>
          <p
            style={{
              margin: 'auto',
              marginRight: '3%',
              fontFamily: 'lato',
              fontWeight: '700',
              fontSize: !isBigScreen ? '0.9em' : '1.6em',
              color: 'white',
              opacity: '70%',
            }}
          >
            Designed & Developed by Workplay Studio Pvt. Ltd.
          </p>
        </div>
        <hr style={{ color: 'white', margin: '0' }} />
      </div>
    );
  } else {
    return (
      <div>
        <div
          className='part1'
          style={{
            width: '100vw',
            height: '130vh',
            marginTop: '-160px',
            marginBottom: '20px',
          }}
        >
          <img
            src={aboutBackground}
            alt='..'
            style={{
              width: '100vw',
              height: '140vh',
              position: 'absolute',
              top: !isMobile ? '100px' : '0',
              zIndex: '-1',
              objectFit: 'cover',
              opacity: '85%',
            }}
          />
          <h1
            style={{
              textAlign: 'center',
              fontFamily: 'lato',
              color: 'white',
              marginTop: '38vh',
            }}
          >
            Creative Project ?
          </h1>
          <h2
            style={{
              textAlign: 'center',
              fontFamily: 'lato',
              color: 'white',
              marginTop: '20px',
            }}
          >
            Write To Us
          </h2>
          <input
            type='text'
            placeholder='Name'
            style={{
              width: '85vw',
              height: '50px',
              display: 'block',
              margin: 'auto',
              paddingLeft: '20px',
              marginTop: '50px',
              outline: 'none',
              color: 'white',
              background: 'rgba(0, 0, 0, 0.7)',
              border: 'none',
            }}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type='text'
            placeholder='Email Id'
            style={{
              width: '85vw',
              height: '50px',
              display: 'block',
              margin: 'auto',
              paddingLeft: '20px',
              marginTop: '20px',
              outline: 'none',
              color: 'white',
              background: 'rgba(0, 0, 0, 0.7)',
              border: 'none',
            }}
            value={emailId}
            onChange={(e) => setEmailId(e.target.value)}
          />
          <FormControl
            style={{
              display: 'flex',
              margin: 'auto',
              marginTop: '20px',
              width: '86vw',
            }}
          >
            <InputLabel
              id='subject-label'
              style={{
                fontFamily: 'lato',
                color: 'white',
              }}
            >
              Subject
            </InputLabel>

            <Select
              id='Subject'
              // labelId='subject-label'
              value={subject}
              label='Age'
              onChange={(e) => setSubject(e.target.value)}
              style={{
                fontFamily: 'lato',
                background: 'rgba(0, 0, 0, 0.7)',
                color: 'white',

                outline: 'none',
                border: 'none',
                width: '100%',
              }}
            >
              <MenuItem value={'home-auto'}>Home Automation</MenuItem>
              <MenuItem value={'audio-visual'}>Audio Visual</MenuItem>
              <MenuItem value={'commercial'}>Commercial</MenuItem>
            </Select>
          </FormControl>
          <textarea
            name=''
            id=''
            cols='30'
            rows='10'
            style={{
              width: '85vw',
              height: !isBigScreen ? '120px' : '170px',
              display: 'block',
              margin: 'auto',
              paddingLeft: '20px',
              marginTop: '20px',
              outline: 'none',
              color: 'white',
              background: 'rgba(0, 0, 0, 0.7)',
              border: 'none',
              paddingTop: '20px',
            }}
            placeholder='Enter Your Message'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <Button
            variant='contained'
            style={{
              color: 'white',
              background: 'rgba(0, 0, 0, 0.7)',
              display: 'block',
              margin: 'auto',
              marginTop: '6vh',
              width: '280px',
              height: '50px',
              fontFamily: 'lato',
              fontWeight: '700',
              border: '1px solid white',
              transitionProperty: 'all',
              transitionDuration: '0.7s',
            }}
            onMouseEnter={(e) => {
              e.target.style.background = 'rgba(0, 0, 0, 0.9)';
            }}
            onMouseLeave={(e) => {
              e.target.style.background = 'rgba(0, 0, 0, 0.7)';
            }}
            onClick={() => sendRequest()}
          >
            Submit
          </Button>
        </div>

        <div className='2' style={{ width: '100vw', paddingBottom: '40px' }}>
          <h2
            style={{
              fontFamily: 'lato',
              color: 'black',
              textAlign: 'center',
              marginTop: '-20px',
              fontWeight: '700',
              opacity: '70%',
              fontSize: !isBigScreen ? '1.5em' : '2.5em',
            }}
          >
            Visit Us
          </h2>
          <h5
            style={{
              fontFamily: 'lato',
              color: 'black',
              textAlign: 'center',
              marginTop: '30px',
              fontWeight: '700',
              opacity: '70%',
              fontSize: !isBigScreen ? '0.83em' : '1.83em',
            }}
          >
            At Our Charming
          </h5>
          <h1
            style={{
              fontFamily: 'lato',
              color: 'black',
              textAlign: 'center',
              marginTop: '30px',
              fontWeight: '700',
              opacity: '90%',
              fontSize: !isBigScreen ? '2em' : '3em',
            }}
          >
            Experience Center
          </h1>
          <h5
            style={{
              fontFamily: 'lato',
              color: 'black',
              textAlign: 'center',
              marginTop: '50px',
              fontWeight: '700',
              opacity: '70%',
              width: '80vw',
              maxWidth: '1000px',
              margin: 'auto',
              fontSize: !isBigScreen ? '0.83em' : '1.83em',
            }}
          >
            We are proud to present our work. These projects are built using
            latest technologies and the best talent. The benefits of our
            experience guarantee quality for many years to come
          </h5>
          <div
            id='carouselExampleControls'
            className='carousel slide'
            data-bs-ride='carousel'
            style={{
              display: 'block',
              margin: 'auto',
              width: !isMobile ? '80vw' : '90vw',
              marginTop: '50px',
              marginBottom: '20px',
            }}
          >
            <div
              className='carousel-inner'
              style={{
                width: !isMobile ? '80vw' : '90vw',
                height: !isMobile ? '70vh' : '40vh',
              }}
            >
              <div className='carousel-item active'>
                <img src={image1} className='d-block w-100' alt='...' />
              </div>
              <div className='carousel-item'>
                <img src={image2} className='d-block w-100' alt='...' />
              </div>
              <div className='carousel-item'>
                <img src={image3} className='d-block w-100' alt='...' />
              </div>
              <div className='carousel-item'>
                <img src={image4} className='d-block w-100' alt='...' />
              </div>
              <div className='carousel-item'>
                <img src={image5} className='d-block w-100' alt='...' />
              </div>
              <div className='carousel-item'>
                <img src={image6} className='d-block w-100' alt='...' />
              </div>
              <div className='carousel-item'>
                <img src={image7} className='d-block w-100' alt='...' />
              </div>
            </div>
            <button
              className='carousel-control-prev'
              type='button'
              data-bs-target='#carouselExampleControls'
              data-bs-slide='prev'
            >
              <span
                className='carousel-control-prev-icon'
                aria-hidden='true'
              ></span>
              <span className='visually-hidden'>Previous</span>
            </button>
            <button
              className='carousel-control-next'
              type='button'
              data-bs-target='#carouselExampleControls'
              data-bs-slide='next'
            >
              <span
                className='carousel-control-next-icon'
                aria-hidden='true'
              ></span>
              <span className='visually-hidden'>Next</span>
            </button>
          </div>
          <h1
            style={{
              fontFamily: 'lato',
              color: 'black',
              textAlign: 'center',
              marginTop: '30px',
              fontWeight: '700',
              opacity: '90%',
            }}
          >
            Location
          </h1>
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7775.665090611164!2d77.639665!3d12.98256!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7d017bc7833555b6!2sThar%20Technologies!5e0!3m2!1sen!2sin!4v1642011456688!5m2!1sen!2sin'
            width='600'
            height='450'
            // style='border:0;'
            allowfullscreen=''
            loading='lazy'
            style={{
              display: 'block',
              margin: 'auto',
              width: '95vw',
              maxWidth: '1000px',
              height: '45vh',
              maxHeight: '800px',
              marginTop: '30px',
              border: '0',
            }}
          ></iframe>
        </div>

        <div
          style={{
            display: 'block',
            margin: 'auto',
            width: '100vw',
            height: '700px',
            background: 'rgb(30, 30, 30)',
            borderTopRightRadius: '10px',
            borderTopLeftRadius: '10px',
            paddingTop: '40px',
            paddingLeft: '40px',
          }}
        >
          <h5
            style={{
              color: 'rgba(255, 255, 255, 0.8)',
              fontFamily: 'lato',
              fontWeight: '700',
            }}
          >
            Contact Info
          </h5>
          <div
            className='heading'
            style={{ display: 'flex', marginTop: '10%' }}
          >
            <img
              src={phoneIcon}
              alt=''
              style={{
                width: !isBigScreen ? '13px' : '25px',
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            />
            <p
              style={{
                fontFamily: 'lato',
                color: 'white',
                opacity: '90%',
                fontWeight: '700',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: '10px',
                fontSize: !isBigScreen ? '1.3em' : '2em',
              }}
            >
              Phone
            </p>
          </div>
          <div className='heading' style={{ display: 'flex', marginTop: '3%' }}>
            <p
              style={{
                fontFamily: 'lato',
                color: 'white',
                opacity: '90%',
                fontWeight: '700',
                marginTop: 'auto',
                marginBottom: 'auto',

                fontSize: !isBigScreen ? '1.3em' : '2em',
              }}
            >
              +91 80-4111 4599
            </p>
          </div>
          <div className='heading' style={{ display: 'flex', marginTop: '3%' }}>
            <p
              style={{
                fontFamily: 'lato',
                color: 'white',
                opacity: '90%',
                fontWeight: '700',
                marginTop: 'auto',
                marginBottom: 'auto',

                fontSize: !isBigScreen ? '1.3em' : '2em',
              }}
            >
              +91 98450 40642
            </p>
          </div>
          <p
            style={{
              fontFamily: 'lato',
              color: 'white',
              opacity: '70%',
              fontWeight: '700',
              marginTop: '20px',
              marginBottom: 'auto',
              fontSize: !isBigScreen ? '1em' : '1.2em',
              marginTop: '24px',
            }}
          >
            Call us any time from 08:00 till 17:00 Mon-Fri
          </p>
          <div
            className='heading'
            style={{ display: 'flex', marginTop: '10%' }}
          >
            <img
              src={emailIcon}
              alt=''
              style={{
                width: !isBigScreen ? '25px' : '25px',
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            />
            <p
              style={{
                fontFamily: 'lato',
                color: 'white',
                opacity: '90%',
                fontWeight: '700',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: '10px',
                fontSize: !isBigScreen ? '1.3em' : '2em',
              }}
            >
              Email
            </p>
          </div>
          <div className='heading' style={{ display: 'flex', marginTop: '3%' }}>
            <p
              style={{
                fontFamily: 'lato',
                color: 'white',
                opacity: '90%',
                fontWeight: '700',
                marginTop: 'auto',
                marginBottom: 'auto',

                fontSize: !isBigScreen ? '1.3em' : '2em',
              }}
            >
              info@thartechnologies.com
            </p>
          </div>

          <p
            style={{
              fontFamily: 'lato',
              color: 'white',
              opacity: '70%',
              fontWeight: '700',
              marginTop: '20px',
              marginBottom: 'auto',
              fontSize: !isBigScreen ? '1em' : '1.2em',
              marginTop: '24px',
            }}
          >
            Email us any time, we usually reply within 24 hours.
          </p>
          <div
            className='heading'
            style={{ display: 'flex', marginTop: '10%' }}
          >
            <img
              src={locationIcon}
              alt=''
              style={{
                width: !isBigScreen ? '13px' : '25px',
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            />
            <p
              style={{
                fontFamily: 'lato',
                color: 'white',
                opacity: '90%',
                fontWeight: '700',
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: '10px',
                fontSize: !isBigScreen ? '1.3em' : '2em',
              }}
            >
              Address
            </p>
          </div>
          <div className='heading' style={{ display: 'flex', marginTop: '3%' }}>
            <p
              style={{
                fontFamily: 'lato',
                color: 'white',
                opacity: '90%',
                fontWeight: '700',
                marginTop: 'auto',
                marginBottom: 'auto',

                fontSize: !isBigScreen ? '1.3em' : '2em',
              }}
            >
              # 615, 1st Floor , 2nd main ,
            </p>
          </div>
          <div className='heading' style={{ display: 'flex', marginTop: '3%' }}>
            <p
              style={{
                fontFamily: 'lato',
                color: 'white',
                opacity: '90%',
                fontWeight: '700',
                marginTop: 'auto',
                marginBottom: 'auto',

                fontSize: !isBigScreen ? '1.3em' : '2em',
              }}
            >
              Indranagar 1st Stage , Bangalore 560 038
            </p>
          </div>
          <p
            style={{
              fontFamily: 'lato',
              color: 'white',
              opacity: '70%',
              fontWeight: '700',
              marginTop: '20px',
              marginBottom: 'auto',
              fontSize: !isBigScreen ? '1em' : '1.2em',
              marginTop: '24px',
            }}
          >
            Schedule your meeting before visiting
          </p>
        </div>
        <div
          style={{
            width: '100vw',
            height: !isBigScreen ? '70px' : '120px',
            display: 'flex',
            justifyContent: 'center',
            background: 'rgb(35, 35, 35)',
          }}
        >
          <p
            style={{
              margin: 'auto',
              marginLeft: '3%',
              fontFamily: 'lato',
              fontWeight: '700',
              fontSize: !isBigScreen ? '0.8em' : '1.8em',
              color: 'white',
            }}
          >
            © 2022 Thar Technologies
          </p>
          <p
            style={{
              margin: 'auto',
              marginRight: '3%',
              fontFamily: 'lato',
              fontWeight: '700',
              fontSize: !isBigScreen ? '0.5em' : '1.6em',
              color: 'white',
              opacity: '70%',
            }}
          >
            Designed & Developed by Workplay Studio Pvt. Ltd.
          </p>
        </div>
      </div>
    );
  }
};

export default Contact;
