import React from 'react';
import { useLocation } from 'react-router-dom';

const Seperator = () => {
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const [isVeryBigScreen] = React.useState(
    window.matchMedia('(min-width: 2000px)').matches
  );

  const [isSmallScreen, setIsSmallScreen] = React.useState(
    window.matchMedia('(max-width: 1300px)').matches
  );

  const location = useLocation();

  const [solutionsOn, setSolutionsOn] = React.useState(false);

  const [path, setPath] = React.useState('');

  const [speakersOn, setSpeakersOn] = React.useState(false);

  const query = useQuery();

  React.useEffect(() => {
    console.log(location.pathname.startsWith('/aboutUs'));
    if (location.pathname.startsWith('/solutions')) {
      setSolutionsOn(true);
    } else if (location.pathname.startsWith('/aboutUs')) {
      setPath('about');
    }

    if (
      location.pathname.startsWith('/solutions/residential/audio/speakers/') ||
      location.pathname.startsWith('/solutions/residential/audio/amplifiers') ||
      location.pathname.startsWith('/solutions/residential/audio/projectors') ||
      location.pathname.startsWith('/solutions/residential/audio/screens') ||
      location.pathname.startsWith('/projects')
    ) {
      setSpeakersOn(true);
    } else {
      setSpeakersOn(false);
    }
  }, [location]);

  const [isMobile, setIsMobile] = React.useState(
    window.matchMedia('(max-width: 768px)').matches
  );

  React.useEffect(() => {
    console.log(location.pathname);
  }, [location, query]);

  function handleScrollMobile(e) {
    console.log(window.scrollY);

    console.log(window.location);

    if (window.scrollY > 110) {
      if (
        (location.pathname === '/' || location.pathname === '/home') &&
        (window.location.hash === '#/home' || window.location.hash === '#/')
      ) {
        window.location = '#/aboutUs';
      } else {
        window.removeEventListener('scroll', handleScrollMobile, {
          passive: false,
        });
      }
    }
  }

  React.useEffect(() => {
    console.log(speakersOn);
  }, []);

  return (
    <div
      style={{
        marginBottom: !speakersOn
          ? !isVeryBigScreen
            ? solutionsOn || path === 'about'
              ? !isMobile
                ? '260px'
                : '160px'
              : !isMobile
              ? '120px'
              : '160px'
            : solutionsOn || path === 'about'
            ? '230px'
            : '180px'
          : '0',
      }}
      id='seperator'
    ></div>
  );
};

export default Seperator;
