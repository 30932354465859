import React from 'react';
import { useLocation } from 'react-router-dom';
import ScreensNav from './ScreensNav';
import EliteScreens from './brands/EliteScreens';
import ScreenResearch from './brands/ScreenResearch';

const Speakers = () => {
  const [brandName, setBrandName] = React.useState('');

  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname.endsWith('/elite')) {
      setBrandName('elite');
    } else if (location.pathname.endsWith('/research')) {
      setBrandName('research');
    } else if (location.pathname.endsWith('/sony')) {
      setBrandName('sony');
    }
  }, [location]);

  React.useEffect(() => {
    if (document.getElementById('nav')) {
      document.getElementById('nav').style.display = 'none';
    }
  });

  return (
    <>
      <ScreensNav></ScreensNav>
      {brandName === 'elite' ? (
        <EliteScreens />
      ) : brandName === 'research' ? (
        <ScreenResearch />
      ) : (
        ''
      )}
    </>
  );
};

export default Speakers;
