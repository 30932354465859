import React from 'react';
import logo from '../../../../assets/Logo.png';
import background from '../../../../assets/home-auto-background.svg';
import { createStyles, makeStyles } from '@mui/styles';
import lutronLogo from '../../../../assets/lutron-logo.svg';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import logoBlack from '../../../../assets/Logo Black.png';
import lutronBlack from '../../../../assets/lutron-black.svg';
import homeAutoBackground from '../../../../assets/homeAutomation-bg.svg';
import lightingBackground from '../../../../assets/lightingBg.svg';
import shadesBg from '../../../../assets/shadesBg.svg';
import temperatureBg from '../../../../assets/temperatureBg.svg';
import audioBg from '../../../../assets/audioBg.svg';
import energyBg from '../../../../assets/energyBg.svg';

import radioRarBg from '../../../../assets/radio-ra3-bg.svg';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from 'react-router-dom';
import WorksWith from './WorksWith';
import Modal from '@mui/material/Modal';
import radioRa3Bg from '../../../../assets/radioRa3Bg.png';

const HomeAutomation = () => {
  const [isVeryBigScreen, setIsVeryBigScreen] = React.useState(
    window.matchMedia('(min-width: 2000px)').matches
  );
  const [collection, setCollection] = React.useState(false);

  const [isSmallScreen, setIsSmallScreen] = React.useState(
    window.matchMedia('(max-width: 1300px)').matches
  );

  const [collectionCategory, setCollectionCategory] =
    React.useState('homeWorks');

  const [isVerySmallScreen, setIsVerySmallScreen] = React.useState(
    window.matchMedia('(max-width: 1100px)').matches
  );

  const [pcScreen, setPcScreen] = React.useState(
    window.matchMedia('(min-width: 1900px)').matches
  );

  React.useEffect(() => {
    if (document.getElementById('nav')) {
      document.getElementById('nav').style.display = 'none';
    }
  }, []);

  const [homeAutoScreen, setHomeAutoScreen] = React.useState(
    window.matchMedia('(min-width: 1500px)').matches
  );

  const [residentialDropDown, setResidentialDropDown] = React.useState(false);

  const [isMobile, setIsMobile] = React.useState(
    window.matchMedia('(max-width: 768px)').matches
  );

  const [homeWorksCategory, setHomeWorksCategory] = React.useState('lighting');

  const [radioAboutOpen, setRadioAboutOpen] = React.useState(false);

  const useStyles = makeStyles(() =>
    createStyles({
      background: {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100vw',
        zIndex: '-1',
        objectFit: 'cover',
        height: '100vh',
      },
      subHeading: {
        color: 'white',
        fontFamily: 'lato',
        fontWeight: '400',
        textAlign: 'center',
        marginTop: !isMobile ? '8vh' : '-100px',
      },
      bottom: {
        width: '100vw',
        height: '35vh',
        position: 'fixed',
        bottom: '0',
        background: 'rgba(0, 0, 0, 0.5)',
        overflow: 'auto',
      },
      smallBg: {
        position: 'fixed',
        width: '100vw',
        top: !isMobile ? '130px' : '0',
        zIndex: '-1',
        display: collectionCategory === '' ? 'block' : 'none',
        height: isMobile ? '100vh' : 'auto',
        objectFit: 'cover',
      },
    })
  );
  const classes = useStyles();

  React.useEffect(() => {
    if (document.getElementById('underline-bottom-nav')) {
      if (homeWorksCategory === 'lighting') {
        document.getElementById('underline-bottom-nav').style.marginLeft =
          '-50.8%';
        document.getElementById('lighting-nav').style.opacity = '100%';
        document.getElementById('underline-bottom-nav').style.width =
          !isVeryBigScreen ? '58px' : '90px';
      } else if (homeWorksCategory === 'shades') {
        document.getElementById('underline-bottom-nav').style.marginLeft =
          !isVeryBigScreen ? '-33%' : '-32.4%';
        document.getElementById('shades-nav').style.opacity = '100%';

        document.getElementById('underline-bottom-nav').style.width =
          !isVeryBigScreen ? '58px' : '90px';
      } else if (homeWorksCategory === 'temperature') {
        document.getElementById('underline-bottom-nav').style.marginLeft =
          !isVeryBigScreen ? '-13.5%' : '-12.5%';
        document.getElementById('temperature-nav').style.opacity = '100%';

        document.getElementById('underline-bottom-nav').style.width =
          !isVeryBigScreen ? '85px' : '135px';
      } else if (homeWorksCategory === 'audio') {
        document.getElementById('underline-bottom-nav').style.marginLeft =
          !isVeryBigScreen ? '9.5%' : '9.5%';
        document.getElementById('audio-nav').style.opacity = '100%';

        document.getElementById('underline-bottom-nav').style.width =
          !isVeryBigScreen ? '85px' : '135px';
      } else if (homeWorksCategory === 'works-with') {
        document.getElementById('underline-bottom-nav').style.marginLeft =
          '49.2%';
        document.getElementById('works-with-nav').style.opacity = '100%';

        document.getElementById('underline-bottom-nav').style.width =
          !isVeryBigScreen ? '85px' : '135px';
      } else if (homeWorksCategory === 'energy') {
        document.getElementById('underline-bottom-nav').style.marginLeft =
          '29.4%';
        document.getElementById('energy-nav').style.opacity = '100%';

        document.getElementById('underline-bottom-nav').style.width =
          !isVeryBigScreen ? '58px' : '90px';
      } else {
        document.getElementById('underline-bottom-nav').style.marginLeft =
          '-50.8%';
        document.getElementById('lighting-nav').style.opacity = '100%';
        document.getElementById('underline-bottom-nav').style.width =
          !isVeryBigScreen ? '58px' : '90px';
      }
    }
  });
  React.useEffect(() => {
    if (collectionCategory !== 'homeWorks') {
      setHomeWorksCategory('lighting');
    }
  }, [collectionCategory]);

  return (
    <div>
      {!isMobile ? (
        <div
          className='topNav'
          style={{
            display: 'flex',
            width: '100vw',
            height: !isVeryBigScreen ? '80px' : '170px',
            justifyContent: 'center',
            marginTop: '-260px',
            background: !collection ? 'transparent' : 'white',
            top: '0',
            zIndex: '7',
          }}
        >
          <img
            src={!collection ? logo : logoBlack}
            alt='..'
            style={
              !isVeryBigScreen
                ? {
                    display: 'block',
                    margin: 'auto',
                    marginLeft: '3vw',
                    width: !collection ? '220px' : '250px',
                    marginTop: !collection ? 'auto' : '30px',
                  }
                : {
                    display: 'block',
                    margin: 'auto',
                    marginLeft: '1vw',
                    width: '15vw',
                  }
            }
          />
          <div
            className='right'
            style={{
              display: 'flex',
              marginLeft: 'auto',
              marginRight: '40px',
              height: '100%',
              justifyContent: 'center',
            }}
          >
            <h5
              style={{
                fontFamily: 'lato',
                fontWeight: '700',
                margin: 'auto',
              }}
            >
              <span
                style={{
                  color: !collection
                    ? 'rgb(220, 220, 220)'
                    : 'rgb(140, 140, 140)',
                  fontSize: !isMobile
                    ? !isVeryBigScreen
                      ? '1em'
                      : '1.8em'
                    : '0.6em',
                  cursor: 'pointer',
                  marginLeft: !isMobile ? '20px' : '-1vw',
                  opacity: '70%',
                }}
                id='back'
                onClick={() => (window.location = '/')}
                onMouseEnter={(e) => {
                  e.target.style.opacity = '100%';
                  document.getElementById('home').style.opacity = '100%';
                }}
                onMouseLeave={(e) => {
                  e.target.style.opacity = '70%';
                  document.getElementById('home').style.opacity = '70%';
                }}
              >
                &lt;
              </span>
              <span
                style={{
                  color: !collection
                    ? 'rgb(220, 220, 220)'
                    : 'rgb(140, 140, 140)',
                  fontSize: !isMobile
                    ? !isVeryBigScreen
                      ? '1em'
                      : '1.8em'
                    : '0.6em',
                  cursor: 'pointer',
                  marginLeft: '10px',
                  opacity: '70%',
                }}
                id='home'
                onClick={() => (window.location = '/')}
                onMouseEnter={(e) => {
                  e.target.style.opacity = '100%';
                  document.getElementById('back').style.opacity = '100%';
                }}
                onMouseLeave={(e) => {
                  e.target.style.opacity = '70%';
                  document.getElementById('back').style.opacity = '70%';
                }}
              >
                Home
              </span>
              <span
                style={{
                  color: !collection
                    ? 'rgb(220, 220, 220)'
                    : 'rgb(140, 140, 140)',
                  fontSize: !isMobile
                    ? !isVeryBigScreen
                      ? '1em'
                      : '1.8em'
                    : '0.6em',
                  cursor: 'pointer',
                  marginLeft: !isMobile ? '40px' : '2vw',
                }}
                onClick={() => (window.location = '/')}
              >
                |
              </span>
              <span
                style={{
                  marginLeft: !isMobile
                    ? !isVeryBigScreen
                      ? '40px'
                      : '45px'
                    : '3vw',
                  fontSize: !isMobile
                    ? !isVeryBigScreen
                      ? '0.8em'
                      : '1.3em'
                    : '0.6em',
                  opacity: '75%',
                  whiteSpace: 'pre',
                  color: !collection ? 'white' : 'black',
                }}
                onMouseEnter={() => {
                  setResidentialDropDown(true);
                }}
                onMouseLeave={() => {
                  setResidentialDropDown(false);
                }}
              >
                {!isMobile
                  ? 'Solutions    >    Residential    >   '
                  : 'Solutions > Residential > '}
              </span>
              <span
                style={{
                  marginLeft: !isMobile
                    ? !isVeryBigScreen
                      ? '30px'
                      : '60px'
                    : '2vw',
                  fontSize: !isMobile
                    ? !isVeryBigScreen
                      ? '1.2em'
                      : '2em'
                    : '0.8em',
                  color: !collection
                    ? 'rgb(220, 220, 220)'
                    : 'rgb(140, 140, 140)',
                }}
              >
                {'Home Automation '}
              </span>
              <span
                style={{
                  transform: 'rotate(90deg)',
                  fontSize: !isMobile
                    ? !isVeryBigScreen
                      ? '1.2em'
                      : '2em'
                    : '0.8em',
                  color: !collection
                    ? 'rgb(220, 220, 220)'
                    : 'rgb(140, 140, 140)',
                }}
              >
                &gt;
              </span>
              <Fade in={residentialDropDown}>
                <div
                  style={{
                    display: 'flex',
                    marginLeft: !pcScreen
                      ? !isVerySmallScreen
                        ? '250px'
                        : !isSmallScreen || isVerySmallScreen
                        ? '24vw'
                        : '32vw'
                      : !isVeryBigScreen
                      ? '14vw'
                      : '14vw',
                    height: !isVeryBigScreen ? '100px' : '210px',
                    width: !isVeryBigScreen ? '150px' : '320px',
                    background: 'rgba(0, 0, 0, 0.9)',

                    border: 'none',
                    position: 'absolute',
                    top: !isVeryBigScreen ? '65px' : '100px',
                    border: 'none',
                    borderRadius: 'none',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    zIndex: '9',
                  }}
                  onMouseEnter={() => {
                    setResidentialDropDown(true);
                  }}
                  onMouseLeave={() => {
                    setResidentialDropDown(false);
                  }}
                >
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.7em' : '1.5em',
                      opacity: '70%',
                      color: 'white',
                    }}
                    to='/solutions/residential/audio/speakers/revox'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Audio Visual
                  </Link>
                  <Link
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      display: 'block',
                      margin: 'auto',
                      textDecoration: 'none',
                      fontSize: !isVeryBigScreen ? '0.7em' : '1.5em',
                      opacity: '70%',
                    }}
                    to='/solutions/residential?active=homeDecoration'
                    onMouseEnter={(e) => (e.target.style.opacity = '100%')}
                    onMouseLeave={(e) => (e.target.style.opacity = '70%')}
                  >
                    Home Automation
                  </Link>
                </div>
              </Fade>
            </h5>
          </div>
        </div>
      ) : (
        ''
      )}
      {!isMobile ? (
        <div
          className='bottom'
          style={{
            width: '40vw',
            minWidth: '600px',
            marginLeft: 'auto',
            marginRight: '0',
            display: collection ? 'flex' : 'none',
            justifyContent: 'center',
            // background: 'white',
            height: '70px',
            marginTop: '-20px',
            marginBottom: '-20px',
            zIndex: '7',
          }}
        >
          <p
            style={{
              color: 'black',
              opacity: collectionCategory === 'homeWorks' ? '70%' : '50%',
              display: 'block',
              margin: 'auto',
              fontFamily: 'lato',
              cursor: 'pointer',
              fontWeight: '700',
              fontSize: !isMobile
                ? !isVeryBigScreen
                  ? '1em'
                  : '1.8em'
                : '0.6em',
            }}
            onMouseEnter={(e) => {
              e.target.style.opacity = '70%';
            }}
            onMouseLeave={(e) => {
              if (collectionCategory !== 'homeWorks') {
                e.target.style.opacity = '50%';
              }
            }}
            onClick={() => setCollectionCategory('homeWorks')}
          >
            HomeWorks
          </p>
          <p
            style={{
              color: 'black',
              opacity: '70%',
              display: 'block',
              margin: 'auto',
              fontFamily: 'lato',
              cursor: 'pointer',
              fontWeight: '700',
              opacity: collectionCategory === 'radio-ra3' ? '70%' : '50%',
              fontSize: !isMobile
                ? !isVeryBigScreen
                  ? '1em'
                  : '1.8em'
                : '0.6em',
            }}
            onMouseEnter={(e) => {
              e.target.style.opacity = '70%';
            }}
            onMouseLeave={(e) => {
              if (collectionCategory !== 'radio-ra3') {
                e.target.style.opacity = '50%';
              }
            }}
            onClick={() => setCollectionCategory('radio-ra3')}
          >
            Radio RA2
          </p>
          <img
            src={lutronBlack}
            alt='..'
            style={{
              display: 'block',
              margin: 'auto',
              width: !isVeryBigScreen ? '220px' : '350px',
            }}
          />
        </div>
      ) : (
        <div
          style={{
            display: collection ? 'flex' : 'none',
            flexDirection: 'column',
            paddingTop: '10px',
            paddingBottom: '0px',
            background: 'rgba(0, 0, 0, 0.7)',
            marginTop: '-160px',
            width: '100vw',
            height: '150px',
            justifyContent: 'center',
          }}
        >
          <div
            className='top'
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '60px',
              marginTop: '-16px',
            }}
          >
            <p
              style={{
                fontFamily: 'lato',
                color: 'black',
                display: 'block',
                margin: 'auto',
                fontSize: '0.9em',
                color: 'white',
              }}
              onClick={() => window.open('/')}
            >
              Home |
            </p>
            <p
              style={{
                fontFamily: 'lato',
                color: 'black',
                display: 'block',
                margin: 'auto',
                fontSize: '0.7em',
                color: 'white',
              }}
            >
              Solutions &gt;
            </p>
            <p
              style={{
                fontFamily: 'lato',
                color: 'black',
                display: 'block',
                margin: 'auto',

                color: 'white',

                fontSize: '0.7em',
              }}
            >
              Residential &gt;
            </p>

            <p
              style={{
                fontFamily: 'lato',
                color: 'black',
                display: 'block',
                margin: 'auto',
                fontSize: '0.9em',
                color: 'white',
                position: 'relative',
                left: '-1%',
              }}
            >
              Home Automation
            </p>
          </div>
          <img
            src={lutronLogo}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              width: '120px',
              marginTop: '10px',
            }}
          />
          <div
            style={{
              display: 'flex',
              margin: 'auto',
              width: '100%',
              height: '40%',
            }}
          >
            <p
              style={{
                fontFamily: 'lato',
                color: 'white',
                fontWeight: '700',
                textAlign: 'center',
                margin: 'auto',
                opacity: collectionCategory === 'homeWorks' ? '100%' : '70%',
              }}
              onClick={() => setCollectionCategory('homeWorks')}
            >
              HomeWorks
            </p>
            <p
              style={{
                fontFamily: 'lato',
                color: 'white',
                fontWeight: '700',
                textAlign: 'center',
                margin: 'auto',
                opacity: collectionCategory === 'radio-ra3' ? '100%' : '70%',
              }}
              onClick={() => setCollectionCategory('radio-ra3')}
            >
              Radio ra2
            </p>
          </div>
        </div>
      )}

      <Fade in={!collection} unmountOnExit>
        <div>
          <img src={background} alt='..' className={classes.background} />

          <h4 className={classes.subHeading}>Discover Total Home Control</h4>
          <h6 className={classes.subHeading} style={{ marginTop: '20px' }}>
            With
          </h6>
          <img
            src={lutronLogo}
            alt='..'
            style={{ display: 'block', margin: 'auto', marginTop: '20px' }}
          />
          <Button
            variant='contained'
            style={{
              color: 'white',
              background: 'rgba(0, 0, 0, 0.7)',
              display: 'block',
              margin: 'auto',
              marginTop: '6vh',
              width: '280px',
              height: '50px',
              fontFamily: 'lato',
              fontWeight: '700',
              border: '1px solid white',
              transitionProperty: 'all',
              transitionDuration: '0.7s',
            }}
            onMouseEnter={(e) => {
              e.target.style.background = 'rgba(0, 0, 0, 0.9)';
            }}
            onMouseLeave={(e) => {
              e.target.style.background = 'rgba(0, 0, 0, 0.7)';
            }}
            onClick={(e) => {
              setCollection(true);
            }}
          >
            View Collection
          </Button>
          <div className={classes.bottom}>
            <h5 className={classes.subHeading} style={{ marginTop: '2%' }}>
              About Lutron
            </h5>
            <h6
              className={classes.subHeading}
              style={{
                width: !isMobile ? '80%' : '95%',
                maxWidth: '900px',
                margin: 'auto',
                marginTop: '2%',
                opacity: '80%',
              }}
            >
              For decades, Lutron has been at the forefront of lighting control
              and automated shades. With distinctly crafted design and
              innovative technology, everything we create enhances the
              atmosphere of personal space. Today, Lutron channels the power of
              performance and turns technology into a timeless solution for the
              most discerning lifestyles and spaces. Homeworks is the
              illuminating force behind the scene to make personal moments shine
              bright. Always forward thinking, Lutron has added Ketra, the most
              innovative light source in the world, to our offerings. This
              expands our HomeWorks portfolio and allows us to build the
              complete luxury home experience.
            </h6>
          </div>
        </div>
      </Fade>
      <Fade in={collection} unmountOnExit>
        <div>
          <Fade
            in={
              collectionCategory !== 'homeWorks' &&
              collectionCategory !== 'radio-ra3'
            }
            unmountOnExit
          >
            <img
              src={homeAutoBackground}
              alt='..'
              className={classes.smallBg}
            />
          </Fade>
          <Fade in={collectionCategory === 'homeWorks'} unmountOnExit>
            <div
              style={{
                width: '100vw',
                height: '100vh',
                position: 'absolute',
                top: '0',
                zIndex: '-1',
              }}
            >
              {!isMobile ? (
                <div
                  className='bottom-nav'
                  style={{
                    display:
                      collectionCategory === 'homeWorks' ? 'flex' : 'none',
                    width: '100vw',
                    height: !isVeryBigScreen ? '50px' : '90px',
                    background: 'rgba(0,0, 0, 0.7)',
                    justifyContent: 'center',
                    marginTop: !isVeryBigScreen ? '125px' : '200px',
                    paddingLeft: '20%',
                    paddingRight: '20%',
                  }}
                >
                  <div
                    className='underline'
                    id='underline-bottom-nav'
                    style={{
                      display: 'block',
                      margin: 'auto',
                      width: '58px',
                      height: '2px',
                      background: 'white',
                      marginTop: 'auto',
                      marginTop: !isVeryBigScreen ? '40px' : '70px',
                      position: 'absolute',
                      transitionProperty: 'all',
                      transitionDuration: '0.7s',
                    }}
                  ></div>

                  <p
                    style={{
                      display: 'block',
                      margin: 'auto',
                      fontFamily: 'lato',
                      color: 'white',
                      fontWeight: '700',
                      cursor: 'pointer',
                      position: 'relative',
                      left: '0',
                      fontSize: !isVeryBigScreen ? '1em' : '1.6em',
                      opacity:
                        homeWorksCategory === 'lighting' ? '100%' : '70%',
                      transitionProperty: 'all',
                      transitionDuration: '0.7s',
                    }}
                    id='lighting-nav'
                    onClick={() => setHomeWorksCategory('lighting')}
                    onMouseEnter={(e) => {
                      e.target.style.left = '-5px';
                      e.target.style.opacity = '100%';
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.left = '0';

                      if (homeWorksCategory !== 'lighting') {
                        e.target.style.opacity = '70%';
                      }
                    }}
                  >
                    Lighting
                  </p>
                  <p
                    style={{
                      display: 'block',
                      margin: 'auto',
                      fontFamily: 'lato',
                      color: 'white',
                      fontWeight: '700',
                      cursor: 'pointer',
                      position: 'relative',
                      opacity: homeWorksCategory === 'shades' ? '100%' : '70%',
                      left: '0',
                      fontSize: !isVeryBigScreen ? '1em' : '1.6em',

                      transitionProperty: 'all',
                      transitionDuration: '0.7s',
                    }}
                    id='shades-nav'
                    onClick={() => setHomeWorksCategory('shades')}
                    onMouseEnter={(e) => {
                      e.target.style.left = '-5px';
                      e.target.style.opacity = '100%';
                    }}
                    // onMouseLeave={(e) => {
                    //   e.target.style.left = '0';
                    //   e.target.style.opacity = '70%';
                    // }}
                    onMouseLeave={(e) => {
                      e.target.style.left = '0';

                      if (homeWorksCategory !== 'shades') {
                        e.target.style.opacity = '70%';
                      }
                    }}
                  >
                    Shades
                  </p>
                  <p
                    style={{
                      display: 'block',
                      margin: 'auto',
                      fontFamily: 'lato',
                      color: 'white',
                      fontWeight: '700',
                      cursor: 'pointer',
                      opacity:
                        homeWorksCategory === 'temperature' ? '100%' : '70%',
                      fontSize: !isVeryBigScreen ? '1em' : '1.6em',

                      position: 'relative',
                      left: '0',

                      transitionProperty: 'all',
                      transitionDuration: '0.7s',
                    }}
                    id='temperature-nav'
                    onClick={() => setHomeWorksCategory('temperature')}
                    onMouseEnter={(e) => {
                      e.target.style.left = '-5px';
                      e.target.style.opacity = '100%';
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.left = '0';

                      if (homeWorksCategory !== 'temperature') {
                        e.target.style.opacity = '70%';
                      }
                    }}
                  >
                    Temperature
                  </p>
                  <p
                    style={{
                      display: 'block',
                      margin: 'auto',
                      fontFamily: 'lato',
                      color: 'white',
                      fontWeight: '700',
                      cursor: 'pointer',
                      opacity: homeWorksCategory === 'audio' ? '100%' : '70%',
                      position: 'relative',
                      left: '0',
                      fontSize: !isVeryBigScreen ? '1em' : '1.6em',

                      transitionProperty: 'all',
                      transitionDuration: '0.7s',
                    }}
                    onClick={() => setHomeWorksCategory('audio')}
                    id='audio-nav'
                    onMouseEnter={(e) => {
                      e.target.style.left = '-5px';
                      e.target.style.opacity = '100%';
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.left = '0';

                      if (homeWorksCategory !== 'audio') {
                        e.target.style.opacity = '70%';
                      }
                    }}
                  >
                    Audio Visual
                  </p>
                  <p
                    style={{
                      display: 'block',
                      margin: 'auto',
                      fontFamily: 'lato',
                      color: 'white',
                      fontSize: !isVeryBigScreen ? '1em' : '1.6em',

                      fontWeight: '700',
                      cursor: 'pointer',
                      opacity: homeWorksCategory === 'energy' ? '100%' : '70%',
                      position: 'relative',
                      left: '0',

                      transitionProperty: 'all',
                      transitionDuration: '0.7s',
                    }}
                    id='energy-nav'
                    onClick={() => setHomeWorksCategory('energy')}
                    onMouseEnter={(e) => {
                      e.target.style.left = '-5px';
                      e.target.style.opacity = '100%';
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.left = '0';

                      if (homeWorksCategory !== 'energy') {
                        e.target.style.opacity = '70%';
                      }
                    }}
                  >
                    Energy
                  </p>
                  <p
                    style={{
                      display: 'block',
                      margin: 'auto',
                      fontFamily: 'lato',
                      color: 'white',
                      fontWeight: '700',
                      cursor: 'pointer',
                      opacity:
                        homeWorksCategory === 'works-with' ? '100%' : '70%',
                      position: 'relative',
                      left: '0',
                      fontSize: !isVeryBigScreen ? '1em' : '1.6em',

                      transitionProperty: 'all',
                      transitionDuration: '0.7s',
                    }}
                    id='works-with-nav'
                    onClick={() => setHomeWorksCategory('works-with')}
                    onMouseEnter={(e) => {
                      e.target.style.left = '-5px';
                      e.target.style.opacity = '100%';
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.left = '0';

                      if (homeWorksCategory !== 'works-with') {
                        e.target.style.opacity = '70%';
                      }
                    }}
                  >
                    Works With
                  </p>
                </div>
              ) : (
                <FormControl
                  style={{
                    width: '150px',
                    display: 'flex',
                    margin: 'auto',
                    marginTop: '210px',
                    visibility: 'hidden',
                  }}
                >
                  <InputLabel
                    id='demo-simple-select-label'
                    style={{ color: 'white' }}
                  >
                    Page
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={homeWorksCategory}
                    label='Page'
                    style={{
                      width: '150px',
                      display: 'flex',
                      margin: 'auto',
                      color: 'white',
                      outlineColor: 'white',
                    }}
                    onChange={(e) => {
                      setHomeWorksCategory(e.target.value);
                    }}
                  >
                    <MenuItem value={'lighting'} style={{ color: 'black' }}>
                      Lighting
                    </MenuItem>
                    <MenuItem value={'shades'} style={{ color: 'black' }}>
                      Shades
                    </MenuItem>
                    <MenuItem value={'temperature'} style={{ color: 'black' }}>
                      Temperature
                    </MenuItem>
                    <MenuItem value={'audio'} style={{ color: 'black' }}>
                      Audio Visual
                    </MenuItem>
                    <MenuItem value={'energy'} style={{ color: 'black' }}>
                      Energy
                    </MenuItem>
                    <MenuItem value={'works-with'} style={{ color: 'black' }}>
                      Works With
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
              <Fade in={homeWorksCategory === 'lighting'} unmountOnExit>
                <div
                  style={{
                    width: '100%',
                    // height: 'vh',
                    paddingBottom: '90px',
                  }}
                >
                  <img
                    src={lightingBackground}
                    alt=''
                    style={{
                      display: 'block',
                      width: '100%',
                      position: 'absolute',
                      top: !isVeryBigScreen
                        ? !isMobile
                          ? '124px'
                          : '79px'
                        : '200px',
                      zIndex: '-1',
                      objectFit: 'cover',
                      height: isMobile ? '114vh' : 'auto',
                    }}
                  />
                  <div
                    style={{
                      display: 'block',
                      margin: 'auto',
                      marginLeft: !isMobile ? '17vw' : 'auto',
                      width: !isVeryBigScreen
                        ? !isMobile
                          ? '430px'
                          : '90vw'
                        : '530px',
                      height: !isVeryBigScreen
                        ? !isMobile
                          ? '380px'
                          : '440px'
                        : '480px',
                      background: 'rgba(0, 0, 0, 0.7)',
                      marginTop: !isMobile ? '12vh' : '4vh',
                      paddingTop: '25px',
                    }}
                  >
                    <h4
                      className={classes.subHeading}
                      style={{
                        textAlign: 'left',
                        marginLeft: '8%',
                        marginTop: '2%',
                        fontSize: !isVeryBigScreen ? '1.6em' : '2.2em',
                      }}
                    >
                      Lighting Control
                    </h4>
                    <h5
                      className={classes.subHeading}
                      style={{
                        textAlign: 'left',
                        marginLeft: '8%',
                        marginTop: '3%',
                        width: '85%',
                        opacity: '70%',
                        fontSize: !isVeryBigScreen ? '1.1em' : '1.6em',
                      }}
                    >
                      Create just the right light for any activity or set moods
                      in specific areas of your home – all at the touch of a
                      button. A dynamic lighting control system that brings the
                      magic of light to elevate everyday moments. Make a design
                      statement with any light fixture you choose – controlled
                      from one system. Nurture a mood in spaces large and small,
                      with tunable white and warm dimming. Use light as an
                      amenity – enhancing the experience in restaurants,
                      galleries, spas, and more. Personalise the moment with
                      user-friendly apps that make it easy to change your
                      lighting scene – and then change it back.
                    </h5>
                  </div>
                </div>
              </Fade>
              <Fade
                unmountOnExit
                in={!isMobile ? homeWorksCategory === 'shades' : true}
              >
                <div
                  style={{
                    width: '100%',
                    paddingBottom: '90px',
                    marginTop: '-70px',
                  }}
                >
                  <img
                    src={shadesBg}
                    alt=''
                    style={{
                      display: 'block',
                      width: '100%',
                      position: !isMobile ? 'absolute' : 'absolute',
                      marginTop: isMobile ? '-20px' : 'auto',
                      top: !isVeryBigScreen
                        ? !isMobile
                          ? '124px'
                          : 'auto'
                        : '200px',
                      zIndex: '-1',
                      objectFit: 'cover',
                      height: isMobile ? '109vh' : 'auto',
                    }}
                  />
                  <div
                    style={{
                      display: 'block',
                      margin: 'auto',
                      marginLeft: !isMobile ? '17vw' : 'auto',
                      width: !isVeryBigScreen
                        ? !isMobile
                          ? '430px'
                          : '90vw'
                        : '530px',
                      height: !isVeryBigScreen
                        ? !isMobile
                          ? '350px'
                          : '450px'
                        : '450px',
                      background: 'rgba(0, 0, 0, 0.7)',
                      marginTop: !isMobile ? '12vh' : '7vh',
                      paddingTop: '25px',
                    }}
                  >
                    <h4
                      className={classes.subHeading}
                      style={{
                        textAlign: 'left',
                        marginLeft: '8%',
                        marginTop: '0%',
                        fontSize: !isVeryBigScreen ? '1.6em' : '2.2em',
                      }}
                    >
                      Shade Control
                    </h4>
                    <h5
                      className={classes.subHeading}
                      style={{
                        textAlign: 'left',
                        marginLeft: '8%',
                        marginTop: '2%',
                        width: '84%',
                        opacity: '70%',
                        fontSize: !isVeryBigScreen ? '1.2em' : '1.7em',
                      }}
                    >
                      Daylight has a quality that we respond to emotionally.
                      Colors read true, finishes reveal their texture, and an
                      uplifting quality pervades any room bathed in daylight. As
                      we become more conscious of our footprint on this planet,
                      we realize that making the most of daylight in our homes
                      simply makes sense. Utilizing daylight reduces our need
                      for electric light and saves energy. Lutron shading
                      systems do just this by providing precision control of
                      daylight – all at the touch of a button.
                    </h5>
                  </div>
                </div>
              </Fade>
              <Fade
                in={!isMobile ? homeWorksCategory === 'temperature' : true}
                unmountOnExit
              >
                <div
                  style={{
                    width: '100%',
                    paddingTop: isMobile ? '10%' : 'auto',
                    paddingBottom: isMobile ? '6%' : 'auto',
                  }}
                >
                  <img
                    src={temperatureBg}
                    alt=''
                    style={{
                      display: 'block',
                      width: '100%',
                      position: !isMobile ? 'absolute' : 'absolute',

                      // marginTop: isMobile ? '-20px' : 'auto',
                      top: !isVeryBigScreen
                        ? !isMobile
                          ? '124px'
                          : ''
                        : '200px',
                      zIndex: '-1',
                      objectFit: 'cover',
                      height: isMobile ? '100vh' : 'auto',
                    }}
                  />
                  <div
                    style={{
                      display: 'block',
                      margin: 'auto',
                      marginLeft: !isMobile ? '17vw' : 'auto',
                      width: !isVeryBigScreen
                        ? !isMobile
                          ? '430px'
                          : '90vw'
                        : '530px',
                      height: !isVeryBigScreen
                        ? !isMobile
                          ? '370px'
                          : '300px'
                        : '450px',
                      background: 'rgba(0, 0, 0, 0.7)',
                      marginTop: !isMobile ? '12vh' : '8vh',
                      paddingTop: !isMobile ? '25px' : '2px',
                    }}
                  >
                    <h4
                      className={classes.subHeading}
                      style={{
                        textAlign: 'left',
                        marginLeft: '8%',
                        marginTop: !isMobile ? '7%' : '7%',
                        fontSize: !isVeryBigScreen ? '1.6em' : '2.2em',
                      }}
                    >
                      Temperature Control
                    </h4>
                    <h5
                      className={classes.subHeading}
                      style={{
                        textAlign: 'left',
                        marginLeft: '8%',
                        marginTop: '8%',
                        width: !isMobile ? '65%' : '85%',
                        opacity: '70%',
                        fontSize: !isVeryBigScreen ? '1.2em' : '1.7em',
                      }}
                    >
                      Whether you want to check if any ACs are left on by
                      mistake when you’re outside or whether you want to turn on
                      the AC in your living room before you reach home,
                      integration of your Air conditioning system to the home
                      automation system adds a lot of convenience and value.
                    </h5>
                  </div>
                </div>
              </Fade>
              <Fade
                in={!isMobile ? homeWorksCategory === 'audio' : true}
                unmountOnExit
              >
                <div
                  style={{
                    width: '100%',
                    paddingTop: isMobile ? '10%' : 'auto',
                    paddingBottom: isMobile ? '6%' : 'auto',
                    paddingBottom: '90px',
                  }}
                >
                  <img
                    src={audioBg}
                    alt=''
                    style={{
                      display: 'block',
                      width: '100%',
                      position: 'absolute',
                      marginTop: !isMobile ? 'auto' : '-20px',
                      top: !isVeryBigScreen
                        ? !isMobile
                          ? '124px'
                          : 'auto'
                        : '200px',
                      zIndex: '-1',
                      objectFit: 'cover',
                      height: isMobile ? '100vh' : 'auto',
                    }}
                  />
                  <div
                    style={{
                      display: 'block',
                      margin: 'auto',
                      marginLeft: !isMobile ? '17vw' : 'auto',
                      width: !isVeryBigScreen
                        ? !isMobile
                          ? '430px'
                          : '90vw'
                        : '530px',
                      height: !isVeryBigScreen
                        ? !isMobile
                          ? '350px'
                          : '430px'
                        : '450px',
                      background: 'rgba(0, 0, 0, 0.7)',
                      marginTop: !isMobile ? '12vh' : '4vh',
                      paddingTop: '25px',
                    }}
                  >
                    <h4
                      className={classes.subHeading}
                      style={{
                        textAlign: 'left',
                        marginLeft: '8%',
                        marginTop: '3%',
                        fontSize: !isVeryBigScreen ? '1.6em' : '2.2em',
                      }}
                    >
                      Audio Visual
                    </h4>
                    <h5
                      className={classes.subHeading}
                      style={{
                        textAlign: 'left',
                        marginLeft: '8%',
                        marginTop: '4%',
                        width: '65%',
                        opacity: '70%',
                        fontSize: !isVeryBigScreen ? '1.2em' : '1.7em',
                      }}
                    >
                      The HomeWorks system makes it possible for you to turn on
                      your 4K projectors, play music, control the sound and a
                      variety of audio visual devices in your home. Set the
                      perfect atmosphere or activate lighting that is right for
                      the task at hand - Lights come on. Blinds open. Your
                      favorite playlist fills the background - at your command.
                    </h5>
                  </div>
                </div>
              </Fade>
              <Fade
                in={!isMobile ? homeWorksCategory === 'energy' : true}
                unmountOnExit
              >
                <div
                  style={{
                    width: '100%',
                    paddingTop: isMobile ? '10%' : 'auto',
                    paddingBottom: isMobile ? '1%' : 'auto',
                    marginBottom: '80px',
                  }}
                >
                  <img
                    src={energyBg}
                    alt=''
                    style={{
                      display: 'block',
                      width: '100%',
                      position: 'absolute',
                      marginTop: !isMobile ? 'auto' : '-20px',
                      top: !isVeryBigScreen
                        ? !isMobile
                          ? '124px'
                          : 'auto'
                        : '200px',
                      zIndex: '-1',
                      objectFit: 'cover',
                      height: isMobile ? '440px' : 'auto',
                    }}
                  />
                  <div
                    style={{
                      display: 'block',
                      margin: 'auto',
                      marginLeft: !isMobile ? '17vw' : 'auto',
                      width: !isVeryBigScreen
                        ? !isMobile
                          ? '430px'
                          : '90vw'
                        : '530px',
                      height: !isVeryBigScreen ? '350px' : '450px',
                      background: 'rgba(0, 0, 0, 0.7)',
                      marginTop: !isMobile ? '12vh' : '4vh',
                      paddingTop: '25px',
                    }}
                  >
                    <h4
                      className={classes.subHeading}
                      style={{
                        textAlign: 'left',
                        marginLeft: '8%',
                        marginTop: !isMobile ? '3%' : '10%',
                        fontSize: !isVeryBigScreen ? '1.6em' : '2.2em',
                      }}
                    >
                      Energy Saver
                    </h4>
                    <h5
                      className={classes.subHeading}
                      style={{
                        textAlign: 'left',
                        marginLeft: '8%',
                        marginTop: '8%',
                        width: !isMobile ? '65%' : '80%',
                        opacity: '70%',
                        fontSize: !isVeryBigScreen ? '1.2em' : '1.7em',
                      }}
                    >
                      An occupancy/vacancy sensor is often ideal for rooms where
                      lights are often inadvertently left on, such as bathroom.
                      The sensor automatically turns lights on when you enter
                      the room then turns them off when you leave helping you
                      save energy throughout your home.
                    </h5>
                  </div>
                </div>
              </Fade>
              <Fade
                in={!isMobile ? homeWorksCategory === 'works-with' : true}
                unmountOnExit
              >
                <div style={{ width: '100%' }}>
                  <WorksWith></WorksWith>
                </div>
              </Fade>
            </div>
          </Fade>
          <Fade in={collectionCategory === 'radio-ra3'} unmountOnExit>
            <div
              style={{
                width: '100vw',
                height: '100vh',
                position: 'absolute',
                top: '0',
                zIndex: '-1',
              }}
            >
              <img
                src={radioRa3Bg}
                alt=''
                style={{
                  position: 'absolute',
                  width: '100vw',
                  display: !isMobile ? 'none' : 'block',
                  zIndex: '-1',
                  height: '113vh',
                  objectFit: 'cover',
                  top: !isVeryBigScreen ? '70px' : '200px',
                }}
              />
              <Modal
                open={radioAboutOpen}
                onClose={() => setRadioAboutOpen(false)}
              >
                <div
                  style={{
                    display: 'block',
                    margin: 'auto',
                    marginTop: '13vh',
                    width: !isMobile ? '650px' : '90vw',
                    height: !isMobile ? '580px' : '40vh',
                    borderRadius: '10px',

                    background: !isMobile
                      ? 'rgba(0, 0, 0, 0.7)'
                      : 'rgba(230, 230, 230, 1)',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: '80%',
                      paddingBottom: '40px',
                      overflow: 'auto',
                    }}
                  >
                    <p
                      style={{
                        marginLeft: '5%',
                        width: '85%',
                        fontFamily: 'lato',

                        marginTop: '5%',
                        position: 'relative',
                        top: '5%',
                        opacity: !isMobile ? '70%' : '100%',
                        fontWeight: !isMobile ? '400' : '700',
                        color: !isMobile ? 'white' : 'black',
                      }}
                    >
                      Enjoy personalized smart lighting and blind control for
                      any home, any budget, with RA2 Select. Added convenience.
                      More comfort. Enhanced peace of mind. Staying in control
                      of your home, even when you're not there.
                    </p>
                    <p
                      style={{
                        marginLeft: '5%',
                        width: '85%',
                        fontFamily: 'lato',
                        color: 'white',
                        marginTop: '5%',
                        position: 'relative',
                        top: '5%',
                        fontWeight: !isMobile ? '400' : '700',
                        opacity: !isMobile ? '70%' : '100%',
                        color: !isMobile ? 'white' : 'black',
                      }}
                    >
                      The RA2 Select main repeater lets you control your lights
                      and blinds on your smartphone or tablet, from anywhere in
                      the world using the free Lutron App. You can also use the
                      versatile Pico remote for control throughout your home.
                      The Pico is available as a keypad, for scene control, or
                      as a remote, for individual light and blind control. The
                      main repeater supports up to 100 devices.
                    </p>
                    <p
                      style={{
                        marginLeft: '5%',
                        width: '85%',
                        fontFamily: 'lato',
                        color: 'white',
                        marginTop: '5%',
                        position: 'relative',
                        top: '5%',
                        opacity: !isMobile ? '70%' : '100%',

                        fontWeight: !isMobile ? '400' : '700',
                        color: !isMobile ? 'white' : 'black',
                      }}
                    >
                      Easy system setup through the Lutron App automatically
                      configures light and blind levels for typical activities.
                      The pre-engraved Pico scene keypad guarantees every scene
                      keypad in the home has an intuitive scene icon, for those
                      pre-set light and blind levels.
                    </p>
                    <p
                      style={{
                        marginLeft: '5%',
                        width: '85%',
                        fontFamily: 'lato',
                        color: 'white',
                        marginTop: '5%',
                        position: 'relative',
                        top: '5%',
                        opacity: !isMobile ? '70%' : '100%',

                        color: !isMobile ? 'white' : 'black',
                        fontWeight: !isMobile ? '400' : '700',
                      }}
                    >
                      Lutron's patented Clear Connect RF technology sets the bar
                      for reliability. You can trust your system will work with
                      precision and accuracy, free from interference with other
                      frequency bands, every time you use it.
                    </p>
                  </div>
                  <div
                    className='bottom'
                    style={{
                      height: '20%',
                      position: 'sticky',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      variant='text'
                      style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: '5%',
                        fontFamily: 'lato',
                        color: 'red',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        border: '1px solid white',
                        padding: '10px',
                        width: !isMobile ? '200px' : '100px',
                        height: !isMobile ? 'auto' : '40px',
                      }}
                      onClick={() => setRadioAboutOpen(false)}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </Modal>
              <img
                src={radioRarBg}
                alt='..'
                className={classes.smallBg}
                style={{
                  display: !isMobile ? 'block' : 'none',
                  width: '100%',
                  position: 'absolute',
                  top: !isVeryBigScreen ? '124px' : '200px',
                  zIndex: '-1',
                  objectFit: 'cover',
                }}
              />
              <div
                style={{
                  display: 'block',
                  margin: 'auto',
                  marginLeft: '0',
                  width: !isVeryBigScreen
                    ? !isMobile
                      ? '580px'
                      : '100vw'
                    : '780px',
                  height: !isVeryBigScreen
                    ? !isMobile
                      ? '500px'
                      : 'auto'
                    : '700px',
                  marginTop: !isMobile ? '15%' : '220px',
                  paddingTop: '25px',

                  paddingBottom: !isMobile ? 'auto' : '20px',
                  background: isMobile ? 'rgba(0, 0, 0, 0.6)' : 'transparent',
                }}
              >
                <h4
                  className={classes.subHeading}
                  style={{
                    textAlign: 'left',
                    marginLeft: '8%',
                    marginTop: '1%',
                    fontSize: !isVeryBigScreen ? '1.6em' : '2.2em',
                  }}
                >
                  Radio RA2
                </h4>
                <h5
                  className={classes.subHeading}
                  style={{
                    textAlign: 'left',
                    marginLeft: '8%',
                    marginTop: '7%',
                    width: '85%',
                    opacity: '70%',
                    fontSize: !isVeryBigScreen ? '1.2em' : '1.7em',
                  }}
                >
                  Enjoy personalized smart lighting and blind control for any
                  home, any budget, with RA2 Select. Added convenience. More
                  comfort. Enhanced peace of mind. Staying in control of your
                  home, even when you're not there.
                </h5>
                <h5
                  className={classes.subHeading}
                  style={{
                    textAlign: 'left',
                    marginLeft: '8%',
                    marginTop: '2%',
                    width: '85%',
                    opacity: '70%',
                    fontSize: !isVeryBigScreen ? '1.2em' : '1.7em',
                  }}
                >
                  The RA2 Select main repeater lets you control your lights and
                  blinds on your smartphone or tablet, from anywhere in the
                  world using the free Lutron App. You can also use the
                  versatile Pico remote for control throughout your home. The
                  Pico is available as a keypad, for scene control, or as a
                  remote, for individual light and blind control. The main
                  repeater supports up to 100 devices.
                </h5>

                <Button
                  style={{
                    background: 'rgba(0, 0, 0, 0.6)',
                    marginLeft: '8%',
                    fontFamily: 'lato',
                    color: 'white',
                    border: '1px solid white',
                    width: '200px',
                    height: '55px',
                    marginTop: '4%',
                  }}
                  onClick={() => setRadioAboutOpen(true)}
                >
                  Read More
                </Button>
              </div>
            </div>
          </Fade>
        </div>
      </Fade>
    </div>
  );
};

export default HomeAutomation;
