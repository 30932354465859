import React from 'react';
import image from '../assets/inTheMediaPicture.jpg';
import image2 from '../assets/inTheMediaPicture2.jpg';
import phoneIcon from '../assets/phone-call-icon.svg';
import locationIcon from '../assets/location-icon.svg';
import emailIcon from '../assets/email-icon.svg';
import facebookIcon from '../assets/facebook.svg';
import instaIcon from '../assets/insta.svg';
import newsLetterImage1 from '../assets/newsLetterImage1.png';
import newsLetterImage2 from '../assets/newsLetterImage2.png';
import newsLetterImage3 from '../assets/newsLetterImage3.png';
import newsLetterImage4 from '../assets/newsLetterImage4.png';
import mobileNewsLetter from '../assets/mobileNewsLetter.png';
import newsLetterMay1 from '../assets/newsLetterMay1.jpg';
import newsLetterMay2 from '../assets/newsLetterMay2.jpg';
import newsLetterJune from '../assets/newsLetterJune.jpg';
import newsLetterNew from '../assets/newsletter-new-1.jpeg';
import newsLetterNew1 from '../assets/newsletter-new.jpeg';

const InTheMedia = () => {
  React.useEffect(() => {
    if (document.getElementById('nav')) {
      document.getElementById('nav').style.background = 'rgb(0, 0, 0)';
    }
  });

  const [isSmallScreen, setIsSmallScreen] = React.useState(
    window.matchMedia('(max-width: 1200px)').matches
  );

  const [isBigScreen, setIsBigScreen] = React.useState(
    window.matchMedia('(min-width: 2000px)').matches
  );

  const [isMobile, setIsMobile] = React.useState(
    window.matchMedia('(max-width: 768px)').matches
  );

  React.useEffect(() => {
    if (document.getElementById('seperator')) {
      document.getElementById('seperator').style.marginBottom = '120px';
    }
  }, []);

  if (!isMobile) {
    return (
      <div>
        <h4
          style={{
            textAlign: 'center',
            fontFamily: 'montserrat',
            fontWeight: '700',
            marginTop: '170px',
          }}
        >
          MONTHLY NEWSLETTER
        </h4>
        <div
          className='flex'
          style={{
            width: '100vw',
            marginTop: '-40px',
            maxWidth: '1100px',
            margin: 'auto',
            height: 'auto',
          }}
        >
          <p
            style={{
              fontFamily: 'montserrat',
              textAlign: 'center',
              fontWeight: '700',
              marginTop: '20px',
              width: '100%',
            }}
          >
            August. 2022 • Newsletter No.4{' '}
          </p>
          <div className='flex'>
            <img
              src={newsLetterNew}
              alt=''
              style={{
                display: 'block',
                margin: 'auto',
                // width: '70%',
                marginTop: '40px',
                marginBottom: '80px',
              }}
            />
          </div>
        </div>
        <h4
          style={{
            textAlign: 'center',
            fontFamily: 'montserrat',
            fontWeight: '700',
            marginTop: '170px',
          }}
        >
          MONTHLY NEWSLETTER
        </h4>
        <div
          className='flex'
          style={{
            width: '100vw',
            marginTop: '-40px',
            maxWidth: '1100px',
            margin: 'auto',
            height: 'auto',
          }}
        >
          <p
            style={{
              fontFamily: 'montserrat',
              textAlign: 'center',
              fontWeight: '700',
              marginTop: '20px',
              width: '100%',
            }}
          >
            July. 2022 • Newsletter No.4{' '}
          </p>
          <div className='flex'>
            <img
              src={newsLetterNew1}
              alt=''
              style={{
                display: 'block',
                margin: 'auto',
                // width: '80%',
                marginTop: '40px',
                marginBottom: '80px',
              }}
            />
          </div>
        </div>
        <h4
          style={{
            textAlign: 'center',
            fontFamily: 'montserrat',
            fontWeight: '700',
            marginTop: '170px',
          }}
        >
          MONTHLY NEWSLETTER
        </h4>
        <div
          className='flex'
          style={{
            width: '100vw',
            marginTop: '-40px',
            maxWidth: '1100px',
            margin: 'auto',
            height: 'auto',
          }}
        >
          <p
            style={{
              fontFamily: 'montserrat',
              textAlign: 'center',
              fontWeight: '700',
              marginTop: '20px',
              width: '100%',
            }}
          >
            June. 2022 • Newsletter No.4{' '}
          </p>
          <div className='flex'>
            <img
              src={newsLetterJune}
              alt=''
              style={{
                display: 'block',
                margin: 'auto',
                width: '80%',
                marginTop: '40px',
                marginBottom: '80px',
              }}
            />
          </div>
        </div>
        <div
          className='flex'
          style={{
            width: '100vw',
            marginTop: '-40px',
            maxWidth: '1100px',
            margin: 'auto',
            height: 'auto',
          }}
        >
          <p
            style={{
              fontFamily: 'montserrat',
              textAlign: 'center',
              fontWeight: '700',
              marginTop: '20px',
              width: '100%',
            }}
          >
            May. 2022 • Newsletter No.3{' '}
          </p>
          <div className='flex'>
            <img
              src={newsLetterMay1}
              alt=''
              style={{
                display: 'block',
                margin: 'auto',
                width: '80%',
                marginTop: '40px',
                marginBottom: '80px',
              }}
            />
          </div>
        </div>
        <p
          style={{
            fontFamily: 'montserrat',
            textAlign: 'center',
            fontWeight: '700',
            marginTop: '20px',
            marginBottom: '-60px',
          }}
        >
          April. 2022 • Newsletter No.2{' '}
        </p>

        <div
          className='flex'
          style={{
            display: 'flex',
            width: '100vw',
            marginTop: '-40px',
            maxWidth: '1100px',
            margin: 'auto',
          }}
        >
          <div
            className='left'
            style={{
              display: 'block',
              margin: 'auto',
              width: '50%',
              marginTop: '70px',
            }}
          >
            <h1
              style={{
                fontFamily: 'montserrat',
                fontSize: '5.5em',
                textAlign: 'left',
                fontWeight: '100',
              }}
            >
              THAR <br /> RAVEN
            </h1>
            <h1
              style={{
                fontFamily: 'montserrat',
                textAlign: 'left',
                fontWeight: '400',
                marginTop: '50px',
                marginLeft: '10px',
              }}
            >
              LUTRON
            </h1>
            <h4
              style={{
                fontFamily: 'montserrat',
                textAlign: 'left',
                fontWeight: '100',
                marginTop: '10px',
                marginLeft: '10px',
              }}
            >
              CURATING ELEGANT EXPERIENCES FOR LUXURY LIFESTYLES.
            </h4>
            <h5
              style={{
                fontFamily: 'montserrat',
                textAlign: 'left',
                fontWeight: '100',
                marginTop: '30px',
                marginLeft: '10px',
                width: '70%',
              }}
            >
              Thar Technologies' founder, Vikram Anand, wants to make each
              lifestyle a living worth remembering. The brand brings you an
              experience you never had before. Thar's dynamic area provides
              sustainable technologies such as light control, temperature
              control, and audiovisual control that helps you enjoy a modern
              lifestyle. With the proper amount of warmth and comfort, these
              technologies bring nature into your personalised living areas.
            </h5>
            <img
              src={newsLetterImage2}
              alt=''
              style={{
                display: 'block',
                marginRight: 'auto',
                width: '80%',
                marginTop: '33px',
              }}
            />
          </div>
          <div
            className='left'
            style={{
              display: 'block',
              margin: 'auto',
              width: '50%',
            }}
          >
            <img
              src={newsLetterImage1}
              alt=''
              style={{
                display: 'block',
                margin: 'auto',
                width: '80%',
                marginTop: '40px',
                marginBottom: '80px',
              }}
            />
            <h5
              style={{
                fontFamily: 'montserrat',
                textAlign: 'left',
                fontWeight: '100',
                marginTop: '115px',
                marginLeft: '10px',
              }}
            >
              Thar Technologies is ready to illuminate your home and life with
              the latest and most innovative lighting solutions possible, by
              bringing to you one of the biggest creators of efficient lighting
              solutions, Lutron. Thar Technologies has joined hands with Lutron
              to ensure easy access to “Hard To Find” lighting products. Now you
              can experience world class designs and unbelievable lightning
              control with impressive Lutron products. The future of Lighting is
              here at Thar Technologies.
            </h5>
          </div>
        </div>
        <div
          className='flex'
          style={{
            display: 'block',
            width: '100vw',
            maxWidth: '1100px',
            margin: 'auto',
            marginTop: '130px',
          }}
        >
          <h1
            style={{
              fontFamily: 'montserrat',
              fontSize: '5em',
              textAlign: 'left',
              fontWeight: '100',
            }}
          >
            SPOTLIGHT <br /> PRODUCTS
          </h1>
          <img
            src={newsLetterImage3}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              marginTop: '40px',
              width: '70%',
            }}
          />
          <img
            src={newsLetterImage4}
            alt=''
            style={{
              display: 'block',
              margin: 'auto',
              marginTop: '40px',
              width: '70%',
            }}
          />
          <h3
            style={{
              fontFamily: 'montserrat',
              textAlign: 'center',
              marginTop: '40px',
            }}
          >
            FOR MORE VISIT: {'  '}WWW.THARTECHNOLOGIES.COM
          </h3>
        </div>
        <p
          style={{
            fontFamily: 'montserrat',
            textAlign: 'center',
            fontWeight: '700',
            marginTop: '100px',
          }}
        >
          Jan. 2022 • Newsletter No.1{' '}
        </p>
        <h1
          style={{
            textAlign: 'center',
            fontFamily: 'playfairDisplay',
            marginTop: '30px',
            fontSize: '3.9em',
            fontWeight: '700',
            marginBottom: '-10px',
          }}
        >
          THAR
        </h1>
        <h1
          style={{
            textAlign: 'center',
            fontFamily: 'playfairDisplay',
            marginTop: '0px',
            fontSize: '3.9em',
            fontWeight: '700',
          }}
        >
          TECHNOLOGIES
        </h1>
        <p
          style={{
            fontFamily: 'montserrat',
            textAlign: 'center',
            fontWeight: '700',
            marginTop: '20px',
          }}
        >
          Turn your space into space full of experience
        </p>
        <div
          style={{
            display: 'flex',
            width: '100vw',
            marginTop: '60px',
            maxWidth: '1100px',
            margin: 'auto',
          }}
        >
          <img src={image} alt='' style={{ width: '35%' }} />
          <div
            style={{
              width: '75%',
              background: '#222222',
              paddingTop: '40px',
              paddingBottom: '20px',
            }}
          >
            <p
              style={{
                fontFamily: 'montserrat',
                color: 'white',
                width: '70%',
                margin: 'auto',
              }}
            >
              Thar Technologies, founded in 2008 and headquartered in Bangalore,
              India, has outwitted residential and commercial spaces into not
              just space with lights, willows, and windows but into space full
              of experiences, with a suite of cutting-edge solutions, such as
              home automation and entertainment not limited to residential
              complexes but as well into commercial spaces.
            </p>
            <p
              style={{
                fontFamily: 'montserrat',
                color: 'white',
                width: '70%',
                margin: 'auto',
                marginTop: '5%',
              }}
            >
              The sustainable automations such as light control, shade control
              (in case, you lack on vitamin-D but hate scorching Sun and pills),
              temperature control, audio visual control, and above all energy
              control are some of the highlights that puts an end to all space
              related quagmire. Not just this, imagine a space with dedicated
              home theatres, redefined living area with quintessential surround
              sound system, or even home cinema seating with rare illuminating
              video experiences such as 4K projections and/or laser or DLP
              projections being set-up with couture sense of aesthetics that
              meets your requirement. The outdoor or landscape solutions meets
              the tough weather with dazzling sound and impeccable lightening
              just as if it was existential since the inception of the times.
            </p>
            <p
              style={{
                fontFamily: 'montserrat',
                color: 'white',
                width: '70%',
                margin: 'auto',
                marginTop: '5%',
              }}
            >
              Now these are not just some solutions that paves way into your
              private suites but as well fits into the commercial spaces. Thar’s
              light management solutions are energy efficient, cost effective
              and redefines space flexibility. Whether you own a café or have a
              commercial office complex – Thar has covered the right amount of
              amplification with their offering and even goes out to set-up wall
              or ceiling speakers and outdoor speakers. Thar’s mirror to mirror
              state of art mirror television is a rare mix that provides your
              workspace the extra charm it needed and the premium I-pad docking
              system would make you feel that I-pads were meant for walls but
              not hands
            </p>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            margin: 'auto',
            marginTop: '5%',
            maxWidth: '1200px',
          }}
        >
          <p
            style={{
              fontFamily: 'montserrat',
              width: '30%',
              margin: 'auto',
              marginTop: '5%',
            }}
          >
            Thar Technologies are just the right thing in the right times of the
            right side in the right wing which enhances your experience that may
            have been on the left side of what was left behind
            (unintentionally). Thar technologies founded by Vikram Anand has
            manage to curate all their work into charming experience centre
            available for you to walkaround and experience space full of
            experiences. A CEDIA accredited member – Thar technologies lifestyle
            solution is a step into the future of merry technologies. A company
            which banks on experience are deeply routed with their commitment of
            quality life to your premium lifestyle.
          </p>
          <p
            style={{
              fontFamily: 'montserrat',
              width: '30%',
              margin: 'auto',
              marginTop: '5%',
            }}
          >
            So next time, you think of ambient lightening and immersive
            audio-video experience with right amount of shade of sun-light
            simmering into your room through windows, and temperature that lets
            you settle in the warmth of nature or built a workspace where
            customers and clients detail the outlining of noteworthy contouring
            you have done to redefine spaces – think of the masters in the area
            – think of Thar Technologies – a company that banks on experience
            (don’t forget it).
          </p>
        </div>
        <img
          src={image2}
          alt=''
          style={{
            width: '80vw',
            margin: 'auto',
            display: 'block',
            marginTop: '40px',
            maxWidth: '1200px',
          }}
        />

        <div
          style={{
            display: 'flex',
            width: '100vw',
            margin: 'auto',
            height: !isSmallScreen
              ? !isBigScreen
                ? '250px'
                : '350px'
              : '260px',
            background: 'rgb(30, 30, 30)',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            marginTop: '40px',
          }}
        >
          <div
            className='1'
            style={{
              width: '70%',
              display: 'block',
              marginTop: '2%',
            }}
          >
            <div id='main-heading-footer'>
              <hr className='hr-footer' />
              <h4 id='heading-tag-footer'>Contact Info</h4>
              <hr className='hr-footer' />
            </div>
            <div className='footer-container'>
              <div
                className='part1'
                style={{
                  display: 'block',
                  margin: 'auto',
                  width: '100%',
                  height: '100%',
                  paddingLeft: '4%',
                  marginTop: '20px',
                }}
              >
                <div className='heading' style={{ display: 'flex' }}>
                  <img
                    src={phoneIcon}
                    alt=''
                    style={{
                      width: !isBigScreen ? '13px' : '25px',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}
                  />
                  <p
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      opacity: '90%',
                      fontWeight: '700',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginLeft: '10px',
                      fontSize: !isBigScreen ? '1.3em' : '2em',
                    }}
                  >
                    Phone
                  </p>
                </div>
                <p
                  style={{
                    fontFamily: 'lato',
                    color: 'white',
                    opacity: '50%',
                    fontWeight: '700',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    marginLeft: '10px',
                    fontSize: !isBigScreen ? '1em' : '1.7em',
                    marginTop: '8px',
                  }}
                >
                  +91 80-4111 4599
                </p>
                <p
                  style={{
                    fontFamily: 'lato',
                    color: 'white',
                    opacity: '50%',
                    fontWeight: '700',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    marginLeft: '10px',
                    fontSize: !isBigScreen ? '1em' : '1.7em',
                    marginTop: '4px',
                  }}
                >
                  +91 98450 40642
                </p>
                <p
                  style={{
                    fontFamily: 'lato',
                    color: 'white',
                    opacity: '70%',
                    fontWeight: '700',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    marginLeft: '10px',
                    fontSize: !isBigScreen ? '0.8em' : '1.2em',
                    marginTop: '24px',
                  }}
                >
                  Call us any time from 08:00 till 17:00 Mon-Fri
                </p>
              </div>
              <div
                className='part1'
                style={{
                  display: 'block',
                  margin: 'auto',
                  width: '100%',
                  height: '100%',
                  paddingLeft: '4%',
                  marginTop: '20px',
                }}
              >
                <div className='heading' style={{ display: 'flex' }}>
                  <img
                    src={emailIcon}
                    alt=''
                    style={{
                      width: !isBigScreen ? '23px' : '35px',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}
                  />
                  <p
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      opacity: '90%',
                      fontWeight: '700',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginLeft: '10px',
                      fontSize: !isBigScreen ? '1.3em' : '2em',
                    }}
                  >
                    Email
                  </p>
                </div>
                <p
                  style={{
                    fontFamily: 'lato',
                    color: 'white',
                    opacity: '50%',
                    fontWeight: '700',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    marginLeft: '10px',
                    fontSize: !isBigScreen ? '1em' : '1.7em',
                    marginTop: '18px',
                  }}
                >
                  info@thartechnologies.com
                </p>
                <p
                  style={{
                    fontFamily: 'lato',
                    color: 'white',
                    opacity: '50%',
                    fontWeight: '700',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    marginLeft: '10px',
                    fontSize: !isBigScreen ? '1em' : '1.7em',
                    marginTop: '0px',
                    visibility: 'hidden',
                  }}
                >
                  +91 80-4111 4599
                </p>
                <p
                  style={{
                    fontFamily: 'lato',
                    color: 'white',
                    opacity: '70%',
                    fontWeight: '700',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    marginLeft: '10px',
                    fontSize: !isBigScreen ? '0.8em' : '1.2em',
                    marginTop: '18px',
                  }}
                >
                  Email us any time, we usually reply within 24 hours.
                </p>
              </div>
              <div
                className='part1'
                style={{
                  display: 'block',
                  margin: 'auto',
                  width: '100%',
                  height: '100%',
                  paddingLeft: '4%',
                  marginTop: '20px',
                }}
              >
                <div className='heading' style={{ display: 'flex' }}>
                  <img
                    src={locationIcon}
                    alt=''
                    style={{
                      width: !isBigScreen ? '20px' : '32px',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}
                  />
                  <p
                    style={{
                      fontFamily: 'lato',
                      color: 'white',
                      opacity: '90%',
                      fontWeight: '700',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      marginLeft: '10px',
                      fontSize: !isBigScreen ? '1.3em' : '2em',
                    }}
                  >
                    Address
                  </p>
                </div>
                <p
                  style={{
                    fontFamily: 'lato',
                    color: 'white',
                    opacity: '50%',
                    fontWeight: '700',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    marginLeft: '10px',
                    fontSize: !isBigScreen ? '1em' : '1.7em',
                    marginTop: '8px',
                  }}
                >
                  # 615, 1st Floor , 2nd main ,
                </p>
                <p
                  style={{
                    fontFamily: 'lato',
                    color: 'white',
                    opacity: '50%',
                    fontWeight: '700',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    marginLeft: '10px',
                    fontSize: !isBigScreen ? '1em' : '1.7em',
                    marginTop: '4px',
                  }}
                >
                  Indranagar 1st Stage , Bangalore 560 038
                </p>
                <p
                  style={{
                    fontFamily: 'lato',
                    color: 'white',
                    opacity: '70%',
                    fontWeight: '700',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    marginLeft: '10px',
                    fontSize: !isBigScreen ? '0.8em' : '1.2em',
                    marginTop: '24px',
                  }}
                >
                  Schedule your meeting before visiting
                </p>
              </div>
            </div>
          </div>
          <div className='2' style={{ width: '30%', marginTop: '2%' }}>
            <div
              id='main-heading-footer'
              style={{
                display: 'flex',
                margin: 'auto',
                alignItems: 'center',
                width: !isBigScreen ? '280px' : '320px',
              }}
            >
              <hr
                className='hr-footer'
                style={{ width: '50px', margin: 'auto' }}
              />
              <h4 id='heading-tag-footer'>Social Media</h4>
              <hr
                className='hr-footer'
                style={{ width: '50px', margin: 'auto' }}
              />
            </div>
            <img
              src={facebookIcon}
              alt='..'
              style={{
                display: 'block',
                margin: 'auto',
                marginTop: '40px',
                cursor: 'pointer',
              }}
              onClick={() =>
                window.open('https://www.facebook.com/Thartechnologies/')
              }
            />
            <img
              src={instaIcon}
              alt='..'
              style={{
                display: 'block',
                margin: 'auto',
                marginTop: '20px',
                cursor: 'pointer',
              }}
              onClick={() =>
                window.open(
                  'https://instagram.com/thartechnologies?utm_medium=copy_link'
                )
              }
            />
          </div>
        </div>
        <div
          style={{
            width: '100vw',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            background: 'rgb(10, 10, 10)',
          }}
        ></div>
        <div
          style={{
            width: '100vw',
            height: !isBigScreen ? '70px' : '120px',
            display: 'flex',
            justifyContent: 'center',
            background: 'rgb(35, 35, 35)',
          }}
        >
          <p
            style={{
              margin: 'auto',
              marginLeft: '3%',
              fontFamily: 'lato',
              fontWeight: '700',
              fontSize: !isBigScreen ? '1.1em' : '1.8em',
              color: 'white',
            }}
          >
            © 2022 Thar Technologies
          </p>
          <p
            style={{
              margin: 'auto',
              marginRight: '3%',
              fontFamily: 'lato',
              fontWeight: '700',
              fontSize: !isBigScreen
                ? !isMobile
                  ? '0.9em'
                  : '0.5em'
                : '1.6em',
              color: 'white',
              opacity: '70%',
            }}
          >
            Designed & Developed by Workplay Studio Pvt. Ltd.
          </p>
        </div>
        <hr style={{ color: 'white', margin: '0' }} />
      </div>
    );
  } else {
    return (
      <div>
        <h4
          style={{
            textAlign: 'center',
            fontFamily: 'montserrat',
            fontWeight: '700',
            marginTop: '-70px',
          }}
        >
          MONTHLY NEWSLETTER
        </h4>
        <div
          className='flex'
          style={{
            width: '100vw',
            marginTop: '-40px',
            maxWidth: '1100px',
            margin: 'auto',
            height: 'auto',
          }}
        >
          <p
            style={{
              fontFamily: 'montserrat',
              textAlign: 'center',
              fontWeight: '700',
              marginTop: '0px',
              width: '100%',
            }}
          >
            August. 2022 • Newsletter No.4{' '}
          </p>
          <div className='flex'>
            <img
              src={newsLetterNew}
              alt=''
              style={{
                display: 'block',
                margin: 'auto',
                width: '80%',
                marginTop: '10px',
                marginBottom: '80px',
              }}
            />
          </div>
        </div>
        <h4
          style={{
            textAlign: 'center',
            fontFamily: 'montserrat',
            fontWeight: '700',
            marginTop: '-50px',
          }}
        >
          MONTHLY NEWSLETTER
        </h4>
        <div
          className='flex'
          style={{
            // width: '100vw',
            marginTop: '-40px',
            maxWidth: '1100px',
            margin: 'auto',
            height: 'auto',
          }}
        >
          <p
            style={{
              fontFamily: 'montserrat',
              textAlign: 'center',
              fontWeight: '700',
              marginTop: '20px',
              width: '100%',
            }}
          >
            July. 2022 • Newsletter No.4{' '}
          </p>
          <div className='flex'>
            <img
              src={newsLetterNew1}
              alt=''
              style={{
                display: 'block',
                margin: 'auto',
                width: '80%',
                marginTop: '10px',
                marginBottom: '80px',
              }}
            />
          </div>
        </div>
        <h4
          style={{
            textAlign: 'center',
            fontFamily: 'montserrat',
            fontWeight: '700',
            marginTop: '-40px',
          }}
        >
          MONTHLY NEWSLETTER
        </h4>
        <div
          className='flex'
          style={{
            width: '100vw',
            marginTop: '-40px',
            maxWidth: '1100px',
            margin: 'auto',
            height: 'auto',
          }}
        >
          <p
            style={{
              fontFamily: 'montserrat',
              textAlign: 'center',
              fontWeight: '700',
              marginTop: '20px',
              width: '100%',
            }}
          >
            June. 2022 • Newsletter No.4{' '}
          </p>
          <div className='flex'>
            <img
              src={newsLetterJune}
              alt=''
              style={{
                display: 'block',
                margin: 'auto',
                width: '80%',
                marginTop: '20px',
                marginBottom: '80px',
              }}
            />
          </div>
        </div>
        <div
          className='flex'
          style={{
            width: '100vw',
            marginTop: '-40px',
            maxWidth: '1100px',
            margin: 'auto',
            height: 'auto',
          }}
        >
          <p
            style={{
              fontFamily: 'montserrat',
              textAlign: 'center',
              fontWeight: '700',
              marginTop: '-30px',
              width: '100%',
            }}
          >
            May. 2022 • Newsletter No.3{' '}
          </p>
          <div className='flex'>
            <img
              src={newsLetterMay1}
              alt=''
              style={{
                display: 'block',
                margin: 'auto',
                width: '80%',
                marginTop: '20px',
                marginBottom: '80px',
              }}
            />
          </div>
        </div>
        <p
          style={{
            fontFamily: 'montserrat',
            textAlign: 'center',
            fontWeight: '700',
            // marginTop: '20px',
            // marginTop: '-60px',

            position: 'relative',
            top: '-50px',
          }}
        >
          April. 2022 • Newsletter No.2{' '}
        </p>
        <div className='flex'>
          <img
            src={mobileNewsLetter}
            alt='..'
            style={{ width: '100vw', marginTop: '-90px' }}
          />
        </div>
        <p
          style={{
            fontFamily: 'montserrat',
            textAlign: 'center',
            fontWeight: '700',
            marginTop: '40px',
          }}
        >
          Jan. 2022 • Newsletter No.1{' '}
        </p>
        <h1
          style={{
            textAlign: 'center',
            fontFamily: 'playfairDisplay',
            marginTop: '30px',
            fontSize: '2.7em',
            fontWeight: '700',
            marginBottom: '-10px',
          }}
        >
          THAR
        </h1>
        <h1
          style={{
            textAlign: 'center',
            fontFamily: 'playfairDisplay',
            marginTop: '0px',
            fontSize: '2.7em',
            fontWeight: '700',
          }}
        >
          TECHNOLOGIES
        </h1>
        <p
          style={{
            fontFamily: 'montserrat',
            textAlign: 'center',
            fontWeight: '700',
            marginTop: '20px',
          }}
        >
          Turn your space into space full of experience
        </p>
        <div
          style={{
            width: '100%',
            background: '#222222',
            paddingTop: '40px',
            paddingBottom: '20px',
          }}
        >
          <p
            style={{
              fontFamily: 'montserrat',
              color: 'white',
              width: '80%',
              margin: 'auto',
            }}
          >
            Thar Technologies, founded in 2008 and headquartered in Bangalore,
            India, has outwitted residential and commercial spaces into not just
            space with lights, willows, and windows but into space full of
            experiences, with a suite of cutting-edge solutions, such as home
            automation and entertainment not limited to residential complexes
            but as well into commercial spaces.
          </p>
          <p
            style={{
              fontFamily: 'montserrat',
              color: 'white',
              width: '80%',
              margin: 'auto',
              marginTop: '5%',
            }}
          >
            The sustainable automations such as light control, shade control (in
            case, you lack on vitamin-D but hate scorching Sun and pills),
            temperature control, audio visual control, and above all energy
            control are some of the highlights that puts an end to all space
            related quagmire. Not just this, imagine a space with dedicated home
            theatres, redefined living area with quintessential surround sound
            system, or even home cinema seating with rare illuminating video
            experiences such as 4K projections and/or laser or DLP projections
            being set-up with couture sense of aesthetics that meets your
            requirement. The outdoor or landscape solutions meets the tough
            weather with dazzling sound and impeccable lightening just as if it
            was existential since the inception of the times.
          </p>
          <p
            style={{
              fontFamily: 'montserrat',
              color: 'white',
              width: '80%',
              margin: 'auto',
              marginTop: '5%',
            }}
          >
            Now these are not just some solutions that paves way into your
            private suites but as well fits into the commercial spaces. Thar’s
            light management solutions are energy efficient, cost effective and
            redefines space flexibility. Whether you own a café or have a
            commercial office complex – Thar has covered the right amount of
            amplification with their offering and even goes out to set-up wall
            or ceiling speakers and outdoor speakers. Thar’s mirror to mirror
            state of art mirror television is a rare mix that provides your
            workspace the extra charm it needed and the premium I-pad docking
            system would make you feel that I-pads were meant for walls but not
            hands
          </p>
        </div>
        <p
          style={{
            fontFamily: 'montserrat',
            width: '80%',
            margin: 'auto',
            marginTop: '5%',
          }}
        >
          Thar Technologies are just the right thing in the right times of the
          right side in the right wing which enhances your experience that may
          have been on the left side of what was left behind (unintentionally).
          Thar technologies founded by Vikram Anand has manage to curate all
          their work into charming experience centre available for you to
          walkaround and experience space full of experiences. A CEDIA
          accredited member – Thar technologies lifestyle solution is a step
          into the future of merry technologies. A company which banks on
          experience are deeply routed with their commitment of quality life to
          your premium lifestyle.
        </p>
        <p
          style={{
            fontFamily: 'montserrat',
            width: '80%',
            margin: 'auto',
            marginTop: '5%',
          }}
        >
          So next time, you think of ambient lightening and immersive
          audio-video experience with right amount of shade of sun-light
          simmering into your room through windows, and temperature that lets
          you settle in the warmth of nature or built a workspace where
          customers and clients detail the outlining of noteworthy contouring
          you have done to redefine spaces – think of the masters in the area –
          think of Thar Technologies – a company that banks on experience (don’t
          forget it).
        </p>
      </div>
    );
  }
};

export default InTheMedia;
